import { Box, Flex, Text, Divider, Input, FormControl, FormErrorMessage, HStack, Select, VStack, Checkbox, FormLabel, Switch, Button, Wrap, Collapse, useDisclosure, useColorModeValue, InputGroup, InputLeftAddon, InputRightElement, useToast, IconButton } from "@chakra-ui/react";
import { faLocationArrow, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import getClientAddress from "../../geocode";
import AuthContext from "../Account/AuthContext";
import { AddressType } from "../Garages/EditGarageForm";
import { AvailableServicesContext, AvailableServicesContextType, DbAvailableService } from "../Services/AvailableServices";

export type SearchValues = {
    zip: number,
    addressLineOne: string,
    addressLineTwo: string,
    city: string,
    state: string,
    services: string[],
    instantBook: boolean,
}


export default function UserSearchForm({onSubmit}:{onSubmit:SubmitHandler<SearchValues>}){

    const Auth = useContext(AuthContext);
    const availableServices = useContext(AvailableServicesContext) as AvailableServicesContextType;
    const [fullAddress, setFullAddress] = useState<AddressType>();
    const [posSearch, setPosSearch] = useState(false);
    const { isOpen, onToggle, onOpen } = useDisclosure();
    const { setValue, register, handleSubmit, control, reset, formState: {errors} } = useForm({mode: "onBlur"});
    const [allServices,setAllServices]=useState<DbAvailableService[]>([]);
    const background = useColorModeValue('white', 'gray.600');
    const backgroundText = useColorModeValue("gray.800","white");
    const backgroundTextGray = useColorModeValue("GrayText","white");
    const states=["AL","AK","AZ","AR","CA","CO","CT","DE","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY"];
    const toast = useToast();

    useEffect(()=>{
        if(availableServices.services.length>0){
            setAllServices(availableServices.services);
        }
    },[availableServices.services]);

    useEffect(()=>{
        if(Auth.address !== undefined && Auth.address !== null && Auth.address.length>0){
            setFullAddress(JSON.parse(Auth.address));
        }
    },[])

    useEffect(() => {
        if(fullAddress!== undefined && fullAddress !== null){
            onOpen();
            if(fullAddress.line1) setValue("addressLineOne", fullAddress.line1);
            if(fullAddress.line2) setValue("addressLineTwo", fullAddress.line2);
            if(fullAddress.city) setValue("city", fullAddress.city);
            if(fullAddress.state) setValue("state", fullAddress.state);
            if(fullAddress.zip) setValue("zip", fullAddress.zip, {shouldValidate: true})
        }
    }, [fullAddress])
    

    function getAddress(){
        setPosSearch(true);
        let navigate = navigator.geolocation;
        if (navigate!== undefined){
            navigate.getCurrentPosition(async (pos) => {
                let address = await getClientAddress({latitude: pos.coords.latitude.toString(), longitude: pos.coords.longitude.toString()});
                if(address.country === "US"){
                    onOpen();
                    setFullAddress({line1: `${address.streetNo} ${address.route}`, line2: address.lineTwo, city: address.city, state: address.state, zip: address.zip });
                }
            });
        }
        setPosSearch(false);
    }


    return (
            
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text as="h2" pt="4" color={backgroundText} fontWeight="bold" textAlign="center">Search for a Garage</Text>
                <Divider my="4"/>
                <Box px="6" maxH={["full","50vh"]} minH="300px" overflowX="scroll" pos="relative" >
                    <Flex alignItems="center">
                        <Text color={backgroundText} as="h3" fontWeight="bold" mr="1" >Zip Code</Text>
                        <Text as="span" color={backgroundTextGray} fontSize="sm" >(required)</Text>
                    </Flex>
                    <Text as="p" pb="4" color={backgroundTextGray}  fontSize="sm">Find a garage near you. Add full address below for more accuracy</Text>
                    {/* <FormFieldInput control={control} id="zip" placeholder="e.g. 90210" minLength={5} maxLength={5} required={"Please make sure you enter a 5 digit Zip Code"} /> */}

                    <FormControl isInvalid={errors.zip}>
                        <InputGroup>
                            <Input color={backgroundText} {...register("zip", {required:"Please make sure you enter a 5 digit Zip Code",minLength: 5,maxLength: 5,})} placeholder="e.g. 90210"/>
                            <InputRightElement children={<IconButton aria-label="Find Location" icon={ <FontAwesomeIcon icon={faLocationArrow} /> } isLoading={posSearch} onClick={() => {getAddress()}} />} />
                        </InputGroup>
                        <FormErrorMessage>
                            {errors.zip && errors.zip.message}
                        </FormErrorMessage>
                    </FormControl>

                    {/* <Divider my="6" /> */}

                    {/* <Accordion allowToggle mt="2" defaultIndex={isOpen} >
                        <AccordionItem border="none">
                            <h2 style={{display:"flex", justifyContent:"flex-end"}}>
                            <AccordionButton pr="2" maxW="max-content" borderRadius="full" >
                                <Box flex="1" textAlign="right">
                                <Text fontSize="sm" color="GrayText" >Full Address</Text>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} px={0}> */}
                                {/* <FormFieldInput control={control} id="addressLineOne" placeholder="Address Line One"/> */}
                                {/* <Input {...register("addressLineOne")} placeholder="Address Line One" mb="2" /> */}
                                {/* <FormFieldInput control={control} id="addressLineTwo" placeholder="Address Line Two"/> */}
                                {/* <Input {...register("addressLineTwo")} placeholder="Address Line Two" mb="2" />
                                <HStack > */}
                                    {/* <FormFieldInput control={control} id="city" placeholder="City" maxLength={{ value: 40, message: "Please make sure your name is no longer than 40 characters" }}/> */}
                                    {/* <Input {...register("city", {maxLength: {value: 40, message:"Please make sure your name is no longer than 40 characters" }})} placeholder="City" /> */}
                                    {/* <FormFieldSelect
                                        control={control}
                                        id="state"
                                        children={states.map((state) => <option key={state} value={state}>{state}</option>)}
                                    /> */}
                                    {/* <FormControl>
                                        <Select 
                                            placeholder="State" {...register("state")}>
                                                {states.map((state) => <option key={state} value={state}>{state}</option>)}
                                        </Select>
                                </FormControl>
                                </HStack>
                            </AccordionPanel>
                        </AccordionItem>

                    </Accordion> */}

                    <Flex justify="flex-end" ><Button onClick={onToggle} color={backgroundText} size="sm" mt="2">Full Address</Button></Flex>
                    <Collapse in={isOpen} animateOpacity>
                        <Box mt="2">
                            <Input color={backgroundText} {...register("addressLineOne")} placeholder="Address Line One" mb="2" />
                            <Input color={backgroundText} {...register("addressLineTwo")} placeholder="Address Line Two" mb="2" />
                            <HStack >
                                <Input color={backgroundText} {...register("city", {maxLength: {value: 40, message:"Please make sure your name is no longer than 40 characters" }})} placeholder="City" />
                                <FormControl>
                                    <Select 
                                        placeholder="State" color={backgroundText} {...register("state")}>
                                            {states.map((state) => <option key={state} value={state}>{state}</option>)}
                                    </Select>
                                </FormControl>
                            </HStack>
                        </Box>
                    </Collapse>

                    <Divider my="6" />
                    {allServices.length > 0 && 
                    <VStack alignItems="flex-start" spacing="0">
                        <Text as="h3" fontWeight="bold" color={backgroundText} >Services</Text>
                        <Text as="p" pb="4" fontSize="sm" color={backgroundTextGray}>Filter by garages that provide what you need</Text>
                            <Wrap>
                                {allServices.map((service) => {
                                    return (
                                        <Controller 
                                            key={service.id}
                                            control={control}
                                            name={`services[${service.id}]`}
                                            render={({field:{value, ref, name, onBlur, onChange}}) => (
                                                <Checkbox spacing="0" color={backgroundText} display="flex" justifyContent="space-between" flexDirection="row-reverse" pb={2} w={["calc(100% - 0.5rem)"]} onBlur={onBlur} onChange={onChange} isChecked={!!value} ref={ref} value={service.id} >{service.name}</Checkbox>
                                            )}
                                        />
                                    )
                                })}
                            </Wrap>
                    </VStack>
                    }
                    {/* {allServices.length > 0 && 
                    <VStack alignItems="flex-start" spacing="0">
                        <Text as="h3" fontWeight="bold">Services</Text>
                        <Text as="p" pb="4" fontSize="sm" color="GrayText">Filter by garages that provide what you need</Text>
                        <FormControl>
                            <Wrap>
                                {allServices.map((service) => {
                                    return (
                                        <Checkbox pb={2} spacing="0" display="flex" justifyContent="space-between" flexDirection="row-reverse" w={["calc(100% - 0.5rem)"]} {...register("services")} isChecked={} key={service.id}>{service.name}</Checkbox>
                                    )
                                })}
                            </Wrap>
                        </FormControl>
                    </VStack>} */}

                    <Divider my="6" />
                    <Controller
                        control={control}
                        name={"instantBook"}
                        render={({field}) => (
                            <Flex justify="space-between" alignItems="center" >
                                <Box mr="4" >
                                    <FormLabel m="0" color={backgroundText} fontWeight="bold" htmlFor={"instantBook"}>Instant Book</FormLabel>
                                    <Text color={backgroundTextGray} fontSize="sm" as="p">Book without waiting for the garage to respond</Text>
                                </Box>
                                <Switch id={"instantBook"} {...field} size="lg" colorScheme="green" isChecked={!!field.value} />
                            </Flex>
                        )}
                    />
                    {/* <Flex justify="space-between" alignItems="center" >
                        <Box mr="4" >
                            <FormLabel m="0" fontWeight="bold" htmlFor={"instantBook"}>Instant Book</FormLabel>
                            <Text color="GrayText" fontSize="sm" as="p">Book without waiting for the garage to respond</Text>
                        </Box>
                        <Switch {...register("instantBook")} size="lg" colorScheme="green" />
                    </Flex> */}
                </Box>
                <Divider my="4"/>
                <HStack pb="4" mx="4">
                    <Button w="50%" borderRadius="full" variant="outline" onClick={() => reset({instantBook:false})} >Clear</Button>
                    <Button w="50%" borderRadius="full" type="submit" ><FontAwesomeIcon color={backgroundText} icon={faSearch}/></Button>
                </HStack>
    </form>

    )
}