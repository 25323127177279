import { Box, chakra, Flex, Skeleton, Image, Link, useDisclosure, LinkBox, LinkOverlay, IconButton, Tooltip, useColorModeValue } from "@chakra-ui/react";
import firebase, { db } from "../../firebase";
import React, { useEffect, useState } from "react";
import DbAttachment from "../Attachments/DbAttachment";
import DbGarage, { DbGarageImage } from "./DbGarage";

import placeHolder from '../../placeholder.png'
import Reviews from "./Reviews";
import { AddressType } from "./EditGarageForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import GarageFull from "./GarageFull";
import moment from "moment";

export default function GarageSearchResultCard({garage, bookGarage}:{garage:DbGarageImage, bookGarage: Function}){

    const [imageUrls,setImageUrls]=useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const background = useColorModeValue('white', 'gray.700');
    const textColor = useColorModeValue("gray.900", "white");

    /** get all the image urls from the database */
    function getImages() {
        const garageImagesSubCollection=db.collection("garages").doc(garage.id).collection("images");
        const unregister=garageImagesSubCollection.onSnapshot(
            (querySnapshot)=>{
                const items:DbAttachment[]=[];
                querySnapshot.forEach((doc)=>{
                    items.push(doc.data() as DbAttachment);
                });
                items.sort((a,b)=>a.order-b.order);
                setImageUrls(items.map(item=>item.url));
                unregister();
            },
            (error:any) => {
                console.error("GarageSearchResultCard.tsx: Error getting images for garage",garage.id);
                console.error(error);
            }
        );
        setLoading(false);
    }

    // this is set up to run once on component creation
    useEffect(()=>{
        getImages();
    },[]);

    // submit the selected garage to continue booking
    function submitResult(){
        const updatedGarage:DbGarageImage = {...garage, imageUrls} 
        bookGarage(updatedGarage);
    }

    /** format drive time as a nice string */
    function driveTime(t?:number){
        if(t===undefined) return '';
        let h=Math.trunc(t/60);
        let m=t%60;
        if(h>0) return `( ${h} hr ${m} min )`;
        return `( ${m} min )`;
    }

    function addressToString(address:AddressType,multiline:boolean=false){
    if(multiline){
        return [address.line1,address.line2,[[address.city,address.state].join(', '),address.zip].join(' ')].join('\n');
    }
    return [address.line1,address.line2,[address.city,address.state].join(', '),address.zip].join(' ');
}

    function jsonAddressToString(addressJson:string,multiline:boolean=false){
    if(addressJson===undefined||addressJson===''){
        return '';
    }
    var address=JSON.parse(addressJson) as AddressType;
    return addressToString(address,multiline);
}

    /** get a url link that a mobile device is most likely to pop out into a map app */
    function getMapUrl(){
        // determine what to use
        var address=encodeURIComponent(jsonAddressToString(garage.address));
        var mapAgent='google';
        if(navigator.userAgent.includes("Mac")){
            mapAgent='apple';
        }
        if(navigator.userAgent.includes("Android")){
            mapAgent='google';
        }
        if(navigator.userAgent.includes("Windows")){
            mapAgent='bing';
        }
        // now create a url based upon that
        var url='';
        if(mapAgent==='apple'){
            // see: https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
            url=`https://maps.apple.com/?daddr=${address}&dirflg=d&t=h`;
        }else if(mapAgent==='bing'){
            // see: https://docs.microsoft.com/en-us/bingmaps/articles/create-a-custom-map-url
            url=`https://bing.com/maps/default.aspx?rtp=~adr.${address}&ss=${garage.name}`;
        }else{ // use google maps
            // see: https://developers.google.com/maps/documentation/urls/get-started
            url=`https://www.google.com/maps/dir/?api=1&destination=${address}&destination_place_id=${garage.name}`;
        }
        return url;
    }

    return (
        <>
        <Flex
            shadow="lg"
            rounded="lg"
            overflow="hidden"
            maxW="md"
            bgColor={background}
        >
            <Box w={1 / 3} pos="relative">
            {garage.instantBook && <Flex borderRadius="full" align="center" justify="center" w="7" h="7" bgColor="#79B85F" pos="absolute" top="2" left="2"><FontAwesomeIcon color="#386126" icon={faBolt} /></Flex>}
            {loading
            ? <Skeleton h="full" />
            : imageUrls.length>0
                ? <Box
                    h="full"
                    bgSize="cover"
                    style={{
                        backgroundImage:`url(${imageUrls[0]})`,
                    }}
                    ></Box>
                : <Image h="200px" maxH="min-content" fit="cover" src={placeHolder} />
            }
            </Box >
            <LinkBox w={2 / 3} p={{ base: 4, md: 4 }} cursor={'pointer'} pos="relative">
                <LinkOverlay onClick={() => onOpen()}>
                    <chakra.h1 color={textColor} noOfLines={2} fontWeight="bold">{garage.name}</chakra.h1>
                </LinkOverlay>
                <Reviews numStars={garage.numStars} numReviews={garage.numReviews} reviewsUrl={garage.reviewsUrl} />

                <chakra.p color={textColor} mt={2} noOfLines={2} fontSize="xs">{garage.description}</chakra.p>

                <Flex mt={3} alignItems="center" justifyContent="space-between">
                    <Link as="a" href={getMapUrl()} >
                        <chakra.h3 color={textColor} ml="-3" _hover={{backgroundColor:"#f0f0f0"}} fontWeight="bold" py="1" px="3" borderRadius="full" fontSize="sm">{garage.distance ? `${(garage.distance / 1.60934).toFixed(1)} miles`: ""} </chakra.h3>
                    </Link>
                </Flex>
                <chakra.h3 fontSize="sm">{garage.driveTime  ? moment.utc(moment.duration(garage.driveTime, "minutes").asMilliseconds()).format("H") + ' h ' +  moment.utc(moment.duration(garage.driveTime, "minutes").asMilliseconds()).format("mm") + ' mins' : ''}</chakra.h3>
                <Tooltip label={`Book ${garage.name}`} aria-label={`Book ${garage.name}`}>
                    <IconButton onClick={() => submitResult()} pos="absolute" bottom="3" right="3" borderRadius="full" colorScheme="blue" aria-label="Book Appointment" icon={<FontAwesomeIcon icon={faCalendarAlt} />} />
                </Tooltip>

            </LinkBox>
        </Flex>
        <GarageFull isOpen={isOpen} onClose={onClose} editMode={false} garage={garage} bookGarage={submitResult} />
        </>
    )
}