import Geocode from 'react-geocode';

interface ClientAddress {
    streetNo: string,
    route: string,
    lineTwo: string,
    city: string,
    state: string,
    zip: string,
    country: string,
}

async function getClientAddress({latitude, longitude}: {latitude: string, longitude: string}):Promise<ClientAddress>{
    let clientAddress:ClientAddress = {
        streetNo: '',
        route: '',
        lineTwo: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    };
    Geocode.setApiKey("AIzaSyD8KYu3kLeFuaz-X7IS12QorqBG9vqle3k");
    await Geocode.fromLatLng(latitude, longitude).then(
        (response) => {
            console.log(response);
            for (let i = 0; i < response.results[0].address_components.length; i++) {
                for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                    switch (response.results[0].address_components[i].types[j]) {
                    case "street_number":
                        clientAddress.streetNo = response.results[0].address_components[i].long_name; 
                        break;
                    case "route":
                        clientAddress.route = response.results[0].address_components[i].long_name;
                        break;
                    case "locality":
                        clientAddress.city = response.results[0].address_components[i].long_name;
                        break;
                    case "administrative_area_level_2":
                        clientAddress.lineTwo = response.results[0].address_components[i].long_name;
                        break;
                    case "administrative_area_level_1":
                        clientAddress.state = response.results[0].address_components[i].short_name;
                        break;
                    case "country":
                        clientAddress.country = response.results[0].address_components[i].short_name;
                        break;
                    case "postal_code":
                        clientAddress.zip = response.results[0].address_components[i].long_name;
                        break;
                    }
                }
            }
        },
        (error) => {
            console.error(error);
        }
    );
    return clientAddress;
}

export default getClientAddress;