import React, {useState} from 'react';

import { useImage } from '../Hooks/LoadImage';

import styled from 'styled-components';
import Loader from '../Common/Loader';


const StyledCarousel = styled.section`
    /* padding-top: 75%; */
    /* filter: drop-shadow(0 0 10px #0003); */
    position: relative;
    box-shadow: 0 0 10px #0003;
    background-color: #dddddd;
    width: 90%;
    padding-top: 90%;
    margin: 0.5em 5%;
    border-radius: 10px;

    &:before {   
        /* left: -1rem; */
        /* top: 0; */
        /* margin-top: 3rem; */
        position: absolute;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 0;
        outline: 0;
        left: calc(-5% - 25px);
        top: 50%;
        transform: translate(0, -50%);
        content: '';
        z-index: 1;
        background-color: rgba(0,0,0,0.5);
        background-size: 1.0rem 1.0rem;
        background-repeat: no-repeat;
        background-position: center center;
        color: white;
        font-size: 2.5rem;
        line-height: 4rem;
        text-align: center;
        pointer-events: none;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
    }

    &:after {   
        /* top: 0; */
        /* right: -1rem; */
        /* margin-top: 3rem; */
        position: absolute;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 0;
        outline: 0;
        right: calc(-5% - 25px);
        top: 50%;
        transform: translate(0, -50%);
        content: '';
        z-index: 1;
        background-color: rgba(0,0,0,0.5);
        background-size: 1.0rem 1.0rem;
        background-repeat: no-repeat;
        background-position: center center;
        color: white;
        font-size: 2.5rem;
        line-height: 4rem;
        text-align: center;
        pointer-events: none;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
    }
`;

const CarouselImage = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
    top: 0;
    left: 0;
`;

const CarouselViewport = styled.div`
    /* position: absolute; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
`;

const CarouselSlide = styled.div`
    /* position: relative; */
    flex: 0 0 100%;
    width: 100%;
    overflow: clip;
`;

const CarouselPrevious = styled.div`
    /* top: 0; */
    /* margin-top: 3rem; */
    /* left: -1rem; */
    position: absolute;
    left: calc(-5% - 25px);
    top: 50%;
    transform: translate(0, -50%);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 0;
    outline: 0;
    cursor: pointer;
`;

const CarouselNext = styled.div`
    /* right: -1rem; */
    /* top: 0; */
    /* margin-top: 3rem; */
    position: absolute;
    right: calc(-5% - 25px);
    top: 50%;
    transform: translate(0, -50%);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 0;
    outline: 0;
    cursor: pointer;
`;


interface ImageCarouselProps{
    id:string;
    images:string[];
};


// unique id is used so that there can be multiple carousels on the same page
function ImageCarousel({id,images}:ImageCarouselProps) {

    const [currentSlide,setCurrentSlide]=useState(0);

    const { hasLoaded } = useImage(images[currentSlide]);

    /** advance to the next slide / go forward */
    function nextSlide(){
        setCurrentSlide((currentSlide+1)%images.length);
    }

    /** return to the previous slide / go back */
    function previousSlide(){
        setCurrentSlide((currentSlide-1+images.length)%images.length);
    }

    return (
        <StyledCarousel aria-label="Gallery">
            <CarouselViewport>
                <CarouselSlide>
                    {!hasLoaded && <Loader />}
                    {hasLoaded && <CarouselImage src={images[currentSlide]} alt={images[currentSlide]} />}
                    <CarouselPrevious onClick={previousSlide}>Previous image</CarouselPrevious>
                    <CarouselNext onClick={nextSlide}>Next image</CarouselNext>
                </CarouselSlide>
            </CarouselViewport>
        </StyledCarousel>);
}


export default ImageCarousel;