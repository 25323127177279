import { Box, Button, FormLabel, InputGroup, InputLeftAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import firebase, { functions, db, storage } from '../../firebase';
import Loading from '../Common/Loading';
import { EditProps } from './MyClassifieds';
import { FormFieldCheckbox, FormFieldInput, FormFieldTextArea } from '../Form/FormFields';
import FileInput from '../Form/FileInput';
import { ClassifiedFormValues, ClassifiedListing, ClassifiedListingFull, StorageImageData, UploadResponse } from './AddClassifiedsForm';
import AuthContext from '../Account/AuthContext';
import FileInputEdit from '../Form/FileInputEdit';

export interface ClassifiedEdit extends ClassifiedFormValues{
    id:string;
}


export const EditMyClassifiedModal = (
    {isOpen, onCloseDialog, onComplete, editState}:
    {isOpen:boolean, onCloseDialog:()=>void, onComplete:(classified: Partial<ClassifiedListing>|undefined) => void, editState:EditProps}) => {

    const methods = useForm({mode: "onBlur"});
    const { handleSubmit, control, formState:{isDirty} } = methods;
    const Auth = useContext(AuthContext);
    // const editClassified = functions.httpsCallable('patchClassified')
    const classifiedRef=db.collection("classifieds");
    const storageFolder = 'classifieds';
    // const [editedListing, setEditiedListing] = React.useState<Partial<ClassifiedListing>|undefined>(undefined)
    const [allImages, setAllImages] = React.useState<StorageImageData[]|undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(false);
    const toast = useToast();

    React.useEffect(() => {
        if(editState.data?.images){
            setAllImages(editState.data.images)
        }
    }, [editState.data])
    

    async function onSubmit(values:ClassifiedFormValues) {
        setLoading(true)
        const newEditedListing:Partial<ClassifiedListing> = {id:editState.data?.id}
        if(Auth.isGarage && Auth.garageId && editState.data?.id){
            try {
                const batch = db.batch()
                const docRef = classifiedRef.doc(editState.data.id)
                const fullRef = docRef.collection('full').doc('data')
                const images = values.photos
                delete values.photos
                const form:ClassifiedEdit = values as ClassifiedEdit
                form.id = editState.data.id

                let updateObj = {}
                let fullDataObj = {}

                if(values.category && values.category !== editState.data.category){
                    updateObj = {...updateObj, ...{category:values.category}}
                    newEditedListing.category = values.category
                }
                if(values.title !== editState.data.title){
                    updateObj = {...updateObj, ...{title:values.title}}
                    newEditedListing.title = values.title
                }
                if(values.description !== editState.data.description && values.description){
                    const newSummary = trimText(values.description)
                    updateObj = {...updateObj, ...{summary:newSummary}}
                    fullDataObj = {...fullDataObj, ...{description:values.description}}
                    newEditedListing.summary = newSummary
                }
                if(values.price !== editState.data.price){
                    updateObj = {...updateObj, ...{price:values.price}}
                    newEditedListing.price = values.price
                }
                if(values.contact !== editState.data.contact){
                    fullDataObj = {...fullDataObj, ...{contact:values.contact}}
                }

                if(images && images.length>0) {
                    const uploadedImages:StorageImageData[] = await uploadAttachments(editState.data.id,images)

                    if(!allImages){
                        newEditedListing.thumbUrl = uploadedImages[0].thumbUrl
                        updateObj = {...updateObj, ...{thumbUrl:uploadedImages[0].thumbUrl}}
                        fullDataObj = {...fullDataObj, ...{images:uploadedImages}}
                    } 

                    if(allImages){
                        if(allImages.length === 0){
                            newEditedListing.thumbUrl = uploadedImages[0].thumbUrl
                            fullDataObj = {...fullDataObj, ...{images:uploadedImages}}
                        } else{
                            batch.update(fullRef,{images: firebase.firestore.FieldValue.arrayUnion(...uploadedImages)})
                        }
                    }
                } else {
                    if(allImages){
                        newEditedListing.thumbUrl = allImages[0].thumbUrl
                    }
                }
                const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
                updateObj = {...updateObj, ...{updatedAt:updatedAt}}
                batch.update(docRef, updateObj)
                batch.update(fullRef, fullDataObj)
                await batch.commit()
                .then(() => {
                    setLoading(false)
                    onComplete(newEditedListing)
                    // onCloseDialog()
                })

            } catch (error) {
                setLoading(false)
                console.log(error)
            }
        }
    }

    async function removeImageFn(image:StorageImageData) {
        if(Auth.isGarage && Auth.garageId && allImages && editState.data?.id){
            try {
                const batch = db.batch()
                const docRef = classifiedRef.doc(editState.data.id)
                const fullRef = docRef.collection('full').doc('data')
                const index = allImages.indexOf(image);
                let newImages = allImages;
                if (index > -1) {
                    newImages.splice(index, 1);
                }
                setAllImages(newImages);
                let nextThumb = ''
                if(newImages.length>0){
                    nextThumb = newImages[0].thumbUrl
                }
                const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
                batch.update(docRef,{thumbUrl:nextThumb,updatedAt:updatedAt})
                batch.update(fullRef,{images: firebase.firestore.FieldValue.arrayRemove(image)})
                const storageUrl=`${storageFolder}/${editState.data.id}/images/${image.filepath}`;
                const thumbUrl=`${storageFolder}/${editState.data.id}/images/thumbs/thumb_${image.filepath}`;
                const storageRef = storage.ref(storageUrl)
                const thumbRef = storage.ref(thumbUrl)
                await batch.commit()
                await storageRef.delete();
                await thumbRef.delete();
            } catch (error) {
                console.log(error)
            }
        }
    }


    async function uploadAttachments(id:string,photos:File[]) {
        const images:StorageImageData[] = []
        const thumb = functions.httpsCallable('thumbTest')
        for(let i=0;i<photos.length;i++){
            let photo=photos[i];
            const name = photo.name.substring(0, photo.name.lastIndexOf('.')) || photo.name;
            const storageRef=`${storageFolder}/${id}/images/${photo.name}`;
            const url = await firebaseStorageUploader(storageRef, photo)
            const thumbUrl = await thumb({bucket:url.bucket, path: storageRef})
            let attachment:StorageImageData={
                fileName:name,
                filepath:photo.name,
                pathReference:storageRef,
                size:photo.size,
                type:photo.type,
                url:url.downloadUrl,
                thumbUrl:thumbUrl.data,
                order:i+1
            };
            images.push(attachment)
        }
        return images
    }

    async function firebaseStorageUploader(path:string,data:File|Blob|Uint8Array|ArrayBuffer) {
        return new Promise<UploadResponse>(function(resolve, reject){
            const storageRef=storage.ref(path);
            const uploadTask=storageRef.put(data);
            uploadTask.on('state_changed',
                function(snapshot) {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    // setUploadPer(progress)
                },
                function error(err:any) {
                    console.error('error', err)
                    // setLoading(false)
                    toast({
                        title:'Upload Error',
                        description:'There was an error uploading the image(s)',
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                    })
                    reject()
                },
                async function complete() {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        const bucket = uploadTask.snapshot.ref.bucket
                        resolve({bucket:bucket,downloadUrl:downloadURL})
                    })
                }
            )
        })
    }

    function trimText(description:string):string{
        const summaryLength = 100;
        if(description.length>summaryLength){
            return description.substring(0, summaryLength)
        } else {
            return description
        }
    }


    return (
        <Modal
            isOpen={isOpen}
            onClose={onCloseDialog}>
            <ModalOverlay/>
                <ModalContent>

                    <ModalHeader fontSize='lg' fontWeight='bold'>Edit Listing</ModalHeader>
                    <ModalCloseButton onClick={onCloseDialog}/>
                    <ModalBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {
                            editState.loading && <Loading height={'200px'}/>
                        }
                        {
                            editState.data && 
                            
                                <VStack spacing={4}>
                                    <FormFieldInput 
                                        pb={'4'}
                                        control={control}
                                        text={'Title'}
                                        id={'title'}
                                        defaultValue={editState.data.title}
                                    />
                                    <FormFieldTextArea
                                        pb={'4'}
                                        control={control}
                                        text={'Description'}
                                        id={'description'}
                                        defaultValue={editState.data.description}
                                    />
                                    <Box w={'full'}>
                                        <FormLabel htmlFor={'price'}>Price</FormLabel>
                                        <InputGroup>
                                            <InputLeftAddon children='$' />
                                            <FormFieldInput
                                                pb={'4'}
                                                control={control}
                                                id={'price'}
                                                defaultValue={editState.data.price}
                                            />
                                        </InputGroup>
                                    </Box>
                                    <Box mb={"3em"} mt={2} w={'full'}>
                                        <FormFieldCheckbox 
                                            pb={'0'} 
                                            control={control} 
                                            id={'contact'} 
                                            text={'Add Contact Info'} 
                                            placeholder={'Add existing contact details from file?'} 
                                            value={'contact'} 
                                            defaultValue={editState.data.contact}
                                            index={0} 
                                        />
                                    </Box>
                                    <FormProvider {...methods}>
                                        <Box as="section" w="full">
                                            <FileInputEdit
                                                formContext={methods}
                                                deleteExistingFn={removeImageFn}
                                                existingImages={allImages}
                                                accept="image/png, image/jpg, image/jpeg"
                                                name="photos"
                                                label="Photos"/>
                                        </Box>
                                    </FormProvider>
                                </VStack>
                        }
                        <ModalFooter px={'0'}>
                            <Button onClick={onCloseDialog}>Cancel</Button>
                            <Button disabled={!isDirty} isLoading={loading} type='submit' colorScheme={'blue'} ml={3}>Submit</Button>
                        </ModalFooter>
                        </form>
                    </ModalBody>
                    
                </ModalContent>
        </Modal>
  )
}
