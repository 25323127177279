import React from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Text, Box, useColorModeValue } from "@chakra-ui/react";



/** help text for sharing our calendar with others */
export function ShareCalendarHelp(){
    return (<>
            <Accordion allowToggle w="full">
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]} >
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')} >
                                    Google Calendar
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'ol'} color={'gray.500'}>
                                <li>On your computer, open <a href="https://calendar.google.com/">Google Calendar</a>.</li>
                                <li>On the left, next to "Other calendars," click add (+) button, "Add other calendars", and then "From URL".</li>
                                <li>Paste the address from the box above.</li>
                                <li>Click "Add calendar". The calendar appears on the left, under "Other calendars."</li>
                                <li>Tip: It might take up to 12 hours for changes to show in your Google Calendar.</li>
                            </Text>
                        </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]}>
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')}>
                                    Apple Calendar
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'ol'} color={'gray.500'}>
                                <li>In the Calendar app on your Mac, choose File &gt; New Calendar Subscription.</li>
                                <li>Paste the address from the box above, then click Subscribe.</li>
                                <li>Enter a name for the calendar in the Name field, then click the adjacent pop-up menu and choose a color.</li>
                                <li>Click the Location pop-up menu, then choose an account for the subscription.</li>
                                <ul>
                                    <li>If you choose your iCloud account, the calendar is available on all your computers and devices that are set up with iCloud.</li>
                                    <li>If you choose On My Mac, the calendar is saved on your computer.</li>
                                </ul>
                                <li>To get the calendar’s event attachments or alerts, deselect the appropriate Remove checkboxes.</li>
                                <li>Click the Auto-refresh pop-up menu, then choose how often to update the calendar.</li>
                                <li>To prevent alerts from appearing for this calendar, select “Ignore alerts.”</li>
                                <li>Click OK.</li>
                            </Text>
                        </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]}>
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')}>
                                    Outlook Calendar
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'ol'} color={'gray.500'}>
                                <li><a href="https://go.microsoft.com/fwlink/p/?linkid=843379">Sign in to Outlook.com</a>.</li>
                                <li>At the bottom of the page, select the calendar icon.</li>
                                <li>In the navigation pane, select "Add calendar".</li>
                                <li>Select "Subscribe from web".</li>
                                <li>Paste the link from the box above.</li>
                                <li>Select "Import".</li>
                                <li>Note: When you subscribe to a calendar, your calendar will automatically refresh if the other calendar is updated. This can sometimes take more than 24 hours.</li>
                            </Text> 
                        </AccordionPanel>
                </AccordionItem>
            </Accordion>
    </>);
}

/** help with sharing another calendar with us */
export function SharedCalendarHelp(){
    return (<>
            <Accordion allowToggle w="full">
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]} >
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')} >
                                    Google Calendar
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'ol'} color={'gray.500'}>
                                <li>On your computer, open <a href="https://calendar.google.com/">Google Calendar</a>.</li>
                                <li>In the top right, click Settings (gear icon) and then "Settings".</li>
                                <li>On the left panel, under "Settings for my calendars" click the name of the calendar you want to use.</li>
                                <li>Click "Integrate calendar".</li>
                                <li>In the "Secret address in iCal format" section, copy the link.</li>
                                <li>Paste the link into the box above.</li>
                            </Text>
                        </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]}>
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')}>
                                    Apple Calendar
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'ol'} color={'gray.500'}>
                                <li>Go to Calendar on <a href="https://www.icloud.com/calendar">iCloud.com</a></li>
                                <li>Click "Share Calendar" to the right of the calendar name in the sidebar, then select "Public Calendar".</li>
                                <li>To invite people to view the calendar, click "Email Link".</li>
                                <li>Enter your own email addres, then click Send.</li>
                                <li>Once you recieve the email, find calendar’s URL and paste it into the box above.</li>
                            </Text>
                        </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]}>
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')}>
                                    Outlook Calendar
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'ol'} color={'gray.500'}>
                                <li><a href="https://go.microsoft.com/fwlink/p/?linkid=843379">Sign in to Outlook.com</a>.</li>
                                <li>In Calendar view, select Settings (gear icon) &gt; "View all Outlook settings".</li>
                                <li>Select "Calendar" &gt; "Shared calendars".</li>
                                <li>Under "Publish a calendar", choose which calendar you want to publish and how much detail people can see.</li>
                                <li>Select "Publish".</li>
                                <li>Paste the ICS link resulting from this process into the box above.</li>
                            </Text> 
                        </AccordionPanel>
                </AccordionItem>
            </Accordion>

    </>);
}




//ORIGINAL

// /** help text for sharing our calendar with others */
// export function ShareCalendarHelp(){
//     return (<>
//         <Twirlie expanded={false}>Howto - add to Google calendar
//         <div>
//             <ol>
//             <li>On your computer, open <a href="https://calendar.google.com/">Google Calendar</a>.</li>
//             <li>On the left, next to "Other calendars," click add (+) button, "Add other calendars", and then "From URL".</li>
//             <li>Paste the address from the box above.</li>
//             <li>Click "Add calendar". The calendar appears on the left, under "Other calendars."</li>
//             <li>Tip: It might take up to 12 hours for changes to show in your Google Calendar.</li>
//             </ol>
//             <Indent>[<a href="https://support.google.com/calendar/answer/37100?hl=en&co=GENIE.Platform%3DDesktop#calendar">official Google documentation</a>]</Indent>
//         </div>
//         </Twirlie>
//         <Twirlie expanded={false}>Howto - add to Mozilla Thunderbird / Ligntning calendar
//         <div>
//             <ol>
//             <li>switch to your calendar view and hit the + button in the left menu to add a new calendar</li>
//             <li>select "on the network" and press next</li>
//             <li>select "iCalendar (ICS)" and paste the link above into the "location" box.</li>
//             <li>finish the wizard according to your personal preferences</li>
//             </ol>
//             <Indent>[<a href="https://support.mozilla.org/en-US/kb/creating-new-calendars#w_icalendar-ics">official Mozilla documentation</a>]</Indent>
//         </div>
//         </Twirlie>
//         <Twirlie expanded={false}>Howto - add to Apple iCalendar
//         <div>
//             <ol>
//             <li>In the Calendar app on your Mac, choose File &gt; New Calendar Subscription.</li>
//             <li>Paste the address from the box above, then click Subscribe.</li>
//             <li>Enter a name for the calendar in the Name field, then click the adjacent pop-up menu and choose a color.</li>
//             <li>Click the Location pop-up menu, then choose an account for the subscription.</li>
//             <ul>
//                 <li>If you choose your iCloud account, the calendar is available on all your computers and devices that are set up with iCloud.</li>
//                 <li>If you choose On My Mac, the calendar is saved on your computer.</li>
//             </ul>
//             <li>To get the calendar’s event attachments or alerts, deselect the appropriate Remove checkboxes.</li>
//             <li>Click the Auto-refresh pop-up menu, then choose how often to update the calendar.</li>
//             <li>To prevent alerts from appearing for this calendar, select “Ignore alerts.”</li>
//             <li>Click OK.</li>
//             </ol>
//             <Indent>[<a href="https://support.apple.com/guide/calendar/subscribe-to-calendars-icl1022/mac">official Apple documentation</a>]</Indent>
//         </div>
//         </Twirlie>
//         <Twirlie expanded={false}>Howto - add to Microsoft Outlook calendar
//         <div>
//             <ol>
//             <li><a href="https://go.microsoft.com/fwlink/p/?linkid=843379">Sign in to Outlook.com</a>.</li>
//             <li>At the bottom of the page, select the calendar icon.</li>
//             <li>In the navigation pane, select "Add calendar".</li>
//             <li>Select "Subscribe from web".</li>
//             <li>Paste the link from the box above.</li>
//             <li>Select "Import".</li>
//             <li>Note: When you subscribe to a calendar, your calendar will automatically refresh if the other calendar is updated. This can sometimes take more than 24 hours.</li>
//             </ol>
//             <Indent>[<a href="https://support.microsoft.com/en-us/office/import-or-subscribe-to-a-calendar-in-outlook-com-cff1429c-5af6-41ec-a5b4-74f2c278e98c">official microsoft documentation</a>]</Indent>
//         </div>
//         </Twirlie>
//     </>);
// }

// /** help with sharing another calendar with us */
// export function SharedCalendarHelp(){
//     return (<>
//         <Twirlie expanded={false}>Howto - add a Google calendar
//         <div>
//             <ol>
//             <li>On your computer, open <a href="https://calendar.google.com/">Google Calendar</a>.</li>
//             <li>In the top right, click Settings (gear icon) and then "Settings".</li>
//             <li>On the left panel, under "Settings for my calendars" click the name of the calendar you want to use.</li>
//             <li>Click "Integrate calendar".</li>
//             <li>In the "Secret address in iCal format" section, copy the link.</li>
//             <li>Paste the link into the box above.</li>
//             </ol>
//             <Indent>[<a href="https://support.google.com/calendar/answer/37648?hl=en#zippy=%2Cget-your-calendar-view-only">official Google documentation</a>]</Indent>
//         </div>
//         </Twirlie>
//         <Twirlie expanded={false}>Howto - add a Apple iCalendar
//         <div>
//             <ol>
//             <li>Go to Calendar on <a href="https://www.icloud.com/calendar">iCloud.com</a></li>
//             <li>Click "Share Calendar" to the right of the calendar name in the sidebar, then select "Public Calendar".</li>
//             <li>To invite people to view the calendar, click "Email Link".</li>
//             <li>Enter your own email addres, then click Send.</li>
//             <li>Once you recieve the email, find calendar’s URL and paste it into the box above.</li>
//             </ol>
//             <Indent>[<a href="https://support.apple.com/guide/icloud/share-a-calendar-mm6b1a9479/1.0/icloud/1.0#mmcc732d5e">official Apple documentation</a>]</Indent>
//         </div>
//         </Twirlie>
//         <Twirlie expanded={false}>Howto - add a Microsoft Outlook calendar
//         <div>
//             <ol>
//             <li><a href="https://go.microsoft.com/fwlink/p/?linkid=843379">Sign in to Outlook.com</a>.</li>
//             <li>In Calendar view, select Settings (gear icon) &gt; "View all Outlook settings".</li>
//             <li>Select "Calendar" &gt; "Shared calendars".</li>
//             <li>Under "Publish a calendar", choose which calendar you want to publish and how much detail people can see.</li>
//             <li>Select "Publish".</li>
//             <li>Paste the ICS link resulting from this process into the box above.</li>
//             </ol>
//             <Indent>[<a href="https://support.microsoft.com/en-us/office/share-your-calendar-in-outlook-com-0fc1cb48-569d-4d1e-ac20-5a9b3f5e6ff2?ui=en-us&rs=en-us&ad=us">official Microsoft documentation</a>]</Indent>
//         </div>
//         </Twirlie>
//     </>);
// }