import { Alert, Button, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';
import React from 'react';
import { NavLink, Redirect, RouteProps } from 'react-router-dom';
import Header from '../Navigation/HeaderLisa';
import { auth, functions } from "../../firebase";

interface LoginProps {
    /** Magical way to get cgi query parameters part of the url */
    location: RouteProps["location"];
};

/** Component to be shown AFTER firebase email confirmation has succeeded.
 */
function EmailConfirm({location}:LoginProps) {

    const query = new URLSearchParams(location===undefined?'':location.search);
    const mode=query.get("mode");
    const actionCode = query.get('oobCode');
    // var continueUrl = query.get('continueUrl');
    // var lang = query.get('lang') || 'en';

    if(mode !== 'verifyEmail' && actionCode === null){
        console.log('not verifyemail mode');
        return <Redirect push to="/login" />
    } else {
        handleVerifyEmail();
    }

    async function handleVerifyEmail() {
        if(actionCode){
            const token = await auth.currentUser?.getIdToken();
            const sendEmail = functions.httpsCallable('sendEmail');
                auth.applyActionCode(actionCode).then(async (resp) => {
                    const user = auth.currentUser;
                    if(user?.displayName && user.email){
                        await sendEmail({token: token, name: user?.displayName, email: user?.email, template: 'welcome'});
                    }

                // Email address has been verified.

                // TODO: Display a confirmation message to the user.
                // You could also provide the user with a link back to the app.

                // TODO: If a continue URL is available, display a button which on
                // click redirects the user back to the app via continueUrl with
                // additional state determined from that URL's parameters.
            }).catch((error) => {
                console.log('action code failed');
                console.log(error);
                // Code is invalid or expired. Ask the user to verify their email address
                // again.
            });
        }
    }


    return (
    <Box w="100vw" h="100vh">
        <Header/>
        <Box pt="3" maxW="container.md" mx="auto">
            <Alert rounded="xl" py="8" status="success" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
                <AlertIcon boxSize="40px" mr={0} />
                <AlertTitle mt={3} mb={1} fontSize="lg">You are signed up!</AlertTitle>
                <AlertDescription fontSize="sm">Awaiting email confirmation.<br />Please check your email for instructions<br />If you do not see the email, you may need to check your spam folder.</AlertDescription>
                <Button colorScheme="green" mt="3"><NavLink to="/login">Login</NavLink></Button>
            </Alert>
        </Box>
    </Box>
    );
}

export default EmailConfirm;