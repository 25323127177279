import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import GaragesTable from "./components/Garages/GaragesTable";
import EditGarage from "./components/Garages/EditGarage";

import { auth } from "./firebase";

import MainPage from "./components/Navigation/MainPage";

import AuthContext, {
  updateAuthContext,
} from "./components/Account/AuthContext";
import Logout from "./components/Account/Logout";
import PasswordReset from "./components/Account/PasswordReset";
import EmailConfirm from "./components/Account/EmailConfirm";

import UserDashboard from "./components/Dashboards/UserDashboard";

import EditAvailableServices from "./components/Services/EditAvailableServices";
import { AvailableServicesProvider } from "./components/Services/AvailableServices";

import WorkorderTable from "./components/Workorders/WorkorderTable";
import CreateWorkOrder from "./components/Workorders/CreateWorkOrder";
import CalendarSettings from "./components/Calendar/CalendarSettings";
import PageNotFound from "./components/Common/PageNotFound";
import { GlobalStyle } from "./components/Common/CommonStyledControls";
import { AdminNav } from "./components/Navigation/Admin";
import { AnimatePresence } from "framer-motion";
import { Box } from "@chakra-ui/layout";
import Loading from "./components/Common/Loading";
import EditAvailableInsuranceProviders from "./components/InsuranceProviders/EditAvailableInsuranceProviders";
import LoginRegister from "./components/Account/LoginRegister";
import ViewWorkorders from "./components/Workorders/ViewWorkorders";
import WorkorderConfirm from "./components/Workorders/WorkorderConfirm";
import WorkorderGarageView from "./components/Workorders/WorkorderGarageView";
import CalendarConfig from "./components/Calendar/CalendarConfig";
import PrivacyPolicy from "./components/MainWebsite/PrivacyPolicy";
import TermsConditions from "./components/MainWebsite/TermsConditions";
import UserAdmin from "./components/Garages/UserAdmin";
import { ViewClassifieds } from "./components/Classifieds/ViewClassifieds";
import { SidebarContextProvider } from './components/Common/SidebarContext';
import { ClassifiedsCategoryProvider } from "./components/Classifieds/ClassifiedsCategoryContext";
import { EditCategories } from "./components/Classifieds/EditCategories";
import { ClassifiedListing } from "./components/Classifieds/ClassifiedListing";
import { ClassifiedsSearchResults } from "./components/Classifieds/ClassifiedsSearchResults";
import { MyClassifieds } from "./components/Classifieds/MyClassifieds";
import { Nav } from './components/Navigation/Nav';
import { Contact } from "./components/MainWebsite/Contact";


function App() {
  /** pages that conditionally update according to authentication state */
  function AuthenticatedArea() {
    const [userInfo, setUserInfo] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [group, setGroup] = useState("visitor");

    const Auth = useContext(AuthContext);

    // whenever Auth changes, updata the ui
    useEffect(() => {
      setUserInfo(Auth);
      setGroup(
        Auth.isLoggedIn
          ? Auth.isGarage
            ? "garge"
            : Auth.isAdmin
              ? "admin"
              : "user"
          : "visitor"
      );
    }, [Auth]);

    // called once on startup
    useEffect(() => {
      async function f(){
        // get account info
        if (auth.currentUser) {
          if (userInfo.id === "") {
            await updateAuthContext(
              Auth,
              `${auth.firstName} ${auth.lastName}`,
              auth.email,
              auth.currentUser.uid.toString()
            );
          }
        }
        // register an account login change watcher with firebase
        auth.onAuthStateChanged(async (firebaseUser) => {
          // get the user info from the firestore "users" list
          if (firebaseUser !== null) {
            await updateAuthContext(
              Auth,
              firebaseUser.displayName,
              firebaseUser.email,
              firebaseUser.uid
            );
            setUserInfo(Auth);
          }
          setLoaded(true);
        });
      }
      f();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /** routing rules for when we are not logged in */
    function NotLoggedInRouting() {


      return (
        <Switch>
          {/* <Route path="/" exact component={MainPage} /> */}
          <Route path="/" exact component={MainPage}/>
          <Route path="/contact" exact component={Contact}/>
          {group}
          <Redirect from="/signup" to="/login?signup" exact />
          <Route path="/login" exact component={LoginRegister} />
          <Route path="/login/confirm" component={EmailConfirm} />
          <Route path="/passwordReset" exact component={PasswordReset} />
          <Redirect from="/workorders" to="/login?redirect=/workorders" />
          <Redirect from="/dashboard" to="/login?redirect=/dashboard" />
          <Redirect from="/logout" to="/" />
          <Redirect from="/services" to="/login?redirect=/services" />
          <Redirect from="/insurance_providers" to="/login?redirect=/insurance_providers" />
          <Redirect from="/garages" to="/login?redirect=/garages" />
        </Switch>
      );
    }

    /** routing rules for when we are logged in as a user */
    function UserLoggedInRouting() {
      return (
        <Switch>
          <Route path="/" exact component={MainPage} />
          <Redirect from="/signup" to="/logout" />
          <Redirect from="/passwordReset" to="/logout" />
          <Route path="/logout" exact component={Logout} />
          <Route path="/dashboard" exact component={UserDashboard} />
          <Route path="/submissions" exact component={ViewWorkorders} />
          <Redirect from="/services" to="/login?redirect=/services" />
          <Redirect from="/insurance_providers" to="/login?redirect=/insurance_providers" />
          <Redirect from="/garages" to="/login?redirect=/garages" />
        </Switch>
      );
    }

    /** routing rules for when we are logged in as a garage */
    function GarageLoggedInRouting() {

      const Dashboard = ({match}) => {
        return(
          <AdminNav>
            <Switch>
              <Redirect from={`${match.url}`} to={`${match.url}/workorders/${Auth.garageId}`} exact={true} />
              <Route path={`${match.url}/workorders`} exact component={WorkorderTable} />
              <Route path={`${match.url}/workorders/:id/confirm`} component={WorkorderConfirm} />
              <Redirect from={`${match.url}/workorders/add`} to={`${match.url}/workorders`} />
              <Route path={`${match.url}/workorders/:id`} component={WorkorderGarageView} />
              <Route path={`${match.url}/garages/${Auth.garageId}/calendarSettings`} component={CalendarSettings} />
              <Route path={`${match.url}/garages/${Auth.garageId}/calendarConfig`} component={CalendarConfig} />
              <Route path={`${match.url}/classifieds/search`} component={ClassifiedsSearchResults}/>
              <Route path={`${match.url}/classifieds/:term`} component={ViewClassifieds} />
              <Route path={`${match.url}/classified/:id`} component={ClassifiedListing} />
              <Route path={`${match.url}/my_classifieds`} component={MyClassifieds} />
            </Switch>
          </AdminNav>
        )
      }

      return (
        <>
            <Switch>
              <Route path="/" exact component={MainPage} />
              <Redirect from="/signup" to="/logout" />
              <Redirect from="/passwordReset" to="/logout" />
              <Route path="/logout" exact component={Logout} />
              <Route path="/dashboard" component={Dashboard} />
              <Redirect from={'*'} to={'/dashboard'} />
              {/* <Redirect from="/services/edit" to="/login?redirect=/services/edit" /> */}
              {/* <Redirect from="/insurance_providers/edit" to="/login?redirect=/insurance_providers/edit" /> */}
              {/* <Redirect from="/garages" exact to="/login?redirect=/garages" /> */}
              {/* <Redirect from="/garages/add" to="/login?redirect=/garages/add" /> */}
              {/* if we are a garage, then we can edit only our own garage info */}
              {/* <Route path={`/garages/${userInfo.garageId}/edit`} component={EditGarage} /> */}
              {/* <Redirect from="/garages/:id/edit" to="/login?redirect=/garages" /> */}
            </Switch>
        </>
      );
    }

    /** routing rules for when we are logged in as an admin */
    function AdminLoggedInRouting() {

      const Dashboard = ({match}) => {
        return(
          <AdminNav>
            <Switch>
              <Redirect from={match.url} to={`${match.url}/workorders`} exact />
              <Route path={`${match.url}/workorders`} exact={true} component={WorkorderTable} />
              <Route path={`${match.url}/services/edit`} exact={true} component={EditAvailableServices} />
              <Route path={`${match.url}/insurance_providers/edit`} exact component={EditAvailableInsuranceProviders} />
              <Route path={`${match.url}/workorders/add`} exact={true} component={CreateWorkOrder} />
              <Route path={`${match.url}/garages`} exact={true} component={GaragesTable} />
              <Route path={`${match.url}/garages/add`} exact={true} component={EditGarage} />
              <Route path={`${match.url}/garages/:id/edit`} component={EditGarage} />
              <Route path={`${match.url}/garages/:id/calendarSettings`} component={CalendarSettings} />
              <Route path={`${match.url}/classifieds/categories`} component={EditCategories} />
              <Route path={`${match.url}/classifieds/search`} component={ClassifiedsSearchResults}/>
              <Route path={`${match.url}/classifieds/:term`} component={ViewClassifieds} />
              <Route path={`${match.url}/classified/:id`} component={ClassifiedListing} />
              <Route path={`${match.url}/user_admin`} component={UserAdmin} />
            </Switch>
          </AdminNav>
        );
      };

      return (
        <>
          <AnimatePresence>
            <Switch>
              <Route path="/" exact component={MainPage} />
              <Redirect from="/signup" to="/logout" />
              <Redirect from="/passwordReset" to="/logout" />
              <Route path="/logout" exact component={Logout} />
              <Route path="/dashboard" component={Dashboard} />
              <Redirect from={'*'} to={'/dashboard'} />
            </Switch>
          </AnimatePresence>
        </>
      );
    }

    return (
      <>
      {!loaded 
        ? (<Box w={"100vw"} h={"100vh"}>
            <Loading height={"100vh"} />
          </Box>) 
        : !userInfo.isLoggedIn 
          ? (<NotLoggedInRouting />) 
          : userInfo.isAdmin 
            ? (<SidebarContextProvider>
                <AvailableServicesProvider>
                  <ClassifiedsCategoryProvider>
                    <AdminLoggedInRouting />
                  </ClassifiedsCategoryProvider>
                </AvailableServicesProvider>
              </SidebarContextProvider>) 
            : userInfo.isGarage 
              ? (<SidebarContextProvider>
                <AvailableServicesProvider>
                  <ClassifiedsCategoryProvider>
                    <GarageLoggedInRouting />
                  </ClassifiedsCategoryProvider>
                </AvailableServicesProvider>
              </SidebarContextProvider>) 
              : (<AvailableServicesProvider>
                    <UserLoggedInRouting />
                </AvailableServicesProvider>)
      }
      </>
    );
  }

  return (
    <div className="App">
      <GlobalStyle />
      <BrowserRouter>
        <AuthContext.Provider value={{}}>
          {/*<Menu></Menu>  Menu is on all pages */}
          <Switch>
            {/* stuff that everyone can access */}
            <Route path="/privacy" exact component={PrivacyPolicy} />
            <Route path="/terms" exact component={TermsConditions} />

            {/* stuff that only specific people can access */}
            <AuthenticatedArea />

            {/* stuff that no one can access... because it doesn't exist */}
            <Route component={PageNotFound} />
          </Switch>
        </AuthContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
