import React, { useContext, useEffect, useState } from 'react'
import { Button, Menu, MenuButton, MenuItem, MenuList, Box, Circle, Flex, HStack, SimpleGrid, Text, VStack, FormControl, Input, FormErrorMessage, Select, Textarea, InputGroup, InputLeftAddon, CheckboxGroup, Checkbox, useColorModeValue } from "@chakra-ui/react";
import { Controller, FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import AuthContext from "../Account/AuthContext";
import { AnimatePresence, motion } from 'framer-motion';
import DbInsuranceProvider from '../InsuranceProviders/DbInsuranceProvider';
import { AvailableInsuranceProvidersContext, AvailableInsuranceProvidersContextType } from '../InsuranceProviders/AvailableInsuranceProviders';
import FileInput from './FileInput';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { AvailableServicesContext, AvailableServicesContextType, DbAvailableService } from '../Services/AvailableServices';
import DbGarage from '../Garages/DbGarage';
import { SearchTermsType } from '../Dashboards/UserDashboard';

export interface ClaimValues {
    firstName: string,
    lastName: string,
    phone: number,
    insuranceProvider?: string,
    otherInsuranceProvider?: string,
    policyNumber?: string,
    description?: string,
    documents?: File[],
    services?: string[] | string,

}

export default function UserSubmission({garage, searchTerms, onSubmit, submitLoading}:{garage:DbGarage, searchTerms?: SearchTermsType, onSubmit:SubmitHandler<ClaimValues>, submitLoading:boolean}) {
    const methods = useForm({mode: "onBlur"});
    const {setValue, getValues, register, watch ,handleSubmit, control, reset, formState: {errors}} = methods;
    const Auth = useContext(AuthContext);
    const Insurance = useContext(AvailableInsuranceProvidersContext) as AvailableInsuranceProvidersContextType;
    const Services = useContext(AvailableServicesContext) as AvailableServicesContextType;
    const [allInsuranceProviders,setAllInsuranceProviders]=useState<DbInsuranceProvider[]>([]);
    const [allServices, setAllServices] = useState<DbAvailableService[]>();
    const {insuranceProvider} = watch();
    const textColor = useColorModeValue('gray.900', 'white');


    useEffect(() => {
        if(Auth !== undefined){
            setValue("firstName", Auth.firstName);
            setValue("lastName", Auth.lastName);
        }
        // if(Insurance.insuranceProviders.length && !allInsuranceProviders.length){
        //     const insuranceList = Insurance.insuranceProviders;
        //     const otherInsurance:DbInsuranceProvider = {
        //         id: "abc123",
        //         name: "Other"
        //     }
        //     insuranceList.push(otherInsurance);
        //     setAllInsuranceProviders(insuranceList);
        // }
        if(Insurance.insuranceProviders.length > 0){
            setAllInsuranceProviders(Insurance.insuranceProviders);
        }
        if(Services.services.length){
            const availableServices = Services.services.filter((service) => garage.services.includes(service.id!));
            setAllServices(availableServices);
        }
    }, [])




    return (
            <AnimatePresence>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{width:"100%", height:"100%"}}
                >
                    <Box w="full" h="full">
                        <Box w={["full","md"]} ml="auto" mr="auto" mb="4">
                            <form onSubmit={handleSubmit(onSubmit)} style={{height:"100%"}}>

                                    <VStack w="full" h="full" justify="space-between">
                                        <VStack w="full" height="full" spacing="4" mt="4" align="flex-start">
                                            <Text as="h1" color={textColor} fontSize="3xl" fontWeight="bold" >Contact Info</Text>
                                            <HStack w="full">
                                                <FormControl isInvalid={errors.firstName}>
                                                    <Input color={textColor} {...register("firstName", {required:"Please make sure you enter a First Name"})} placeholder="John"/>
                                                    <FormErrorMessage>
                                                        {errors.firstName && errors.firstName.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                                <FormControl isInvalid={errors.lastName}>
                                                    <Input color={textColor} {...register("lastName", {required:"Please make sure you enter a Last Name"})} placeholder="Smith"/>
                                                    <FormErrorMessage>
                                                        {errors.lastName && errors.lastName.message}
                                                    </FormErrorMessage>
                                                </FormControl>
                                            </HStack>
                                            <FormControl isInvalid={errors.phone}>
                                                <InputGroup>
                                                    <InputLeftAddon children="+1" />
                                                    <Input color={textColor} {...register("phone", {required:"Please make sure you enter a Contact Number",
                                                                minLength :{value: 10, message: "Please enter a valid phone number"},
                                                                maxLength: {value: 10, message: "Please enter a valid phone number"}
                                                            })} placeholder="Phone Number"/>
                                                </InputGroup>
                                                <FormErrorMessage>
                                                    {errors.phone && errors.phone.message}
                                                </FormErrorMessage>
                                            </FormControl>

                                            {allServices?.length &&
                                            <>
                                            <Text as="h1" color={textColor} fontSize="3xl" fontWeight="bold" >Services Requested</Text>
                                            <CheckboxGroup colorScheme="green" defaultValue={searchTerms?.services} >
                                                <HStack>
                                                    {allServices.map((service) => {
                                                        return (
                                                            <Checkbox color={textColor} key={service.id} value={service.id} {...register("services")}>{service.name}</Checkbox>
                                                        )
                                                    })

                                                    }
                                                </HStack>
                                            </CheckboxGroup>
                                            </>
                                            }

                                            {
                                                allInsuranceProviders !== undefined && allInsuranceProviders.length > 0 &&
                                                    <Flex flexWrap="wrap" w="full">
                                                        <Text w="full" as="h1" color={textColor} fontSize="3xl" fontWeight="bold">Insurance Info</Text>
                                                        <Select color={textColor} w={insuranceProvider ? insuranceProvider !== "Other" ? "calc(50% - 0.5rem)" : "100%" : "100%"} mt="4" {...register("insuranceProvider")} placeholder="Select Insurance">
                                                            {
                                                                allInsuranceProviders.map((insurance) => {
                                                                    return (
                                                                        <option key={insurance.id} value={insurance.name}>{insurance.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                        {insuranceProvider === "Other" &&
                                                            <FormControl isInvalid={errors.otherInsuranceProvider} w="calc(50% - 0.5rem)" mt="4">
                                                                <Input color={textColor} {...register("otherInsuranceProvider", {
                                                                    validate: {
                                                                        required: value => {
                                                                            if(!value && getValues("insuranceProvider")) return "Required when 'Other' insurance provider is selected";
                                                                            return true;
                                                                        }
                                                                    }
                                                                })} placeholder="Other Insurance Provider Name"></Input>
                                                                <FormErrorMessage>
                                                                    {errors.otherInsuranceProvider && errors.otherInsuranceProvider.message}
                                                                </FormErrorMessage>
                                                            </FormControl>
                                                        }
                                                        {insuranceProvider &&
                                                            <FormControl isInvalid={errors.policyNumber} w="50%" ml="0.5rem" mt={4} >
                                                                <Input color={textColor} {...register("policyNumber", {
                                                                    validate : {
                                                                        required: value => {
                                                                            if(!value && getValues("insuranceProvider")) return "Required when an Insurance Provider is selected";
                                                                            return true;
                                                                        }
                                                                    }
                                                                })} placeholder="Policy Number" mb="2" />
                                                                <FormErrorMessage>
                                                                    {errors.policyNumber && errors.policyNumber.message}
                                                                </FormErrorMessage>
                                                            </FormControl>
                                                        }
                                                    </Flex>
                                        
                                            }
                                            {insuranceProvider &&
                                            <>
                                                <Text color={textColor} as="h1" fontSize="3xl" fontWeight="bold">Claim Info</Text>
                                            <Textarea color={textColor} {...register("description")} placeholder="Description of claim, you can include accident details, drop off person, notes to Carera etc." />
                                            <FormProvider {...methods}>
                                                <FileInput
                                                    iconMode={true}
                                                    accept="image/png, image/jpeg, image/jpg, application/pdf,csv, application/msword, application/vnd.ms-excel, text/plain"
                                                    name="documents"
                                                    label="Supporting Documents"
                                                    componentIcon={faFileUpload}
                                                    acceptText="PNG, JPG, PDF, CVS, DOCX, XLS, TXT up to 5MB"
                                                />
                                            </FormProvider>
                                            </>
                                            }
                                        </VStack>
                                        <Button isLoading={submitLoading} type="submit" colorScheme="blue">Book</Button>
                                    </VStack>
                            </form>
                        </Box>
                    </Box>
                </motion.div>

            </AnimatePresence>
    )
}
