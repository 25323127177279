import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import moment, { weekdays, weekdaysShort } from "moment";

import { AddButton, DeleteButton } from "../Common/CommonStyledControls";
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';


const StyledDay=styled.div`
    display: inline-block;
    border: 1px solid black;
    width: 100%;
    flex: 1;
`;

const StyledWeek=styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 0px 50px;
    width: 100%;
`;

const WeekdayHeading=styled.div`
    display:inline-block;
    font-size:130%;
    width:100%;
    text-align:center;
    background-color:#444;
    color:#fff;
    text-decoration:underline;
    padding-bottom: 5px;
`;

const EditableText=styled.input`
    border: 1px solid rgba(0,0,0,0);
    display: inline-block;
    font-size: small;

    :hover {
        border: 1px dashed black;
    }
    
    :active {
        border: 1px dashed black;
    }
`;


export type TimespanType=[string,string];
type TimespanChangeFn=(newTimespan:TimespanType)=>void;


/** Component consisting of a start and end time, both of which are editable */
export function EditableTimespan({startTime,endTime,onChange}:{startTime:string,endTime:string,onChange?:TimespanChangeFn}){

    const [start,setStart]=useState(startTime);
    const [end,setEnd]=useState(endTime);

    function change(startTime?:string,endTime?:string){
        let changed=false;
        if(startTime!==undefined){
            setStart(startTime);
            changed=true;
        } else if(startTime!==start){
            startTime=start;
            changed=true;
        }
        if(endTime!==undefined){
            setEnd(endTime);
            changed=true;
        } else if(endTime!==end){
            endTime=end;
            changed=true;
        }
        if(changed&&onChange!==undefined){
            let newTimespan:TimespanType=[startTime,endTime];
            onChange(newTimespan);
        }
    }

    return (
        <Flex align="center" p="1">
            <EditableText 
                value={start} 
                onChange={(e)=>setStart(startTime=e.target.value)} onBlur={()=>change()}
                onKeyPress={(e)=>{if(e.key==='Enter')(e.target as HTMLInputElement).blur();}}
                size={6}/>
            -
            <EditableText 
                value={end} 
                onChange={(e)=>setEnd(startTime=e.target.value)} onBlur={()=>change()}
                onKeyPress={(e)=>{if(e.key==='Enter')(e.target as HTMLInputElement).blur();}}
                size={6}/> 
            {/* <DeleteButton onClick={()=>change('','')}/> */}
            <FontAwesomeIcon style={{marginLeft:"auto", cursor:"pointer"}} icon={faTimes} onClick={()=>change('','')}  />
        </Flex>
    );
}

export type DayTimespans=TimespanType[];
/** Component to add/edit timespans for a certain day of the week */
export function DaySelect({dayTimespans,day,onChange}:{dayTimespans:DayTimespans,day:string,onChange?:any}){

    const [rangeSelects,setRangeSelects]=useState(dayTimespans);

    function addRangeSelect(startTime:string='',endTime:string=''){
        if(startTime===''){
            startTime=moment().format('h:00 A');
        }
        if(endTime===''){
            endTime=moment().add(1,'hours').format('h:00 A');
        }
        let modifiedRange:DayTimespans=[...rangeSelects,[startTime,endTime]];
        setRangeSelects(modifiedRange);
        if(onChange!==undefined){
            onChange(modifiedRange);
        }
    }

    /** called when a date range is changed
     * NOTE: the way that we signify a date range should be removed is if one or more times is empty string
     */
    function onRangeChanged(idx:number,newTimespan:TimespanType){
        let modifiedRange=[...rangeSelects];
        if(newTimespan[0]===''||newTimespan[1]===''){
            modifiedRange.splice(idx,1); // delete it
        } else {
            modifiedRange[idx]=newTimespan;
        }
        setRangeSelects(modifiedRange);
        if(onChange!==undefined){
            onChange(modifiedRange);
        }
    }

    return (
    <Flex flexDirection="column" mb={["8", "0"]}>
        <StyledDay>
            <WeekdayHeading>{day}</WeekdayHeading>
            {rangeSelects.map((rangeSelect,idx)=>(
                <EditableTimespan key={`${(new Date()).getMilliseconds()}.${idx}`} startTime={rangeSelect[0]} endTime={rangeSelect[1]} onChange={(newTimespan:TimespanType)=>onRangeChanged(idx,newTimespan)} />
            ))}
        </StyledDay>
        <Flex justify="center" marginTop="auto" pt="2">
            {/* <AddButton onClick={()=>addRangeSelect()}/> */}
            <FontAwesomeIcon style={{cursor:"pointer"}} icon={faPlus} onClick={()=>addRangeSelect()} />
        </Flex>
    </Flex>
    );
}

export type WeekTimespans=[DayTimespans,DayTimespans,DayTimespans,DayTimespans,DayTimespans,DayTimespans,DayTimespans];
type WeekSelectOnChange = (weekTimespans:WeekTimespans) => void;
/** Component to edit all timespans for an entire week */
export function WeekSelect({weekTimespans,onChange}:{weekTimespans:WeekTimespans,onChange:WeekSelectOnChange}){

    const [weekData,setWeekData]=useState<WeekTimespans>(weekTimespans);

    useEffect(()=>{
        setWeekData(weekTimespans);
    },[weekTimespans]);

    function onRangeChanged(idx:number,dayTimespans:DayTimespans){
        const newWeekData:WeekTimespans=[...weekData];
        newWeekData[idx]=dayTimespans;
        setWeekData(newWeekData);
        if(onChange!==undefined){
            onChange(newWeekData);
        }
    }

    return (
        // <StyledWeek>
        <SimpleGrid gridTemplateColumns={["1fr","1fr", "1fr" ,"repeat(7, 1fr)"]} w="full">
            {weekData.map((dayData,idx)=>
                <DaySelect key={`${(new Date()).getMilliseconds()}.${idx}`} dayTimespans={dayData} day={weekdaysShort()[idx]} onChange={(dayTimespans:DayTimespans)=>onRangeChanged(idx,dayTimespans)}/>
            )}
        </SimpleGrid>
        // </StyledWeek>
    );
}