import React, { useState,useEffect } from "react";
import firebase, { db } from '../../firebase';
import DbInsuranceProvider from "./DbInsuranceProvider";


// IMPORTANT: this merely makes typescript happy.
// If you want to export this stuff, you MUST, MUST, MUST
// add it to the .Provider tag in jsx!!!
export type AvailableInsuranceProvidersContextType = {
    /** all of the insuranceProviders listed in the database */
    insuranceProviders:DbInsuranceProvider[];

    /** will attempt to lookup nameOrId and return the results if found
     * in the list of insurance provider names.
     * 
     * otherwise, will return nameOrId directly
     */
    getInsuranceProvider:{(nameOrId:string):string};
}
    

export const AvailableInsuranceProvidersContext = React.createContext({});


// more typescript joy
interface AvailableInsuranceProvidersComponentProps {
    children:any;
};


/** gets the available insuranceProviders from the database and supplies it to all components */
export function AvailableInsuranceProviders({children}:AvailableInsuranceProvidersComponentProps) {
    const [insuranceProviders, setInsuranceProviders] = useState<DbInsuranceProvider[]>([]);

    const insuranceProvidersCollection = db.collection("insurance_providers");

    /** will attempt to lookup nameOrId and return the results if found
     * in the list of insurance provider names.
     * 
     * otherwise, will return nameOrId directly
     */
    function getInsuranceProvider(nameOrId:string){
        for(let provider of insuranceProviders){
            if(provider.id===nameOrId){
                return provider.name;
            }
        }
        return nameOrId;
    }

    // called once on component start to get the insuranceProviders from the database
    useEffect(()=>{
        loadInsuranceProviders();
        }
        ,[]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Loads the insuranceProviders from the database */
    function loadInsuranceProviders() {
        const unregister=insuranceProvidersCollection.onSnapshot(
            (querySnapshot)=>{
                let items:DbInsuranceProvider[]=[];
                querySnapshot.forEach((doc)=>{
                    items.push({...doc.data() as DbInsuranceProvider,id:doc.id});
                });
                items.sort((a,b)=>{return a.name>b.name?1:0});
                setInsuranceProviders(items);
                unregister();
            },
            (error:any) => {
                console.error("AvailableInsuranceProviders.tsx: Error getting insurance providers");
                console.error(error);
            }
        );
    }

    return (
        <AvailableInsuranceProvidersContext.Provider value={{insuranceProviders,getInsuranceProvider}}>{children}</AvailableInsuranceProvidersContext.Provider>
      )
}