import firebase, {auth, db} from '../../firebase';
import React, { useEffect, useState } from 'react'
import { Box, Flex, useToast, Text, IconButton, Tooltip, Link } from '@chakra-ui/react';
import Loading from '../Common/Loading';
import { NoResults } from '../Common/Graphics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faInfoCircle, faMailBulk } from '@fortawesome/free-solid-svg-icons';

interface GarageUser {
    email:string,
    firstName: string,
    lastName: string,
    garageId: string,
    verified: boolean,
}

function UserAdmin() {
    
    const usersCollection = db.collection("users");
    const [loading, setLoading] = useState(true);
    const [sendLoading, setSendLoading] = useState(false);
    const [users, setUsers] = useState<GarageUser[]>();
    const toast = useToast();


    // get a list of users who are not currently verified upon load
    useEffect(() => {
        usersCollection.where("verified", "==", false ).get().then((querySnapshot) => {
            const unverifiedUsers:GarageUser[] = [];
            querySnapshot.forEach((doc) =>{
                const user = doc.data() as GarageUser;
                unverifiedUsers.push(user);
            })
            setUsers(unverifiedUsers);
        }).catch((error) => {
            console.log(error);
            toast({
                title: "Error",
                description: `There was an error getting the values, please try again later`,
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
        
        setLoading(false);
    }, [])

    async function resendVerificationEmail(user:GarageUser){
        setSendLoading(true);
        try {
            const redirectBackToUrl=window.location.origin+'/login?gId='+user.garageId;
            await auth.sendSignInLinkToEmail(user.email,{url:redirectBackToUrl,handleCodeInApp:true});
            toast({
                title: "Email Sent",
                description: `Verification email was successfully sent`,
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: "Error",
                description: `There was an error sending the email, please try again`,
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        setSendLoading(false);
    }

    return (
        <Box px="4">
        <Text pb="1" as="h6" fontSize="sm" fontWeight="bold" textTransform="uppercase" >Unregistered Users</Text>
        <Flex pb="10" alignItems="center">
            <FontAwesomeIcon style={{marginRight:"0.75rem"}} icon={faInfoCircle} color="#CBD5E0" />
            <Text pr={["6", "12", "24"]} as="p" fontSize="xs" >A simple list of Garages who have an account but have not verified their email address, from here you can resend the verification email</Text>
        </Flex>
            {
                loading
                ? <Loading />
                : users !== undefined && users.length > 0 
                    ? users.map((user) => {
                        return (
                            <Flex key={user.garageId} pb="3" justifyContent="space-between">
                                <Box>
                                    <Text as="span">{`${user.firstName} `}</Text>
                                    <Text as="span" >{`${user.lastName} `}</Text>
                                    <Link as="a" href={`mailto:${user.email}`}  >{`<${user.email}>`}</Link>
                                </Box>
                                <Tooltip label="Resend Verification Email">
                                    <IconButton isLoading={sendLoading} aria-label="Resend Email" onClick={() => resendVerificationEmail(user)} icon={ <FontAwesomeIcon icon={faMailBulk} /> } />
                                </Tooltip>
                            </Flex>
                        )
                    })
                    : <NoResults />
            }
        </Box>
    )
}

export default UserAdmin
