import { Wrap, Text } from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

type OpeningObj = {
        day: string,
        opening: string,
        closing: string,
    }
    
export const daysOpen = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    /** control to display open store hours */
    function OpeningHours({times}:{times:string}){

        const hoursArray:Array<string>=JSON.parse(times);

        const openingTimes:OpeningObj[]=hoursArray.map((hours, i) => {
                if(hours.length !== 0){
                    const hour = Object.values(hours);
                    const formattedOpening = moment(`${hour[0][0]}`, "Hmm").format("LT");
                    const formattedClosing = moment(`${hour[0][1]}`, "Hmm").format("LT");
                    const completedDay:OpeningObj = {day: daysOpen[i], opening: formattedOpening, closing: formattedClosing}
                    return completedDay;
                } else {
                    const emptyDay:OpeningObj = {day: "", opening: "", closing: ""}
                    return emptyDay; 
                }
            });
        const filtered:OpeningObj[] = openingTimes.filter((open) => open.day);

        return (
            <>
            {
            filtered.map((time) => {
                return(
                <Wrap key={time.day}>
                    <Text>{time.day}</Text>
                    <Text>{`${time.opening} - ${time.closing}`}</Text>
                </Wrap>
                )
            })}
            </>
        )
        
    }

    export default OpeningHours