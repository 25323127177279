import {useState} from 'react';
import { useEffect } from 'react';
import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, IconButton, Text } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import useViewport from "../Hooks/Viewport";


export type OnPageChangeFn=(startItem:number,count:number)=>void;

interface PaginationProps{

    /**
     * how many items total there are
     */
    totalItems:number,

    /**
     * function to be called whenever the page changes
     * the function is of the form fn(startItem:number,count:number)
     * where startItem is the item number to start your display list with, and cout is how many total you should display
     */
    onPageChange:OnPageChangeFn,

    /** number of items per page
    */
    itemsPerPage?:number,

    /** whether to display the control in a "loading" state */
    pagLoadingState?: boolean,
    
    /** used to set the current page
     * (first page is 0)
     */
    page?: number
};


function Pagination({totalItems,onPageChange,itemsPerPage=15,pagLoadingState=false,page=0}:PaginationProps){

    const [selectedItemsPerPage,_setSelectedItemsPerPage]=useState(itemsPerPage);
    const [currentPage,_setCurrentPage]=useState(page+1);  // this is +1 for display, that is, the first page is "1", not "0"
    const [numPages,setNumPages]=useState(0);
    const { width } = useViewport();
    const mdBreakpoint = 480;

    /** called at start and whenever totalItems changes */
    useEffect(()=>{
        let newNumPages=Math.ceil(totalItems/selectedItemsPerPage);
        setNumPages(newNumPages);
        //console.log(`A there are ${totalItems} items with ${itemsPerPage} per page which equals ${newNumPages} pages`)
    },[totalItems]); // eslint-disable-line react-hooks/exhaustive-deps

    /** go to a specific page */
    function setCurrentPage(pageNo:number){
        if(pageNo!==currentPage){
            _setCurrentPage(pageNo);
            const startItem=(pageNo-1)*selectedItemsPerPage;
            onPageChange(startItem,selectedItemsPerPage);
        }
    }

    /** go to the previous page */
    function previousPage(){  
        setCurrentPage(Math.max(currentPage-1,1));
    }

    /** go to the next page */
    function nextPage(){
        setCurrentPage(Math.min(currentPage+1,numPages));
    }

    /** change how many items go on each page */
    function setSelectedItemsPerPage(newItemsPerPage:number){
        const startItem=(currentPage-1)*selectedItemsPerPage;
        const newNumPages=Math.ceil(totalItems/newItemsPerPage);
        const newCurrentPage=Math.floor(startItem/newItemsPerPage)+1;
        const newStartItem=(newCurrentPage-1)*newItemsPerPage;
        //console.log("out of",totalItems,"items")
        //console.log("change from",itemsPerPage,"items per page to",newItemsPerPage,"items per page");
        //console.log("change from",Math.ceil(totalItems/itemsPerPage),"pages of results to",newNumPages,"pages of results");
        //console.log("change from page",currentPage,"to page",newCurrentPage);
        //console.log("change startItem from",startItem,"to",newStartItem);
        // set the ui values
        _setSelectedItemsPerPage(newItemsPerPage);
        setNumPages(newNumPages);
        _setCurrentPage(newCurrentPage);
        // indicate to parent what data needs to be displayed
        onPageChange(newStartItem,newItemsPerPage);
    }

    return (
        <Flex
        pt={6}
        w="full"
        alignItems="center"
        justifyContent="center">
            <Flex align="center">
                <IconButton mx={1.5} aria-label="previous page" 
                    isDisabled={currentPage<=1}
                    icon={<ChevronLeftIcon/>}
                    onClick={previousPage}
                />
                { [...Array(numPages).keys()].map((idx) => {
                    let n=idx+1;
                    if(n===1||n===numPages||n===currentPage||n===currentPage-1||n===currentPage+1){
                        // add buttons for first, last page, current page, and its nearest neigbors.
                        return (
                        <Button 
                            mx={0.5} 
                            id={`page_${n}`}
                            key={`page_${n}`} 
                            isActive={currentPage === n} 
                            onClick={() => setCurrentPage(n)}
                            isLoading={currentPage === n && pagLoadingState}
                        >
                            {n}
                        </Button>);
                    }else if(n===currentPage){
                        // the current page is un-clickable
                        return (
                        <Button 
                            mx={0.5} 
                            key={`page_${n}`} 
                            isActive={false}>
                            {n}
                        </Button>);
                    }else if((n===currentPage+2&&n!==numPages)||(n===currentPage-2&&n!==1)){
                        // if there is stuff between current series of pages and the front/end page, add a "..."
                        return <Text as="span" key="span" mb={1.5} mx={1} >...</Text> ;
                    }
                    return '';
                }) // end map
                }

                <IconButton mx={1.5} aria-label="next page" 
                    isDisabled={currentPage>=numPages} 
                    icon={<ChevronRightIcon/>}
                    onClick={nextPage}
                />
                <Menu autoSelect={false}>
                    <MenuButton ml={1} as={Button} rightIcon={<ChevronDownIcon />}>
                        { width < mdBreakpoint 
                            ? `${selectedItemsPerPage}`
                            : `${selectedItemsPerPage} / page`
                        }
                    </MenuButton>
                    <MenuList >
                        <MenuItem onClick={()=>setSelectedItemsPerPage(5)}>5 / page</MenuItem>
                        <MenuItem onClick={()=>setSelectedItemsPerPage(10)}>10 / page</MenuItem>
                        <MenuItem onClick={()=>setSelectedItemsPerPage(15)}>15 / page</MenuItem>
                        <MenuItem onClick={()=>setSelectedItemsPerPage(25)}>25 / page</MenuItem>
                        <MenuItem onClick={()=>setSelectedItemsPerPage(50)}>50 / page</MenuItem>
                        <MenuItem onClick={()=>setSelectedItemsPerPage(100)}>100 / page</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    );
};

export default Pagination;