import { Textarea, Badge, Box, Button, Flex, IconButton, Switch, Tag, TagLabel, Text, useToast, Link, Input } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import ServicesList from "../Services/ServicesList";
import DbWorkorderType, { DbWorkorderNotes, formatWorkorderNo } from "./DbWorkorderType"
import { faEdit, faEnvelope, faPhone, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import firebase, { db } from '../../firebase';
import DbAttachment from "../Attachments/DbAttachment";
import { checkFileType } from "../Form/FileInput";
import Loading from "../Common/Loading";
import { formatPhoneNumber } from "../Helpers/phoneFormatting";
import { YesnoButton } from '../Common/CommonStyledControls';
import moment from "moment";

interface WorkorderProps {
    workorderInput: DbWorkorderType,

    onClose: () => void,

    updateState?: Function,

    guestView?: boolean,

    // Calls this when workorder is cancelled from the calendar 
    // if undefined, workorder will not be cancelable
    cancelWorkorderCB?:Function 
}

const WorkorderContent = ({workorderInput, onClose, updateState, guestView = false, cancelWorkorderCB}:WorkorderProps) => {

    const [hasChanged,setHasChanged]=useState(false);
    const [estimate,setEstimate]=useState<string>("");
    const [workorder,setWorkorder]=useState<DbWorkorderType>(workorderInput);
    const [closed,setClosed]=useState<boolean>();
    const toast = useToast();
    const [dbAttachments, setDbAttachments]=useState<DbAttachment[]>([]);
    const [careraNotes, setCareraNotes] = useState<string>("");
    const [editing, setEditing] = useState(false);
    const [cancelPopupOpen, setCancelPopupOpen] = useState(false);
    // const initialNotes = useRef(workorder.careraNotes ?? "");
    const dbDoc = db.collection("work_orders");
    const [loading, setLoading] = useState(true);
    const initalNotes = useRef<DbWorkorderNotes>();


    function getAttachments() {
        // setLoadingImages(true);
        const workorderAttachments=dbDoc.doc(workorder.id!).collection("attachments");
        const unregister=workorderAttachments.onSnapshot(
            (querySnapshot)=>{
                // const items:string[]=[];
                const items:DbAttachment[]=[];
                const urls:string[]=[];
                querySnapshot.forEach((doc)=>{
                    let data=doc.data() as DbAttachment;
                    // items.push(doc.data().url);
                    items.push({...data,id:doc.id});
                    urls.push(data.url);
                });
                // setImageUrls(items);
                setDbAttachments(items);
                unregister();
            },
            (error:any) => {
                console.error("Error getting attachments for workorder",workorder.id);
                console.error(error);
            }
        );
    }

    async function getNotes() {
        var workorderPrivate;
        try{
            workorderPrivate=await dbDoc.doc(workorder.id!).collection("private").doc("notes").get();
        }catch(error:any){
            console.error("Error getting notes for workorder",workorder.id);
            console.error(error);
            return;
        }
        if(workorderPrivate.exists){
            const notes = workorderPrivate.data() as DbWorkorderNotes;
            initalNotes.current = notes;
            if(notes.careraNotes) setCareraNotes(notes.careraNotes);
            if(notes.estimate) setEstimate(notes.estimate);
        }
    }

    useEffect(() => {
        getAttachments();
        if(!guestView)getNotes();
        setLoading(false);
    }, [])


    useEffect(() => {
        setClosed(workorder.closed);
    }, [workorder])



    /** change the closed state in the database */
    async function dbUpdate(){
        setLoading(true);
        const closedState = !closed;
        if(workorder===undefined) return; // nothing to update
        // if(closed===workorder.closed && estimate===workorder.estimate) return; // no change
        try {
            const collectionsRef=db.collection("work_orders");
            const docRef=collectionsRef.doc(workorder.id);
            await docRef.update({
                closed:closedState,
                // estimate:estimate,
                // careraNotes: careraNotes
            });
            if(closedState!==workorder.closed){
                // update the item counters
                const countersRef=collectionsRef.doc('counters');
                if(workorder.insurancePolicy===''){
                    if(!closedState){
                        // remove it from the closed list
                        await countersRef.update("closed_noinsurance",firebase.firestore.FieldValue.increment(-1))
                        // add it to the open list
                        await countersRef.update("open_noinsurance"  ,firebase.firestore.FieldValue.increment( 1))
                    } else {
                        // remove it from the open list
                        await countersRef.update("open_noinsurance"  ,firebase.firestore.FieldValue.increment(-1))
                        // add it to the closed list
                        await countersRef.update("closed_noinsurance",firebase.firestore.FieldValue.increment( 1))
                    }
                }else{
                    if(!closedState){
                        // remove it from the closed list
                        await countersRef.update("closed_insurance",firebase.firestore.FieldValue.increment(-1))
                        // add it to the open list
                        await countersRef.update("open_insurance"  ,firebase.firestore.FieldValue.increment( 1))
                    } else {
                        // remove it from the open list
                        await countersRef.update("open_insurance"  ,firebase.firestore.FieldValue.increment(-1))
                        // add it to the closed list
                        await countersRef.update("closed_insurance",firebase.firestore.FieldValue.increment( 1))
                    }
                }
            }
            // setHasChanged(false);
            setWorkorder({
                ...workorder,
                closed: closedState,
                // estimate,
                // careraNotes
            });
            toast({
                title: closedState ? "Order Closed" : "Order Opened",
                description: "Your changes were saved successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        } catch(error) {
            console.error(error);
            toast({
                title: "An Error Occurred",
                description: "There was a problem saving your changes, please try again",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        setLoading(false);
    }


    async function submitEdits(){
        setLoading(true);
        try {
            const updated:DbWorkorderNotes = { careraNotes: careraNotes, estimate: estimate }; 
            await dbDoc.doc(workorder.id!).collection("private").doc("notes").set(updated);
            initalNotes.current = updated;
            setHasChanged(false);
            setEditing(false);
            toast({
                title: "Notes Saved",
                description: "Your changes were saved successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            console.log(error);
            toast({
                title: "An Error Occurred",
                description: "There was a problem saving your changes, please try again",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        setLoading(false);
    }


    return (
        <>
        <Flex justify="space-between" align="center" px={6} py={4} id="workorder-header" >
            <Switch size="lg" mt={0.5} isChecked={!closed} onChange={() => {dbUpdate()}} colorScheme={"green"} />
                <Tag variant="subtle" size="lg" colorScheme="teal" pos="relative">
                    <TagLabel mt={1} fontWeight="bold" ml="2">{formatWorkorderNo(workorder.workorderNo)}</TagLabel>
                    { hasChanged && <Box
                        as="span"
                        pos="absolute"
                        top="2px"
                        right="2px"
                        px={2}
                        py={1}
                        fontSize="xs"
                        fontWeight="bold"
                        lineHeight="none"
                        color="red.100"
                        transform="translate(50%,-50%)"
                        bg="red.600"
                        rounded="full"
                        >
                        !
                    </Box> }
                </Tag>
            <IconButton onClick={onClose} aria-label="Close Popup" icon={<CloseIcon/>} />
        </Flex>
        <Box px={6} py={2} id="workorder-body">
            <Flex justify="space-between">
                <Text as="h2" fontSize="2xl" >{`${workorder.firstName} ${workorder.lastName}`}</Text>
                {!editing && !guestView && <IconButton size="sm" onClick={() => setEditing(true)} aria-label="edit" icon={<FontAwesomeIcon icon={faEdit} />} />}
            </Flex>
            <Text>{workorder.selectedGarageName}</Text>
            <Text mb="1">{workorder.appointment && (`${moment.unix(workorder.appointment[0]).format("HH:mm a")} - ${moment.unix(workorder.appointment[1]).format("HH:mm a")} / ${moment.unix(workorder.appointment[1]).format("Do MMM YY")} `)}</Text>
            <Flex>
                {workorder.email && <Tag mr="2" colorScheme="teal" rounded="full"><FontAwesomeIcon style={{marginRight:"4px"}} icon={faEnvelope} /> <TagLabel><Link as="a" href={`mailto: ${workorder.email}`}>{workorder.email}</Link></TagLabel> </Tag>}
                {workorder.phone && <Tag colorScheme="teal" rounded="full" > <FontAwesomeIcon style={{marginRight:"4px"}} icon={faPhone}/> <TagLabel><Link as="a" href={`tel:+1${workorder.phone}`} >+1 {formatPhoneNumber(workorder.phone)}</Link></TagLabel></Tag>}
            </Flex>
            <Text>{workorder.description}</Text>

            { workorder.insuranceProvider &&
            <Flex>
                <Text mr="2" fontWeight="bold" >{workorder.insuranceProvider}</Text>
                <Text>No: {workorder.insurancePolicy}</Text>
            </Flex>}
            {workorder.selectedServiceIds===undefined?'':(
                <ServicesList serviceIdList={workorder.selectedServiceIds} />
            )}
            {dbAttachments.length > 0 &&
                <Flex pb="3" overflowX="scroll" maxW="full">
                    {dbAttachments.map((attachment) => {
                        return (
                            <Box key={attachment.id} maxW="100px" textAlign="center">
                                <Link as="a" href={attachment.url} isExternal><FontAwesomeIcon style={{marginBottom:"0.5rem"}} icon={checkFileType(attachment.type).icon} color={checkFileType(attachment.type).color} size="3x" /></Link>
                                <Text lineHeight={1} textAlign="center" fontSize="xs" noOfLines={2} >{attachment.fileName}</Text>
                            </Box>
                        )
                    })}
                </Flex>
            }
            {
            editing && !guestView
                ? <Box>
                    <Input placeholder="Estimate" mb="3" type="number" value={estimate} onChange={(e) => {setEstimate(e.target.value);setHasChanged(true)}} />
                    <Textarea placeholder="Notes" mb="3" value={careraNotes} onChange={(e) => {setCareraNotes(e.target.value);setHasChanged(true)}} />
                    <Flex>
                        <IconButton mr="3" aria-label="submit" colorScheme="green" isDisabled={!hasChanged} onClick={() => submitEdits()} icon={<CheckIcon />} />
                        <IconButton aria-label="cancel" colorScheme="red" onClick={()=> {
                            setHasChanged(false);
                            setEditing(false);
                            setEstimate(initalNotes.current?.estimate ?? "");
                            setCareraNotes(initalNotes.current?.careraNotes ?? "");
                        }} icon={<CloseIcon />} />
                    </Flex>
                        
                    {workorder.closed===true && cancelWorkorderCB!==undefined?'':(
                        <Button borderRadius="full" color='#990000' onClick={()=>{
                            setEditing(false);setCancelPopupOpen(true)}}><FontAwesomeIcon color='#990000' icon={faTrash}/> &nbsp; Cancel Appointment</Button>
                    )}
                </Box>
                : cancelPopupOpen?
                <Box>
                    Are you sure you want to cancel this appointment?
                    <div>
                        <YesnoButton onClick={()=>{
                            if(cancelWorkorderCB!==undefined) cancelWorkorderCB(workorder);
                            setCancelPopupOpen(false);
                            setClosed(true)}}>Yes</YesnoButton>
                        <YesnoButton onClick={()=>{
                            setCancelPopupOpen(false);
                            setClosed(true)}}>No</YesnoButton>
                    </div>
                </Box>
                : <Box>
                    {estimate && <Flex align="center">
                        <Text mr="2" fontSize="sm" fontWeight="bold" >Estimate:</Text>
                        <Text>${estimate}</Text>
                    </Flex>}
                    {careraNotes.length>0 && <Text>{careraNotes}</Text>}
                </Box>
            }
        </Box>
        <Flex justifyContent="flex-start" alignItems="center" px={6} py={4} id="workorder-footer">
            {closed ? <Badge mr="2" colorScheme="red">Closed</Badge> : <Badge mr="2" colorScheme="green" >Open</Badge>}
        </Flex>
        {loading && <Box w="full" h="full" pos="absolute" bgColor="#70707062" ><Loading height="100%" logoWidth={["200px"]} /></Box>}
        </>
        )

}



export default WorkorderContent