import { Box, Flex, HStack, IconButton, Text, Image, VStack, useColorModeValue } from "@chakra-ui/react"
import { faCameraRetro, faFilePdf, faTimes, faFileImage, faFileCsv, faFileWord, faFileExcel, faFileAlt, faFile, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useMemo } from "react"
import { useDropzone } from "react-dropzone"
import { FieldValues, useFormContext, UseFormReturn } from "react-hook-form"
import DbAttachment from "../Attachments/DbAttachment"
import { StorageImageData } from "../Classifieds/AddClassifiedsForm"

interface FileInputProps {
    name: string,
    label: string,
    accept: string,
    // existingImages?: string[],
    existingImages?: DbAttachment[]|StorageImageData[],
    deleteExistingFn?: Function,
    iconMode?: boolean,
    componentIcon?: IconDefinition,
    acceptText?: string,
    hideFirst?: boolean,
    formContext: UseFormReturn<FieldValues, object>
}

interface FileIconProps {
    icon: IconDefinition,
    color: string,
}

const FileInput = ({name, label = name, accept, existingImages, deleteExistingFn, iconMode, componentIcon, acceptText, hideFirst, formContext}:FileInputProps) => {
    // const { name, label = name, existingImages } = props
    const textColor = useColorModeValue('gray.900', 'white');
    const { register, unregister, setValue, watch } = formContext
    const files = watch(name);
    const onDrop = useCallback(
        (droppedFiles) => {
            const newFiles = (!!files?.length && [...files].concat(droppedFiles)) || droppedFiles;
            setValue(name, newFiles, { shouldValidate: true, shouldDirty:true });
        },
        [setValue, name, files],
    );

    function onRemove(index:number){
        const newFiles = files.filter((file:any, i:number) => i !== index);
        setValue(name, newFiles, {shouldValidate: true});
    }

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2em 1em',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    } as React.CSSProperties;

    const activeStyle = {
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        borderColor: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744'
    };


    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: accept,
        minSize: 0,
        maxSize: 5242880
    })

    
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [ isDragActive, isDragReject, isDragAccept ]); // eslint-disable-line react-hooks/exhaustive-deps
    
    
    useEffect(() => {
        register(name)
        return () => {
            unregister(name)
        }
    }, [register, unregister, name])


    const thumbs = !!files?.length && 
        (
        <>
            {files.map((file: File, index:number) => {
                return (
                    iconMode
                    ? <Box key={`${file.name}_${Math.random()}`} pos="relative" m="2" h="24" fontSize="3rem" maxW="100px">
                        <IconButton size="sm" onClick={() => onRemove(index)} top="-12px" right="8px"  pos="absolute" isRound aria-label="delete image" icon={ <FontAwesomeIcon icon={faTimes} /> } />
                        <VStack>
                            <FontAwesomeIcon icon={checkFileType(file.type).icon} color={checkFileType(file.type).color} />
                            <Text lineHeight={1} textAlign="center" fontSize="xs" noOfLines={2} >{file.name}</Text>
                        </VStack>
                    </Box>
                    : <Box key={`${file.name}_${Math.random()}`} pos="relative" m={2} minW={24} minH={24} w={24} h={24}>
                        <IconButton onClick={() => onRemove(index)} top="-1" right="-1"  pos="absolute" isRound aria-label="delete image" icon={ <FontAwesomeIcon icon={faTimes} /> } />
                        <Image
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            rounded="full"
                            objectFit="cover"
                            w="full"
                            h="full"
                        />
                    </Box>
                    
                )
            })}
        </>
        )

    const existing = existingImages?.length &&
        (
        <>
            {existingImages.map((image, index) => {
            return (
                <Box key={index} pos="relative" m={2} minW={24} minH={24} w={24} h={24} display={hideFirst && image.order === 0 ? "none" : "block" }>
                    <IconButton onClick={() => {if(deleteExistingFn!==undefined) deleteExistingFn(image)}} top="-1" right="-1"  pos="absolute" isRound aria-label="delete image" icon={ <FontAwesomeIcon icon={faTimes} /> } />
                    <Image
                        src={image.url}
                        alt={image.fileName}
                        rounded="full"
                        objectFit="cover"
                        w="full"
                        h="full"
                    />
                </Box>
                )
            })}
        </>
        )

    return (
        <>
            <label style={{color:textColor}} className=" " htmlFor={name}>
                {label}
            </label>
            <Box
                w="full"
                {...getRootProps({style})}
                role="button"
                aria-label="File Upload"
                id={name}>
                <input name={name} accept={accept} {...getInputProps()} type="file"/>
                <FontAwesomeIcon icon={componentIcon ?? faCameraRetro} style={{height:"2em", width:"2em", marginBottom:"0.5em"}} />
                <HStack>
                    <Text as="span" fontWeight="bold" cursor="pointer" >Upload a file </Text>
                    <Text as="span">or drag and drop</Text>
                </HStack>
                <Text mt="1" as="p" fontSize="xs" >{acceptText ?? "PNG, JPG up to 5MB"}</Text>
            </Box>
            <Flex mt={4} overflowX="scroll" maxW="full" >
                {existing}
                {thumbs}
            </Flex>
        </>
    )
}

export default FileInput



export const checkFileType = (type: string):FileIconProps => {
    switch (type) {
        case "image/png":
            return {icon: faFileImage, color: "#6139b2"};
        case "image/jpeg":
            return {icon: faFileImage, color: "#6139b2"};
        case "image/jpg":
            return {icon: faFileImage, color: "#6139b2"};
        case "application/pdf":
            return {icon: faFilePdf, color: "#e14f3f"};
        case "application/csv":
            return {icon: faFileCsv, color: "#67ac5c"};
        case "application/msword":
            return {icon: faFileWord, color: "#4895ec"};
        case "application/vnd.ms-excel":
            return {icon: faFileExcel, color: "#67ac5c"};
        case "text/plain":
            return {icon: faFileAlt, color: "#755549"};
        default:
            return {icon: faFile, color: "#767676"};
    }
}