import  {useState, useEffect, useRef} from "react";
import styled from 'styled-components';
import { ReactSortable } from "react-sortablejs";

import firebase, {storage} from '../../firebase';
import DbAttachment, { DbAttachmentData } from "./DbAttachment";
import Attachment from "./Attachment";
import { ErrorText, IconButton } from "../Common/CommonStyledControls";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';


const StyledAttachments = styled.div`
    min-width: 50%;
    min-height: 100px;
    margin: 5px;
    padding: 15px;
    background-color: #dddddd7f;
    border-radius: 10px;
    display: flex;
    align-items: center;
`;

const IconPlus = <FontAwesomeIcon icon={faPlusCircle} style={{color: "#16c91f", fontSize: "1.5em", marginLeft:"0.5em"}} />;

/** the trick for custom file upload buttons is to make
 * make the <input type="file"> component hidden, then
 * call the click() method on it to make it upload.
 */
const HiddenFilesInput = styled.input`
    display: none;
`;


interface AttachmentsProps {

    /** Firestore database "collection" where attachments are listed */
    dbAttachmentCollection:firebase.firestore.CollectionReference|undefined;

    /** Firebase storage folder location where attachments are uploaded */
    storageFolder:string|undefined;

    /** a list of "file type specifiers".
     * (basically a string containing a comma-separated list of .ext file extensions and/or mime types
     * Using the magical type "audio/*","video/*", or "image/*" will also allow the camera as an option on mobile)
     * see:
     *    https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
     *    https://www.w3.org/TR/html-media-capture/#the-capture-attribute */
    acceptFileTypes:string;

    /** (optional) max size of each file (in MB) */
    maxFileSize:number|undefined;

    /** (optional) max size of all files together (in MB) */
    maxTotalSize:number|undefined;

    /** (optional) max number of attachments allowed */
    maxAttachments:number|undefined;

    /** (optional) function to call, passing our uploader function
     *  (so parent component can tell us when to upload)
    */
    onUploaderReady?:OnUploaderReady;

    /** html form input readonly property */
    readOnly?: boolean
};


/** parent function used to watch the progress of an upload */
type UploadWatcher = (percentComplete: number) => void;

/** function we give the owning component to initiate an upload */
export type Uploader = (uploadWatcher:UploadWatcher) => void;

type OnUploaderReady= (uploader:Uploader) => void;


/** general-purpose control for file attachments stored in firebase store
 * 
 * the idea is you pass in onUploaderReady(uploader) which gives you
 * access to the uploader(path) member to upload the attachments when 
 * you are ready to. */
function Attachments({dbAttachmentCollection,storageFolder,acceptFileTypes,maxFileSize,maxTotalSize,maxAttachments,onUploaderReady,readOnly=undefined}:AttachmentsProps) {
    const [loadingAttachments, setLoadingAttachments]=useState(false);
    const [error,setErrors]=useState("");
    const [attachments, setAttachments]=useState<DbAttachment[]>([]); // current attachments list

    const removeAttachmentQueue=useRef<DbAttachment[]>([]); // existing dbAttachments to remove from database
    const dbAttachments=useRef<DbAttachment[]>([]); // attachments exsiting in the db

    const MB=1024000; // definitions may vary, but we'll go with that
    
    // NOTE: Do not try to move this into useEffect() or the whole world burns.
    if(onUploaderReady!==undefined){
        onUploaderReady(storeData);
    }

    // called on component creation and any time dbAtachmentCollection changes
    useEffect(()=>{
        getAttachments();
    }
    ,[dbAttachmentCollection]); // eslint-disable-line react-hooks/exhaustive-deps

    /** get all the existing attachments from the database */
    async function getAttachments() {
        if(dbAttachmentCollection!==undefined){
            setLoadingAttachments(true);
            const results=await dbAttachmentCollection.get();//.order('order');
            const items:DbAttachment[]=[];
            results.forEach((doc)=>{
                let data=doc.data() as DbAttachment;
                let att={...data,id:doc.id};
                items.push(att);
            });
            items.sort((a,b)=>a.order-b.order);
            dbAttachments.current=items;
            setAttachments(items); // start out with these items
            setLoadingAttachments(false);
        }
    }

    /** Kick off storing the value in the database
     * If successful, will also upload the dbAttachments */
    async function storeData(uploadWatcher:UploadWatcher) {
        var total=removeAttachmentQueue.current.length+attachments.length;
        var current=0;
        uploadWatcher(0);
        try {
            // first, remove everything that has gone away
            for(let i=0;i<removeAttachmentQueue.current.length;i++){
                await deleteFirebaseAttachment(removeAttachmentQueue.current[i]);
                current+=1;
                uploadWatcher(Math.round(current/total*100));
            }
            // now update/add attachments that should be there
            for(let i=0;i<attachments.length;i++){
                let attachment:DbAttachment|undefined=attachments[i];
                if(attachment.id===''){
                    // not yet in database, so add it
                    attachment.order=i; // set the order
                    attachment=await addFirebaseAttachment(attachment);
                } else if(attachment.order!==i) {
                    // need to update the database record with the order
                    attachment=await updateFirebaseAttachmentOrder(attachment,i);
                }
                current+=1;
                uploadWatcher(Math.round(current/total*100));
            }
            uploadWatcher(100);
        } catch(error:any) {
            setErrors(error.message);
        }
    }

    /** add dbAttachments to the list */
    function queueAttachmentAdd(moreFiles:FileList|null){
        if(!moreFiles) return;
        // determine the existing total size
        var totalSize=0;
        for(let i=0;i<attachments.length;i++){
            totalSize+=attachments[i].size/MB;
        }
        // see if we can add each new file
        for(let i=0;i<moreFiles.length;i++){
            let file=moreFiles[i];
            let fileSize=file.size/MB;
            if(maxFileSize!==undefined&&fileSize>maxFileSize){
                let msg=`Unable to upload.  Maximum file size is ${maxFileSize}MB. "${file.name}" was ${fileSize}MB.`;
                setErrors(msg);
                return;
            }
            if(maxAttachments!==undefined&&attachments.length>=maxAttachments){
                let msg=`Unable to upload.  Maximum number of attachments allowed is ${maxAttachments}`;
                setErrors(msg);
                return;
            }
            totalSize+=fileSize;
            if(maxTotalSize!==undefined&&totalSize>maxTotalSize){
                let msg=`Unable to upload.  Maximum size for all attachments is ${maxTotalSize}MB. "${file.name}" puts us at ${totalSize}MB.`;
                setErrors(msg);
                return;
            }
            let newAttachment:DbAttachment={
                id:'', // empty id indicates it is a new item that needs to be uploaded
                fileName:file.name,
                storageFilename:'',
                size:file.size,
                type:file.type,
                url:URL.createObjectURL(file),
                file: file,
                order: attachments.length
            };
            setAttachments([...attachments,newAttachment]);
        }
    }

    /** similar to firebase store uploadTask only better because it's all async and nice 
     * 
     * returns downloadUrl
    */
    async function firebaseStorageUploader(path:string,data:File|Blob|Uint8Array|ArrayBuffer,onProgress:any=undefined) {
        return new Promise<string>(function(resolve,reject) {
            const storageRef=storage.ref(path);
            const uploadTask=storageRef.put(data);
            uploadTask.on('state_changed',
                function(snapshot) {
                    if(onProgress!==undefined) onProgress(snapshot.bytesTransferred/snapshot.totalBytes);
                },
                function error(err) {
                    console.log('error', err)
                    reject()
                },
                function complete() {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        resolve(downloadURL)
                    })
                }
            )
        })
    }

    /** add an attachment to firebase 
     * returns the data created
    */
    async function addFirebaseAttachment(attachment:DbAttachment){
        var ret:DbAttachment|undefined=undefined;
        if(dbAttachmentCollection===undefined){
            setErrors("No database attached");
            return ret;
        }
        const storageFilename=`${storageFolder}/${attachment.fileName}`;
        if(attachment.file!==undefined){
            //try {
                let url=await firebaseStorageUploader(storageFilename,attachment.file);
                const firestoreDbFile:DbAttachmentData={
                    fileName:attachment.fileName,
                    storageFilename:storageFilename,
                    type:attachment.type,
                    size:attachment.size,
                    url:url,
                    order:attachment.order
                }
                // now add info about the new attachment to the database
                if(dbAttachmentCollection!==undefined){
                    let result=await dbAttachmentCollection.add(firestoreDbFile);
                    ret={...firestoreDbFile,id:result.id};
                }
            /*} catch(err) {
                setErrors(err.message);
            }*/
        }
        return ret;
    }

    /**
     * update firebase item with a new attachment order
     */
    async function updateFirebaseAttachmentOrder(attachment:DbAttachment,order:number){
        let doc=await dbAttachmentCollection?.doc(attachment.id);
        await doc?.update({order});
        return {...attachment,order}
    }

    /** remove an attachment from firebase */
    async function deleteFirebaseAttachment(attachment:DbAttachment){
        if(dbAttachmentCollection===undefined){
            setErrors("No database attached");
            return;
        }
        const storageRef=storage.ref(attachment.storageFilename);
        try{
            await storageRef.delete();
            const dbRef=await dbAttachmentCollection.doc(attachment.id);
            dbRef.delete();
        } catch (err) {
            console.error(err);
        }
    }
    
    /** remove an attachment from the list
     * will que it up to be removed from firebase if it exists in that
     */
    function removeAttachment(imgUrl:string){
        // check if it is in the database and needs to be queued for removal
        let index=dbAttachments.current.findIndex((attachment)=>attachment.url===imgUrl);
        if(index>=0){
            // add to removal queue
            removeAttachmentQueue.current.push(dbAttachments.current[index]);
            // remove from database entries list
            dbAttachments.current.splice(index,1);
        }
        let newAttachments=attachments.filter((attachment)=>attachment.url!==imgUrl);
        setAttachments(newAttachments);
    }

    return (
        <StyledAttachments>
            { loadingAttachments?"Loading...":"" }
            {readOnly?"":(<>
                <ReactSortable list={attachments} setList={setAttachments}>
                    { attachments.map((attachment)=>(
                        <Attachment key={attachment.url} dbAttachmentData={attachment} onDelete={removeAttachment} />
                    )) } 
                </ReactSortable>
                <IconButton onClick={(e)=>{let el=document.getElementById('selectFile');if(el!==null){el.click()}}} >{IconPlus}</IconButton>
                <HiddenFilesInput id="selectFile" capture="environment" type="file" multiple onChange={(e)=>queueAttachmentAdd(e.target.files)} accept={acceptFileTypes} />
            </>)}
            <ErrorText>{error}</ErrorText>
        </StyledAttachments>
    );
}


export default Attachments;