import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';

import firebase, { db } from "../../firebase";
import Garage, { OnGarageSelectedFunction, OnScheduleFunction } from "./Garage";
import DbGarage from "./DbGarage";

import { AddButton } from '../Common/CommonStyledControls';
import axios from 'axios';
import PageControl from '../Common/PageControl';


const StyledGarages = styled.div`
    display: flex;
    flex-wrap: wrap;
`;


interface GaragesProps {
    
    /** (optional) parameter to limit the garages to those that match the presented services.
     * If there are no matches, return all garages.
    */
    matchingServices?:string[],

    /** (optional) firestore collection to store user actions to */
    actionsCollection?:firebase.firestore.CollectionReference,

    /** (optional) from address for determining distance */
    distanceFromAddress?:string,

    /** (optional) function to be called when garage is selected */
    onGarageSelected?:OnGarageSelectedFunction,

    /** (optional) function to be called to schedule an appointment */
    onSchedule?:OnScheduleFunction,

    /** (optional) require displayed garages to have the instantBook feature */
    requireInstantBook?:boolean
}


/** How many items per page to start with (user can change later) */
const defaultItemsPerPage=5;


// a list of garages (pulled from database or webservice)
function Garages({matchingServices,actionsCollection,distanceFromAddress,onGarageSelected,onSchedule,requireInstantBook=false}:GaragesProps) {
    const [garages, setGarages]=useState<DbGarage[]>([]);
    const [filteredGarages, setFilteredGarages]=useState<DbGarage[]>([]);
    const [showGarages, setShowGarages]=useState<DbGarage[]>([])
    const [loading, setLoading]=useState(false);
    
    const [page,setPage]=useState(0);
    const [itemsPerPage,setItemsPerPage]=useState(defaultItemsPerPage);

    //const { currentUser }  = useAuth();

    const garagesCollection = db.collection("garages");
    const editable:boolean = false;//auth===null||auth.isAdmin===true; // admins get to see add and edit icons

    // CHOOSE ONE:
    // production server
    const webservice="https://us-central1-carera-be9c6.cloudfunctions.net/getGarages?fromAddress=";
    // local firebase emulator
    //const webservice="https://localhost:5001/carera-be9c6/us-central1/getGarages?fromAddress=";

    /** get all the garages from the database */
    async function getGarages() {
        setLoading(true);
        if(distanceFromAddress===undefined){
            // may as well get the list directly from the database
            const unregister=garagesCollection.onSnapshot(
                (querySnapshot)=>{
                    let items:DbGarage[]=[];
                    querySnapshot.forEach((doc)=>{
                        items.push({...doc.data() as DbGarage,id:doc.id});
                    });
                    items=items.sort((a,b)=>{
                        if (a.name<b.name) {
                            return -1;
                        }
                        if (a.name>b.name) {
                            return 1;
                        }
                        return 0;
                    });
                    setGarages(items);
                    setLoading(false);
                    unregister();
                },
                (error:any) => {
                    console.error("Garages.tsx: Error getting garages");
                    console.error(error);
                    setLoading(false);
                }
            );
        } else {
            // get the list from the webservice (which also calulates distance)
            var response=null;
            var url=webservice+encodeURIComponent(distanceFromAddress);
            try{
                //console.log('Get garages with == ',url);
                response=await axios.get(url);
                var items:DbGarage[]=response.data as DbGarage[];
                items.sort((a,b)=>(b.distance===undefined||b.distance===0||a.distance===undefined||a.distance===0)?0:a.distance-b.distance); // order by shortest distance
                setGarages(items);
            }catch(error:any){
                if(error.name==="NetworkError"){
                    console.error(error.request)
                    console.error(error)
                    console.error(`${error.response.status} - ${error.response.statusText}`);
                }else{
                    throw(error);
                }
            } finally {
                setLoading(false);
            }
        }
    }

    /**
     * called when the page we are on changes
     */
     async function onPageChange(startItem:number,count:number){
        let pageNo=Math.floor(startItem/count);
        setPage(pageNo);
        // we are getting the next page from the filtered garage set
        setShowGarages(filteredGarages.slice(startItem,startItem+count));
        if(count!==itemsPerPage){
            setItemsPerPage(count);
        }
    }

    /** 
     * go back to the first page when the filtered garage list changes
     */
    useEffect(()=>{
        // Jump back to the first page
        onPageChange(0,itemsPerPage);
    },[filteredGarages,itemsPerPage]);

    /**
     * filter a list of garages by requiredServices and returns only those that match 
     */
    useEffect(()=>{
        if(matchingServices===undefined) {
            // there is nothing to match upon, so take everything
            setFilteredGarages(garages);
            return;
        }
        var results:DbGarage[]=[];
        for(let i=0;i<garages.length;i++){
            let garage=garages[i];
            if(!requireInstantBook||garage.instantBook) { // match the instantBook requirement first thing
                let shouldAdd=true;
                for(let j=0;j<matchingServices.length;j++){
                    if(garage.services.indexOf(matchingServices[j])<0){
                        // we know this particular garage is missing a required service, so don't add it
                        shouldAdd=false;
                        break;
                    }
                }
                if(shouldAdd){
                    results.push(garage);
                }
        }
        }
        if(results.length<1){
            // there were no garages that matched, so take everything
            setFilteredGarages(garages);
        } else {
            // take the results
            setFilteredGarages(results);
        }
    },[garages,matchingServices]);

    // this is set up to run once on component creation
    // we use it to kick off the database query
    useEffect(()=>{
        getGarages();
      }
    ,[]);// eslint-disable-line react-hooks/exhaustive-deps

    // return the JSX control
    return (<StyledGarages>
        {loading?"loading...":""}
        {showGarages.map((garage)=>
            (<Garage key={garage.id}
                garage={garage}
                editable={editable} 
                actionsCollection={actionsCollection}
                onGarageSelected={onGarageSelected}
                onSchedule={onSchedule}
            />)
        )}
        { // if we are editable, add an "add another" button
            editable&&!loading?(
                <NavLink to="/garages/add"><AddButton/></NavLink>
            ):''
        }
        <PageControl totalItems={filteredGarages.length} itemsPerPage={itemsPerPage} page={page} onPageChange={onPageChange} pagLoadingState={loading} />      
    </StyledGarages>);
}

export default Garages;