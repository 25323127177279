import React, { useContext, useState } from 'react';
import { Box, Center, useDisclosure, useColorModeValue, Stack, Text, Flex, Avatar, VStack, Spacer, Tag, Wrap, Badge, Button, LinkBox, LinkOverlay } from "@chakra-ui/react";
import DbWorkorderType, { formatWorkorderNo } from "./DbWorkorderType";
import { AvailableInsuranceProvidersContext, AvailableInsuranceProvidersContextType } from '../InsuranceProviders/AvailableInsuranceProviders';
import WorkorderContentView from './WorkorderContentView';
import moment from 'moment-timezone';
import AuthContext from '../Account/AuthContext';
import { confirmWorkorder } from './WorkorderConfirm';


export default function WorkorderCard({workorder, updateState, guestView, cancelWorkorderCB}:
    {workorder:DbWorkorderType, updateState?:Function, guestView?:boolean, cancelWorkorderCB:Function}){

    const availableInsuranceProviders=useContext(AvailableInsuranceProvidersContext) as AvailableInsuranceProvidersContextType;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const createdDate = workorder.createdDate;
    const newDate = moment().subtract(5, 'days');
    const Auth = useContext(AuthContext);
    const [msg,setMsg]=useState('Confirm Appointment');
    return (
    <>
    <Center>
        <Box 
            onClick={onOpen}
            cursor={'pointer'}
            position={'relative'}
            height={'100%'}
            maxW={"380px"}
            w={"full"}
            boxShadow={["md", "md", "none"]}
            _after={{
                md: {
                    rounded:'xl',
                    content: `""`,
                    boxShadow:'2xl',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    transition: 'box-shadow 0.3s ease-in-out',
                }
            }}
            _hover={{
                _after:{
                    boxShadow:'md',
                }
            }}
        >
            <Box
                w={'full'}
                bg={useColorModeValue('white', 'gray.900')}
                rounded={'md'}
                p={6}
                transition={'0.3s'}
                position={'relative'}
            >
                <Box>
                    {
                    workorder.cancelled !== false &&
                            <Box pos={"absolute"} w={"150px"} h={"150px"} overflow={"hidden"} top={"-10px"} left={"-10px"} _before={{"position":"absolute", "content":"''", "display":"block", "border":"5px solid #c24c4c","borderLeftColor":"transparent", "borderTopColor":"transparent", "top":"0", "right": "57px"}} _after={{"position":"absolute", "content":"''", "display":"block", "border":"5px solid #c24c4c", "borderTopColor":"transparent", "borderLeftColor":"transparent", "bottom":"57px", "left":"0"}} >
                                <Box as={"span"} pos={"absolute"} display={"block"} width={"140px"} padding={"10px 0 7px 10px"} backgroundColor={"red.400"} color={"#fff"} textAlign={"center"} right={"50px"} top={"20px"} transform={"rotate(-45deg)"} zIndex={"1"} textTransform={"uppercase"} fontSize={"smaller"} >
                                    Canceled
                                </Box>
                            </Box>
                    }
                    {
                    workorder.confirmed === false && workorder.cancelled === false &&
                            <Box pos={"absolute"} w={"150px"} h={"150px"} overflow={"hidden"} top={"-10px"} left={"-10px"} _before={{"position":"absolute", "content":"''", "display":"block", "border":"5px solid #a14cc2","borderLeftColor":"transparent", "borderTopColor":"transparent", "top":"0", "right": "57px"}} _after={{"position":"absolute", "content":"''", "display":"block", "border":"5px solid #a14cc2", "borderTopColor":"transparent", "borderLeftColor":"transparent", "bottom":"57px", "left":"0"}} >
                                <Box as={"span"} pos={"absolute"} display={"block"} width={"140px"} padding={"10px 0 7px 10px"} backgroundColor={"purple.400"} color={"#fff"} textAlign={"center"} right={"50px"} top={"20px"} transform={"rotate(-45deg)"} zIndex={"1"} textTransform={"uppercase"} fontSize={"smaller"} >
                                    Pending
                                </Box>
                            </Box>
                    }
                    <Flex alignItems={"center"}>
                        <Avatar name={workorder.firstName && `${workorder.firstName} ${workorder.lastName}`} size={"md"} />
                        <VStack spacing={0} ml={4} align={"flex-start"}>
                            {workorder.firstName && <Text noOfLines={1} as={"h4"} fontSize={"lg"} fontWeight={"bold"} pos={"relative"} overflow={"visible"} >
                                {`${workorder.firstName} ${workorder.lastName}`}
                                {
                                    createdDate !== undefined && moment.unix(createdDate).isAfter(newDate) && <Badge pos={"absolute"} left={"-12px"} top={"-10px"} colorScheme={"teal"} >New</Badge>
                                }
                            </Text>}
                            <Text fontSize={"sm"} lineHeight={"1"} color={"gray.500"}>{workorder.createdDate===undefined?'':moment.unix(workorder.createdDate).format("MM/DD/YY")}</Text>
                        </VStack>
                    </Flex>
                    <Stack spacing={0} mt={4}>
                        <Flex align="center" >
                            <Tag fontSize="sm" fontWeight="bold" bg={workorder.closed ? "red.50" : "green.50"} >{formatWorkorderNo(workorder.workorderNo)}</Tag>
                            <Spacer/>
                            {/* {workorder.estimate && <Text mr="1.5" fontSize="xs" color="gray.400" >est</Text>}
                            <Text fontSize="sm" fontWeight="bold" >{workorder.estimate}</Text> */}
                        </Flex>
                        <Text noOfLines={1} >{`${(workorder.insuranceProvider===undefined||workorder.insurancePolicy===undefined)?"(No insurance)":availableInsuranceProviders.getInsuranceProvider(workorder.insuranceProvider)}`}</Text>
                        {
                            Auth.isAdmin && <Text noOfLines={1} w={"full"} >{workorder.selectedGarageName}</Text>
                        }
                        {
                            workorder.appointment && <Text noOfLines={1} w={"full"} >{moment.unix(workorder.appointment[0]).tz("America/Los_Angeles").format("ddd, MMM DD YYYY \\at h:mm A")}</Text>
                        }
                        {Auth.isGarage && !workorder.confirmed &&
                            <Button zIndex={1} onClick={(e) => {e.stopPropagation();confirmWorkorder(workorder.id!, Auth, setMsg)}}>{msg}</Button>
                        }
                    </Stack>
                </Box>
            </Box>
        </Box>
    </Center>
    <WorkorderContentView isOpen={isOpen} onClose={onClose} workorder={workorder} updateState={updateState} guestView={guestView} cancelWorkorderCB={cancelWorkorderCB} />
    </>
    )
}
