import { Text, Heading, Box, IconButton, Wrap, Tag, Input, Link, Collapse, Button, HStack, useToast, Flex, Skeleton, Image } from "@chakra-ui/react"
import firebase, { db } from "../../firebase";
import DbGarage, { DbGarageData } from "./DbGarage";
import SchedulingInfo from '../Calendar/schedulingInfo';
import { useEffect, useRef, useState } from "react";
import { PreloadImage } from "../Common/preloadImage";
import FsLightBox from "fslightbox-react";

import ServicesList from "../Services/ServicesList";
import { AddressType, jsonAddressToObject } from "../Common/AddressForm";
import Reviews from "./Reviews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faGlobe } from "@fortawesome/free-solid-svg-icons";
import EditGarageForm from './EditGarageForm'

import { useHistory } from "react-router";
import OpeningHours from "../Common/OpeningHours";
import DbAttachment from "../Attachments/DbAttachment";
import { formatPhoneNumber } from "../Helpers/phoneFormatting";
import placeHolder from '../../placeholder.png'


interface OnGarageSelectedFunction { (id: string): void }
interface OnScheduleFunction { (info:SchedulingInfo): void }
type UpdateGarageFn=(garage:DbGarage)=>void;
type DeleteGarageFn=(garageId:string)=>void;
export type OpeningObj = {
    opening: string,
    closing: string,
}

export interface GarageProps {

    /** database info about this garage */
    garage:DbGarage,

    /** is the garage editable for this user? */
    editable:boolean,

    /** (optional) database location to log user's actions to */
    actionsCollection?:firebase.firestore.CollectionReference,

    /** (optional) function to be called when this garage's scheduling calendar is to be accessed */
    onSchedule?:OnScheduleFunction,

    /** (optional) function to be called when this garage is selected */
    onGarageSelected?:OnGarageSelectedFunction,

    /** check if the modal is open */
    isOpen: boolean,

    /** function to close the modal */
    onClose: () => void,

    /** function to update the garage on db and state */
    updateGarage?: UpdateGarageFn,

    /** function to delete the garage on db and state */
    deleteGarage?: DeleteGarageFn,

    editMode: boolean,

    setEditMode?: (value: React.SetStateAction<boolean>) => void,

};

const GarageContent = ({garage,editable,actionsCollection,onSchedule,onGarageSelected, isOpen, onClose, updateGarage, deleteGarage, editMode, setEditMode}:GarageProps) => {

    const [currentGarage,setCurrentGarage]=useState(garage);
    const [imageUrls,setImageUrls]=useState<string[]>([]);
    const [dbAttachments, setDbAttachments]=useState<DbAttachment[]>([]);
    const [loadingImages,setLoadingImages]=useState(true);
    const [services, setServices]= useState<string[]>(currentGarage.services);
    const openingDefaults = useRef<OpeningObj[]>([]);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
    const toast = useToast();

    const [show, setShow] = useState(false)

    const handleToggle = () => setShow(!show)

    const history = useHistory();

    const garagessCollection = db.collection("garages");

    /** get all the image urls from the database */
    function getImages() {
        // setLoadingImages(true);
        const garageImagesSubCollection=db.collection("garages").doc(currentGarage.id).collection("images");
        const unregister=garageImagesSubCollection.onSnapshot(
            (querySnapshot)=>{
                // const items:string[]=[];
                const items:DbAttachment[]=[];
                const urls:string[]=[];
                querySnapshot.forEach((doc)=>{
                    let data=doc.data() as DbAttachment;
                    // items.push(doc.data().url);
                    items.push({...data,id:doc.id});
                });
                if(items.length>0){
                    items.sort((a,b) => a.order - b.order);
                    items.map((item) => {
                        urls.push(item.url);
                    })
                }
                // setImageUrls(items);
                setDbAttachments(items);
                setImageUrls(urls);
                setLoadingImages(false);
                unregister();
            },
            (error:any) => {
                console.error("GarageContent.tsx: Error getting images for garage",currentGarage.id);
                console.error(error);
                setLoadingImages(false);
            }
        );
    }

    // this is set up to run once on component creation
    useEffect(()=>{
        getImages();
    },[]);

    // change services when the current garage changes
    useEffect(()=>{
        setServices(currentGarage.services);
    },[currentGarage]);

    /** create the day times array to be sent to the editor component */
    useEffect(() => {
        function openingHours(times:string){
            const hoursArray:Array<string> = JSON.parse(times!);
            const openingTimes:OpeningObj[] = hoursArray.map((hours, i) => {
                if(hours.length !== 0){
                    const hour = Object.values(hours);
                    const formattedOpening = hour[0][0];
                    const formattedClosing = hour[0][1];
                    const completedDay:OpeningObj = {opening: formattedOpening, closing: formattedClosing}
                    return completedDay;
                } else {
                    const emptyDay:OpeningObj = { opening: "", closing: ""}
                    return emptyDay; 
                }

            })
            openingDefaults.current = openingTimes;
        }
        if(currentGarage.storeHours){
            openingHours(currentGarage.storeHours)
        }
    }, [])

    /** log a single action to actionsCollection, if provided */
    async function logAction(actionName:string){
        if(actionsCollection===undefined) return;
        let dbFile={
            name:actionName,
            garageId:currentGarage.id,
            timestamp:Date.now(),
        };
        try {
            let newDocRef=actionsCollection.doc(); // creates new entry
            newDocRef.set(dbFile);
        } catch(error) {
            console.error(error);
        }
    }

    /** select this garage */
    function selectGarage(){
        if(onGarageSelected!==undefined){
            onGarageSelected(currentGarage.id);
        }
    }
    

    function getMapUrl(){
        // determine what to use
        var mapAgent='google';
        if(navigator.userAgent.includes("Mac")){
            mapAgent='apple';
        }
        if(navigator.userAgent.includes("Android")){
            mapAgent='google';
        }
        if(navigator.userAgent.includes("Windows")){
            mapAgent='bing';
        }
        // now create a url based upon that
        var url='';
        if(mapAgent==='apple'){
            // see: https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
            url=`https://maps.apple.com/?daddr=${encodeURIComponent(currentGarage.address)}&dirflg=d&t=h`;
        }else if(mapAgent==='bing'){
            // see: https://docs.microsoft.com/en-us/bingmaps/articles/create-a-custom-map-url
            url=`https://bing.com/maps/default.aspx?rtp=~adr.${encodeURIComponent(currentGarage.address)}&ss=${currentGarage.name}`;
        }else{ // use google maps
            // see: https://developers.google.com/maps/documentation/urls/get-started
            url=`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(currentGarage.address)}&destination_place_id=${currentGarage.name}`;
        }
        return url;
    }

    /** format drive time as a nice string */
    function driveTime(t?:number){
        if(t===undefined) return '';
        let h=Math.trunc(t/60);
        let m=t%60;
        if(h>0) return `( ${h} hr ${m} min )`;
        return `( ${m} min )`;
    }

    const createAddress = (address:string) => {
        const fullAdd:AddressType = jsonAddressToObject(address) 
        return (
            <>
            <Text>{fullAdd.line1}</Text>
            <Text>{`${fullAdd.city}, ${fullAdd.state} ${fullAdd.zip}`}</Text>
            </>
        )
    }

    const createEditAddress = (address:string) => {
        const fullAdd:AddressType = jsonAddressToObject(address) 
        return (
            <>
            <Input value={fullAdd.line1} placeholder={"Address"} size={"sm"} />
            <Input value={fullAdd.city} placeholder={"Address"} size={"sm"} />
            <Input value={fullAdd.zip} placeholder={"Address"} size={"sm"} />
            </>
        )
    }


    function onGarageChanged(newGarage:DbGarageData){
        setCurrentGarage({...currentGarage,...newGarage});
    }

    function openLightboxOnSlide(number:number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    function EditMode(){
        return (
            <EditGarageForm garage={currentGarage} times={openingDefaults.current} onGarageChangeFn={onGarageChanged} setEdit={setEditMode!} deleteGarage={deleteGarage!} images={dbAttachments} />
        )
    }

    function ReadMode(){
        return (
            <>
            {loadingImages 
                ? <Skeleton h="200"/> 
                : dbAttachments.length>0 && dbAttachments[0].order === 0
                    ? <PreloadImage src={imageUrls[0]} loadingImages={loadingImages} height={"200px"} />
                    : <Image src={placeHolder} h="200px" fit="cover" width="full" />
            }
            <Box p={4} pt={4} pos={"relative"}>
                {deleteGarage && updateGarage && setEditMode && <IconButton onClick={() => setEditMode(!editMode)} icon={<FontAwesomeIcon icon={faEdit} />} aria-label={"edit"} pos={"absolute"} right={2} top={2} />}
                <Flex mb={4}>
                    {currentGarage.phone && 
                    <Link href={`tel:${currentGarage.phone}`}>
                        <Tag mr="2" whiteSpace="nowrap" borderRadius="full" colorScheme="green" fontWeight={"bold"} >{formatPhoneNumber(currentGarage.phone)}</Tag>
                    </Link>}
                    {currentGarage.url &&
                        <Link href={`https://${currentGarage.url}`} target="_blank">
                            <Tag borderRadius="full" colorScheme="green" fontWeight="bold"><FontAwesomeIcon icon={faGlobe} /></Tag>
                        </Link>
                    }
                </Flex>
                {Reviews(currentGarage)}
                <Heading as="h4" size="md">{currentGarage.name}</Heading>
                {createAddress(currentGarage.address)}
                {currentGarage.description && 
                    <>
                    <Box mt={show?2:0}>
                    <Collapse startingHeight={0} in={show}>
                        {currentGarage.description}
                    </Collapse>
                    </Box>
                    <Button size="sm" onClick={handleToggle} mt={2}>
                        {show ? "Hide" : "Description"}
                    </Button>
                    </>
                }
                {currentGarage.storeHours && 
                    <>
                        <Box mt={show?2:0}>
                        <Collapse startingHeight={0} in={show}>
                            <OpeningHours times={currentGarage.storeHours} />
                        </Collapse>
                        </Box>
                        <Button size="sm" onClick={handleToggle} mt={2}>
                            {show ? "Hide" : "Times"}
                        </Button>
                    </>
                }
                <Wrap my={4}>
                    <ServicesList serviceIdList={services} />
                </Wrap>
                {imageUrls.length > 1 && 
                <>
                    <HStack w="full" overflowX="scroll">
                        {imageUrls.slice(dbAttachments[0].order===0 ? 1 : 0).map((image, index) => {
                            return (
                                <Box key={index} onClick={()=> openLightboxOnSlide(index+2)} borderRadius="lg" overflow="hidden" minW="150px" maxW="150px" cursor="pointer">
                                    <PreloadImage src={image} loadingImages={loadingImages} height={"150px"} width={"150px"} />
                                </Box>
                            )
                        })}
                    </HStack>
                </>
                }
            </Box>
            </>
        )
    }


    return (
        <>
        {/* <PreloadImage src={imageUrls[0]} loadingImages={loadingImages} height={"200px"} /> */}
        {/* <Box p={4} pt={4} pos={"relative"}> */}
            {editMode ? <EditMode/> : <ReadMode/>}
        {/* </Box> */}
        <FsLightBox 
            type="image"
            toggler={lightboxController.toggler}
            sources={imageUrls}
            slide={lightboxController.slide}
        />
        </>
        )
    }

export default GarageContent