import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import DbWorkorderType from './DbWorkorderType';
import firebase, { db } from '../../firebase';
import Loading from '../Common/Loading';
import { NoResults } from '../Common/Graphics';
import { motion } from 'framer-motion';
import { SimpleGrid, useToast } from '@chakra-ui/react';
import WorkorderCard from './WorkorderCard';
import { AvailableInsuranceProviders } from '../InsuranceProviders/AvailableInsuranceProviders';

function WorkorderGarageView() {
    const [loading, setLoading] = useState(true); // initial state of page
    const [displayedWorkorders,setDisplayedWorkorders]=useState<DbWorkorderType[]>([]);
    const workordersCollection=db.collection("work_orders");
    const toast = useToast();

    var {id}=useParams<{id:string}>();


     // this is set up to run once on component creation
    useEffect(()=>{
        async function getWorkorder(){
            if(id !== undefined){
                try {
                    let filteredWorkorders:DbWorkorderType[] = [];
                    await workordersCollection.where("selectedGarageId", "==", id).get().then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            let data = doc.data() as DbWorkorderType;
                            let docId = doc.id;
                            const order:DbWorkorderType = {...data, id: docId}; 
                            filteredWorkorders.push(order);
                        })
                    })
                    if(filteredWorkorders.length) {
                        setDisplayedWorkorders(filteredWorkorders);
                    }
                } catch (error) {
                    console.log(error);
                    toast({
                        title: "Error",
                        description: "Sorry, there was an error, please try again",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    })
                }
                setLoading(false);
            }
        }
        getWorkorder();
    }
    ,[]);


    return (
        <>
        <AvailableInsuranceProviders>
        {!loading
            ? displayedWorkorders.length > 0
                ? <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}>
                    <SimpleGrid minChildWidth="250px" spacing="30px" px={[0,4]} my={6} w={"100%"} gridAutoRows={"minmax(160px, min-content)"}>
                        { displayedWorkorders!.map((workorder, i)=>(
                        <WorkorderCard key={i} workorder={workorder} guestView={true} cancelWorkorderCB={(wo:DbWorkorderType)=>console.log(wo)}/>
                        )) }
                    </SimpleGrid>
                </motion.div>
                : <NoResults />
        : <Loading/>
        }
        </AvailableInsuranceProviders>
        </>
    )
}

export default WorkorderGarageView
