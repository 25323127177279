import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Badge, Box, Button, Flex, Heading, HStack, IconButton, Image, LinkBox, LinkOverlay, ListItem, Stack, Table, Tag, Tbody, Td, Text, Th, Thead, Tr, UnorderedList, useDisclosure, useToast } from "@chakra-ui/react";
import { CloseIcon } from '@chakra-ui/icons'
import React, { useContext, useState } from "react";
import { db, functions } from "../../firebase";
import AuthContext from "../Account/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { ClassifiedListing } from "./AddClassifiedsForm";
import { Link as ReactRouterLink } from "react-router-dom";

export default function ClassifiedsTableView({classifieds, setList, itemCount}:{
    classifieds:ClassifiedListing[], 
    setList: React.Dispatch<React.SetStateAction<ClassifiedListing[]>>,
    itemCount:number
}){

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef:any = React.useRef()
    const [itemId, setItemId] = useState<string|undefined>(undefined);
    const toast = useToast();
    const Auth = useContext(AuthContext);
    const newDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const deleteClassified = functions.httpsCallable('deleteClassified');
    const items = classifieds.slice(0,itemCount);
    const smBreakpoint = 480;
    
    const onConfirm = (id:string) => {
        setItemId(id)
        onOpen()
    }

    const onCloseDialog = () => {
        setItemId(undefined);
        onClose()
    }

    const onDelete = async () => {
        if(itemId){
            try {
                // await classifiedsRef.doc(itemId).delete();
                const result = await deleteClassified(itemId)
                const id = result.data as string
                if(id === itemId){
                    setList((oldList) => {
                        const newList = oldList.filter((item)=>{
                            return item.id !== itemId
                        })
                        return newList
                    })
                    // setItemId(undefined)
                    onClose()
                    toast({
                        title:'Listing Deleted',
                        description: `The listing was successfully deleted`,
                        status: 'success',
                        duration: 5000,
                        isClosable: true
                    })
                }
            } catch (error) {
                console.log(error)
                toast({
                    title:'Error',
                    description: `There was an error removing the listing, please try again`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                })
            }
        }
    }
    
    return (
        <>
        <Box px={[0,4]} overflowX="auto" w="full" >
            {
            /**********************************/
            /* table view */
            /**********************************/
            }
            <UnorderedList m={'0'}>
                <Box w={'full'} borderTop={'1px'} borderColor={'gray.200'}/>
                {
                    items.map((classified) => (
                        <ListRow key={classified.id} classified={classified} onConfirm={onConfirm} isAdmin={Auth.isAdmin} newDate={newDate} />
                    ))
                }
            </UnorderedList>
        </Box>
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onCloseDialog}>
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>Delete Listing</AlertDialogHeader>

                <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseDialog}>
                    Cancel
                </Button>
                <Button colorScheme='red' onClick={onDelete} ml={3}>
                    Delete
                </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        </>
    )
}

const ListRow = ({classified, onConfirm, isAdmin, newDate}:{classified:ClassifiedListing, onConfirm:(id:string)=>void, isAdmin:boolean|undefined, newDate:Date}) => {
    const listingDate = classified.createdAt as firebase.firestore.Timestamp
    // const newListing = listingDate.toDate() > newDate

    return (
        <LinkBox as='li' w={'full'} listStyleType={'none'} borderBottom={'1px'} borderColor={'gray.200'} py={4}>
            <Stack direction={'row'}>
                <Box minW={['100px','200px']} w={['100px','200px']} h={['100px','200px']} border={'1px'} borderColor={'gray.200'} mr={2}>
                {
                    classified.thumbUrl
                    ? <Image width={'full'} height={'full'} objectFit={"cover"} src={classified.thumbUrl}/>
                    : <Stack h={'full'} justifyContent={'center'} alignItems={'center'}><FontAwesomeIcon icon={faImage} color={'lightgray'} style={{fontSize:"75px"}} /></Stack>
                }
                </Box>
                <Stack direction={'column'} w={'full'}>
                    <LinkOverlay as={ReactRouterLink} to={`/dashboard/classified/${classified.id}`} >
                        <Heading as='h2' size='md' noOfLines={1}>{classified.title}</Heading>
                    </LinkOverlay>
                    {/* {
                        newListing && <Stack direction={'row'}><Tag size={'sm'} colorScheme={'green'}>New Listing</Tag></Stack>
                    } */}
                    <Text noOfLines={2}>{classified.summary}</Text>
                    <Heading size={'lg'}>${classified.price}</Heading>
                </Stack>
                {isAdmin && <IconButton onClick={()=>onConfirm(classified.id)} bg={"gray.200"} aria-label="search" icon={<CloseIcon/>}/>}
            </Stack>
        </LinkBox>
    )
}

function TableRow({classified, onConfirm, isAdmin}:{classified:ClassifiedListing, onConfirm:(id:string)=>void, isAdmin:boolean|undefined}){

    // const thumbImage:string|undefined = classified.images && classified.images[0].thumbUrl

    return (
        <>
        <Tr>
            <Td textAlign={"center"} w="50px">
                {
                    classified.thumbUrl
                    ? <Image marginInline={"auto"} maxH={"50px"} maxW={"100px"} src={classified.thumbUrl} />
                    : <FontAwesomeIcon icon={faFileImage} style={{fontSize:"50px"}} />
                }
            </Td>
            <Td w={'15%'} fontSize="sm" fontWeight="bold" px="6" pos={"relative"} >
                <Heading as={'h4'} size={'sm'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} >{classified.title}</Heading>
            </Td>
            <Td w={'55%'} pos={"relative"} px="6" textOverflow="ellipsis" >
                <Text>{classified.description}</Text>
            </Td>
            <Td w={'15%'} textOverflow="ellipsis">${classified.price}</Td>
            {isAdmin && <Td w={'10%'} textAlign={"right"} ><IconButton onClick={()=>onConfirm(classified.id)} bg={"gray.200"} aria-label="search" icon={<CloseIcon/>}/></Td>}
        </Tr>
        </>
    )
}