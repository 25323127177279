import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../firebase';
import { LogoutGraphic } from '../Common/Graphics';

import AuthContext, {clearAuthContext} from "./AuthContext";


/** page to log the user out */
function Logout(){
    const Auth = useContext(AuthContext);
    const history = useHistory();

    /** actually performs the logout (after a brief delay) */
    async function onLogout(){
        await auth.signOut();
        clearAuthContext(Auth);
        // redirect to the top page
        history.push('/');
    }

    // run on startup
    useEffect(()=>{
          // set a timer to allow the user to feel like something is happeing
          // before we redirect
          window.setTimeout(onLogout,1500);
      }
    ,[]);// eslint-disable-line react-hooks/exhaustive-deps

    // return (<p>Logging out...</p>);
    return (
        <LogoutGraphic />
    )
}


export default Logout;