import React, { useState, useEffect, useRef } from 'react';
import styled from "styled-components";
import moment from "moment";
import { RouteProps, useHistory } from 'react-router-dom';

import { Button, InputTextareaLabel, StyledCheckbox } from "../Common/CommonStyledControls";
import {ShareCalendarHelp, SharedCalendarHelp} from './CalendarHelp';
import { WeekTimespans, WeekSelect } from './TimeSpansSelector';

import { db } from '../../firebase';


// for local testing.
// ALWAYS comment out before checking in code
//const calendarsService="http://localhost:5001/carera-be9c6/us-central1/calendars/garages/";

const calendarsService="https://us-central1-carera-be9c6.cloudfunctions.net/calendars/garages/";


const CalendarLink=styled.textarea`
    border: none;
    border-bottom: 1px solid black;
    display: inline-block;
    min-width: 33%;
    max-width: 80%;
    height:20px;
    resize: none;
    white-space: nowrap;
    overflow: visible;
`;

const CopyButton=styled.button`
    border: 1px solid black;
    border-radius: 3px;
    min-width:1em;
    cursor:pointer;
    margin-left:3px;

    &:after {
        content: "📋";
    }
`;

const Indent=styled.div`
    margin-left:50px;
`;

const LimitCheckbox=styled.div`
    font-size:150%;
`;


type DbTimespans=[[number,number][],[number,number][],[number,number][],[number,number][],[number,number][],[number,number][],[number,number][]];

interface CalendarSettingsProps {
    
    /** Magical way to get cgi query parameters part of the url */
    location: RouteProps["location"];
};

/** Component to set up all calendar settings */
function CalendarSettings({location}:CalendarSettingsProps){

    const [limitHoursChecked,setLimitHoursChecked]=useState(true);
    const [limitToCalendarChecked,setLimitToCalendarChecked]=useState(false);
    const [limitToHours,setLimitToHours]=useState<DbTimespans>([[],[],[],[],[],[],[]]);
    const [limitToHoursString,setLimitToHoursString]=useState('');
    const [garageCalendar,setGarageCalendar]=useState('');
    const [instantBook, setInstantBook]=useState(false);

    const garageId=useRef('');

    const history = useHistory();

    const defaultHours="[[],[[900,1200],[1300,1700]],[[900,1200],[1300,1700]],[[900,1200],[1300,1700]],[[900,1200],[1300,1700]],[[900,1200],[1300,1700]],[]]";

    /** get limitToHours as time strings */
    function getHoursAsStrings(dbTimespans:DbTimespans|null):WeekTimespans{
        var ret:WeekTimespans=[[],[],[],[],[],[],[]];
        if(dbTimespans!==null){
            ret=dbTimespans.map(d=>d.map(ts=>ts.map(t=>{
                // for each time in the data, convert to string
                const mo=moment();
                mo.hour(Math.floor(t/100));
                mo.minute(t%100);
                return mo.format('LT');
            }))) as WeekTimespans;
        }
        return ret;
    }

    /** called whenever limitToHoursString changes */
    useEffect(()=>{
        if(limitToHoursString!==''){
            setLimitToHours(JSON.parse(limitToHoursString));
        }
    },[limitToHoursString]);

    /** load the current settings from the database */
    async function loadSettings(){
        const docRef=db.collection('garages').doc(garageId.current);
        const results=await docRef.get();
        const data=results.data() as {limitToHours?:string,garageCalendar?:string, instantBook?:boolean};
        if(data.limitToHours===undefined||data.garageCalendar===null){
            // also give it a default in case they check the box
            setLimitToHours(JSON.parse(defaultHours));
            setLimitToHoursString(defaultHours);
            setLimitHoursChecked(false);
        } else {
            setLimitToHours(JSON.parse(data.limitToHours));
            setLimitToHoursString(data.limitToHours);
            setLimitHoursChecked(true);
        }
        if(data.garageCalendar===undefined||data.garageCalendar===null){
            setGarageCalendar('');
            setLimitToCalendarChecked(false);
        } else {
            setGarageCalendar(data.garageCalendar);
            setLimitToCalendarChecked(true);
        }
        setInstantBook(data.instantBook===true);
    }

    /** save changed settings to the database */
    async function saveSettings(){
        const docRef=db.collection('garages').doc(garageId.current);
        await docRef.update({
            garageCalendar:limitToCalendarChecked?garageCalendar:null,
            limitToHours:limitHoursChecked?JSON.stringify(limitToHours):null, // firebase doesn't like nested arrays, so we'll encode it as string
            instantBook: instantBook,
        });
        // go back to the dashboard
        history.push('/dashboard');
    }

    /** copy button pressed */
    function copyCalendarLink(){
        var copyText=document.getElementById("calendarLink") as HTMLInputElement;
        if(copyText!==null){
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */
        }
        document.execCommand("copy");
    }

    /** when the control text changes, update the structure to be saved in the db */
    function onWeekChanged(changedWeekData:WeekTimespans){
        let newWeekData=changedWeekData.map(d=>d.map(ts=>ts.map(t=>{
            // for each time in the data
            let m=moment(t,'H:mm A');
            return m.hours()*100+(m.minutes()); // 24 hr clock, eg "1:15 PM" = 1315
        }))) as DbTimespans;
        setLimitToHours(newWeekData);
    }

    /** Run once upon startup */
    useEffect(()=>{
        if(location!==undefined){
            let pth=location.pathname.split('garages/');
            if(pth.length>=2){
                let gar=pth[1].split('/')[0];
                if(gar!=='add'){
                    garageId.current=gar;
                }
            }
            loadSettings();
        }
    },[]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (<div>
        <h1>CalendarSettings</h1>
        <Indent>
        <h2>Add appointements to my calendar</h2>
        <Indent>
            <div><CalendarLink id="calendarLink" rows={1} cols={9999} readOnly={true} value={calendarsService+garageId.current}/><CopyButton onClick={copyCalendarLink}/></div>
            <ShareCalendarHelp />
        </Indent>
        <h2>Limit appointment timeslots</h2>
        <Indent>
            <LimitCheckbox>Limit to hours</LimitCheckbox>
            <WeekSelect weekTimespans={getHoursAsStrings(limitToHours)} onChange={onWeekChanged} />
            <LimitCheckbox><StyledCheckbox checked={limitToCalendarChecked} onClick={()=>setLimitToCalendarChecked(!limitToCalendarChecked)}/>Limit by external calendar</LimitCheckbox>
            <div style={{display:limitToCalendarChecked?"block":"none"}}><Indent>
                <input style={{minWidth:"33%"}} type="text" placeholder="paste link here" value={garageCalendar} onChange={(e)=>setGarageCalendar((e.target as HTMLInputElement).value)}/>
                <SharedCalendarHelp />
            </Indent></div>
            <InputTextareaLabel htmlFor="about">Instant Book</InputTextareaLabel>
            <input type="checkbox" checked={instantBook} onChange={(e)=>setInstantBook(e.target.checked)}/>&nbsp;Enable Instant Book 
            <Button onClick={saveSettings}>Save</Button>
        </Indent>
        </Indent>
    </div>);
}


export default CalendarSettings;