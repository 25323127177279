import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import Sheet from "react-modal-sheet";
import { RemoveScroll } from "react-remove-scroll";
import useViewport from "../Hooks/Viewport";
import AddGarageForm from "./AddGarageForm";


function AddGarageView({isOpen, onClose}:{isOpen:boolean, onClose: () => void}){

    const { width } = useViewport();
    const mdBreakpoint = 480;

    return (
        <>
        {isOpen && width > mdBreakpoint
        ? <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" size={"3xl"}>
            <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <AddGarageForm onClose={onClose}/>
                    </ModalBody>
                    <ModalFooter>
                        
                    </ModalFooter>
                </ModalContent>
        </Modal>
        : <Sheet rootId="root" isOpen={isOpen} onClose={onClose}>
            <RemoveScroll>
            <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                    <AddGarageForm onClose={onClose}/>
                </Sheet.Content>
            </Sheet.Container>
            </RemoveScroll>
            <Sheet.Backdrop onTap={onClose} style={{touchAction:"none"}} />
        </Sheet>
        }
        </>
    )
}

export default AddGarageView