
import firebase, {storage, auth, db, functions}  from '../../firebase';
import axios from 'axios';
import DbAttachment, { DbAttachmentData } from "../Attachments/DbAttachment";
import { Button, Divider, FormControl, FormErrorMessage, FormLabel, HStack, VStack, Text, Textarea, Avatar, Select, Box, Checkbox, Wrap, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider} from 'react-hook-form';
import CoverFile from "../Form/CoverFile";
import FileInput from "../Form/FileInput";
import TimeField from "../Form/TimeFields";
import { FormFieldInput, FormFieldInputAddon } from "../Form/FormFields";
import { DbGarageData } from "./DbGarage";
import moment from "moment";
import { AvailableServicesContext, AvailableServicesContextType, DbAvailableService } from '../Services/AvailableServices';
import { daysOpen } from '../Common/OpeningHours';
// import { createAccountFor } from '../Account/CreateAccountFor';


export interface FormValues {
    firstName: string,
    lastName: string,
    email: string,
    garageName: string,
    description: string,
    phone: number,
    website: string,
    hoursSundayFrom: string,
    hoursMondayFrom: string,
    hoursTuesdayFrom: string,
    hoursWednesdayFrom: string,
    hoursThursdayFrom: string,
    hoursFridayFrom: string,
    hoursSaturdayFrom:string,
    hoursSundayTo: string,
    hoursMondayTo: string,
    hoursTuesdayTo: string,
    hoursWednesdayTo: string,
    hoursThursdayTo: string,
    hoursFridayTo: string,
    hoursSaturdayTo: string,
    hoursSundayToggle: boolean,
    hoursMondayToggle: boolean,
    hoursTuesdayToggle: boolean,
    hoursWednesdayToggle: boolean,
    hoursThursdayToggle: boolean,
    hoursFridayToggle: boolean,
    hoursSaturdayToggle: boolean,
    services:string[],
    addressLineOne: string,
    addressLineTwo: string,
    city: string,
    state: string,
    zip: number,
    instantBook: boolean,
    photos:File[],
    coverImage:File[],
    yelpBusinessId: string,
    contactPhone: string,
}


function AddGarageForm({onClose}:{onClose: () => void}) {

    const methods = useForm({mode: "onBlur"});
    const { register, handleSubmit, formState: {errors}, getValues, watch, control } = methods;
    const availableServices = useContext(AvailableServicesContext) as AvailableServicesContextType;
    const [allServices,setAllServices]=useState<DbAvailableService[]>([]);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [coverImage, setCoverImage ] = useState({preview: "", raw: undefined});
    // functions.useEmulator('localhost',5001); // For debugging, redirect requests to emulator
    const sendEmail = functions.httpsCallable('sendEmail');

    const watchSunday:boolean = watch("hoursSundayToggle", false);
    const watchMonday:boolean = watch("hoursMondayToggle", false);
    const watchTuesday:boolean = watch("hoursTuesdayToggle", false);
    const watchWednesday:boolean = watch("hoursWednesdayToggle", false);
    const watchThursday:boolean = watch("hoursThursdayToggle", false);
    const watchFriday:boolean = watch("hoursFridayToggle", false);
    const watchSaturday:boolean = watch("hoursSaturdayToggle", false);

    const watchedVars:boolean[] = [watchSunday, watchMonday, watchTuesday, watchWednesday, watchThursday, watchFriday, watchSaturday];

    const states=["AL","AK","AZ","AR","CA","CO","CT","DE","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY"];

    const watchFile = watch("coverImage");

    const storageFolder = 'garages';

    // let time = firebase.firestore.FieldValue.serverTimestamp();

    useEffect(() => {
        if(watchFile !== undefined){

            setCoverImage({
                preview: URL.createObjectURL(getValues("coverImage")[0]),
                raw: getValues("coverImage")[0],
            })
        }
    }, [watchFile]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(availableServices.services.length>0){
            setAllServices(availableServices.services);
        }
    },[availableServices.services]);

    /** get the hours formatted as a JSON string, capable of being stored in the database */
    function getFormattedHours(values:FormValues) {
        const watches=[watchSunday,watchMonday,watchTuesday,watchWednesday,watchThursday,watchFriday,watchSaturday];
        const starts=[values.hoursSundayFrom,values.hoursMondayFrom,values.hoursTuesdayFrom,values.hoursWednesdayFrom,values.hoursThursdayFrom,values.hoursFridayFrom,values.hoursSaturdayFrom];
        const ends=[values.hoursSundayTo,values.hoursMondayTo,values.hoursTuesdayTo,values.hoursWednesdayTo,values.hoursThursdayTo,values.hoursFridayTo,values.hoursSaturdayTo];
        function timeFix(formatted:string|undefined):number{
            if(formatted===undefined){
                return 0;
            }
            return parseInt(formatted.replace(':',''));
        }
        let days=watches.map((watch,idx)=>watch?[[timeFix(starts[idx]),timeFix(ends[idx])]]:[]);
        return JSON.stringify(days);
    }

    /** called to finish the form */
    function done(){
        //window.location.reload();
        onClose();
    }

    /** similar to firebase store uploadTask only better because it's all async and nice 
     * 
     * returns downloadUrl
    */
    async function firebaseStorageUploader(path:string,data:File|Blob|Uint8Array|ArrayBuffer,onProgress:any=undefined) {
        return new Promise<string>(function(resolve,reject) {
            const storageRef=storage.ref(path);
            const uploadTask=storageRef.put(data);
            uploadTask.on('state_changed',
                function(snapshot) {
                    if(onProgress!==undefined) onProgress(snapshot.bytesTransferred/snapshot.totalBytes);
                },
                function error(err:any) {
                    console.error('error', err)
                    reject()
                },
                function complete() {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        resolve(downloadURL)
                    })
                }
            )
        })
    }

    /** add an attachment to firebase 
     * returns the data created
    */
    async function addFirebaseAttachment(garageId:string,dbAttachmentCollection:firebase.firestore.CollectionReference,file:File,attachment:DbAttachment){
        var ret:DbAttachment|undefined=undefined;
        const storageFilename=`${storageFolder}/${garageId}/images/${attachment.fileName}`;
        if(file!==undefined){
            //try {
                let url=await firebaseStorageUploader(storageFilename,file);
                const firestoreDbFile:DbAttachmentData={
                    fileName:attachment.fileName,
                    storageFilename:storageFilename,
                    type:attachment.type,
                    size:attachment.size,
                    url:url,
                    order:attachment.order
                }
                // now add info about the new attachment to the database
                if(dbAttachmentCollection!==undefined){
                    let result=await dbAttachmentCollection.add(firestoreDbFile);
                    ret={...firestoreDbFile,id:result.id};
                }
                //console.log(dbAttachmentCollection)
                //console.log(firestoreDbFile)
                //console.log(ret)
            /*} catch(err) {
                setErrors(err.message);
            }*/
        }
        return ret;
    }

    /** called to upload all of the attachements 
     * attachements are in order, where photo[0] is the "cover image"
    */
    async function uploadAttachments(garageId:string,dbAttachmentCollection:firebase.firestore.CollectionReference,photos:File[],coverImage?:File) {
        for(let i=0;i<photos.length;i++){
            let photo=photos[i];
            let attachment:DbAttachment|undefined={
                id:'', // filled in later
                fileName:photo.name,
                storageFilename: '', // filled in later
                size:photo.size,
                url:'', //filled in later
                type:photo.type,
                order:coverImage!==undefined ? i : i+1
            };
            if(attachment!==undefined) attachment=await addFirebaseAttachment(garageId,dbAttachmentCollection,photo,attachment);
        }
    }

    /** called when form is submitted */
    async function onSubmit(values:FormValues) {
        let now=moment().unix();
        setLoading(true);
        try {
            const docRef=db.collection("garages").doc();
            const usersCollection=db.collection("users");
            const batch = db.batch();
            const redirectBackToUrl=window.location.origin+'/login?gId='+docRef.id;
            var querySnapshot=await usersCollection.where("email","==",values.email).get();
            if(querySnapshot===undefined||querySnapshot.empty){
                const usersCollectionRef=db.collection("users").doc(docRef.id);
                // await createAccountFor(values.email,{garageId:docRef.id,isGarage:true, firstName:values.firstName, lastName:values.lastName, verified: false,});
                let storeHours=getFormattedHours(values);
                let data:DbGarageData={
                    firstName: values.firstName,
                    lastName: values.lastName,
                    notificationEmail:values.email, // default to use the same email account
                    createdDate:now,
                    name:values.garageName===undefined?'':values.garageName.trim(),
                    description:values.description===undefined?'':values.description,
                    phone:'+1'+values.phone.toString(),
                    contactPhone: values.contactPhone===undefined?'':'+1'+values.contactPhone.toString(),
                    services:values.services===undefined?[]:values.services,
                    url:values.website===undefined?'':values.website,
                    storeHours:storeHours,
                    limitToHours:storeHours, // default to the same thing as store hours
                    instantBook:values.instantBook===undefined?false:values.instantBook,
                    yelpBusinessId: values.yelpBusinessId===undefined?'':values.yelpBusinessId,
                    address:JSON.stringify({
                        line1:values.addressLineOne===undefined?'':values.addressLineOne,
                        line2:values.addressLineTwo===undefined?'':values.addressLineTwo,
                        city:values.city===undefined?'':values.city,
                        state:values.state===undefined?'':values.state,
                        zip:values.zip===undefined?'':values.zip,
                    }),
                };
                const userData = {
                    garageId:docRef.id,
                    isGarage:true, 
                    firstName:values.firstName, 
                    lastName:values.lastName, 
                    verified: false,
                    email:values.email,
                }
                batch.set(docRef, data);
                batch.set(usersCollectionRef, userData);
                await batch.commit();
                // await docRef.set(data);
                // await usersCollectionRef.doc(docRef.id).set(userData)
                auth.sendSignInLinkToEmail(values.email,{url:redirectBackToUrl,handleCodeInApp:true}).catch((e) => {
                    console.log('send email error');
                    console.log(e);
                    toast({
                        title: "Could not send sign up email",
                        description: `The account was created, but there was an error sending a sign up email, please resend the email from the user management page`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    })
                });
                // the database update worked, so now add/remove the attached images
                let att:File[]=[];
                if(values.coverImage===undefined){
                    if(values.photos!==undefined){
                        att=values.photos;
                    }
                } else if(values.photos===undefined) {
                    att=[values.coverImage[0]];
                } else {
                    att=[values.coverImage[0],...values.photos];
                }
                let dbAttachmentCollection=docRef.collection('images');
                if(att.length>0) await uploadAttachments(docRef.id,dbAttachmentCollection,att,values.coverImage!==undefined?values.coverImage[0]:undefined);
                // get the initial yelp reviews using the cloud function
                await axios.get("https://us-central1-carera-be9c6.cloudfunctions.net/getGarages");
                // now let the user know we are done
                // send email to the user
                const token = await auth.currentUser?.getIdToken();
                sendEmail({
                    token: token,
                    name: values.firstName,
                    email: values.email,
                    template: 'welcome',
                }).catch((e) => {
                    console.log('send email error');
                    console.log(e);
                    toast({
                        title: "Could not send email",
                        description: `There was an error sending a welcome email`,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    })
                });
                setLoading(false);
                done();
                toast({
                    title: "New Garage Saved",
                    description: `${values.garageName} was saved and added!`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    // onCloseComplete:()=>done()
                })
            } else {
                setLoading(false);
                toast({
                    title: "Existing Account",
                    description: `An account with the email address ${values.email} already exists`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
        } catch(err:any) {
            console.error(err.message);
            setLoading(false);
            toast({
                title: "Could not save",
                description: `There was an error saving ${values.garageName}, please try again`,
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <VStack align="flex-start" spacing="6" mx={[4,0]}>
                <VStack align="flex-start" w="full" >
                    <Text as="h1" fontSize="3xl" fontWeight="bold" >Primary Contact</Text>
                    <HStack w="full" >

                        <FormFieldInput
                            control={control} 
                            text="First Name" 
                            id="firstName"
                            required={"First Name is a required field"}
                            maxLength={{ value: 25, message: "Please make sure your name is no longer than 25 characters" }}
                        />

                        <FormFieldInput
                            id="lastName"
                            text="Last Name"
                            control={control}
                            required={"Last Name is a required field"}
                            maxLength={{ value: 25, message: "Please make sure your name is no longer than 25 characters" }}
                        />
                        

                    </HStack>
                </VStack>

                <FormFieldInput 
                    control={control}
                    id="email"
                    text="Email"
                    required={"Email is a required field"}
                    pattern={{
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                    }}
                    type="email"
                />

                <FormFieldInputAddon
                    control={control}
                    id="contactPhone"
                    text="Primary Contact Phone"
                    children="+1"
                    type="number"
                    minLength={{value: 10, message: "Please enter a valid phone number"}}
                    maxLength={{value: 10, message: "Please enter a valid phone number"}}
                />


                <Divider/>

                <VStack w="full" align="flex-start" >
                    <Text as="h1" fontSize="3xl" fontWeight="bold" >Business Details</Text>

                    <FormFieldInput
                        control={control}
                        id="garageName"
                        text="Garage Name"
                        required="Garage Name is a required field"
                        maxLength={
                            { value: 50, message: "Please make sure the Garage Name is no longer than 50 characters" }
                        }
                    />

                </VStack>

                <FormFieldInputAddon
                    control={control}
                    id="phone"
                    text="Phone"
                    children="+1"
                    type="number"
                    required="Garage Phone number is a required field"
                    minLength={{value: 10, message: "Please enter a valid phone number"}}
                    maxLength={{value: 10, message: "Please enter a valid phone number"}}
                />

                <FormFieldInput
                        control={control}
                        id="addressLineOne"
                        text="Address Line One"
                        required="Address is a required field"
                    />


                <FormFieldInput
                        control={control}
                        id="addressLineTwo"
                        text="Address Line Two"
                    />

                <HStack w="full">

                    <FormFieldInput
                        control={control}
                        id="city"
                        text="City"
                        required="City is a required field"
                        maxLength={{ value: 40, message: "Please make sure your name is no longer than 40 characters" }}
                    />

                    <FormControl isInvalid={errors.state}>
                        <FormLabel htmlFor="state">State</FormLabel>
                            <Select 
                                placeholder="State" {...register("state", {
                                    required: "State is a required field"
                                    })}>
                                    {states.map((state) => <option key={state} value={state}>{state}</option>)}
                            </Select>
                        <FormErrorMessage>
                            {errors.state && errors.state.message}
                        </FormErrorMessage>
                    </FormControl>

                    <FormFieldInput
                        control={control}
                        id="zip"
                        text="Zip"
                        required="Zip is a required field"
                        maxLength={{ value: 5, message: "Please enter a valid Zip" }}
                        minLength={{ value: 5, message: "Please enter a valid Zip" }}
                        type="number"
                    />
                </HStack>
                
                <FormFieldInputAddon
                        control={control}
                        id="yelpBusinessId"
                        text="Yelp Business ID"
                        children="www.yelp.com/biz/"
                        placeholder="yelp-san-francisco"
                    />

                <FormFieldInputAddon 
                    control={control}
                    id="website"
                    text="Website"
                    placeholder="www.example.com"
                    pattern={{
                        value: /^(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
                        message: "invalid website url, please make sure the url does not start with http(s)"
                    }}
                    children="https://"
                />

                <FormControl isInvalid={errors.description}>
                    <FormLabel htmlFor="description">About</FormLabel>
                    <Textarea 
                        id="description"
                        placeholder="About"
                        {...register("description")}
                        size="sm"
                    />
                    <FormErrorMessage>
                        {errors.description && errors.description.message}
                    </FormErrorMessage>
                </FormControl>

                <VStack align="flex-start">
                    <Text>Cover Photo</Text>
                    <HStack align="center" >
                        <Avatar src={coverImage.preview} />
                        <CoverFile name="coverImage" control={control} />
                    </HStack>
                </VStack>

                <VStack w="100%" align="flex-start">

                    <FormProvider {...methods}>
                        <Box as="section" w="full">
                            <FileInput
                                accept="image/png, image/jpg, image/jpeg"
                                name="photos"
                                label="Photos"/>
                        </Box>
                    </FormProvider>



                </VStack>

                <Divider/>
                {/* <VStack align="flex-start" w="full">
                    <Text as="h1" fontSize="3xl" fontWeight="bold" >Instant Book</Text>
                    <Checkbox pb={2} name="instantBook">Enable Instant Book</Checkbox>
                </VStack> */}

                <VStack align="flex-start" w="full">
                    <Text as="h1" fontSize="3xl" fontWeight="bold" >Hours</Text>
                    <HStack alignItems="flex-start" wrap="wrap" spacing="0" justify="space-between" >
                        {
                        <>
                            {daysOpen.map((day, i) => {
                                return <TimeField width={["94%", "94%", "48%"]} key={day} control={control} day={day} watch={watchedVars[i]} />
                            })}
                        </>
                        }
                    </HStack>
                </VStack>

                    {allServices.length > 0 && <VStack alignItems="flex-start">
                        <Text as="h1" fontSize="3xl" fontWeight="bold">Services</Text>
                        <FormControl isInvalid={errors.services}>
                            <Wrap>
                                {allServices.map((service) => {
                                    return (
                                        <Checkbox pb={2} w={["calc(100% - 0.5rem)", "calc(50% - 0.5rem)" ,"calc(33.3% - 0.5rem)"]} {...register("services", {required:"Please select at least 1 service"})} value={service.id} key={service.id}>{service.name}</Checkbox>
                                    )
                                })}
                            </Wrap>
                            <FormErrorMessage>
                                {errors.services && errors.services.message}
                            </FormErrorMessage>
                        </FormControl>
                    </VStack>}
                

            </VStack>
            
            <Button mt={4} mx={[4,0]} mb={[4,0]} colorScheme="teal" isLoading={loading} type="submit">
                Submit
            </Button>
        </form>
    )

}

export default AddGarageForm
