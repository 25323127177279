import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import { device } from '../Common/Breakpoints';
import firebase, { auth, db } from '../../firebase';


// import logo from '../../logo512.png';
import logo from '../../carera-logo.svg'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Avatar, Box, Button, Circle, Flex, FormControl, HStack, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, useColorMode, useColorModeValue, useDisclosure, Text, useToast } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../Account/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faLayerGroup, faLock, faMailBulk, faNewspaper, faSignOutAlt, faSun, faTachometerAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import CareraLogo from '../../carera-logo';
import { faMoon } from '@fortawesome/free-regular-svg-icons';
import PasswordStrengthBar from 'react-password-strength-bar';

const HeaderLogo = styled.img`
    width: 120px;
    height: 70px;
    fill: #fff;
`;

export const LinkElem = styled(NavLink)`
    color: rgba(255,255,255,0.9);
    text-decoration: none;
    white-space: nowrap;
    font-size: 0.9em;
    letter-spacing: 0.5px;

    &.selected{
        font-weight: bold;
        color: rgba(255,255,255,1);
    }
`;

export const NavItemsContainer = styled.ul`
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    width: calc(70% - 140px);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 0;
    margin: 0;

    @media ${device.laptop} { 
        width: calc(90% - 100px);
    }
`;

export const NavItem = styled.li`
    /* padding: 0em 1.5em; */
    color: rgba(255,255,255,0.75);
    padding: 1em 0.5em;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-basis: 0;
    flex-grow: 1;
    border-radius: 30px;
    transition: 0.3s;

    &:hover{
        background:  rgba(255, 255, 255, 0.05);
    }
`;

interface headerProps {
    children?: React.ReactNode,
    bgColor?: string,
    logoFillColor?: string,
    showButton?: boolean,
    buttonFn?: () => void,
    hasOrder?:boolean,
}

export default function Header({children, bgColor="transparent", logoFillColor, showButton, buttonFn, hasOrder}:headerProps){
    const Auth = useContext(AuthContext);
    const { toggleColorMode: toggleMode } = useColorMode();
    const text = useColorModeValue("dark", "light");
    const logoColor = useColorModeValue("black", "white");
    const SwitchIcon = useColorModeValue(<FontAwesomeIcon icon={faMoon} />, <FontAwesomeIcon color="#fff" icon={faSun} />);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(Auth.isLoggedIn);
    const [isPasswordSet, setIsPasswordSet] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [registerPassword, setRegisterPassword] = useState("");
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(false);
    const [registerShow, setRegisterShow] = useState(false);
    const handleRegisterClick = () => setRegisterShow(!registerShow);
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    // const [hasOrder, setHasOrder] = useState<boolean>(false);
    
    useEffect(() => {
        if(Auth.id){
            setIsLoggedIn(true)
            // console.log('auth logged in');
            // console.log(Auth.id);
            if(Auth.isGarage && Auth.usePassword === undefined){
                setIsPasswordSet(false);
            }
        }
        if(!Auth.id){
            setIsLoggedIn(false);
            // console.log('auth not logged in');
        }
        // if(Auth.orders !== undefined && Auth.orders.length>0){
        //     setHasOrder(true);
        // }
    }, [Auth])


    function ColorSwitchIcon(){
        return (
            <IconButton
                size="md"
                fontSize="lg"
                aria-label={`Switch to ${text} mode`}
                variant="ghost"
                color="current"
                ml={{ base: "0", md: "3" }}
                onClick={toggleMode}
                icon={SwitchIcon}
            />
        )
    }

    function validatePassword(){
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,25}$/;
        return re.test(String(registerPassword));
    }

    function submitSetPassword(e:React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        if(registerPassword !== registerConfirmPassword){
            setPasswordInvalid(true);
            return;
        }
        if(!validatePassword()){
            setPasswordStrength(true);
            return;
        }
        setLoading(true);
        const user = auth.currentUser;
        if(user){
            user.updatePassword(registerPassword).then(async () => {
                const existingUserGarageRef=db.collection("users").doc(Auth.id);
                await existingUserGarageRef.update({usePassword: true});
                setIsPasswordSet(true);
                onClose();
                setLoading(false);
                toast({
                    title: "Password Set",
                    description: "Pleae use your password to login in future",
                    status: "success",
                    duration:5000,
                    isClosable: true,
                })
            }).catch((error) => {
                setLoading(false);
                toast({
                    title: "Error setting password",
                    description: "There was an error setting your password, please sign out, and log back in again to set",
                    status: "error",
                    duration:5000,
                    isClosable: true,
                });
            });
        }
    }

    async function submitSetPasswordFalse(){
        setLoading(true);
        try {
            const existingUserGarageRef=db.collection("users").doc(Auth.id);
            await existingUserGarageRef.update({usePassword: false});
            setIsPasswordSet(true);
            setLoading(false);
            toast({
                title: "Continue using email login",
                description: `Thanks, you won't see this message again`,
                status: "success",
                duration:5000,
                isClosable: true,
            })
        } catch (error) {
            console.log(error);
            toast({
                title: "There was an Error",
                description: `Please try again`,
                status: "error",
                duration:5000,
                isClosable: true,
            })
        }
        setLoading(false);
    }


    return (
        <>
        {!isPasswordSet && 
            <Alert status='info'>
                <AlertIcon/>
                <AlertTitle mr={1}>Set Password?</AlertTitle>
                <AlertDescription>Would you like to set an account password?</AlertDescription>
                <Spacer/>
                <Button isLoading={loading} colorScheme="blue" size="sm" mr="2" onClick={onOpen} >Yes please</Button>
                <Button isLoading={loading} colorScheme="red" size="sm" onClick={submitSetPasswordFalse} >No thanks</Button>
            </Alert>}
        <Box as="nav" display={"flex"} justifyContent="space-between" alignItems="center" w="full" py={4} px={[4,8]} bgColor={bgColor}>
            {/* <HeaderLogo src={logo} alt="Logo" /> */}
            <CareraLogo width="120px" height="70px" fillColor={logoFillColor ?? logoColor} />
            {children}
            {/* <LinkElem to="/logout" activeClassName="selected">Logout</LinkElem> */}
            {
                isLoggedIn 
                ? <HStack>
                    { !Auth.isAdmin && !Auth.isGarage && <ColorSwitchIcon />}
                    <Menu>
                        <MenuButton pos="relative" >
                            <Avatar  name={Auth.firstName && `${Auth.firstName}`} />
                            {hasOrder && <Circle w={4} h={4} pos="absolute" top={0} left={0} bgColor="red" /> }
                        </MenuButton>
                        <MenuList zIndex={3}>
                            <NavLink to="/dashboard"><MenuItem icon={ <FontAwesomeIcon icon={faTachometerAlt} />} >Dashboard</MenuItem></NavLink>
                            <NavLink to="/logout"><MenuItem icon={<FontAwesomeIcon icon={faSignOutAlt} />}>Logout</MenuItem></NavLink>
                            {
                                Auth.isGarage && <NavLink to="/dashboard/my_classifieds"><MenuItem icon={<FontAwesomeIcon icon={faNewspaper}/>}>My Classifieds</MenuItem></NavLink>
                            }
                            {hasOrder && 
                                <NavLink to="/submissions"><MenuItem icon={<FontAwesomeIcon icon={faLayerGroup} />} >View Submissions</MenuItem> </NavLink>
                            }
                        </MenuList>
                    </Menu>
                </HStack>
                : <HStack>
                    { !Auth.isAdmin && !Auth.isGarage && <ColorSwitchIcon />}
                    <NavLink to="/login" >
                        <FontAwesomeIcon color={logoColor} icon={faUser} />
                    </NavLink>
                    {
                        showButton && buttonFn && <IconButton colorScheme="white" onClick={buttonFn} aria-label="Open Menu" icon={ <FontAwesomeIcon color={logoColor} icon={faBars} /> } />
                    }
                </HStack>
            }
        </Box>
        <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Set Account Password</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <form action="submit" onSubmit={(e) => submitSetPassword(e)} >
                            <FormControl isRequired isInvalid={passwordInvalid} mb="4">
                                <InputGroup>
                                    <InputLeftElement children={<FontAwesomeIcon icon={faLock} />} />
                                    <Input type={registerShow ? "text" : "password"} placeholder="Password" autoComplete="new-password" aria-label="Password" value={registerPassword} onChange={(event) => {setRegisterPassword(event.currentTarget.value);setPasswordInvalid(false);}} />
                                    <InputRightElement width="4.5rem">
                                        <Button h="1.75rem" size="sm" onClick={handleRegisterClick}>
                                        {registerShow ? "Hide" : "Show"}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            {registerPassword.length > 0 && <PasswordStrengthBar minLength={8} password={registerPassword} />}
                            <FormControl isRequired isInvalid={passwordInvalid} mb="6" >
                                <InputGroup>
                                    <InputLeftElement children={<FontAwesomeIcon icon={faLock} />} />
                                    <Input type={registerShow ? "text" : "password"} placeholder="Confirm Password" autoComplete="new-password" aria-label="Password" value={registerConfirmPassword} onChange={(event) => {setRegisterConfirmPassword(event.currentTarget.value);setPasswordInvalid(false)}} />
                                    <InputRightElement width="4.5rem">
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            {registerPassword.length > 0 && !loading && !passwordStrength && <Text fontSize="xs" textAlign="center" >Please make sure your password has a minimum of 8 letters, 1 uppercase letter, 1 lowercase letter and 1 number</Text>}
                            {passwordInvalid && <Text px="6" textAlign="center" fontSize="sm" fontWeight="bold" >These passwords do not match, please try again</Text>}
                            {passwordStrength && <Text textAlign="center" fontSize="sm" color="red.500">Please make sure your password has a minimum of 8 letters, 1 uppercase letter, 1 lowercase letter and 1 number</Text> }
                            <Flex justify="flex-end" mt="4" mb="4" >
                                <Button colorScheme="teal" type="submit" isLoading={loading} >Save</Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}