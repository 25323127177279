import React, { useContext, useEffect, useRef, useState } from "react";
import DbGarage, { DbGarageData } from './DbGarage';
import { Controller, FormProvider, useForm} from 'react-hook-form';
import { FormValues } from './AddGarageForm';
import { FormFieldInput, FormFieldInputAddon, FormFieldSelect, FormFieldTextArea } from "../Form/FormFields";
import { VStack, Text, HStack, Box, Flex, IconButton, FormControl, Checkbox, Wrap, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button, useToast, Image } from "@chakra-ui/react";
import { AvailableServicesContext, AvailableServicesContextType, DbAvailableService } from "../Services/AvailableServices";
import TimeField from "../Form/TimeFields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { DeleteGarageFn } from "./GarageCard";
import FileInput from "../Form/FileInput";
import firebase, { db, storage } from "../../firebase";
import DbAttachment, { DbAttachmentData } from "../Attachments/DbAttachment";
import Loading from "../Common/Loading";
import placeHolder from '../../placeholder.png'
import CoverFile from "../Form/CoverFile";


type GarageChangeFn=(garage:DbGarageData)=>void;

type EditFormProps = {
    garage: DbGarage,
    times?: OpeningObj[],
    onGarageChangeFn: GarageChangeFn,
    setEdit: Function,
    deleteGarage: DeleteGarageFn,
    // imageUrls: string[],
    images: DbAttachment[],
}

export type AddressType = {
    line1: string,
    line2: string,
    city: string,
    state: string,
    zip: string,
}

type OpeningObj = {
    opening: string,
    closing: string,
}



function EditGarageForm({garage, times, onGarageChangeFn, setEdit, deleteGarage, images}:EditFormProps){
    const states=["AL","AK","AZ","AR","CA","CO","CT","DE","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY"];
    const availableServices = useContext(AvailableServicesContext) as AvailableServicesContextType;
    const [allServices,setAllServices]=useState<DbAvailableService[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [coverImage, setCoverImage ] = useState({preview: "", raw: undefined});
    const [loading, setLoading] = useState(false);
    const [allImages, setAllImages] = useState(images);
    const methods = useForm({mode: "onBlur"});
    const { handleSubmit, getValues, watch, control } = methods;
    const fullAdd:AddressType = JSON.parse(garage.address);
    const toast = useToast();

    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const watchFile = watch("coverImage");

    useEffect(() => {
        if(watchFile !== undefined){

            setCoverImage({
                preview: URL.createObjectURL(getValues("coverImage")[0]),
                raw: getValues("coverImage")[0],
            })
        }
    }, [watchFile]) // eslint-disable-line react-hooks/exhaustive-deps

    const storageFolder = 'garages';

    async function onSubmit(values:FormValues) {
        // console.log(values);
        // convert true false object into list of selected list items
        setLoading(true);
        try {
            const docRef=db.collection("garages").doc(garage.id);
            const trueServices = Object.entries(values.services);
            const servicesList = trueServices.filter(([_,value]) => value).map((e) => e[0]);
            let data:DbGarageData={
                // firstName: garage.firstName,
                // lastName: garage.lastName,
                // createdDate:garage.createdDate,
                // reviewsUrl: garage.reviewsUrl ?? "",
                name:garage.name,
                description:values.description===undefined?'':values.description,
                phone:values.phone===undefined?'':values.phone.toString(),
                services:servicesList===undefined?[]:servicesList,
                url:values.website===undefined?'':values.website,
                storeHours:getFormattedHours(values),
                instantBook:values.instantBook===undefined?false:values.instantBook,
                address:JSON.stringify({
                    line1:values.addressLineOne===undefined?'':values.addressLineOne,
                    line2:values.addressLineTwo===undefined?'':values.addressLineTwo,
                    city:values.city===undefined?'':values.city,
                    state:values.state===undefined?'':values.state,
                    zip:values.zip===undefined?'':values.zip,
                })
            };
            await docRef.update(data);
            let att:File[]=[];
            // if(values.photos!==undefined){
            //     att=values.photos;
            // } 
            if(values.coverImage===undefined){
                if(values.photos!==undefined){
                    att=values.photos;
                }
            } else if(values.photos===undefined) {
                att=[values.coverImage[0]];
            } else {
                att=[values.coverImage[0],...values.photos];
            }
            let dbAttachmentCollection=docRef.collection('images');
            if(att.length>0) await uploadAttachments(docRef.id,dbAttachmentCollection,att, values.coverImage !==undefined ? values.coverImage[0]:undefined);
            setLoading(false);
            setEdit(false);
            if(onGarageChangeFn!==undefined)onGarageChangeFn(data);
            toast({
                title: "Garage Updated",
                description: `${garage.name} was updated!`,
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        } catch (error:any) {
            console.error(error.message);
            setLoading(false);
            toast({
                title: "Could not save",
                description: `There was an error saving ${garage.name}, please try again`,
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    }

    /** get the hours formatted as a JSON string, capable of being stored in the database */
    function getFormattedHours(values:FormValues) {
        const watches=[values.hoursSundayToggle,values.hoursMondayToggle,values.hoursTuesdayToggle,values.hoursWednesdayToggle,values.hoursThursdayToggle,values.hoursFridayToggle,values.hoursSaturdayToggle];
        const starts=[values.hoursSundayFrom,values.hoursMondayFrom,values.hoursTuesdayFrom,values.hoursWednesdayFrom,values.hoursThursdayFrom,values.hoursFridayFrom,values.hoursSaturdayFrom];
        const ends=[values.hoursSundayTo,values.hoursMondayTo,values.hoursTuesdayTo,values.hoursWednesdayTo,values.hoursThursdayTo,values.hoursFridayTo,values.hoursSaturdayTo];
        function timeFix(formatted:string|undefined):number{
            if(formatted===undefined){
                return 0;
            }
            return parseInt(formatted);
        }
        let days=watches.map((watch,idx)=>watch?[[timeFix(starts[idx]),timeFix(ends[idx])]]:[]);
        return JSON.stringify(days);
    }


    /** called to upload all of the attachements 
     * attachements are in order, where photo[0] is the "cover image"
    */
    async function uploadAttachments(garageId:string,dbAttachmentCollection:firebase.firestore.CollectionReference,photos:File[],coverImage?:File) {
        for(let i=0;i<photos.length;i++){
            let photo=photos[i];
            let attachment:DbAttachment|undefined={
                id:'', // filled in later
                fileName:photo.name,
                storageFilename: '', // filled in later
                size:photo.size,
                url:'', //filled in later
                type:photo.type,
                order:coverImage!==undefined ? i : i+1
            };
            if(attachment!==undefined) attachment=await addFirebaseAttachment(garageId,dbAttachmentCollection,photo,attachment);
        }
    }

    /** add an attachment to firebase 
     * returns the data created
    */
    async function addFirebaseAttachment(garageId:string,dbAttachmentCollection:firebase.firestore.CollectionReference,file:File,attachment:DbAttachment){
        var ret:DbAttachment|undefined=undefined;
        const storageFilename=`${storageFolder}/${garageId}/images/${attachment.fileName}`;
        if(file!==undefined){
            //try {
                let url=await firebaseStorageUploader(storageFilename,file);
                const firestoreDbFile:DbAttachmentData={
                    fileName:attachment.fileName,
                    storageFilename:storageFilename,
                    type:attachment.type,
                    size:attachment.size,
                    url:url,
                    order:attachment.order
                }
                // now add info about the new attachment to the database
                if(dbAttachmentCollection!==undefined){
                    let result=await dbAttachmentCollection.add(firestoreDbFile);
                    ret={...firestoreDbFile,id:result.id};
                }
        }
        return ret;
    }

    /** similar to firebase store uploadTask only better because it's all async and nice 
     * 
     * returns downloadUrl
    */
    async function firebaseStorageUploader(path:string,data:File|Blob|Uint8Array|ArrayBuffer,onProgress:any=undefined) {
        return new Promise<string>(function(resolve,reject) {
            const storageRef=storage.ref(path);
            const uploadTask=storageRef.put(data);
            uploadTask.on('state_changed',
                function(snapshot) {
                    if(onProgress!==undefined) onProgress(snapshot.bytesTransferred/snapshot.totalBytes);
                },
                function error(err:any) {
                    console.error('error', err)
                    reject()
                },
                function complete() {
                    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        resolve(downloadURL)
                    })
                }
            )
        })
    }

    /** remove an attachment from firebase */
    async function deleteFirebaseImage(attachment:DbAttachment){
        setLoading(true);
        const garageImagesSubCollection=db.collection("garages").doc(garage.id).collection("images");
        if(garageImagesSubCollection===undefined){
            toast({
                title: "Error Deleting Image",
                description: 'Could not delete Image',
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            setLoading(false);
            return;
        }
        const storageRef=storage.ref(attachment.storageFilename);
        try{
            await storageRef.delete();
            const dbRef=await garageImagesSubCollection.doc(attachment.id);
            await dbRef.delete();
            const index = allImages.indexOf(attachment);
            let newImages = allImages;
            if (index > -1) {
                newImages.splice(index, 1);
            }
            setAllImages(newImages);
        } catch (err) {
            toast({
                title: "Error Deleting Image",
                description: 'Could not delete Image',
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            console.error(err);
        }
        setLoading(false);
    }


    function AlertDialogModal() {
        const onClose = () => {
            deleteGarage(garage.id);
        }
        const cancelRef:any = useRef()
        return (
            <>
            <AlertDialog
                isCentered
                isOpen={isDialogOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsDialogOpen(false)}
            >
                <AlertDialogOverlay zIndex={[9999999, "modal"]}>
                <AlertDialogContent mx={[4,0]}>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Delete Garage
                    </AlertDialogHeader>

                    <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={()=> setIsDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button colorScheme="red" onClick={onClose} ml={3}>
                        Delete
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            </>
        )
    }

    useEffect(()=>{
        if(availableServices.services.length>0){
            setAllServices(availableServices.services);
        }
    },[availableServices.services]);

    return (
        <>
        { allImages.length > 0 && allImages[0].order === 0
            ? <Box pos="relative">
                <Image src={allImages[0].url} height={"200px"} fit="cover" w="full" />
                <Button pos="absolute" transform="translate(-50%, -50%)" top="50%" left="50%" onClick={()=>deleteFirebaseImage(allImages[0])} isLoading={loading}>Remove Cover Image</Button>
            </Box>
            : <Box pos="relative">
                <Image src={coverImage.preview !== "" ? coverImage.preview : placeHolder} h="200px" fit="cover" width="full" />
                <Box pos="absolute" transform="translate(-50%, -50%)" top="50%" left="50%">
                    <CoverFile name="coverImage" control={control} />
                </Box>
            </Box>
        }
        <Box p={4} pt={4} pos={"relative"}>
            <Box px={2} py={4}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={4}>
                        
                        <VStack w="full" align="flex-start">
                            <Text as="h1" fontSize="3xl" fontWeight="bold" >Business Details</Text>
                            <FormFieldInput
                                pb={"4"}
                                control={control}
                                text="Phone"
                                id="phone"
                                defaultValue={garage.phone}
                            />
                            <FormFieldInput
                                pb={"4"}
                                control={control}
                                text="Address Line One"
                                id="addressLineOne"
                                defaultValue={fullAdd.line1}
                            />
                            <FormFieldInput
                                pb={"4"}
                                control={control}
                                text="Address Line Two"
                                id="addressLineTwo"
                                defaultValue={fullAdd.line2}
                            />
                            <HStack flexDirection={["column", "row"]} w="full" pb={4}>
                                <FormFieldInput
                                    control={control}
                                    text="City"
                                    id="city"
                                    defaultValue={fullAdd.city}
                                />
                                <FormFieldSelect
                                    control={control}
                                    text="State"
                                    id="state"
                                    defaultValue={fullAdd.state}
                                    children={states.map((state) => <option key={state} value={state}>{state}</option>)}
                                />
                                <FormFieldInput
                                    control={control}
                                    text="Zip"
                                    id="zip"
                                    defaultValue={fullAdd.zip}
                                />
                            </HStack>
                            <FormFieldInputAddon
                                pb={"4"}
                                control={control}
                                defaultValue={garage.url}
                                id="website"
                                text="Website"
                                placeholder="www.example.com"
                                pattern={{
                                    value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                                    message: "invalid website url"
                                }}
                                children="https://"
                            />
                            <FormFieldTextArea
                                control={control}
                                defaultValue={garage.description}
                                id="description"
                                text="About"
                            />
                        </VStack>
                        <VStack align="flex-start" w="full">
                            <Text as="h1" fontSize="3xl" fontWeight="bold" >Hours</Text>
                            <HStack alignItems="flex-start" wrap="wrap" spacing="0" justify="space-between" >
                            {days.map((day, i) => {
                                return <TimeField width={["94%", "94%", "48%"]} key={day} control={control} day={day} watch={true} 
                                    defaultEnabled={times!==undefined&&times.length>1 ? !!times[i].opening : false} 
                                    defaultFrom={times!==undefined&&times.length>1 ? times[i].opening : ''}
                                    defaultTo={times!==undefined&&times.length>1 ? times[i].closing : ''}  />
                                })
                            }
                            </HStack>
                        </VStack>
                        {allServices.length > 0 && <VStack alignItems="flex-start">
                            <Text as="h1" fontSize="3xl" fontWeight="bold">Services</Text>
                            <FormControl>
                                <Wrap>
                                    {allServices.map((service) => {
                                        return (
                                            <Controller 
                                                key={service.id}
                                                control={control}
                                                defaultValue={garage.services.includes(service.id!)}
                                                name={`services[${service.id}]`}
                                                render={({field:{value, ref, name, onBlur, onChange}}) => (
                                                    <Checkbox pb={2} w={["calc(100% - 0.5rem)", "calc(50% - 0.5rem)" ,"calc(33.3% - 0.5rem)"]} onBlur={onBlur} onChange={onChange} name={name} ref={ref} value={service.id} defaultChecked={garage.services.includes(service.id!)} >{service.name}</Checkbox>
                                                )}
                                            />
                                        )
                                    })}
                                </Wrap>
                            </FormControl>
                        </VStack>}

                        {/* {imageUrls.length > 0 &&
                        <VStack w="full" alignItems="flex-start">
                            <Text as="h1" fontSize="3xl" fontWeight="bold">Images</Text>
                            <HStack w="full">
                                {imageUrls.map((image, index) => {
                                    return (
                                        <Box key={index} pos="relative" minW={24} minH={24} w={24} h={24}>
                                            <IconButton top="0" right="0"  pos="absolute" isRound aria-label="delete image" icon={ <FontAwesomeIcon icon={faTimes} /> } />
                                            <Image
                                                src={image}
                                                alt={image}
                                                rounded="full"
                                                objectFit="cover"
                                                w="full"
                                                h="full"
                                            />
                                        </Box>
                                    )
                                })}
                            </HStack>
                        </VStack>
                        } */}

                        <VStack w="100%" align="flex-start">
                            <Text as="h1" fontSize="3xl" fontWeight="bold">Images</Text>
                            <FormProvider {...methods}>
                                <Box as="section" w="full">
                                    <FileInput
                                        accept="image/png, image/jpg, image/jpeg"
                                        // existingImages={imageUrls}
                                        existingImages={allImages}
                                        hideFirst
                                        deleteExistingFn={deleteFirebaseImage}
                                        name="photos"
                                        label="Photos"/>
                                </Box>
                            </FormProvider>
                        </VStack>

                    </VStack>
                <Flex mt={10} justify="center" pos="relative" >
                    <IconButton aria-label="commit" size="lg" colorScheme="green" isLoading={loading} icon={<FontAwesomeIcon icon={faCheck} />} mr={2} type="submit" />
                    <IconButton aria-label="cancel" size="lg" colorScheme="red" icon={<FontAwesomeIcon icon={faTimes} />} onClick={() => setEdit(false)} />
                    <IconButton pos="absolute" right="0" aria-label="delete" size="lg" colorScheme="red" icon={<FontAwesomeIcon icon={faTrash} />} onClick={() => setIsDialogOpen(true)} />
                </Flex>
                </form>
            </Box>
            {isDialogOpen && <AlertDialogModal />}
            {loading && <Box top="0" left="0" w="full" h="full" pos="absolute" bgColor="#70707062" ><Loading height="100%" logoWidth={["200px"]} /></Box>}
        </Box>
            </>
    )


}

export default EditGarageForm