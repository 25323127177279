import { Stack, Text, Flex, Box, Link, useColorModeValue, chakra, SimpleGrid, Container, ChakraProps,   } from '@chakra-ui/react'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Footer from '../Navigation/Footer'
import {useInView} from 'react-intersection-observer';
import * as Scroll from 'react-scroll';
import useViewport from '../Hooks/Viewport';

let Element = Scroll.Element;

// export default function Reviews({setIndex}:{setIndex:Function}) {
export default function Reviews() {

    // const {ref, inView, entry} = useInView({threshold: 0.1});
    const [currentSlide, setCurrentSlide] = useState(0);
    const slidesCount = 3;
    const { width } = useViewport();
    const mdBreakpoint = 768;

    const prevSlide = () => {
        setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };
    
    const nextSlide = () => {
        setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };

    const carouselStyle = {
        transition: "all .5s",
        ml: `-${currentSlide * 100}%`,
    };

    const arrowStyles:ChakraProps = {
        pos:"absolute",
        cursor: "pointer",
        top: "50%",
        w: "auto",
        mt: "-22px",
        p: "16px",
        color: useColorModeValue("gray.800", "teal.400"),
        fontWeight: "bold",
        fontSize: "18px",
        transition: "0.6s ease",
        borderRadius: "0 3px 3px 0",
        userSelect: "none",
        _hover: {
        opacity: 0.8,
        bg: "black",
        },
    };

    // useEffect(() => {
    //     if(inView){
    //         setIndex(4);
    //     }
    // }, [inView])

    function ReviewCarousel() {
        return (
            <Flex
            pos="relative"
            w="full"
            px={10}
            py={0}
            alignItems="center"
            justifyContent="center"
            >
            <Flex w="full" overflow="hidden" pos="relative">
                <Flex h="400px" w="full" {...carouselStyle}>
                
                {
                    reviews.map((review, i) => {
                        return (
                            <Box key={i} w="full" >{review}</Box>
                        )
                    } )
                }

                </Flex>
            </Flex>
                <Text {...arrowStyles} left="0" onClick={prevSlide}>
                &#10094;
                </Text>
                <Text {...arrowStyles} right="0" onClick={nextSlide}>
                &#10095;
                </Text>
            </Flex>
        );
    }

    function ReviewItem({content, author}:{content:string, author:string}){
        return (
            <Flex
            flex={1}
            alignItems="center"
            justifyContent="center"
            height="full"
            >
                <Flex
                    w="full"
                    alignItems="center"
                    justifyContent="center"
                    >
                    <Box
                        w={["md"]}
                        mx="auto"
                        py={4}
                        px={8}
                        bg={useColorModeValue("white", "gray.700")}
                        shadow={["unset","lg"]}
                        rounded="lg"
                    >
                        <Flex justifyContent={{ base: "center" }} mt={-16}>
                            <Flex
                                w={20}
                                h={20}
                                fit="cover"
                                rounded="full"
                                borderStyle="solid"
                                borderWidth={2}
                                borderColor={useColorModeValue("teal.500", "teal.400")}
                                justify="center"
                                align="center"
                            >
                            <FontAwesomeIcon color={useColorModeValue('black', 'white')} icon={faQuoteRight} size="lg"/>
                            </Flex>
                        </Flex>
                        <chakra.p mt={2} color={useColorModeValue('gray.600', 'gray.500')}>{content}</chakra.p>
                        <Flex justifyContent="end" mt={4}>
                            <Link
                                fontSize="sm"
                                color={useColorModeValue('gray.600', 'gray.500')}
                            >{author}
                            </Link>
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
        )
    }

    const reviews = [
        <ReviewItem content="CARERA was super helpful in working with my insurance provider. I got to avoid all of the hassle and they even covered my deductible!" author="Jen Caldmore" />,
        <ReviewItem content="Good experience - will use again (hopefully won't need to though)..." author="Casey Johnson" />,
        <ReviewItem content="I was not expecting this to be as easy as it was. Signed up, booked an appointment and they took care of the rest. Thanks!" author="Kyle Smith" />
    ]

    return (
        <>
        {/* <Element name="reviews" style={{height:"100%", minHeight:"600px", scrollSnapAlign:"start"}}> */}
                <Box 
                minH={"400px"}
                py={{base: 16, md:12}}
                // ref={ref} 
                w="full" h="full" bgColor="light-gray" backgroundRepeat="no-repeat" backgroundSize="cover">
                    <Flex maxW="container.xl" 
                    // pt="calc(2rem + 70px)" 
                    h="full" m="auto" direction="column" justify="space-between">
                        <Flex maxW={"full"} h="full" flexDirection="column" justifyContent="center" px={[1, 3, 5]} m="auto" >
                            
                            <Stack 
                            as={Box} 
                            textAlign={'center'} 
                            spacing={{ base: 8, md: 10 }} 
                            pt={{ base: 0, md: 2, lg: 5 }}
                            pb={{ base: 0, md: 5, lg: 20 }}
                            // pt={[3,3,0]}
                            >
                                <Text px={4} fontWeight="bold" fontSize={{ base: '2xl', sm: '3xl', lg: '5xl' }} color={useColorModeValue('gray.800', 'gray.100')}>
                                    What our customers are saying
                                </Text>
                            </Stack>
                            { width > mdBreakpoint 
                                ? <SimpleGrid columns={[1,1,3]} spacing={[16, 16, 5]} >
                                    {
                                        reviews.map((review, i) => {
                                            return (
                                                <Box key={i}>{review}</Box>
                                            )
                                        })
                                    }
                                </SimpleGrid>
                                : <ReviewCarousel />    
                            }
                        </Flex>
                    </Flex>
                </Box>
            {/* </Element> */}

        </>
    )
}
