import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, Icon, IconProps, Stack, Text, Image, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect } from "react";

import stepTwoImage from "../../images/step-two.jpg"
import industry_markerplace from "../../images/industry-marketplace.jpg"
import SectionWrapper from "./SectionWrapper";
import {useInView} from 'react-intersection-observer';

const Blob = (props: IconProps) => {
    return (
        <Icon
        width={'100%'}
        viewBox="0 0 578 440"
        fill="none"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
            fill="currentColor"
        />
        </Icon>
    );
};

// export default function StepTwo({setIndex}:{setIndex:Function}){
export default function StepTwo(){
    // const {ref, inView, entry} = useInView({threshold: 0.1})

    // useEffect(() => {
    //     if(inView){
    //         setIndex(2);
    //     }
    // }, [inView])

    // function Headline(){
    //     return (
    //         <Heading
    //             lineHeight={1.1}
    //             fontWeight={600}
    //             fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
    //                 <Text
    //                 color={useColorModeValue('gray.800', 'gray.100')}
    //                 as={'span'}
    //                 position={'relative'}
    //                 _after={{
    //                     content: "''",
    //                     width: 'full',
    //                     height: '30%',
    //                     position: 'absolute',
    //                     bottom: 1,
    //                     left: 0,
    //                     bg: useColorModeValue('teal.400', 'gray.800'),
    //                     zIndex: -1,
    //                 }}>
    //                     Step Two,
    //                 </Text>
    //                 <Text as={'p'} fontSize={{base:'2xl', sm: '4xl', lg: '6xl'}} color={'teal.400'}>
    //                     Start your insurance claim
    //                 </Text>
    //         </Heading>
    //     )
    // }

    return (
    <SectionWrapper name="step-two" 
    // reference={ref}
    >
    <Stack
    align={'center'}
    spacing={{ base: 4, md: 10 }}
    py={{ base: 0, md: 22 }}
    direction={{ base: 'column', md: 'row' }}>
        <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
                <Blob
                    w={'150%'}
                    h={'150%'}
                    position={'absolute'}
                    top={'-20%'}
                    left={0}
                    zIndex={-1}
                    color={useColorModeValue('teal.50', 'teal.400')}
                />
                <Box
                    position={'relative'}
                    height={'300px'}
                    rounded={'2xl'}
                    boxShadow={'2xl'}
                    width={'full'}
                    overflow={'hidden'}>
                        <Image 
                        src={industry_markerplace}
                        fit={'cover'}
                        align={'center'}
                        w={'100%'}
                        h={'100%'}/>
                </Box>
        </Flex>
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
        <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
            <Text
                color={useColorModeValue('gray.800', 'gray.100')}
                as={'span'}
                position={'relative'}
                _after={{
                    content: "''",
                    width: 'full',
                    height: '30%',
                    position: 'absolute',
                    bottom: 1,
                    left: 0,
                    bg: 'teal.400',
                    zIndex: -1,
                }}>
                    Industry Marketplace
                </Text>
            </Heading>
            <Text color={useColorModeValue('gray.600', 'gray.500')}>
                Experience a seamless and efficient way to buy, sell, and trade high-quality auto parts within our secure environment. Our platform is designed to cater to the unique needs of auto professionals, ensuring that you have access to a wide range of reliable components and supplies for your clients' vehicles.
            </Text>
            {/* <Box display={['none', 'block']} ><Headline /></Box>
            <Text color={useColorModeValue('gray.600', 'gray.500')}>
                If you'd like us to handle your claim, simply add your insurance details. CARERA will cover your deductible!
            </Text>

            <Accordion allowToggle>
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]} >
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')} >
                                    How long does it take to complete my claim?
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'p'} color={'gray.500'}>
                                After the inital call with your insurance company, our process can be done in less than 10 minutes.
                            </Text>
                        </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]}>
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')}>
                                    What type of information do I need to provide?
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'p'} color={'gray.500'}>
                                As little as 3 things are needed; your claim number, any preliminary estimate provided by your insurance company and photos of the damage.
                            </Text>
                        </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]}>
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')}>
                                    Will CARERA cover my entire deductible?
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'p'} color={'gray.500'}>
                                Carera will cover any deductible upto $500, at any Carera Cetified Repair facility.
                            </Text> 
                        </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <h2>
                        <AccordionButton px={[2,4]} py={[0,2]}>
                                <Box flex="1" textAlign="left" color={useColorModeValue('gray.600', 'gray.500')}>
                                    Does Carera warranty the repairs?
                                </Box>
                                <AccordionIcon color={useColorModeValue('gray.600', 'gray.500')} />
                        </AccordionButton>
                    </h2>
                        <AccordionPanel pb={4}>
                            <Text as={'p'} color={'gray.500'}>
                                All repairs done by a Carera certified repair facility are warranted for the life of your car, so long as you are the owner.
                            </Text> 
                        </AccordionPanel>
                </AccordionItem>
            </Accordion> */}
            
        </Stack>
    </Stack>
    </SectionWrapper>
    )
}