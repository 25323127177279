import React, { useState,useEffect } from "react";
import firebase, { db } from '../../firebase';


/** Reresents a single entry in the firestore database */
export interface DbAvailableService {

    /** database id of the service */
    id:string|undefined;

    /** name of the service */
    name:string;
};


// IMPORTANT: this merely makes typescript happy.
// If you want to export this stuff, you MUST, MUST, MUST
// add it to the .Provider tag in jsx!!!
export type AvailableServicesContextType = {
    /** all of the services listed in the database */
    services:DbAvailableService[];
}
    

export const AvailableServicesContext = React.createContext({});


// more typescript joy
interface AvailableServicesComponentProps {
    children:any;
};


/** gets the available services from the database and supplies it to all components */
export function AvailableServicesProvider({children}:AvailableServicesComponentProps) {
    const [services, setServices] = useState<DbAvailableService[]>([]);

    const servicesCollection = db.collection("services");

    // called once on component start to get the services from the database
    useEffect(()=>{
        loadServices();
        }
        ,[]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Loads the services from the database */
    function loadServices() {
        const unregister=servicesCollection.onSnapshot(
            (querySnapshot)=>{
                    let items:DbAvailableService[]=[];
                    querySnapshot.forEach((doc)=>{
                        items.push({...doc.data() as DbAvailableService,id:doc.id});
                    });
                    items.sort((a,b)=>{return a.name>b.name?1:0});
                    setServices(items);
                    unregister();
            },
            (error:any) => {
                console.error("AvailableServices.tsx: Error getting services");
                console.error(error);
            }
        );
    }

    return (
        <AvailableServicesContext.Provider value={{services}}>{children}</AvailableServicesContext.Provider>
      )
}