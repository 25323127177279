import { Button, Flex, HStack, IconButton, Link, Spacer, Tag, TagLabel, TagLeftIcon, VStack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, Link as ReactRouterLink } from 'react-router-dom';
import Typesense from 'typesense';
import { NoResults } from '../Common/Graphics';
import Loading from '../Common/Loading';
import { ClassifiedListing } from './AddClassifiedsForm';
import ClassifiedsTableView from './ClassifiedsTableView';
import { faGripHorizontal, faTable } from '@fortawesome/free-solid-svg-icons'
import { CloseIcon } from '@chakra-ui/icons';
import SearchBar from '../Common/SearchBar';
import useViewport from '../Hooks/Viewport';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(()=>new URLSearchParams(search),[search])
}

export const ClassifiedsSearchResults = () => {

  const query = useQuery();
  const searchString = query.get("term") ?? '';
  const size = query.get("size")
  const pageNo = query.get("page")
  const perpage = size ? parseInt(size) : 20;
  const page = pageNo ? parseInt(pageNo) : 1;
  const [loading, setLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<ClassifiedListing[]>([]);
  const [showSearch, setShowSearch] = useState(false);
  const history = useHistory();
  const smBreakpoint = 480;
  const { width } = useViewport();

  useEffect(() => {
    typesense.collections('classifieds').documents().search({
      'q':searchString,
      'query_by':'title,summary',
      'per_page': perpage+1,
      'page':page
    }).then((res) => {
        const searchList:ClassifiedListing[] = []
        const searchData = res.hits;
        searchData?.forEach((v) => {
            const item = v.document as ClassifiedListing
            searchList.push(item)
        })
        setSearchResults(searchList)
        setLoading(false)
        console.log(searchList)
    }).catch((err) => {
        console.log(err)
    })
  }, [])
  

  const typesense = new Typesense.Client({
    'nodes':[
        {
            'host':'localhost',
            'port':8108,
            'protocol':'http'
        }
    ],
    'apiKey': 'xyz'
  })

  const clearSearch = () => {
    setShowSearch(true)
  }

  const onSearch = (searchString:string) => {
    if(searchString === '') {
        return
    }
    const searchTerm = encodeURI(searchString)
    history.push(`/dashboard/classifieds/search?term=${searchTerm}&page=1&size=20`)
  }

  return (
    <>
    <Flex w={"full"} mb={6} px={[0,4]} pos="relative">
        {
          showSearch
          ? <SearchBar searchFn={onSearch} searchLeftStart={width < smBreakpoint ? "calc(100% - 8.5rem)" : "calc(100% - 10rem)"} />
          : <Tag onClick={clearSearch} cursor={'pointer'} borderRadius={'full'} px={4} mt={[16,0]}>
            <TagLeftIcon boxSize={'10px'} as={CloseIcon}/>
            <TagLabel>{searchString}</TagLabel>
          </Tag>
        }
        <Spacer/>
        <IconButton aria-label="table" mr={[2,3]} icon={<FontAwesomeIcon icon={faTable}/>} />
        <IconButton aria-label="card" mr={[2,3]} icon={<FontAwesomeIcon icon={faGripHorizontal} />} />
    </Flex>
    {
        loading
        ? <Loading/>
        : searchResults.length > 0
            ? <VStack>
              <ClassifiedsTableView classifieds={searchResults} setList={setSearchResults} itemCount={perpage} />
              {searchResults.length>perpage && <HStack p={2} justify={'center'} ><Button><Link as={ReactRouterLink} to={`/dashboard/classifieds/search?term=${searchString}&page=${page+1}&size=${size}`}>Next</Link></Button></HStack>}
            </VStack> 
            : <NoResults />
    }
    </>
  )
}
