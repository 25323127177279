import React, {useState, useEffect, useRef} from 'react';

import firebase, {db, storage} from '../../firebase';
import algoliasearch from 'algoliasearch';
import { SearchResponse } from '@algolia/client-search';
import DbGarage, { DbGarageData } from './DbGarage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { 
    Button,
    Flex,
    SimpleGrid,
    Spacer,
    useDisclosure,
    useToast,
    Text,
    Tag,
    TagLabel,
    TagCloseButton,
    } from '@chakra-ui/react';
import GarageCard from './GarageCard';
import { motion } from 'framer-motion';
import Loading from '../Common/Loading';
import PageControl from '../Common/PageControl';
import SearchBar from '../Common/SearchBar';
import useViewport from '../Hooks/Viewport';
import AddGarageView from './AddGarageView';
import { NoResults } from '../Common/Graphics';
import * as Scroll from 'react-scroll';


const algoliaClient=algoliasearch('XS1SQLAXE9','af8f230b9d3aeb9c8a16febb6666ac26');
const algoliaIndex=algoliaClient.initIndex('garages');


const defaultItemsPerPage=5;

let Element = Scroll.Element;
let scroller = Scroll.scroller;


/** A list of garagess based on garage, user, or just all of them */
function GaragesTable(){
    const [page,setPage]=useState(0);
    const [garages,setGarages]=useState<DbGarage[]>([]);
    const [allGarages,setAllGarages]=useState<DbGarage[]>([]);
    const [totalItems,setTotalItems]=useState<number>(0); // total number of items
    const [itemsPerPage,setItemsPerPage]=useState(defaultItemsPerPage);
    const [pagLoading, setPagLoading] = useState(false); // pagination loading state
    const [searchText,setSearchText]=useState<string>('');
    const [loading, setLoading] = useState(true);
    const [isSearching, setIsSearching] = useState({
        state: false,
        term: '',
    });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const inputRef = useRef<null | HTMLElement>(null)
    const toast = useToast();
    const { width } = useViewport();
    const smBreakpoint = 480;

    const garagessCollection = db.collection("garages");

    // this is set up to run once on component creation
    useEffect(()=>{
        loadGarages();
    }
    ,[]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Loads from the database */
    function loadGarages() {
        let u:firebase.firestore.CollectionReference|firebase.firestore.Query=garagessCollection;
        setLoading(true);
        const unregister=u.onSnapshot(
            (querySnapshot:firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>)=>{
                let items:DbGarage[]=[];
                querySnapshot.forEach((doc)=>{
                    items.push({...doc.data() as DbGarage,id:doc.id});
                });
                items=items.sort((a,b)=>{
                    if (a.name<b.name) {
                        return -1;
                    }
                    if (a.name>b.name) {
                        return 1;
                    }
                    return 0;
                });
                setAllGarages(items);
                setGarages(items.slice(0,itemsPerPage));
                setTotalItems(items.length);
                setLoading(false);
                unregister();
            },
            (error:any) => {
                console.error("GaragesTable.tsx: Error getting garages");
                console.error(error);
                setLoading(false);
            }
        );
    }

    /** Delete a garage from the database */
    async function deleteGarage(garageId:string){
        try {
            const garageDoc=garagessCollection.doc(garageId);
            // remove any user(s) pointing to this garage
            try { // (allow this to fail in case there is nothing there)
                const usersCollection=db.collection('users');
                const userSnapshots=await usersCollection.where("garageId","==",garageId).get();
                for(let userSnapshot of userSnapshots.docs){
                    await userSnapshot.ref.delete();
                }
            } catch(err) {
                console.error(err);
            }
            // remove any images from database collection
            try { // (allow this to fail in case there is nothing there)
                const imagesSnapshot=await garageDoc.collection('images').get();
                const promises=imagesSnapshot.docs.map((docSnap)=>docSnap.ref.delete());
                await Promise.all(promises);
            } catch(err) {
                console.error(err);
            }
            // delete image files from storage
            try { // (allow this to fail in case there is nothing there)
                // find anything in the images directory
                let ref=storage.ref(`garages/${garageId}/images`);
                let children=(await ref.listAll()).items;
                let promises=children.map((child)=>child.delete());
                ref=storage.ref(`garages/${garageId}`); // also grab anything in the root
                children=(await ref.listAll()).items;
                children.forEach((child)=>promises.push(child.delete()));
                // do it!
                await Promise.all(promises);
            } catch(err) {
                console.error(err);
            }
            // remove the garage doc itself from the database
            await garageDoc.delete();
            setGarages(garages.filter((garage)=>garage.id!==garageId));
            toast({
                title: "Garage Deleted",
                description: `The selected Garage was deleted!`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch(err) {
            console.error(err);
            toast({
                title: "Error",
                description: `There was an error in deleting, please try again!`,
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
    }

    /** Update a garage in the db and the state */
    function updateGarage(garage:DbGarage){
        try {
            garagessCollection.doc(garage.id).update(garage);
            const newGarages = garages.map(g => g.id !== garage.id ? g : garage);
            setGarages(newGarages);
        } catch (error) {
            /** TODO catch error and undo changes */
            console.log(`update garage error ${error}`);
        }
    }

    /** process search results */
    function processSearchResults(response:SearchResponse<DbGarageData>):DbGarage[]{
        return response.hits.map(hit=>({
            id:hit.objectID,
            createdDate:hit.createdDate,
            name:hit.name,
            about:hit.about,
            address:hit.address,
            phone:hit.phone,
            services:hit.services,
            garageCalendar:hit.garageCalendar,
            limitToHours:hit.limitToHours,
            url:hit.url,
            instantBook:hit.instantBook,
            description:hit.description
        }));
    }

    /** Search for a particular garage name */
    async function search(queryString:string,page:number=0,numItemsPerPage:number=itemsPerPage){
        // queryString !== "" ? setIsSearching(true) : setIsSearching(false);
        if(queryString!==""){
            setIsSearching({
                state: true,
                term: queryString,
            });
            setLoading(true);
            let response=await algoliaIndex.search<DbGarageData>(queryString, {
                hitsPerPage: numItemsPerPage,
                page: page
            });
            setTotalItems(response.nbHits);
            let items=processSearchResults(response);
            setGarages(items);
            setLoading(false);
        } else {
            setIsSearching({
                state: false,
                term: '',
            });
            loadGarages();
        }
    }
    
    /**
     * called when the page we are on changes
     */
    async function onPageChange(startItem:number,count:number){
        let pageNo=Math.floor(startItem/count);
        setPage(pageNo);
        if(searchText===''){
            setPagLoading(true);
            // we are getting the next page from firebase
            setGarages(allGarages.slice(startItem,startItem+count));
            setPagLoading(false);
            if(window.scrollY!==0){
                scroller.scrollTo('listContainer',{duration: 500, smooth: true, offset: -24});
            }
        } else {
            // next page of search results
            search(searchText,pageNo,count);
        }
        if(count!==itemsPerPage){
            setItemsPerPage(count);
        }
    }
    
    return (
    <>
    <Element name='listContainer' className="list_container">
        <Flex w={"full"} mb={6} px={[0,4]} align="center" justify={{base: "flex-end", md: "flex-start"}} pos="relative" >
            <SearchBar searchFn={search} searchLeftStart={width < smBreakpoint ? "calc(100% - 7.5rem)" : "calc(100% - 8.5rem)"} />
            <Spacer display={{base: "none", md: "initial"}} />
            <Button leftIcon={<FontAwesomeIcon icon={faPlus}/> } onClick={() => onOpen()} colorScheme="blue"><FontAwesomeIcon icon={faWarehouse} /></Button>
        </Flex>
        { isSearching.state && 
            <Flex px={4} pb={6} alignItems="center">
                <Text as="h1" fontSize="large" >Search Results for: </Text>
                <Tag size="lg" colorScheme="blue" fontWeight="bold" ml={2} fontStyle="italic" ><TagLabel>{isSearching.term}</TagLabel><TagCloseButton onClick={() => {search('');setSearchText('');}} /></Tag>
            </Flex> 
        }
        {
            !loading
                ? totalItems > 0
                    ? <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}>
                            <SimpleGrid minChildWidth="295px" spacing="30px" px={[0,4]} w={"100%"} gridAutoRows={"minmax(200px, min-content)"}>
                                { garages.map((garage)=>(
                                    <GarageCard key={garage.id} garage={garage} updateGarage={updateGarage} deleteGarage={deleteGarage}></GarageCard>
                                )) }
                            </SimpleGrid>
                            <PageControl totalItems={totalItems} itemsPerPage={itemsPerPage} page={page} onPageChange={onPageChange} pagLoadingState={pagLoading} />
                    </motion.div>
                    : <NoResults />
            : <Loading/>
        }
    </Element>
    <AddGarageView isOpen={isOpen} onClose={onClose} />
    </>


    );
}


export default GaragesTable;