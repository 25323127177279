import { SimpleGrid } from "@chakra-ui/react"
import { motion } from "framer-motion"
import PageControl, { OnPageChangeFn } from "../Common/PageControl"
import DbWorkorderType from "./DbWorkorderType"
import WorkorderCard from "./WorkorderCard"
import WorkorderTableView from "./WorkorderTableView"


interface WorkorderViewOptionsTypes {

    /** whether to be in table view or card view */
    tableView: boolean,

    /** all the workorder objects to display */
    displayedWorkorders: DbWorkorderType[],

    /** the total number of items there are */
    totalItems: number,

    /** called when changing to another page of results */
    onPageChange: OnPageChangeFn,

    /** how many items per page */
    itemsPerPage: number,

    /** whether to view the control in a "loading" state or not */
    pagLoading: boolean,

    updateState: Function,
    
    /** the page we are on */
    page: number
}


export default function WorkorderViewOption({tableView, displayedWorkorders, totalItems, itemsPerPage, onPageChange, pagLoading, updateState, page}:WorkorderViewOptionsTypes){
    return (
        tableView 
        ? <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}>
            <WorkorderTableView workorders={displayedWorkorders} updateState={updateState} />
            <PageControl totalItems={totalItems} itemsPerPage={itemsPerPage} page={page} onPageChange={onPageChange} pagLoadingState={pagLoading} />
        </motion.div>
        : <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}>
            <SimpleGrid minChildWidth="250px" spacing="30px" px={[0,4]} my={6} w={"100%"} gridAutoRows={"minmax(160px, min-content)"}>
                { displayedWorkorders!.map((workorder)=>(
                <WorkorderCard key={workorder.id} workorder={workorder} updateState={updateState} cancelWorkorderCB={(wo:DbWorkorderType)=>console.log(wo)} ></WorkorderCard>
                )) }
            </SimpleGrid>
            <PageControl totalItems={totalItems} itemsPerPage={itemsPerPage} page={page} onPageChange={onPageChange} pagLoadingState={pagLoading} />
        </motion.div>
    )
}