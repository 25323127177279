import React, { useContext } from 'react'
import { createCtx } from '../Helpers/createCtx';

export enum NavSize {
    Small, Large
}

export interface NavSizeProps{
    navSize: NavSize,
    userSet: boolean,
}


export interface SidebarContextProps {
    navSizeProps: NavSizeProps;
}

export enum SidebarCtx {
    navSize = 'NAVSIZE',
    navSizeInitial = 'NAVSIZEINITIAL',
}

const initialState:SidebarContextProps = {
    navSizeProps: {navSize:NavSize.Large, userSet: false},
}

export type SidebarAction = 
    | {type:SidebarCtx.navSize, payload: NavSize}
    | {type:SidebarCtx.navSizeInitial, payload:NavSizeProps}

type AppState = typeof initialState

const reducer = (state:SidebarContextProps, action: SidebarAction):AppState => {
    switch (action.type) {
        case SidebarCtx.navSize:{
            return {...state, navSizeProps:{navSize: action.payload, userSet: true}}
        }
        case SidebarCtx.navSizeInitial:
            return {...state, navSizeProps:action.payload}
        default:
            return state
    }
}

const [ctx, SidebarContextProvider] = createCtx(reducer, initialState);
const SidebarContext = ctx;
const useSidebarContext = () => {
    const sidebarContext = useContext(SidebarContext)
    if(!sidebarContext){
        throw new Error('No SidebarContext.Provider found when calling useSidebarContext');
    }
    return sidebarContext
}

export { useSidebarContext, SidebarContextProvider }