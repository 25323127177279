import React from "react"
import { db } from "../../firebase"


export interface AuthContextData {
   
   /** is the current user an administrator? */
   isAdmin?:boolean;
   
   /** is the current user a garage account? */
   isGarage?:boolean;

   /** if user is a garage, then which garage are they? */
   garageId?:string,

   /** current user's first name */
   firstName:string;

   /** current user's last name */
   lastName:string;

   /** current user's email address */
   email?:string;

   /** current user's phone number */
   phone?:string;

   /** current user's address */
   address?:string;

   /** current users orders */
   orders?: string[];

   /** flag to see if user has created the account */
   verified? : boolean;

   /** flag to check if garage wants to create a password */
   usePassword? : boolean;
};


export interface AuthContextType extends AuthContextData {

   /** id of the current user */
   id:string;

   /** is there is logged in user? */
   isLoggedIn:boolean;
};


// a global context used to tell components about the logged-in user
const AuthContext = React.createContext<AuthContextType>({
   id:'',
   isAdmin:false,
   isGarage:false,
   garageId:'',
   isLoggedIn: false,
   firstName: '',
   lastName: '',
   email: '',
   phone: '',
   address: '',
   orders: [],
});

/** call this to clear out the context (aka, logout) */
export function clearAuthContext(ctx:AuthContextType){
   ctx.id='';
   ctx.isAdmin=false;
   ctx.isGarage=false;
   ctx.garageId='';
   ctx.firstName='';
   ctx.lastName='';
   ctx.phone='';
   ctx.email='';
   ctx.address='';
   ctx.orders=[];
 }

/** called to get user data whenever the logged-in state of the user changes */
export async function updateAuthContext(ctx:AuthContextType,name:string|null,email:string|null,uid:(string|undefined|null),gid:(string|undefined|null)=undefined){
   if(uid===undefined||uid===null||uid==='') {
      clearAuthContext(ctx);
      return;
   }
   // console.log('attempting to get doc');
   const docRef=db.collection("users").doc(uid);
   var doc;
   try{
      doc=await docRef.get();
   }catch(error:any){
      console.error(error);
      return;
   }
   if(doc.exists) {
      // console.log('doc exists');
      // console.log(doc.data());
      // stuff everything from the database records into Auth
      const data=doc.data() as AuthContextData
      Object.assign(ctx,data,{id:uid,isLoggedIn:true}); // update the ctx
   } else if(email!==null) {
      // create a new database record
      // check if new user is garage or user, in case garage, copy existing garage data from user record, then create new record
      // that has a correct uid, delete previous record and update garage with correct uid
      // in either case assign the correct context after garage cretion or after verifying simple user
      let nameSplit=['',''];
      if(name!==null){
         nameSplit=name.split(' ');
         while(nameSplit.length<2){
            nameSplit.push('');
         }
      }
      if(gid!==undefined&&gid!==null){
         try {
            const batch = db.batch();
            const existingUserGarageRef=db.collection("users").doc(gid);
            const garageUserData = (await existingUserGarageRef.get()).data() as AuthContextData;
            const newDocValues = {...garageUserData, verified: true};

            batch.set(docRef, newDocValues);
            batch.delete(existingUserGarageRef);
            await batch.commit();
            Object.assign(ctx,newDocValues,{id:uid,isLoggedIn:true}); // update the ctx
         } catch (error) {
            console.log(error);
            throw Error;
         }
      } else {
         const data:AuthContextData = {firstName: nameSplit[0], lastName: nameSplit[nameSplit.length-1], email, isAdmin:false, isGarage:false}
         Object.assign(ctx, data ,{id:uid, isLoggedIn:true});
      }
   }
   return ctx;
}


export default AuthContext;