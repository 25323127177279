import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons'

import { DbReviewsInfo } from "./DbReviews";
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';


function Star({full=false,half=false}:{full?:boolean,half?:boolean}){

    const color="#F6E05E";
    const bgColor="#EDF2F7";

    return (
        <Box pos="relative">
            <FontAwesomeIcon fontSize="14px" icon={faStar} style={{fontSize:"14px",position:"relative",color:(full!==undefined&&full?color:bgColor)}} />
            {// overlay a half star over the background
            half!==undefined&&half
                ?(<Box pos="absolute" top="0" left="0">
                    <FontAwesomeIcon  icon={faStarHalf} style={{fontSize:"14px", color:color }} />
                </Box>)
                :''
            }
        </Box>)
    };



// const NumStarsText=NumRatingsText; // for now the same


interface ReviewsProps extends DbReviewsInfo {
}

/** a control to display reviews and stars 
 * 
 * link - (optional) when they click on the rating, go to this site
*/

// TODO: remove default values!
function Reviews({numStars,numReviews,reviewsUrl}:ReviewsProps){

    const stars=numStars===undefined?0:numStars;
    const textColor = useColorModeValue("gray.900", "white");
    const hoverColor = useColorModeValue("gray.50", "gray.400");

    return (
        <>
        {numReviews && numReviews > 0
        ? <Flex 
            w="min-content"
            p={1}
            borderRadius="full"
            _hover={{
                background: hoverColor
            }}
            cursor={reviewsUrl ? "pointer" : "inherit"}
            mt={"0 !important"}
            zIndex="1" 
            align="center"
            onClick={(e)=>{
                if(reviewsUrl!==undefined)window.open(reviewsUrl,'_blank');
                e.stopPropagation();
            }}>
            <Text color={textColor} fontSize="xs" fontWeight="bold" mr={1}>{numStars}</Text>
            { [1,2,3,4,5].map((star)=>
                star <= stars
                    ?(<Star key={star} full={true} />)
                    :star<=Math.ceil(stars)
                        ?(<Star key={star} half={true}/>)
                        :(<Star key={star}/>)
                )}
            <Text color={textColor} fontSize="sm" ml={1}>({numReviews})</Text>
        </Flex>
        : <Text color={textColor} mt={"0 !important"} fontStyle="italic">No Reviews</Text>
        }
        </>
    );
}



export default Reviews;
