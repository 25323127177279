import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8KYu3kLeFuaz-X7IS12QorqBG9vqle3k",
  authDomain: "carera-be9c6.firebaseapp.com",
  databaseURL: "https://carera-be9c6-default-rtdb.firebaseio.com",
  projectId: "carera-be9c6",
  storageBucket: "carera-be9c6.appspot.com",
  messagingSenderId: "189589143220",
  appId: "1:189589143220:web:e29a3f8542409d4b5ae1e1",
  measurementId: "G-F05P08BX9R"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storage=firebase.storage();
const db = firebase.firestore()
const functions = firebase.functions()

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL); // store login info

// eslint-disable-next-line no-restricted-globals
// if(location.hostname === 'localhost'){
//   auth.useEmulator('http://localhost:9099/')
//   db.useEmulator('localhost', 8080);
//   storage.useEmulator('localhost', 9199);
//   functions.useEmulator('localhost', 5001);
// }


export default firebase;
export { auth, storage, db, functions }