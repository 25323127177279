import React from 'react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useToast } from '@chakra-ui/react'
import { functions } from '../../firebase'

export const DeleteMyClassifiedModal = (
    {isOpen, cancelRef, onCloseDialog, onDelete, itemId}:
    {isOpen:boolean, cancelRef:any, onCloseDialog:()=>void, onDelete:(id:string)=>void, itemId:string|undefined}) => {

    const deleteClassified = functions.httpsCallable('deleteClassified');
    const toast = useToast();
    const [loading, setLoading] = React.useState<boolean>(false);

    const deleteListing = async () => {
        if(itemId){
            try {
                setLoading(true)
                const result = await deleteClassified(itemId)
                const id = result.data as string
                setLoading(false)
                onDelete(id)
            } catch (error) {
                console.log(error)
                toast({
                    title:'Error',
                    description: `There was an error removing the listing, please try again`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                })
                setLoading(false)
            }
        }
    }

  return (
    <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}>
        <AlertDialogOverlay>
            <AlertDialogContent>

                <AlertDialogHeader fontSize='lg' fontWeight='bold'>Delete Listing</AlertDialogHeader>

                <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onCloseDialog}>Cancel</Button>
                    <Button isLoading={loading} colorScheme='red' onClick={deleteListing} ml={3}>Delete</Button>
                </AlertDialogFooter>
                
            </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>
  )
}
