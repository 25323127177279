import { Box, Button, Link, Stack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import {NavLink} from 'react-router-dom';

import carImage from '../../images/get-your-car-fixed.jpeg';
import {useInView} from 'react-intersection-observer';
import * as Scroll from 'react-scroll';

let Element = Scroll.Element;

// export default function Hero({setIndex}:{setIndex:Function}){
export default function Hero(){

    // const {ref, inView, entry} = useInView({threshold: 0.1})

    // useEffect(() => {
    //     if(inView){
    //         setIndex(0);
    //     }
    // }, [inView])

    return (
    // <Element name="get-started" id="get-started" style={{height:"100%", minHeight:"600px", scrollSnapAlign:"start"}}>
        <Box 
        height={"100vh"}
        minH={"600px"}
        id={"get-started"}
        // ref={ref}
        backgroundImage={ useColorModeValue(`linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8)),url(${carImage})`, `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.6)),url(${carImage})` )} 
        backgroundRepeat="no-repeat" 
        backgroundSize="cover" 
        w="full" 
        // h="full" 
        bgColor="light-gray" 
        backgroundPosition="center">
            <Box maxW="container.xl" pt="calc(2rem + 70px)" h="full" m="auto">
                <VStack h="full" justify="center" maxW={'6xl'} mx="8">
                    <Stack 
                        marginBottom={["calc(1rem + 35px)", null, "0"]}
                        align="center"
                        as={Box} 
                        textAlign={'center'} 
                        spacing={{ base: 8, md: 10 }} >
                        <Text fontWeight={600} color={useColorModeValue('gray.800', 'gray.100')} fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }} lineHeight={'110%'}>Buy and sell auto parts</Text>
                        <Text fontWeight={600} color={useColorModeValue('gray.800', 'gray.100')} fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }} lineHeight={'110%'}>without platform fees</Text>
                        <Text maxW="xl" color={useColorModeValue('gray.600', 'gray.300')}>CARERA is a private network for automotive industry professionals to buy and sell directly from and to each other.</Text>
                        <Box>
                            <Stack
                                direction={'column'}
                                spacing={3}
                                align={'center'}
                                alignSelf={'center'}
                                position={'relative'}>
                                <Link as={NavLink} to="/signup" >
                                <Button
                                colorScheme={'teal'}
                                bg={'teal.400'}
                                rounded={'full'}
                                px={6}
                                _hover={{
                                    bg: 'teal.500',
                                }}>Become a Member</Button></Link>
                                <Link as={NavLink} to="/login" >
                                    <Button variant={'link'} colorScheme={'teal'} size={'sm'}>Log into My Account</Button>
                                </Link>
                            </Stack>
                        </Box>
                    </Stack>
                </VStack>
            </Box>
        </Box>
    // </Element>
    )
}