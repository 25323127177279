import { Box, Button, FormLabel, Heading, InputGroup, InputLeftAddon, Progress, useToast } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import firebase, { db, functions, storage } from '../../firebase';
import AuthContext from '../Account/AuthContext';
import FileInput from '../Form/FileInput';
import { FormFieldCheckbox, FormFieldInput, FormFieldSelect, FormFieldTextArea } from '../Form/FormFields'
import { useClassifiedsCategoryContext } from './ClassifiedsCategoryContext';


export interface ClassifiedFormValues {
    title:string;
    description?:string|undefined;
    category:string;
    price:number;
    photos?:File[];
    garageId?:string|undefined;
    contact:boolean;
}

export interface ClassifiedListing extends ClassifiedFormValues{
    id:string;
    createdAt:firebase.firestore.FieldValue;
    summary:string;
    thumbUrl?:string;
}

export interface ClassifiedListingFull extends ClassifiedFormValues{
    id:string;
    createdAt:firebase.firestore.FieldValue;
    images?: StorageImageData[]|undefined;
    phone?:string|undefined;
    email?:string|undefined;
}


export interface StorageImageData{
    fileName:string;
    filepath:string;
    pathReference:string;
    type:string;
    size:number;
    order:number;
    url:string;
    thumbUrl:string;
}

export interface UploadResponse {
    bucket: string;
    downloadUrl: string;
}


export const AddClassifiedsForm = ({setList, onClose}:{setList: React.Dispatch<React.SetStateAction<ClassifiedListing[]>>, onClose: () => void}) => {

    const formControl = useForm()
    const { handleSubmit, control } = formControl
    const classifiedRef=db.collection("classifieds");
    const toast = useToast();
    const Auth = useContext(AuthContext);
    const {state:{categories}} = useClassifiedsCategoryContext();
    const storageFolder = 'classifieds';
    const [uploadImages, setUploadImages] = useState<boolean>(false);
    const [uploadPer, setUploadPer] = useState<number>(0);
    const createClassified = functions.httpsCallable('postClassified')
    const [loading, setLoading] = useState<boolean>(false);



    const onSubmit = async (values:ClassifiedFormValues) => {

        if(Auth.isGarage && Auth.garageId){
            try {
                setLoading(true)
                const batch = db.batch()
                const images = values.photos
                delete values.photos
                const form:ClassifiedFormValues = values as ClassifiedFormValues
                form.garageId = Auth.garageId


                // add listing via firebase function
                const data = JSON.stringify(form)
                const response = await createClassified(data)
                const item = response.data as ClassifiedListing

                const newItem:ClassifiedListing = item

                if(images && images.length>0) {
                    setUploadImages(true)
                    const uploadedImages:StorageImageData[] = await uploadAttachments(newItem.id,images,classifiedRef)
                    newItem.thumbUrl = uploadedImages[0].thumbUrl
                    const docRef = classifiedRef.doc(newItem.id)
                    const fullRef = docRef.collection('full').doc('data')
                    const updatedAt = firebase.firestore.FieldValue.serverTimestamp()
                    batch.update(docRef, {updatedAt:updatedAt,thumbUrl:uploadedImages[0].thumbUrl})
                    batch.update(fullRef, {images:uploadedImages})
                    await batch.commit()
                }

                setLoading(false)

                // // set local state
                setList((oldState) => {
                    const newState = oldState
                    newState.unshift(newItem)
                    return newState
                })
                toast({
                    title:'Listing Added',
                    description: `Your listing ${form.title} was successfully added`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                })
                onClose()
            } catch (error) {
                console.log(error)
                setLoading(false)
                toast({
                    title:'An Error has occurred',
                    description:'Unfortunately the listing could not be added, please try again',
                    status: 'error',
                    duration: 10000,
                    isClosable: true
                })
            }
        }
    }

    /** called to upload all of the attachements 
     * attachements are in order, where photo[0] is the "cover image"
    */
    async function uploadAttachments(id:string,photos:File[],classifiedRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>) {
        const images:StorageImageData[] = []
        const thumb = functions.httpsCallable('thumbTest')
        for(let i=0;i<photos.length;i++){
            let photo=photos[i];
            const name = photo.name.substring(0, photo.name.lastIndexOf('.')) || photo.name;
            const storageRef=`${storageFolder}/${id}/images/${photo.name}`;
            const url = await firebaseStorageUploader(storageRef, photo, name, id)
            const thumbUrl = await thumb({bucket:url.bucket, path: storageRef})
            let attachment:StorageImageData={
                fileName:name,
                filepath:photo.name,
                pathReference:storageRef,
                size:photo.size,
                type:photo.type,
                url:url.downloadUrl,
                thumbUrl:thumbUrl.data,
                order:i+1
            };
            images.push(attachment)
        }
        return images
    }


    /** 
     * similar to firebase store uploadTask only better because it's all async and nice 
    */
    async function firebaseStorageUploader(path:string,data:File|Blob|Uint8Array|ArrayBuffer, name:string, id:string) {
            return new Promise<UploadResponse>(function(resolve, reject){
                const storageRef=storage.ref(path);
                const uploadTask=storageRef.put(data);
                uploadTask.on('state_changed',
                    function(snapshot) {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadPer(progress)
                    },
                    function error(err:any) {
                        console.error('error', err)
                        setUploadImages(false)
                        toast({
                            title:'Upload Error',
                            description:'There was an error uploading the image(s)',
                            status: 'error',
                            duration: 5000,
                            isClosable: true
                        })
                        reject()
                    },
                    async function complete() {
                        setUploadImages(false)
                        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                            const bucket = uploadTask.snapshot.ref.bucket
                            resolve({bucket:bucket,downloadUrl:downloadURL})
                        })
                    }
                )
            })
    }

  return (
    <>
    <Heading pb={8} as={'h1'}>Create Listing</Heading>
    <form onSubmit={handleSubmit(onSubmit)}>
        <FormFieldInput control={control} id={'title'} text={'Title'} required placeholder={'Title of the listing'} pb={'1em'} />
        <FormFieldTextArea control={control} id={'description'} text={'Description'} placeholder={'Description goes here...'} pb={'1em'} required />
        <FormFieldSelect control={control} id={'category'} text={'Category'} placeholder={'Choose Category...'} required pb={'1em'} children={categories.map((category) => <option key={category} value={category}>{category}</option>)} />
        {/* <InputGroup>
            <FormFieldInput control={control} id={'price'} text={'Price'} required placeholder={'$9.99'} pb={'1em'} />
        </InputGroup> */}
        <Box w={'full'} pb={'4'}>
            <FormLabel htmlFor={'price'}>Price</FormLabel>
            <InputGroup>
                <InputLeftAddon children='$' />
                <FormFieldInput control={control} id={'price'} required placeholder={'9.99'}/>
            </InputGroup>
        </Box>
        <Box mb={"3em"} mt={2}>
            <FormFieldCheckbox pb={'0'} control={control} id={'contact'} text={'Add Contact Info'} placeholder={'Add existing contact details from file?'} value={'contact'} index={0} />
        </Box>
        <FormProvider {...formControl}>
            <Box as="section" w="full">
                <FileInput
                    accept="image/png, image/jpg, image/jpeg"
                    name="photos"
                    label="Photos"/>
            </Box>
        </FormProvider>
        <Button isLoading={loading} mt={16} type='submit'>Submit</Button>
    </form>
    {
        uploadImages && <Progress value={uploadPer} />
    }
    </>
  )
}
