import { Checkbox, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftAddon, Select, Switch, Textarea, Wrap } from '@chakra-ui/react';
import { Controller, Control, FieldValues, ValidationRule } from 'react-hook-form';

interface FormFieldInputValues{
    control:Control<FieldValues, object>, 
    text?: string, 
    id: string, 
    required?: string | ValidationRule<boolean>, 
    maxLength?: ValidationRule<string | number>, 
    minLength?: ValidationRule<string | number> , 
    pattern?: ValidationRule<RegExp>, type?:string,
    placeholder?: string,
    defaultValue?: any,
    pb?: string,
}

interface Addons extends FormFieldInputValues{
    children: string,
}

interface SelectProps extends FormFieldInputValues{
    children: React.ReactNode
}

interface CheckboxProps extends FormFieldInputValues{
    width?: string,
    value:string,
    index:number,
}


export function FormFieldInput({control, text, id, required, maxLength, minLength, pattern, type, placeholder=text, defaultValue, pb}:FormFieldInputValues) {
    return (
        <Controller
            control={control}
            name={id}
            defaultValue={defaultValue ?? ''}
            rules={{
                required: required, 
                maxLength: maxLength,
                minLength: minLength,
                pattern: pattern
            }}
            render={({field, fieldState: {invalid, error}}) => (
                <FormControl isInvalid={invalid} pb={pb}>
                    {text && <FormLabel htmlFor={id}>{text}</FormLabel>}
                    <Input
                        id={id}
                        placeholder={placeholder}
                        {...field}
                        type = {type ?? "text"}
                    />
                    <FormErrorMessage>
                        {error && error.message}
                    </FormErrorMessage>
                </FormControl>
            )}
        />
    )
}

export function FormFieldInputAddon({control, text, id, required, maxLength, minLength, pattern, type, placeholder=text, children, defaultValue, pb}:Addons){
    return (
        <Controller 
            control={control}
            name={id}
            defaultValue={defaultValue ?? ''}
            rules={{
                required: required,
                maxLength: maxLength,
                minLength: minLength,
                pattern: pattern
            }}
            render={({field, fieldState: {invalid, error}}) => (
                <FormControl isInvalid={invalid} pb={pb}>
                    <FormLabel htmlFor={id}>{text}</FormLabel>
                    <InputGroup>
                        <InputLeftAddon children={children} />
                        <Input
                            id={id}
                            placeholder={placeholder}
                            {...field}
                            type = {type ?? "text"}
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {error && error.message}
                    </FormErrorMessage>
                </FormControl>
            ) }
        />
    )
}


export function FormFieldSelect({control, defaultValue, children, id, text, placeholder=text, required, maxLength, minLength, pattern, pb}:SelectProps){
    return (
        <Controller 
            control={control}
            name={id}
            rules={{
                required: required,
                maxLength: maxLength,
                minLength: minLength,
                pattern: pattern
            }}
            defaultValue={defaultValue ?? ''}
            render={({field, fieldState: {invalid, error}}) => (
                <FormControl isInvalid={invalid} pb={pb}>
                    <FormLabel htmlFor={id}>{text}</FormLabel>
                    <Select
                        id={id}
                        placeholder={placeholder}
                        {...field}
                    >
                        {children}
                    </Select>
                    <FormErrorMessage>
                        {error && error.message}
                    </FormErrorMessage>
                </FormControl>
            )}
        />
    )
}


export function FormFieldTextArea({control, defaultValue, id, text, placeholder=text, required, maxLength, minLength, pattern, pb}:FormFieldInputValues){
        return (
        <Controller 
            control={control}
            name={id}
            rules={{
                required: required,
                maxLength: maxLength,
                minLength: minLength,
                pattern: pattern
            }}
            defaultValue={defaultValue ?? ''}
            render={({field, fieldState: {invalid, error}}) => (
                <FormControl isInvalid={invalid} pb={pb}>
                    <FormLabel htmlFor={id}>{text}</FormLabel>
                    <Textarea
                        id={id}
                        placeholder={placeholder}
                        {...field}
                        size="sm"
                    />
                    <FormErrorMessage>
                        {error && error.message}
                    </FormErrorMessage>
                </FormControl>
            )}
        />
    )
}


export function FormFieldCheckbox({control, defaultValue, id, placeholder, text, required, width="100%", index, value, pb}:CheckboxProps){
    return (
        <Controller 
            control={control}
            name={id}
            rules={{
                required: required
            }}
            defaultValue={defaultValue ?? ''}
            render={({field:{name, ref, onChange, onBlur}, fieldState:{invalid}})=>(
                <FormControl isInvalid={invalid} pb={pb}>
                    <FormLabel htmlFor={id}>{placeholder}</FormLabel>
                    <Checkbox defaultChecked={defaultValue} w={width} onBlur={onBlur} onChange={onChange} ref={ref} name={name+index} value={value}>{text}</Checkbox>
                </FormControl>
            )}
        />
    )
}

export function FormFieldCheckboxContainer({id, children, control}:SelectProps){
    return (
        <Controller 
            control={control}
            name={id}
            render={({fieldState: {invalid, error}}) => (
                <FormControl isInvalid={invalid} >
                    <Wrap>
                        {children}
                    </Wrap>
                    <FormErrorMessage>
                        {error && error.message}
                    </FormErrorMessage>
                </FormControl>
            )}
        />
    )
}


export function FormFieldSwitch({text, id, control}:FormFieldInputValues){
    return (
        <Controller 
            control={control}
            name={id}
            render={({field, fieldState: {invalid, error}}) => (
                <FormControl isInvalid={invalid}>
                    <FormLabel htmlFor={id}>{text}</FormLabel>
                    <Switch id={id} {...field} size="lg" colorScheme="green" />
                    <FormErrorMessage>
                        {error && error.message}
                    </FormErrorMessage>
                </FormControl>
            )}
        />
    )
}