import * as React from 'react';

interface FileIconProps {
    width?:string,
    height?:string,
    extension:string,
};

/** an icon to be used with file type
 * "common" file extensions are assigned a color
 * empty or unknown file extension is black */
function FileIcon({width,height,extension}:FileIconProps){

    const fillColor={ // mapping of extensions to preferred fill colors
        '':"#000000",
        '.XLSX':"#008000",
        '.XLS':"#008000",
        '.DOC':"#000080",
        '.DOCX':"#000080",
        '.PDF':"#800000",
    }[extension];

    return (
        <svg viewBox="0 0 150 150" width={width===undefined&&height===undefined?"150px":width} height={height} version="1.1"><g>
            <path
                style={{stroke:"none",fillRule:"nonzero",fill:fillColor,fillOpacity:1}}
                d="M 21 6 L 21 144 L 129 144 L 129 43.78125 L 128.15625 42.84375 L 92.15625 6.84375 L 91.21875 6 Z M 27 12 L 87 12 L 87 48 L 123 48 L 123 138 L 27 138 Z M 93 16.3125 L 118.6875 42 L 93 42 Z M 93 16.3125 "
            />
            <text
                style={{fontSize:"140px",lineHeight:1.25,fontFamily:"Arial",
                    fill:fillColor,fillOpacity:1,stroke:"none"}}
                x="99.871414" y="-12.453007" transform="rotate(58.81107)">
                <tspan x="99.871414" y="-12.453007"
                    style={{fontWeight:"bold",fontSize:"40px",fontFamily:"Arial",
                        textAlign:"center",textAnchor:"middle",fill:fillColor}}>
                    {extension}
                </tspan>
            </text>
        </g></svg>
    );
};


export default FileIcon;