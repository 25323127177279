import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Button, Divider, HStack, VStack, Text, Box, Input, Switch, InputGroup, InputRightElement, useToast, FormControl, FormLabel } from "@chakra-ui/react";

import moment from "moment";
import { RouteProps, useHistory } from 'react-router-dom';
import {ShareCalendarHelp, SharedCalendarHelp} from './CalendarHelp';
import { WeekTimespans, WeekSelect } from './TimeSpansSelector';
import { db } from '../../firebase';
import { faUpload, faDownload, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DbGarageData } from '../Garages/DbGarage';


const calendarsService="https://us-central1-carera-be9c6.cloudfunctions.net/calendars/garages/";


type DbTimespans=[[number,number][],[number,number][],[number,number][],[number,number][],[number,number][],[number,number][],[number,number][]];

interface CalendarSettingsProps {
    
    /** Magical way to get cgi query parameters part of the url */
    location: RouteProps["location"];
};

/** Component to set up all calendar settings */
function CalendarConfig({location}:CalendarSettingsProps){

    const [limitToHours,setLimitToHours]=useState<DbTimespans>([[],[],[],[],[],[],[]]);
    const [limitToHoursString,setLimitToHoursString]=useState('');
    const [garageCalendar,setGarageCalendar]=useState('');
    const [instantBook, setInstantBook]=useState(false);
    const toast = useToast();
    const [saveLoading, setSaveLoading] = useState(false);

    const garageId=useRef('');

    const defaultHours="[[],[[900,1200],[1300,1700]],[[900,1200],[1300,1700]],[[900,1200],[1300,1700]],[[900,1200],[1300,1700]],[[900,1200],[1300,1700]],[]]";

    /** get limitToHours as time strings */
    function getHoursAsStrings(dbTimespans:DbTimespans|null):WeekTimespans{
        var ret:WeekTimespans=[[],[],[],[],[],[],[]];
        if(dbTimespans!==null){
            ret=dbTimespans.map(d=>d.map(ts=>ts.map(t=>{
                // for each time in the data, convert to string
                const mo=moment();
                mo.hour(Math.floor(t/100));
                mo.minute(t%100);
                return mo.format('LT');
            }))) as WeekTimespans;
        }
        return ret;
    }

    /** called whenever limitToHoursString changes */
    useEffect(()=>{
        if(limitToHoursString!==''){
            setLimitToHours(JSON.parse(limitToHoursString));
        }
    },[limitToHoursString]);

    /** load the current settings from the database */
    async function loadSettings(){
        const docRef=db.collection('garages').doc(garageId.current);
        const results=await docRef.get();
        const data=results.data() as DbGarageData;
        if(data!==undefined) {
            // populate info about the limitToHours
            if(data.limitToHours===undefined){
                // if there are no limitToHours, choose default
                if(data.storeHours!==null&&data.storeHours!==undefined){
                    // if there is a storeHours, use that as a default
                    setLimitToHours(JSON.parse(data.storeHours));
                    setLimitToHoursString(data.storeHours);
                }else{
                    // if there are no storeHours either, use a default guess
                    setLimitToHours(JSON.parse(defaultHours));
                    setLimitToHoursString(defaultHours);
                }
            } else {
                // since there was a limitToHours set, obviously we want that
                setLimitToHours(JSON.parse(data.limitToHours));
                setLimitToHoursString(data.limitToHours);
            }
            // populate info about the garage's calendar url
            if(data.garageCalendar===undefined||data.garageCalendar===null){
                setGarageCalendar('');
            } else {
                setGarageCalendar(data.garageCalendar);
            }
            // populate instant book info
            setInstantBook(data.instantBook===true);
        }
    }

    /** save changed settings to the database */
    async function saveSettings(){
        setSaveLoading(true);
        const docRef=db.collection('garages').doc(garageId.current);
        try {
            await docRef.update({
                garageCalendar:garageCalendar,
                limitToHours:JSON.stringify(limitToHours), // firebase doesn't like nested arrays, so we'll encode it as string
                instantBook: instantBook,
            });
            // go back to the dashboard
            // history.push('/dashboard');
            toast({
                title: "Saved",
                description: "Your settings were saved",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            console.log(error);
            toast({
                title: "Error",
                description: "There was an error saving the settings, please try again",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        setSaveLoading(false);
    }


    /** when the control text changes, update the structure to be saved in the db */
    function onWeekChanged(changedWeekData:WeekTimespans){
        let newWeekData=changedWeekData.map(d=>d.map(ts=>ts.map(t=>{
            // for each time in the data
            let m=moment(t,'H:mm A');
            return m.hours()*100+(m.minutes()); // 24 hr clock, eg "1:15 PM" = 1315
        }))) as DbTimespans;
        setLimitToHours(newWeekData);
    }

    /** Run once upon startup */
    useEffect(()=>{
        if(location!==undefined){
            let pth=location.pathname.split('garages/');
            if(pth.length>=2){
                let gar=pth[1].split('/')[0];
                if(gar!=='add'){
                    garageId.current=gar;
                }
            }
            loadSettings();
        }
    },[]);  // eslint-disable-line react-hooks/exhaustive-deps


    function copyCalendarUrl(){
        const url = calendarsService+garageId.current;
        navigator.clipboard.writeText(url);
        toast({
            title: "Copied",
            description: "The calendar link was copied to your clipboard",
            status: "info",
            duration: 5000,
            isClosable: true,
        })
    }

    /*function pasteCalendarUrl(){
        navigator.clipboard.readText()
            .then(text => {
                setGarageCalendar(text)
            })
            .catch(err => {
                toast({
                    title: "Error",
                    description: "Could not paste text",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
                console.log('Something went wrong', err);
            });
    }*/

    return (<div>
        <VStack align="flex-start" spacing="6" mx={[4,0]}>
            <VStack align="flex-start" w="full">
                <Text as="h1" fontSize="3xl" fontWeight="bold" >Appointment Availability</Text>
                <Text fontSize="sm" as="p">What times on your calendar would you like to make available to Carera customers?</Text>
                <WeekSelect weekTimespans={getHoursAsStrings(limitToHours)} onChange={onWeekChanged} />
            </VStack>    
            <Divider/>

            <VStack align="flex-start" w="full">
                <Text as="h1" fontSize="3xl" fontWeight="bold" >Instant Book</Text>
                <HStack display="flex" alignItems="flex-start" wrap="wrap" spacing="5" justify="space-between" >
                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor="instant-book" mb="0">
                        Allow customers to make a booking without garage confirmation
                    </FormLabel>
                    <Switch size="lg" isChecked={instantBook} id="instant-book" onChange={(e) => setInstantBook(e.target.checked)} />
                </FormControl>
                </HStack>
            </VStack>   

            <VStack w="full" align="flex-start" >
                <Text as="h1" fontSize="3xl" fontWeight="bold" >Sync Calendars</Text>
            </VStack>

            <Text display="block" as="h4" fontSize="1xl" fontWeight="bold" >Import Calendar{<FontAwesomeIcon style={{marginLeft:"1rem"}} icon={faDownload}/>}</Text>         
            <Box display="block" w="100%">
                <InputGroup>
                    <Input placeholder="Paste link here" type="text" value={garageCalendar} onChange={(e)=>setGarageCalendar((e.target as HTMLInputElement).value)} />
                    {/* <InputRightElement>
                        <IconButton onClick={pasteCalendarUrl} aria-label="Copy" icon={ <FontAwesomeIcon icon={faPaste} /> } />
                    </InputRightElement> */}
                </InputGroup>
            </Box>
            <Box display={"block"} w="100%">
                <SharedCalendarHelp />
            </Box>

            <Text as="h4" fontSize="1xl" fontWeight="bold" >Export Calendar{<FontAwesomeIcon style={{marginLeft:"1rem"}} icon={faUpload}/>}</Text>
            <InputGroup>
                <Input isReadOnly value={calendarsService+garageId.current} />
                <InputRightElement>
                    <IconButton onClick={copyCalendarUrl} aria-label="Copy" icon={ <FontAwesomeIcon icon={faCopy} /> } />
                </InputRightElement>
            </InputGroup>
            <ShareCalendarHelp />

            <Button mt={4} mx={[4,0]} mb={[4,0]} colorScheme="teal" type="submit" onClick={saveSettings} isLoading={saveLoading} >Save</Button>

        </VStack>
    </div>);
}

export default CalendarConfig;
