import { Box, Flex, VStack, Button, useToast, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import image from '../../images/schedule-appointment.jpeg';

import Header from "../Navigation/HeaderLisa";
import Footer from "../Navigation/Footer";
import firebase, { auth, db, functions } from '../../firebase';
import UserSearchForm, { SearchValues } from "../Form/UserSearchForm";
import { AnimateSharedLayout, motion } from "framer-motion";
import { useSteps, Step, Steps } from "chakra-ui-steps";
import GarageSearchResults from "../Garages/GarageSearchResults";
import GarageScheduler from "../Calendar/GarageScheduler";
import DbGarage, { DbGarageImage } from "../Garages/DbGarage";
import UserSubmission, { ClaimValues } from "../Form/UserSubmission";
import { AvailableInsuranceProviders } from "../InsuranceProviders/AvailableInsuranceProviders";
import { DbWorkorderTypeData } from "../Workorders/DbWorkorderType";
import AuthContext from "../Account/AuthContext";
import uploadAttachments from "../Form/AttachmentUpload";
import Confetti from 'react-confetti'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import useViewport from "../Hooks/Viewport";
import moment from "moment";

export type SearchTermsType = {
    address:string,
    services?:string[],
    instantBook?:boolean,
}


/** Dashboard component for user controls */
function UserDashboard() {
    const [fullScreen, setFullScreen] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [searchTerms, setSearchTerms] = useState<SearchTermsType>();
    const [selectedGarage, setSelectedGarage] = useState<DbGarageImage>();
    const [selectedTime, setSelectedTime] = useState<[moment.Moment,moment.Moment]>();
    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    })
    const [hasOrders, setHasOrders] = useState(false);
    const workorderNo=useRef(createWorkorderNumber());
    const Auth = useContext(AuthContext);
    const collectionsRef=db.collection("work_orders");
    // const usersRef=db.current.collection("users");
    const toast = useToast();
    const background = useColorModeValue('white', '#4A5568');
    const variant = useBreakpointValue({ base: background, sm: "transparent" });
    const variantColor = useBreakpointValue({ base: useColorModeValue("black","white"), sm: "white" });
    const { width } = useViewport();
    const mdBreakpoint = 480;



    /** create a unique workirder number
     * this is based upon the current time in milliseconds, plus a random value
     */
    function createWorkorderNumber(){
        return ((Date.now()-1630534917869)*100)+Math.floor(Math.random()*100);
    }

    const footerVariant = {
        open: { height: "auto" },
        closed: { height: "0px", overflow: "hidden" },
    }

    type StepButtonsProps = {
        nextStep?: () => void;
        prevStep?: () => void;
        prevDisabled?: boolean;
        nextDisabled?: boolean;
        isLast?: boolean;
    };

    const StepButtons = ({
        nextStep,
        prevStep,
        prevDisabled,
        nextDisabled,
        isLast,
        }: StepButtonsProps): JSX.Element => {
        return (
            <Flex width="100%" justify="flex-end">
            <Button
                mr={4}
                variant="ghost"
                size="sm"
                onClick={prevStep}
                isDisabled={prevDisabled}
            >
                Prev
            </Button>
            <Button isDisabled={nextDisabled} size="sm" onClick={nextStep}>
                {isLast ? 'Finish' : 'Next'}
            </Button>
            </Flex>
        );
    };

    function searchSubmit(values:SearchValues) {
        const trueServices = Object.entries(values.services);
        const servicesList = trueServices.filter(([_,value]) => value).map((e) => e[0]);
        const lineOne = values.addressLineOne;
        const lineTwo = values.addressLineTwo;
        const city = values.city;
        const state = values.state;
        const zip = values.zip;
        const searchTerm:SearchTermsType = {
            address: JSON.stringify({lineOne, lineTwo, city, state, zip}),
            services: servicesList,
            instantBook: values.instantBook,
        }
        setSearchTerms(searchTerm);
    }

    /** 
     * send notification emails to the customer and to the garage
     * 
    */
    async function SendEmails(selectedGarage:DbGarage|undefined,workorderId:string) {
        try {
            if(selectedGarage!==undefined){
                // functions.useEmulator('localhost',5001); // For debugging, redirect requests to emulator
                // send email to the user
                const sendEmail = functions.httpsCallable('sendEmail');
                const token = await auth.currentUser?.getIdToken();
                sendEmail({
                    token: token,
                    name: Auth.firstName,
                    email: Auth.email,
                    template: 'userAppointment',
                    garageId: selectedGarage.id,
                    workorderId: workorderId
                });
                if(selectedGarage.notificationEmail!==undefined&&selectedGarage.notificationEmail!==''){
                    // send email to the garage so they can confirm
                    const baseUrl=window.location.origin+'/dashboard';
                    const confirmUrl=`${baseUrl}/workorders/${workorderId}/confirm`;
                    sendEmail({
                        token: token,
                        name: selectedGarage.name,
                        email: selectedGarage.notificationEmail,
                        template: 'garageAppointment',
                        confirmUrl,
                        workorderId: workorderId
                    });
                }
            }
        } catch(error) {
            console.error(error);
            console.error("unable to send email");
        }
    }

    async function workorderSubmit(values:ClaimValues) {
        setSubmitLoading(true);
        try {
            const batch = db.batch();
            const countersRef=collectionsRef.doc('counters');
            // const userRef = usersRef.doc(Auth.id);
            const docRef=collectionsRef.doc();
            const currentOrders = Auth.orders;
            const updatedOrders:string[] = currentOrders!==undefined ? [...currentOrders, docRef.id] : [docRef.id]; 
            const services = values.services;
            const servicesArray = !services ? undefined : typeof services === 'string' ? [services]:services;
            let dbFile:DbWorkorderTypeData={
                userId: Auth.id,
                workorderNo:workorderNo.current,
                firstName: values.firstName,
                lastName: values.lastName,
                email: Auth.email,
                phone: `${values.phone}`,
                description: values.description ?? "",
                hasInsurance: !!values.insuranceProvider,
                insurancePolicy: values.policyNumber ?? "",
                insuranceProvider: values.insuranceProvider === "Other" ? values.otherInsuranceProvider! : values.insuranceProvider ?? "",
                closed:false,
                selectedServiceIds:servicesArray??[],
                selectedGarageId: selectedGarage?.id, 
                selectedGarageName: selectedGarage?.name,
                createdDate: moment().unix(),
                confirmed:selectedGarage===undefined?false:selectedGarage.instantBook,
                cancelled:false
            };
            if(selectedTime!==undefined){
                dbFile.appointment=[
                    selectedTime[0].unix(),
                    selectedTime[1].unix()
                ];
            }
            batch.set(docRef, dbFile);
            batch.update(countersRef, !!values.insuranceProvider 
                ? {open_insurance: firebase.firestore.FieldValue.increment(1), total: firebase.firestore.FieldValue.increment(1)} 
                : {open_noinsurance:firebase.firestore.FieldValue.increment(1), total: firebase.firestore.FieldValue.increment(1)}
            );
            await batch.commit();
            // send notification emails
            SendEmails(selectedGarage,docRef.id);
            // upload the attachments
            const storageLocation=`/work_orders/${docRef.id}`;
            if(values.documents !== undefined && values.documents.length >0) await uploadAttachments(storageLocation, docRef.collection("attachments"), values.documents);
            toast({
                title: "Success",
                description: `Your new claim was submitted!`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            // await sendEmail({name: values.firstName, email: Auth.email, template: 'welcome'});
            nextStep();
        } catch (error) {
            console.log(error);
            toast({
                title: "Error",
                description: `There was an error submitting your application. Please try again!`,
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        // console.log(values);
        
        setSubmitLoading(false);
    }


    useEffect(() => {
        if(searchTerms !== undefined && !fullScreen){
            setFullScreen(true);
        }
    }, [searchTerms])



    useEffect(() => {

        if(selectedGarage && activeStep === 0){
            nextStep();
        }
        if(selectedTime && activeStep === 1){
            nextStep();
        }
    }, [selectedGarage, selectedTime])

    useEffect(() => {
        async function getSubmissions(){
            var querySnapshot = await collectionsRef.where("userId","==",Auth.id).get();
            if(!querySnapshot.empty){
                setHasOrders(true);
            }
        }
        getSubmissions();
    }, [])


    function StepsSection(){
        return (
            <VStack w="full" px="4" pb="4" h="full" overflowX="scroll" >
                <Steps responsive={false} state={submitLoading ? "loading": undefined} activeStep={activeStep} css={{position:"sticky", top:"0", backgroundColor:background, zIndex:2, padding:"0.5rem", paddingTop:"1rem"}}>
                    <Step label={ width < mdBreakpoint ? activeStep === 0 ? "Search" : undefined : "Search"}>
                        <GarageSearchResults searchTerms={searchTerms!} onSubmit={searchSubmit} bookGarage={(garage:DbGarageImage) => setSelectedGarage(garage)} />
                    </Step>
                    <Step label={ width < mdBreakpoint ? activeStep === 1 ? "Book" : undefined : "Book"}>
                        <GarageScheduler garage={selectedGarage!} onSubmit={setSelectedTime} prevStep={prevStep} />
                    </Step>
                    <Step label={ width < mdBreakpoint ? activeStep === 2 ? "Submit" : undefined : "Submit"}>
                        <UserSubmission garage={selectedGarage!} searchTerms={searchTerms} onSubmit={workorderSubmit} submitLoading={submitLoading} />
                    </Step>
                </Steps>
                {activeStep === 3 &&
                    <Flex w="full" h="full" justify="center" align="center" direction="column" >
                        <Confetti recycle={false} />
                        <FontAwesomeIcon icon={faCalendarCheck} color="#48BB78" size="4x" />
                        <Text mt="4" mb="2" color="gray.500" >You're booking has been submitted and you should receive an email shortly.</Text>
                        <Text color="gray.500" fontWeight="bold">Thanks for choosing Carera!</Text>
                    </Flex>
                }
                {/* <StepButtons
                    {...{ nextStep, prevStep }}
                    prevDisabled={activeStep === 0}
                /> */}
            </VStack>
        )
    }



    return (
        <AvailableInsuranceProviders>
        <Flex direction="column" justify="space-between" w="100vw" h="100vh" backgroundImage={ `linear-gradient(rgba(0,0,0,0.95), rgba(0,0,0,0.8), rgba(0,0,0,0.4)), url(${image})` } backgroundRepeat="no-repeat" backgroundSize="cover" minH="600px" >
            <Box>
                <Header bgColor={variant} logoFillColor={variantColor} hasOrder={hasOrders} />
                <Flex justify="center">
                    <AnimateSharedLayout>
                        
                        <motion.div layout initial={{opacity: 0}} animate={{opacity:1}} layoutId="expanded-card">
                            {fullScreen
                            ? <Box maxW="container.xl" borderRadius={["none","xl"]} w={["100vw","90vw"]} h={["calc(100vh - 2rem - 70px)","calc(95vh - 2rem - 70px)"]} maxH="2000px" minH="500px"  bgColor={background} > <StepsSection /> </Box>
                            : <Box borderRadius={["0","xl"]} bgColor={ background } w={["full", "md"]} boxShadow={["none","dark-lg"]} mb={[0,6]} minH="500px" >
                                <motion.div initial={{opacity:0}} animate={{opacity:1, transition:{delay:0.3}}} exit={{opacity:0}} >
                                    <UserSearchForm onSubmit={searchSubmit} />
                                </motion.div>
                            </Box>
                            }
                        </motion.div>
                        

                    </AnimateSharedLayout>
                    {/* </Box> */}
                </Flex>
            </Box>
            <motion.div animate={ fullScreen ? "closed" : "open" } variants={footerVariant} ><Footer/></motion.div>
                        
        </Flex>
        </AvailableInsuranceProviders>

        );

}


export default UserDashboard;