import { NavLink } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Popup from 'reactjs-popup';

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

  body {
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif;
    color: #252B42;
  }

  .sidebarLink.selected{
    background-color: #E2E8F0;
    color: #556d6e;
  }

  /* @media only screen and (max-width: 480px) {
    #root{
      height: 100vh;
      overflow: auto;
    }
  } */
  
`;


export const InputText = styled.input<{missingRequired?:boolean}>`
  border: 0px none;
  border-bottom: 2px solid #CCCCCC;
  background-color: ${props=>props.missingRequired===true?"pink":"transparent"};
  width: 100%;
  font-size: 12px;
  color: #BBBBBB;
  font-family: Montserrat, sans-serif;
  letter-spacing: 1px;

  &:focus {
    color: #252B42;
    outline: none;
    border-bottom-color: #14A3CE;
  }

`;

export const InputTextLabel = styled.label`
  font-size: 100%;
  margin-right: 10px;
`;

export const InputTextarea = styled.textarea`
  border: 1px solid #444;
  width: 100%;
  padding: 1em;
  margin: 0.5em 0 2em;
  border-radius: 10px;
  font-size: 12pt;
  min-width: 300px;
  min-height: 200px;
`;

export const InputTextareaLabel = styled.label`
  font-size: 150%;
  margin-right: 10px;
  display: block;
`;

export const Button = styled.button<{readOnly?:boolean}>`
  border: none;
  color: white;
  background-color:cornflowerblue;
  width: 100%;
  min-width: 150px;
  // font-size: 100%;
  font-size: 12px;
  border-radius: 50px;
  cursor: ${props=>props.readOnly===false?"not-allowed":"pointer"};
  padding: 15px;
  background: ${props=>props.readOnly===false?"#aaa":"#14A3CE"};
  letter-spacing: 1px;
  box-shadow: 0px 10px 27px 0px #0000001A;
  transition: filter 0.3s;

  :hover {
    filter: ${props=>props.readOnly===false?"":"brightness(90%)"};
  }
`;

export const HeightSpacer = styled.div<{height?: string}>`
  height: ${props => props.height ? props.height + "px" : "1em"};
`;

export const LoginTitle = styled.h1`
  font-weight: 500;
`;

export const InfoText = styled.span`
  font-size: 12px;
  letter-spacing: 1px;
  opacity: 0.5;
`;

export const InfoNav = styled(NavLink)`
  font-size:12px;
  letter-spacing: 1px;
  color: #252B42;
  border-bottom: 2px solid rgba(37, 43, 66, 0.5);
  padding-bottom: 3px;
  text-decoration: none;
`;

export const IconButton = styled.div`
  border: none;
  color: white;
  font-size: 150%;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: inline-block;
  border-radius: 20px;
  font-size: 15px;
  margin-left:3px;
  margin-right:3px;
  padding: 0;
  text-decoration: none;
  cursor: pointer;

  :hover {
    filter: brightness(90%);
  }
`;

export const CloseButton = styled.a`
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background-color: #FAFAFA;
  box-shadow: 0px 20px 27px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:before, &:after {
    content: '';
    width: 15px;
    height: 2px;
    background-color: rgba(34, 34, 34, 0.5);
    position: absolute;
    transition: transform 0.3s;
  }

  &:hover:before, &:hover:after {
    background-color: rgba(34, 34, 34, 1);
    transform: rotate(0deg);
    transition: transform 0.3s;
  }
  
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

export const AddButton = styled(IconButton)`
    background-color: #008000;
    font-weight:900;
    font-size:18pt;
    text-align:center;
    line-height:20px;
    padding-bottom:5px;

    &:after {
        content: "+";
    }
`;

export const DeleteButton = styled(IconButton)`
    background-color: #800000;
    font-size:10pt;
    text-align:center;
    line-height:20px;

    &:after {
        content: "❌";
    }
`;

export const EditButton = styled(IconButton)`
  background-color: #800000;
  &:after {
      content: "✏️";
  }
`;

export const SearchButton = styled(IconButton)`
  background-color: #808080;

  &:after {
      content: "🔍";
  }
`;

export const ErrorText = styled.div`
    font-weight: 700;
    color: red;
`;

export const YesnoPopup = styled(Popup)`
    // for ".popup-overlay"
    &-overlay { 
        background-color:rgba(106,102,125,0.66);
        backdrop-filter:blur(30px);
        box-shadow: inset 0 0 40px rgb(72,69,90);
    }

    // for .popup-content"
    &-content {
        background-color:white;
        border-radius:30px;
        box-shadow: 0 0 40px rgb(72,69,90);
        padding:20px 40px;
        font-size:120%;
        text-align:center;
    }
`;

export const YesnoButton=styled(Button)`
    display: inline-block;
    width: 33%;
    margin: 2px;
`;

export const StyledCheckbox=styled.div<{checked:boolean}>`
    margin: 2px;
    border: 1px solid black;
    border-radius: 3px;
    min-width: 15px;
    min-height:15px;
    max-width: 15px;
    max-height:15px;
    display: inline-block;
    position: relative;

    &:after {
        position:absolute;
        bottom: -7px;
        left: -2px;
        content: '${props=>props.checked?"✓":""}';
        font-size:27px;
        color: ${props => props.checked ? "#11a711": "#f33131" };
        z-index: 1;
        text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
        font-weight:800;
    }
`;

export const PopupModal = styled(Popup)`
    // for ".popup-overlay"
    &-overlay { 
        background-color: rgba(255,255,255,0.8);
        
    }

    // for .popup-content"
    &-content {
        background-color:white;
        border-radius:15px;
        box-shadow: 0px 13px 33px rgba(0, 0, 0, 0.05);
        padding:15px;
        font-size:120%;
    }
`;