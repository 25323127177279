import { VStack, Text, Center, Box } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import noresults from '../../noresults.svg';
import logout from '../../logout.svg';

export function NoResults({height = "calc(100vh - 150px"}:{height?:string}){
    return (
        <CreateGraphic image={noresults} text="No results found..." height={height} />
    )
}

export function LogoutGraphic({height = "calc(100vh - 86px"}:{height?:string}){
    return (
        <CreateGraphic image={logout} text="Logging out..." height={height} />
    )
}


    function CreateGraphic({image, text, height}:{image:string, text: string, height: string}){

        const variants = {
            hidden: {
                opacity: 0,
            },
            visible: {
                opacity: 1,
                transition: {
                    duration: 0.1,
                    delay: 0.1
                }
            }
        }

        return (
            <motion.div style={{width: "100%", height:`${height}`}}
                variants={variants}
                initial="hidden"
                animate="visible"
            >
                <Center h="full">
                    <VStack alignItems="center" justify="center">
                        <Image h={["192px", "260px", "320px"]} src={image} />
                        <Text as="h2" fontWeight="black" textTransform="uppercase">{text}</Text>
                    </VStack>
                </Center>
            </motion.div>
        )
    }