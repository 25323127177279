import { Checkbox, FormControl, Spacer, Text, Select } from "@chakra-ui/react";
import { Control, Controller, FieldValues } from "react-hook-form";
import moment from "moment";

type TimeFieldTypes = {
    control: Control<FieldValues, object>,
    day: string,
    watch: boolean,
    defaultEnabled?: boolean,
    defaultFrom?: string,
    defaultTo?: string,
    width?: Array<string>,
}

function TimeField({day, watch, control, defaultEnabled, defaultFrom, defaultTo, width=["48%"]}:TimeFieldTypes) {

    const toggle:string = `hours${day}Toggle`;
    const from:string = `hours${day}From`;
    const to:string = `hours${day}To`;

    const startTime=700;
    const endTime=1900;
    const timeInterval=15;

    /**
     * Turn a range of times into an array of times
     * 
     * @param start time to start with (24hr clock)
     * @param step how much to increment in minutes
     * @param end number to stop (24hr clock)
     * @returns array of times
     * 
     * Example:
     *  range(1200,15,1300) == [1200,1215,1230,1245]
     */
    function timeRange(start:number,step:number,end:number){
        let ret=[];
        let t=start;
        while(t<end){
            ret.push(t);
            let min=t%100+step;
            let hr=(Math.floor(t/100)+Math.floor(min/60))*100;
            t=hr+(min%60);
        }
        return ret;
    }

    function SelectTimesTo() {
        return (
            <Controller 
                control={control}
                name={to}
                defaultValue={defaultTo}
                render={({field}) => (
                    <Select {...field} id={to} placeholder="-" isDisabled={!watch} w="120px">
                        {timeRange(startTime+timeInterval,timeInterval,endTime).map(t=>{
                            let formatted=moment(`${t}`, "Hmm").format("LT");
                            return (<option key={t} value={t}>{formatted}</option>);
                            })
                        }
                    </Select>
                )}
            />
        )
    }

    function SelectTimesFrom() {
        return (
            <Controller 
                control={control}
                name={from}
                defaultValue={defaultFrom}
                render={({field}) => (
                    <Select {...field} id={from} placeholder="-" isDisabled={!watch} w="120px">
                        {timeRange(startTime,timeInterval,endTime-timeInterval).map(t=>{
                            let formatted=moment(`${t}`, "Hmm").format("LT");
                            return (<option key={t} value={t}>{formatted}</option>);
                            })
                        }
                    </Select>
                )}
            />
        )
    }

    return (
        <Controller 
            control={control}
            name={toggle}
            defaultValue={defaultEnabled}
            render={({field: {onBlur, onChange, value, ref}}) => (
                <FormControl display="flex" alignItems="center" w={width} pb="4" >
                    <Checkbox id={toggle} onBlur={onBlur} onChange={onChange} defaultChecked={value} value={value} ref={ref} >
                        {day}
                    </Checkbox>
                    <Spacer />
                    <SelectTimesFrom />
                    <Text mx="2">to</Text>
                    <SelectTimesTo />
                </FormControl>
            ) }

        />
    )
}

export default TimeField