import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { IconButton } from "../Common/CommonStyledControls";
import Popup from "reactjs-popup";
import moment, { weekdays } from "moment";
import getAvailableAppointments, {MonthlyAppointmentsType, AppointmentsType, AppointmentType} from "../../availableAppointments";


const StyledCalendarDay=styled.div<{isDisabled:boolean,isToday:boolean}>`
    box-sizing: border-box;
    display: inline-block;
    border: 1px solid black;
    background-color: #fff;
    width: 100%;
    min-height: 150px;
    ${props=>props.isToday?"box-shadow: inset 0 0 10px #f00;":""}
    ${props=>!props.isDisabled?"cursor: pointer;":""}
    padding:5px;
    position:relative;

    :hover {
        ${props=>!props.isDisabled?"filter: brightness(90%);":""}
    }

`;

const DayBox=styled.div`
    border: 1px solid black;
    border-top: 0;
    border-left:0;
    background-color:white;
    position:absolute;
    top:0;
    left:0;
    width: 1.5em;
    height: 1.5em;
    text-align:center;
    vertical-align:middle;
    z-index:1;
`;

const WeekdayHeading=styled.div`
    display:inline-block;
    font-size:130%;
    width:100%;
    text-align:center;
    background-color:#444;
    color:#fff;
    text-decoration:underline;
    padding-bottom: 5px;
`;

const CalendarDayAvailability=styled.div<{avail:boolean}>`
      background-color:${props => props.avail ? "#6a6" : "#ddd"};
      border-radius:8px;
      width: 99%;
      height:50%;
      text-align:center;
      vertical-align:middle;
`;

const BlankCalendarDay=styled.div`
`;

const StyledCalendarScheduler=styled.div`
`;

const StyledPopup = styled(Popup)`
    // for ".popup-overlay"
    &-overlay { 
        background-color:rgba(106,102,125,0.66);
        backdrop-filter:blur(30px);
        box-shadow: inset 0 0 40px rgb(72,69,90);
    }

    // for .popup-content"
    &-content {
        min-width:50%;
        min-height:50%;
        background-color:white;
        border-radius:30px;
        box-shadow: 0 0 40px rgb(72,69,90);
        padding:0 30px 30px 30px;
        font-size:120%;
    }
`;

const CalendarHeading=styled.div`
    font-size:175%;
    font-weight:600;
    width:100%;
    text-align:center;
    margin-bottom:3px;
    vertical-align:middle;
    position: relative;
`;

const Calendar=styled.div`
    border: 1px solid black;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    background-color: #999;
`;

const Appointment=styled.div`
    background-color: #6a6;
    border-radius:7px;
    color:white;
    height: 20px;
    padding: 0 1em 3px 1em;
    margin:1px;
    cursor:pointer;

    :hover {
        filter: brightness(90%);
      }
`;

export const ForwardButton = styled(IconButton)`
    color: #00a000;
    font-size:16pt;

    &:after {
        content: "▶";
    }
`;

export const BackButton = styled(IconButton)`        
    color: #00a000;
    font-size:16pt;

    &:after {
        content: "◀";
    }
`;


/** parent function used when a day is selected */
type OnClick = (event:any) => void;

/** parent function used when an appointment sime is selected */
export type OnSelect = (appointment:AppointmentType) => void;

/** A single day square on the calendar */
function CalendarDay({day,onClick,isOld,isToday,appointments}:{day:number,onClick:OnClick,isOld:boolean,isToday:boolean,appointments:AppointmentsType}){

    const [amAvail,setAmAvail]=useState(false);
    const [pmAvail,setPmAvail]=useState(false);
    const [anyAvail,setAnyAvail]=useState(false);
    
    /** called once upon startup and whenever the appointments list changes */
    useEffect(()=>{
        if(appointments!==undefined){
            for(let i=0;i<appointments.length;i++){
                if(appointments[i][0].hour()<=12){
                    setAmAvail(true);
                    setAnyAvail(true);
                } else {
                    setPmAvail(true);
                    setAnyAvail(true);
                    break; // since they are in order, no need to proceed
                }
            }
    }
    },[appointments]);

    return (
        <StyledCalendarDay onClick={anyAvail&&!isOld?onClick:()=>{}} isDisabled={isOld||!anyAvail} isToday={isToday}>
            <CalendarDayAvailability avail={amAvail&&!isOld}>AM:<br/>{amAvail?"has openings":"booked"}</CalendarDayAvailability>
            <CalendarDayAvailability avail={pmAvail&&!isOld}>PM:<br/>{pmAvail?"has openings":"booked"}</CalendarDayAvailability>
            <DayBox>{day}</DayBox>
        </StyledCalendarDay>
    );
}

/** A page to schedule appointments */
function CalendarScheduler({onSelect,garageCalendar,limitToHours}:{onSelect:OnSelect,garageCalendar?:string,limitToHours?:string}){

    const today=moment();

    const [selectedDay,setSelectedDay]=useState(0);
    const [startDow,setStartDow]=useState(0);
    const [numDays,setNumDays]=useState(0);
    const [availableAppointments,setAvailableAppointments]=useState<MonthlyAppointmentsType>([]); // a separate list of [startMoment, endMoment] for every day of the month

    const viewMonth=useRef(moment(today).date(1)); // view always points to the first day of the month!

    /** Daily schedule (inside the popup) */
    function ScheduleDay({day}:{day:number}){
        return (<div><h2 style={{textAlign:'center'}}>{moment(viewMonth.current).date(day).format('dddd, MMMM D YYYY')}</h2>
            {
            availableAppointments[day-1].map((appointment,idx)=>
                (<Appointment key={'appointment2'+idx} onClick={(e)=>onSelect(appointment)}>
                    {`${appointment[0].format('hh:mm A')} - ${appointment[1].format('hh:mm A')}`}
                </Appointment>)
            )}
        </div>)
    }

    /** called once upon startup */
    useEffect(()=>{
        updateCalendarControls();
    },[viewMonth,garageCalendar,limitToHours]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Called when we have changed the viewMonth and therefore need to update the calendar */
    async function updateCalendarControls(){
        setAvailableAppointments([]);
        setStartDow(viewMonth.current.day());
        setNumDays(viewMonth.current.daysInMonth());
        let limitToHoursArray=undefined;
        if(limitToHours!==undefined){
            limitToHoursArray=JSON.parse(limitToHours);
        }
        let appointments=await getAvailableAppointments(viewMonth.current,garageCalendar,limitToHoursArray);
        setAvailableAppointments(appointments);
    }

    /** go forward one month */
    function forward(){
        viewMonth.current=viewMonth.current.add(1,'months');
        updateCalendarControls();
    }

    /** go back one month */
    function back(){
        viewMonth.current=viewMonth.current.add(-1,'months');
        updateCalendarControls();
    }

    return (<StyledCalendarScheduler>
        <CalendarHeading>
            <BackButton onClick={back}/>
            {viewMonth.current.format('MMMM YYYY')}
            <ForwardButton onClick={forward}/>
        </CalendarHeading>
        <Calendar>
            { weekdays().map((day:string,idx:number)=>{
                return (<WeekdayHeading key={'weekday'+idx}>{day}</WeekdayHeading>);
            })}
            { Array.from({length: startDow},(x,day:number) => 
                (<BlankCalendarDay key={'pre'+day}/>)
            )}
            { Array.from({length: numDays},(x,day:number)=>{
                let isOld=viewMonth.current.month()<today.month()||(viewMonth.current.month()===today.month()&&(day+1)<today.date());
                return (
                    <CalendarDay isOld={isOld} isToday={viewMonth.current.month()===today.month()&&today.date()===(day+1)} key={'day'+viewMonth.current.month()+'/'+day} day={day+1} 
                    onClick={(e)=>setSelectedDay(day+1)} appointments={availableAppointments[day]} />
                    )}
            )}
            <StyledPopup open={selectedDay>0} onClose={()=>setSelectedDay(0)} modal closeOnDocumentClick position="center center">
                <ScheduleDay day={selectedDay} />
            </StyledPopup>
        </Calendar>
    </StyledCalendarScheduler>)
}

export default CalendarScheduler;