import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader"

const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

function Loader(){

    return (
        <BounceLoader color='#14a3ce' css={override} ></BounceLoader>    
    )
}

export default Loader;