import { Text, Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Divider, FormControl, Input, InputGroup, InputLeftElement, InputRightElement, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useToast, Flex, useColorModeValue, CloseButton, Link } from "@chakra-ui/react";
import { faEnvelope, faInfo, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, RouteProps, useHistory } from "react-router-dom";
import CareraLogo from "../../carera-logo";
import firebase, { auth } from "../../firebase";
import Header from "../Navigation/HeaderLisa";
import AuthContext, { clearAuthContext, updateAuthContext } from "./AuthContext";
import stepThreeImage from "../../images/step-three.jpg"
import { WarningIcon } from "@chakra-ui/icons";
import PasswordStrengthBar from 'react-password-strength-bar';

interface LoginProps {
    /** Magical way to get cgi query parameters part of the url */
    location: RouteProps["location"];
    tabPosition: number;
};

export default function LoginRegister({location}:LoginProps){
    const [isCompletingInvite, setIsCompletingInvite]=useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [registerFirstName, setRegisterFirstName] = useState("");
    const [registerLastName, setRegisterLastName] = useState("");
    const [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");
    const [show, setShow] = useState(false);
    const [registerShow, setRegisterShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailCheck, setEmailCheck] = useState(true);
    const [passwordLogin, setPasswordLogin] = useState(false);
    const [emailLinkLogin, setEmailLinkLogin] = useState(false);
    const [linkExpired, setLinkExpired] = useState(false);
    const [emailSentMessage, setEmailSentMessage] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(false);
    const [awaitingConfirmation, setAwaitingConfirmation] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [tabIndex, setTabIndex] = useState(1)
    const handleClick = () => setShow(!show);
    const handleRegisterClick = () => setRegisterShow(!registerShow);
    const toast = useToast();

    const Auth = useContext(AuthContext);
    const history = useHistory();

      // unpack cgi query parameters part of the url
    const query = new URLSearchParams(location===undefined?'':location.search);
    const redirect=query.get("redirect");
    const gId=query.get("gId");
    const signup = query.get("signup");
    const background = useColorModeValue('white', 'gray.400');
    const formText = useColorModeValue('gray.800', 'gray.200');


    const Blob = () => {
        return (
        <svg height="260" width="350">
        <image xlinkHref={stepThreeImage} clipPath="url(#theSVGPath)" x="0" y="0" width="350" height="260"/>
        <defs>
            <clipPath id="theSVGPath">
                <rect x="0" y="0" stroke="#000000" strokeMiterlimit="10" width="60" height="220"/>
                <rect x="65.5" y="29.5" stroke="#000000" strokeMiterlimit="10" width="35" height="225"/>
                <rect x="108.5" y="39.5" stroke="#000000" strokeMiterlimit="10" width="45" height="204"/>
                <rect x="160.5" y="4.5" stroke="#000000" strokeMiterlimit="10" width="39" height="205"/>
                <rect x="202.5" y="25.5" stroke="#000000" strokeMiterlimit="10" width="20" height="447"/>
                <rect x="225.5" y="44.5" stroke="#000000" strokeMiterlimit="10" width="45" height="190"/>
                <rect x="274.5" y="29.5" stroke="#000000" strokeMiterlimit="10" width="28" height="471"/>
                <rect x="305.5" y="18.5" stroke="#000000" strokeMiterlimit="10" width="19" height="230"/>
            </clipPath>
        </defs>
    </svg>
        )
    }

    // called once upon construction
    useEffect(()=>{
        if(auth.isSignInWithEmailLink(window.location.href)) {
            setIsCompletingInvite(true);
            var emailAdd = window.localStorage.getItem('emailForSignIn');
            if (emailAdd) {
                setEmail(emailAdd);
            }
        }
        if(signup !== null){
            setTabIndex(0);
        }
    },[]);

    /** Called when the login button is clicked */
    async function onLogin(e:React.FormEvent<HTMLFormElement>) {
        // console.log('attempting to login');
        e.preventDefault();
        setLoading(true);
        try {
            var res:firebase.auth.UserCredential;
            if(isCompletingInvite){
                try {
                    res=await auth.signInWithEmailLink(email,window.location.href);
                    if(!res.user) {
                        // throw Error("No user");
                        setLoading(false);
                        toast({
                            title: "Error",
                            description: "Sorry, there was an error, please enter your email address and try again",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                        return;
                    }
                    console.log(res);
                    // get the user info from the firestore "users" list
                    await updateAuthContext(Auth,res.user.displayName,res.user.email,res.user.uid,gId);
                    window.localStorage.removeItem('emailForSignIn');
                } catch (error:any) {
                    setLoading(false);
                    console.log(error);
                    if(error.code === "auth/invalid-action-code"){
                        setLinkExpired(true);
                    } else {
                        toast({
                            title: "Error",
                            description: "Sorry, there was an error creating your account, please try again",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                    }
                    return;
                }
            } else {
                res=await auth.signInWithEmailAndPassword(email,password);
                if(!res.user) {
                    // throw Error("No user");
                    setLoading(false);
                    toast({
                        title: "Error",
                        description: "Sorry, no user with this email exists, please register your account in the 'Sign Up' tab first",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    })
                    return;
                }
                if(!res.user.emailVerified){
                    // if the email has not been verified, flag an error and clear everything
                    // setShowConfirmButton(true);
                    setLoading(false);
                    toast({
                        title: "Error",
                        duration: null,
                        isClosable: true,
                        render: ({onClose}) => (
                            <Box color="white" py={3} pl={4} pr={8} bg={"red.500"} boxShadow="lg" borderRadius="md" pos="relative" display="flex">
                                <CloseButton onClick={onClose} pos="absolute" right="1" top="1" outline="transparent" fontSize="10px"/>
                                <WarningIcon mt={1} mr={3}/>
                                <Text>
                                    Sorry, your email address has not been verified, please check for a verification email and follow the link to finish signing up, or click <Link color="blue.200" fontWeight="bold" onClick={() => {onClose(); resentEmailVerifcation(res)} }>here</Link> to resend verification email
                                </Text>
                            </Box>
                        )
                    })
                    return;
                    // throw Error("Please refer to the confirmation email for instructions.");
                }
                // get the user info from the firestore "users" list
                await updateAuthContext(Auth,res.user.displayName,res.user.email,res.user.uid);
            }
            // We finally feel good about declaring them "logged in"
            Auth.isLoggedIn=true;
            // if there is a redirect specified, use it
            let url=redirect;
            if(url===null||url===undefined||url===''){
                // when in doubt, redirect to dashboard
                url='/dashboard';
            }
            // redirect to another page
            history.push(url);
        } catch(err:any) {
            if(err.code === "auth/wrong-password"){
                toast({
                    title: "Error",
                    description: "Sorry, the password you entered was incorrect, please try again",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: "Error",
                    description: "Sorry, there was an error logging in to your account, please try again",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
            setLoading(false);
            console.error(err);
            clearAuthContext(Auth);
        }
    };

    function resentEmailVerifcation(res: firebase.auth.UserCredential){
        setLoading(true);
        const url = window.location.origin+'/dashboard';
        try {
            res.user?.sendEmailVerification({url});
            toast({
                title: "Email Verification Sent",
                description: "Please check your email account, a verification email has been sent to you",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: "Error",
                description: "Sorry, there was an error, please try again",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        }
        setLoading(false);
    }

    async function submitSignup(e:React.FormEvent<HTMLFormElement>){
        e.preventDefault();
        if(registerPassword !== registerConfirmPassword){
            setPasswordInvalid(true);
            return;
        }
        if(!validatePassword()){
            setPasswordStrength(true);
            return;
        }
        setLoading(true);
        try {
            const Auth=await auth.createUserWithEmailAndPassword(registerEmail,registerPassword);
            if (Auth.user) {
                // Send the verification email
                // const url=window.location+'confirm?id='+Auth.user.uid;
                const url = window.location.origin+'/dashboard';
                await Auth.user.sendEmailVerification({url});
                setAwaitingConfirmation(true);
                // Meanwhile, let's set the user data
                await Auth.user.updateProfile({
                    displayName:`${registerFirstName} ${registerLastName}`
                });
                auth.signOut();
            }
            setLoading(false);
        } catch(error) {
            console.log(error);
            toast({
                title: "Error",
                description: "Sorry, there was an error, please try again",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            setLoading(false);
        }
    }

    function validateEmail() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validatePassword(){
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,25}$/;
        return re.test(String(registerPassword));
    }


    function checkEmail(){
        if(!validateEmail()){
            toast({
                title: "Error",
                description: "Please enter a valid email",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
            return;
        }
        setLoading(true);
        auth.fetchSignInMethodsForEmail(email).then((signInMethods) => {
            setLoading(false);
            if(signInMethods.length){
                if(signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1){
                    setPasswordLogin(true);
                    setEmailCheck(false);
                    return;
                }
                if(signInMethods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !== -1){
                    setEmailLinkLogin(true);
                }
                setEmailCheck(false);
            } else {
                setEmail("");
                toast({
                    title: "Error",
                    description: "Sorry, no user with this email exists, please register your account in the 'Sign Up' tab first",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
                return;
            }
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Sorry, there was an error, please try again",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    }

    async function sendEmailLink(){
        setLoading(true);
        let redirectBackToUrl=window.location.origin+'/login';
        if(gId !== null){
            redirectBackToUrl = window.location.origin+'/login?gId=' + gId;
        }
        try {
            await auth.sendSignInLinkToEmail(email,{url:redirectBackToUrl,handleCodeInApp:true});
            window.localStorage.setItem('emailForSignIn', email);
            setLoading(false);
            setEmailLinkLogin(false);
            setEmailSentMessage(true);
            toast({
                title: "Email sent",
                description: `Pleae check your email for the sign in link`,
                status: "success",
                duration:5000,
                isClosable: true,
            })
        } catch(error:any) {
            setLoading(false);
            console.log(error);
            toast({
                title: "Error",
                description: "Sorry, there was an error, please try again",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }   
    }

    async function sendResetEmail(event:React.MouseEvent<HTMLElement>) {
        setLoading(true);
        event.preventDefault();
        try {
            await auth.sendPasswordResetEmail(email);
            toast({
                title: "Email sent",
                description: `Pleae check your email for instructions to reset your password`,
                status: "success",
                duration:5000,
                isClosable: true,
            })
        } catch(err:any) {
            toast({
                title: "Error",
                description: "Sorry, there was an error, please try again",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
        setEmail('');
        setPassword('');
        setLoading(false);
    };

    const searchVariant = {
        start: { opacity: 1, width: "350px", height: "100%" },
        finish: { opacity: 0, width: "0px", height: "0px" },
    }

    return (
    <AnimatePresence>
        <Box w="100vw" h="100vh">
            <Header/>
            <Flex justify="center" mt="14">
                <Box display={["none", "none", "block"]} >
                <motion.div transition={{ type: "spring", stiffness: 100 }} style={{overflow: "hidden", display:"flex", flexDirection:"column", justifyContent:"center"}} variants={searchVariant} animate={ tabIndex === 0 ? "start" : "finish" }>
                    <Box w={"350px"} pr="4" >
                        {/* <Text mb="3" fontWeight="bold" fontSize="xl">Benefits</Text> */}
                        <Text color={useColorModeValue('gray.800', 'gray.100')} mb="4">Having an account with CARERA allows you to search our approved service providers based on what you need before you make the big decision of which shop cares for your vehicle.</Text>
                        {/* <Text>If you'd like us to handle your claim, simply add your insurance details. CARERA will cover your deductible!</Text> */}
                        <Blob/>
                    </Box>
                </motion.div>
                </Box>
                {/* <AnimateSharedLayout> */}
                {/* <motion.div animate={ tabIndex === 0 ? {opacity:[0,1]} : {opacity:[0,1]} } style={{width: "350px", backgroundColor: "#E2E8F0", padding: "0.75rem", marginTop: "3.5rem", boxShadow: "0 1px 2px 0 rgba(0,0,0,0.05)", borderRadius: "0.5rem" }}> */}
                <Box w="350px" bgColor="gray.200" p="3" boxShadow="sm" rounded="lg" >
                    <Box my="6">
                        <CareraLogo height="50" />
                    </Box>
                    {isCompletingInvite?(
                        <form action="submit" onSubmit={(e) => onLogin(e)}>
                        <Stack spacing={3}>
                            <FormControl isRequired>
                                <InputGroup bgColor={background}>
                                    <InputLeftElement children={<FontAwesomeIcon icon={faEnvelope} />} />
                                    <Input type="email" placeholder="Email" aria-label="Email" value={email} onChange={event => setEmail(event.currentTarget.value)} />
                                </InputGroup>
                            </FormControl>
                            {linkExpired
                            ? <Box w="full" textAlign="center">
                                <Text px="4" pb="3" fontSize="sm" fontWeight="bold" >Unfortunately your email link has expired, tap below to send another</Text>
                                <Button isLoading={loading} w="full" onClick={() => sendEmailLink()} >Send Email Link</Button>
                            </Box>
                            : <Button isLoading={loading} boxShadow="sm" _hover={{ boxShadow: "md" }} type="submit">Click to Login</Button>
                            }
                        </Stack>
                        </form>
                    ):(
                    <Tabs index={tabIndex} defaultIndex={tabIndex} variant="enclosed-colored" isFitted m="4" onChange={(index) => setTabIndex(index)}>
                        <TabList>
                            <Tab>Sign Up</Tab>
                            <Tab>Login</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel px="0">
                                {
                                !awaitingConfirmation 
                                ? <Box>
                                    <form action="submit" onSubmit={(e) => submitSignup(e)}>
                                        <Stack spacing={3}>
                                            <Flex w="full">
                                                <FormControl isRequired mr="1">
                                                    <InputGroup bgColor={background} color={formText}>
                                                        <InputLeftElement children={<FontAwesomeIcon icon={faInfo} />} />
                                                        <Input type="name" placeholder="First Name" aria-label="First Name" value={registerFirstName} onChange={event => setRegisterFirstName(event.currentTarget.value)} />
                                                    </InputGroup>
                                                </FormControl>
                                                <FormControl isRequired ml="1">
                                                    <InputGroup bgColor={background} color={formText}>
                                                        <InputLeftElement children={<FontAwesomeIcon icon={faInfo} />} />
                                                        <Input type="name" placeholder="Last Name" aria-label="Last Name" value={registerLastName} onChange={event => setRegisterLastName(event.currentTarget.value)}  />
                                                    </InputGroup>
                                                </FormControl>
                                            </Flex>
                                            <Divider />
                                            <FormControl isRequired>
                                                <InputGroup bgColor={background} color={formText}>
                                                    <InputLeftElement children={<FontAwesomeIcon icon={faEnvelope} />} />
                                                    <Input type="email" autoComplete="off" placeholder="Email" aria-label="Email" value={registerEmail} onChange={event => setRegisterEmail(event.currentTarget.value)} />
                                                </InputGroup>
                                            </FormControl>
                                            <FormControl isRequired isInvalid={passwordInvalid}>
                                                <InputGroup bgColor={background} color={formText}>
                                                    <InputLeftElement children={<FontAwesomeIcon icon={faLock} />} />
                                                    <Input type={registerShow ? "text" : "password"} placeholder="Password" autoComplete="new-password" aria-label="Password" value={registerPassword} onChange={(event) => {setRegisterPassword(event.currentTarget.value);setPasswordInvalid(false);}} />
                                                    <InputRightElement width="4.5rem">
                                                        <Button h="1.75rem" size="sm" onClick={handleRegisterClick}>
                                                        {registerShow ? "Hide" : "Show"}
                                                        </Button>
                                                    </InputRightElement>
                                                </InputGroup>
                                            </FormControl>
                                            {registerPassword.length > 0 && <PasswordStrengthBar minLength={8} password={registerPassword} />}
                                            <FormControl isRequired isInvalid={passwordInvalid}>
                                                <InputGroup bgColor={background} color={formText}>
                                                    <InputLeftElement children={<FontAwesomeIcon icon={faLock} />} />
                                                    <Input type={registerShow ? "text" : "password"} placeholder="Confirm Password" autoComplete="new-password" aria-label="Password" value={registerConfirmPassword} onChange={(event) => {setRegisterConfirmPassword(event.currentTarget.value);setPasswordInvalid(false)}} />
                                                    <InputRightElement width="4.5rem">
                                                    </InputRightElement>
                                                </InputGroup>
                                            </FormControl>
                                            {registerPassword.length > 0 && !loading && !passwordStrength && <Text fontSize="xs" textAlign="center" >Please make sure your password has a minimum of 8 letters, 1 uppercase letter, 1 lowercase letter and 1 number</Text>}
                                            {passwordInvalid && <Text px="6" textAlign="center" fontSize="sm" fontWeight="bold" >These passwords do not match, please try again</Text>}
                                            {passwordStrength && <Text textAlign="center" fontSize="sm" color="red.500">Please make sure your password has a minimum of 8 letters, 1 uppercase letter, 1 lowercase letter and 1 number</Text> }
                                            <Button isLoading={loading} boxShadow="sm" _hover={{ boxShadow: "md" }} type="submit">Sign Up</Button>
                                            
                                            
                                        </Stack>
                                    </form>
                                </Box>
                                : <Box pt="3">
                                    <Alert status="success" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
                                        <AlertIcon boxSize="40px" mr={0} />
                                        <AlertTitle mt={3} mb={1} fontSize="lg">You are signed up!</AlertTitle>
                                        <AlertDescription fontSize="sm">Awaiting email confirmation.<br />Please check your email for instructions<br />If you do not see the email, you may need to check your spam folder.</AlertDescription>
                                        <Button colorScheme="green" mt="3"><NavLink to="/">Back to Homepage</NavLink></Button>
                                    </Alert>
                                </Box>    
                            }
                            </TabPanel>
                            <TabPanel px="0">
                                <Box>
                                    <form action="submit" onSubmit={(e) => onLogin(e)}>
                                        <Stack spacing={3}>
                                            <FormControl isRequired>
                                                <InputGroup bgColor={background} color={formText}>
                                                    <InputLeftElement children={<FontAwesomeIcon icon={faEnvelope} />} />
                                                    <Input type="email" placeholder="Email" aria-label="Email" value={email} onKeyPress={(e)=>{e.key === 'Enter' && e.preventDefault();}} onChange={event => setEmail(event.currentTarget.value)} />
                                                </InputGroup>
                                            </FormControl>
                                            { passwordLogin && !resetPassword && 
                                                <FormControl isRequired={passwordLogin}>
                                                    <InputGroup bgColor={background} color={formText}>
                                                        <InputLeftElement children={<FontAwesomeIcon icon={faLock} />} />
                                                        <Input placeholder="Password" aria-label="Password" type={show ? "text" : "password"} value={password} onChange={event => setPassword(event.currentTarget.value)} />
                                                        <InputRightElement width="4.5rem">
                                                            <Button h="1.75rem" size="sm" onClick={handleClick}>
                                                            {show ? "Hide" : "Show"}
                                                            </Button>
                                                        </InputRightElement>
                                                    </InputGroup>
                                                </FormControl>
                                            }
                                            {
                                                passwordLogin && !resetPassword &&
                                                <Link fontSize="xs" fontWeight="bold" textAlign="center" onClick={()=>setResetPassword(true)} >Forgot Password?</Link>
                                            }
                                            {emailCheck && <Button isLoading={loading} onClick={() => checkEmail()} >Next</Button> }
                                            {emailLinkLogin && 
                                                <Box w="full" textAlign="center">
                                                    <Text px="4" pb="3" fontSize="sm" fontWeight="bold" >Authorize your account with an email link</Text>
                                                    <Button isLoading={loading} w="full" onClick={() => sendEmailLink()} >Send Email Link</Button>
                                                </Box>
                                                }
                                            {passwordLogin && !resetPassword && <Button isLoading={loading} boxShadow="sm" _hover={{ boxShadow: "md" }} type="submit">Login</Button>}
                                            {resetPassword && 
                                            <>
                                                <Button isLoading={loading} boxShadow="sm" _hover={{ boxShadow: "md" }} onClick={sendResetEmail} >Send Reset Link</Button> 
                                                <Link fontSize="xs" fontWeight="bold" textAlign="center" as="a" onClick={()=>setResetPassword(false)} >Go Back</Link>
                                            </>
                                            }
                                            {emailSentMessage &&
                                                <Box pt="3">
                                                    <Alert status="success" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
                                                        <AlertIcon boxSize="40px" mr={0} />
                                                        <AlertTitle mt={3} mb={1} fontSize="lg">Email Sent!</AlertTitle>
                                                        <AlertDescription>Please check your email for a log in link to continue.</AlertDescription>
                                                        <Button colorScheme="green" mt="3"><NavLink to="/">Back to Homepage</NavLink></Button>
                                                    </Alert>
                                                </Box>
                                            }
                                        </Stack>
                                    </form>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>)}
                </Box>
                {/* </motion.div> */}
                {/* </AnimateSharedLayout> */}
            </Flex>
        </Box>
    </AnimatePresence>
    )

}