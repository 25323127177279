import { Button, Flex, IconButton, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, useColorModeValue } from "@chakra-ui/react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Sheet from "react-modal-sheet";
import { RemoveScroll } from "react-remove-scroll";
import useViewport from "../Hooks/Viewport";
import DbGarage from "./DbGarage";
import { DeleteGarageFn, UpdateGarageFn } from "./GarageCard";
import GarageContent from "./GarageContent";


type GarageFullProps = {
    isOpen: boolean,
    onClose: () => void,
    editMode: boolean,
    setEditMode?: React.Dispatch<React.SetStateAction<boolean>>,
    garage: DbGarage,
    updateGarage?: UpdateGarageFn,
    deleteGarage?: DeleteGarageFn,
    bookGarage?: Function,
}

export default function GarageFull({isOpen, onClose, editMode, setEditMode, garage, updateGarage, deleteGarage, bookGarage}:GarageFullProps){

    const { width } = useViewport();
    const mdBreakpoint = 480;
    const background = useColorModeValue('white', '#4A5568');
    const textColor = useColorModeValue("gray.900", "white");

    return (
    <>
    {isOpen && width > mdBreakpoint
    ? <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" isCentered={editMode ? false: true} size={editMode ? "3xl" : "lg"}>
            <ModalOverlay />
                <ModalContent overflow="hidden" color={textColor} pos="relative">
                <IconButton aria-label="Close" onClick={onClose} icon={<FontAwesomeIcon icon={faTimes}/>} pos="absolute" top={2} right={2} />
                <ModalBody p={0}>
                    <GarageContent garage={garage} editable={false} isOpen={isOpen} onClose={onClose} updateGarage={updateGarage} deleteGarage={deleteGarage} editMode={editMode} setEditMode={setEditMode}  />
                </ModalBody>
                {bookGarage && <ModalFooter justifyContent="center"><Button colorScheme="blue" onClick={()=> bookGarage()} >Book</Button></ModalFooter>}
            </ModalContent>
        </Modal>
    : <Sheet rootId="root" isOpen={isOpen} onClose={onClose} >
        <RemoveScroll>
        <Sheet.Container style={{backgroundColor:background}}>
            <Sheet.Header></Sheet.Header>
            <Sheet.Content style={{color:textColor, position:"relative"}}>
                <GarageContent garage={garage} editable={false} isOpen={isOpen} onClose={onClose} updateGarage={updateGarage} deleteGarage={deleteGarage} editMode={editMode} setEditMode={setEditMode}  />
                {bookGarage && <Flex justify="center"> <Button mb="4" colorScheme="blue" onClick={()=> bookGarage()} >Book</Button> </Flex> }          
            </Sheet.Content>
        </Sheet.Container>
        </RemoveScroll>
        <Sheet.Backdrop onTap={onClose} style={{touchAction:"none"}} />
    </Sheet>
    }
    </>
    )
}