import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase, { db, auth as fAuth, functions } from '../../firebase';

import AuthContext, { AuthContextType } from '../Account/AuthContext';
import DbGarage from '../Garages/DbGarage';
import DbWorkorderType from './DbWorkorderType';


function WorkorderConfirm() {
    var {id}=useParams<{id:string}>();
    const Auth = useContext(AuthContext);

    const [msg,setMsg]=useState('Confirming...');


    /** called once upon startup */
    useEffect(()=>{
        confirmWorkorder(id, Auth, setMsg);
    }
    ,[]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
        <h2>Workorder Confirmation</h2>
        {msg}
        </>
    );
}

export default WorkorderConfirm;


/** confirm a workorder 
 * workorderId is the database id, not DbWorkorderType.workorderNo
*/
export async function confirmWorkorder(workorderId:string, auth:AuthContextType, setMsg: React.Dispatch<React.SetStateAction<string>>){
    const workordersCollection=db.collection("work_orders");
    const garagesCollection=db.collection("garages");
    try {
        setMsg('Loading');
        // functions.useEmulator('localhost',5001); // For debugging, redirect requests to emulator
        const workorderRef=workordersCollection.doc(workorderId)
        const workorderDoc=await workorderRef.get();
        const workorder={...workorderDoc.data(),id:workorderDoc.id} as DbWorkorderType;
        if(workorder.selectedGarageId!==auth.garageId){
            throw new Error("This garage does not own that workorder.");
        }
        if(workorder.confirmed){
            setMsg("The appointment was already confirmed.");
        } else {
            // update the database (must set first, so that email is formatted correctly)
            await workorderRef.update({confirmed:true}).then( async () => {
                // send the emails
                const sendEmail = functions.httpsCallable('sendEmail');
                const token = await fAuth.currentUser?.getIdToken();
                try {
                    // send a new email to user
                    sendEmail({
                        token: token,
                        name: workorder.firstName,
                        email: workorder.email,
                        template: 'userAppointment',
                        garageId: workorder.selectedGarageId,
                        workorderId: workorder.id
                    });
                    // send a new email to garage
                    const garageDoc=await garagesCollection.doc(workorder.selectedGarageId).get();
                    const garage={...garageDoc.data(),id:garageDoc.id} as DbGarage;
                    sendEmail({
                        token: token,
                        name: garage.name,
                        email: garage.notificationEmail,
                        template: 'garageAppointment',
                        workorderId: workorder.id
                    });
                    setMsg("The appointment is confirmed.");
                } catch(error) {
                    console.error(error);
                    workorderRef.update({confirmed:false});
                    setMsg("Error sending email, please try again");
                }
            })
        }
    } catch(error) {
        console.error(error);
        setMsg("Invalid workorder!")
    }
}