import { Heading, Stack,Text, Flex, Box, Image, IconProps, Icon, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect } from "react";
import stepOneImage from '../../images/step-one.jpg';
import SectionWrapper from "./SectionWrapper";
import {useInView} from 'react-intersection-observer';

const Blob = (props: IconProps) => {
    return (
        <Icon
        width={'100%'}
        viewBox="0 0 578 440"
        fill="none"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
            fill="currentColor"
        />
        </Icon>
    );
};

// export default function StepOne({setIndex}:{setIndex:Function}){
export default function StepOne(){
    // const {ref, inView, entry} = useInView({threshold: 0.1})

    // useEffect(() => {
    //     if(inView){
    //         setIndex(1);
    //     }
    // }, [inView])

    return (
        <SectionWrapper name="how-it-works" id="how-it-works" 
        // reference={ref}
        >
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 0, md: 24 }}
                direction={{ base: 'column', md: 'row' }}>
                <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                    <Heading
                    lineHeight={1.1}
                    fontWeight={600}
                    fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
                    <Text
                        color={useColorModeValue('gray.800', 'gray.100')}
                        as={'span'}
                        position={'relative'}
                        _after={{
                            content: "''",
                            width: 'full',
                            height: '30%',
                            position: 'absolute',
                            bottom: 1,
                            left: 0,
                            bg: 'teal.400',
                            zIndex: -1,
                        }}>
                            Exclusive
                        </Text>
                    </Heading>
                    <Text color={useColorModeValue('gray.600', 'gray.500')}>
                        Unlock new opportunities for auto professionals with our exclusive platform dedicated to selling and trading auto parts. Take your business to the next level as we connect you with a private network tailored specifically for professionals in the automotive industry.
                    </Text>
                </Stack>
                <Flex
                flex={1}
                justify={'center'}
                align={'center'}
                position={'relative'}
                w={'full'}>
                    <Blob
                        w={'150%'}
                        h={'150%'}
                        position={'absolute'}
                        top={'-20%'}
                        left={0}
                        zIndex={-1}
                        color={useColorModeValue('teal.50', 'teal.400')}
                    />
                    <Box
                        position={'relative'}
                        height={'300px'}
                        rounded={'2xl'}
                        boxShadow={'2xl'}
                        width={'full'}
                        overflow={'hidden'}>
                        <Image src={stepOneImage}
                        fit={'cover'}
                        align={'center'}
                        w={'100%'}
                        h={'100%'}
                        />
                    </Box>
                </Flex>
            </Stack>
        </SectionWrapper>
    )
}