import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import * as Scroll from 'react-scroll';

let Element = Scroll.Element;

export default function SectionWrapper({children, name, reference, id}:{children:React.ReactNode, name: string, reference?: React.LegacyRef<HTMLDivElement> | undefined, id?:string}){
    return (
    <Element id={id} name={name} style={{height:"100%", minHeight:"600px", scrollSnapAlign: "start"}} >
        <Box ref={reference} w="full" h="full" bgColor="light-gray" backgroundRepeat="no-repeat" backgroundSize="cover">
            <Box maxW="container.xl" pt="calc(2rem + 70px)" h="full" m="auto">
                <VStack h="full" justify="center" maxW={'6xl'} mx="8">
                    {children}
                </VStack>
            </Box>
        </Box>
    </Element>
    )
}