import { Button } from "@chakra-ui/react";
import * as React from "react";
import { useController, Control, FieldValues } from "react-hook-form";


const CoverFile = ({ control, name }:{control:Control<FieldValues, object>, name:string}) => {
    const { field } = useController({ control, name });
    const [value, setValue] = React.useState("");
    return (
        <label htmlFor="coverImage">
            <input
            type="file"
            accept="image/png, image/jpeg"
            id="coverImage"
            style={{display:"none"}}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                field.onChange(e.target.files);
            }}
            />
            <Button pointerEvents="none" variant="outline" size="sm">Change</Button>
        </label>
    );
};

export default CoverFile