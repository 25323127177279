import { Box, Button, Container, FormControl, FormHelperText, FormLabel, Input, Text, Textarea } from '@chakra-ui/react';
import React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { Nav } from '../Navigation/Nav';

export const Contact = () => {

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log(e)
  }


  return (
    <Box>
    <Nav/>
    <Container maxW={'xl'}>
      <Box pt={'44'}>
        <form onSubmit={(e) => onSubmit(e)}>
      <ReCAPTCHA sitekey={'6LdGeNUmAAAAAFOS4shJvYmsnBcI5fkrQqEJ0cwt'} size={'invisible'}>
        <FormControl isRequired mb={12}>
          <FormLabel>Your email</FormLabel>
          <Input type={'email'}/>
          <FormHelperText>We'll never share your email</FormHelperText>
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Message</FormLabel>
          <Textarea/>
        </FormControl>
        <Button my={4} type={'submit'}>Submit</Button>
      </ReCAPTCHA>
        </form>
      </Box>
    </Container>
    </Box>
  )
}
