import React, { useContext, useEffect, useState } from 'react';
import {
    Flex,
    Text,
    IconButton,
    Divider,
    Avatar,
    Heading,
    Box,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    VStack,
    Image,
    DrawerHeader,
    CloseButton,
    DrawerBody,
    DrawerFooter,
} from '@chakra-ui/react';

import { faColumns, faWarehouse, faHeadset, faWrench, faBars, faArchive, faLock, faUnlock, faSignOutAlt, faClipboardList, faCalendar, faCalendarAlt, faUser, faTimes, faNewspaper } from '@fortawesome/free-solid-svg-icons';


import {NavItem, SubNav} from './NavItem';
import AuthContext from '../Account/AuthContext';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../../carera-logo.svg';
import CareraLogo from '../../carera-logo';
import useViewport from '../Hooks/Viewport';
import { NavLink, RouteProps, useLocation, useParams } from 'react-router-dom';
import { NavSize, SidebarContextProvider, SidebarCtx, useSidebarContext } from './SidebarContext';
import { useClassifiedsCategoryContext } from '../Classifieds/ClassifiedsCategoryContext';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}


export default function Sidebar() {
    const Auth = useContext(AuthContext);
    const drawer = useDisclosure();
    const { width } = useViewport();
    const { state:{navSizeProps}, dispatch } = useSidebarContext();
    const { state:{categories} } = useClassifiedsCategoryContext();
    const breakpoint = 992;
    const smBreakpoint = 768;
    let query = useQuery();
    const location = useLocation()

    useEffect(() => {
        if(!navSizeProps.userSet){
            if(width <= breakpoint && width >= smBreakpoint){
                dispatch({type:SidebarCtx.navSizeInitial, payload:{navSize:NavSize.Small, userSet:false}})
            } else {
                dispatch({type:SidebarCtx.navSizeInitial, payload:{navSize:NavSize.Large, userSet:false}})
            }
        }
    }, [width])



    // useEffect(() => {
    //     console.log(location.pathname)
    // }, [location.pathname])
    
    


    const openDrawVariant = {
        opened: {
            minWidth: "180px",
            width: "180px"
        },
        closed: {
            minWidth: "75px",
            width: "75px"
        }
    }

    
    const SidebarContent = ({fontSize, fontSizeSmall}:{fontSize?:string, fontSizeSmall?: string}) => {
        return (
        <Box w="full">
            {Auth.isAdmin && 
                <>
                <NavItem fontSize={fontSize} navSize={navSizeProps.navSize} icon={faColumns} title="Dashboard" linkTo="/dashboard/workorders" />
                {
                    location.pathname === '/dashboard/workorders' &&
                    <>
                        <SubNav active={ (query.get('closed') === "no" && query.get('insurance') === "yes") ? true : false}  fontSize={fontSizeSmall} navSize={navSizeProps.navSize} icon={faUnlock} color="#68D391" title="Open" linkTo="/dashboard/workorders?closed=no&insurance=yes" />
                        <SubNav active={ (query.get('closed') === "yes") ? true : false}  fontSize={fontSizeSmall} navSize={navSizeProps.navSize} icon={faLock} color="#FC8181" title="Closed" linkTo="/dashboard/workorders?closed=yes" />
                        <SubNav active={ (query.get('closed') === "no" && query.get("insurance") === "no") ? true : false}  fontSize={fontSizeSmall} navSize={navSizeProps.navSize} icon={faArchive} color="#91c1ff" title="Empty" linkTo="/dashboard/workorders?closed=no&insurance=no"/>
                        <SubNav active={ (query.get('cancelled') === "yes") ? true : false} fontSize={fontSizeSmall} navSize={navSizeProps.navSize} icon={faTimes} color="#ff7575" title="Canceled" linkTo="/dashboard/workorders?cancelled=yes" />
                    </>
                }
                <NavItem fontSize={fontSize} navSize={navSizeProps.navSize} icon={faWarehouse} title="Garages" linkTo="/dashboard/garages" />
                <NavItem fontSize={fontSize} navSize={navSizeProps.navSize} icon={faWrench} title="Services" linkTo="/dashboard/services/edit" />
                <NavItem fontSize={fontSize} navSize={navSizeProps.navSize} icon={faHeadset} title="Providers" linkTo="/dashboard/insurance_providers/edit" />
                <NavItem fontSize={fontSize} navSize={navSizeProps.navSize} icon={faNewspaper} title="Classifieds" linkTo="/dashboard/classifieds/latest?size=20" />
                <NavItem fontSize={fontSize} navSize={navSizeProps.navSize} icon={faUser} title="User Mgmt" linkTo="/dashboard/user_admin" />
                </>
            }
            {Auth.isGarage && 
                <>
                <NavItem fontSize={fontSize} navSize={navSizeProps.navSize} icon={faClipboardList} title="Bookings" linkTo={`/dashboard/workorders/${Auth.garageId}`} />
                <NavItem fontSize={fontSize} navSize={navSizeProps.navSize} title="Calendar" icon={faCalendarAlt} linkTo={`/dashboard/garages/${Auth.garageId}/calendarConfig`} />
                <NavItem fontSize={fontSize} navSize={navSizeProps.navSize} title="Classifieds" icon={faNewspaper} linkTo={'/dashboard/classifieds/latest?size=20'} />
                {
                    location.pathname.includes('/dashboard/classifieds') &&
                    (
                        categories.map((category)=>(
                            <SubNav key={category} fontSize={fontSize} navSize={navSizeProps.navSize} title={category} linkTo={`/dashboard/classifieds/${category}`} />
                        ))
                    )
                }
                </>
            }
        </Box>
        )
    }

    const SidebarFooter = (props:any) => {
        return (
            <Flex
                p={2}
                flexDir="column"
                w="100%"
                alignItems={navSizeProps.navSize === NavSize.Small ? "center" : "flex-start"}
                mb={4}
            >
                <Divider display={navSizeProps.navSize === NavSize.Small ? "none" : "flex"} />
                <Flex mt={4} align="center" justify={"center"} w={"full"} >
                    <Avatar size="sm" name={Auth.firstName && `${Auth.firstName}`} />
                    <Flex flexDir="column" mx={2.5} display={navSizeProps.navSize === NavSize.Small ? "none" : "flex"}>
                        {Auth.firstName && <Heading as="h3" size="sm">{`${Auth.firstName}`}</Heading>}
                        <Text color="gray">{Auth.isAdmin ? "Admin" : Auth.isGarage ? "Garage" : "User"}</Text>
                    </Flex>
                </Flex>
            </Flex>
        )
    }


    return (
        <>
        <Box display={{base: "none", md: "unset"}}>
            <motion.div
            initial={false}
            variants={openDrawVariant}
            animate={navSizeProps.navSize === NavSize.Small ? "closed" : "opened"}
            >
                <Flex
                    pos="sticky"
                    left="5"
                    h="95vh"
                    mr={3}
                    marginTop={6}
                    boxShadow={"sm"}
                    background={"#f7f7f7"}
                    rounded={navSizeProps.navSize === NavSize.Small ? "lg" : "md"}
                    flexDir="column"
                    justifyContent="space-between"
                >
                    <Flex
                        p={"8px 12px"}
                        flexDir="column"
                        w="100%"
                        alignItems={navSizeProps.navSize === NavSize.Small ? "center" : "flex-start"}
                        as="nav"
                    >
                        <IconButton
                            _focus={{
                                outline: "none"
                            }}
                            aria-label="nav-size"
                            background="none"
                            mt={5}
                            _hover={{ background: 'none' }}
                            icon={<FontAwesomeIcon icon={faBars} />}
                            onClick={() => {
                                if (navSizeProps.navSize === NavSize.Small)
                                    // changeNavSize("large")
                                    dispatch({type:SidebarCtx.navSize, payload:NavSize.Large})
                                else
                                    // changeNavSize("small")
                                    dispatch({type:SidebarCtx.navSize, payload:NavSize.Small})
                            }}
                        />
                        <SidebarContent />
                    </Flex>
                    <SidebarFooter />
                </Flex>
            </motion.div>
        </Box>
        <Flex pos="absolute" left={[0,4]} top={6} display={{base: "flex", md: "none"}} align="center" >
            <IconButton zIndex={1} aria-label="Menu" onClick={drawer.onOpen} icon={<FontAwesomeIcon icon={faBars} />} mr={2} />
            <CareraLogo height="40px" />
        </Flex>
        {/* <Image src={logo} w={10} h={5} fill="#000" />     */}
        <Drawer isOpen={drawer.isOpen} onClose={drawer.onClose} placement={"left"}>
            <DrawerOverlay />
            <DrawerContent >
                <DrawerHeader> <Flex justify="space-between" > <CareraLogo width="30%" /> <CloseButton onClick={drawer.onClose} /></Flex> </DrawerHeader>
                <DrawerBody>
                    <SidebarContent fontSize="1.5rem" fontSizeSmall="1.25rem" />
                </DrawerBody>
                <DrawerFooter>
                    <SidebarFooter />
                    <NavLink to="/logout"><IconButton aria-label="Log Out" icon={<FontAwesomeIcon icon={faSignOutAlt} />}  /></NavLink>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
        </>
    )
}