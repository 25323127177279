import React from 'react'
import { Box, VStack, Text, UnorderedList, ListItem, useColorModeValue, Link } from '@chakra-ui/react'
import Header from '../Navigation/HeaderLisa'
import Footer from '../Navigation/Footer'

function PrivacyPolicy() {
    return (
        <Box>
            <Header/>
            <Box maxW="container.xl" pt="2rem" h="full" m="auto" mb="12" color={useColorModeValue('gray.800', 'gray.100')}>
                <VStack h="full" justify="center" maxW={'6xl'} mx="8">
                    <Text as="h1" fontSize="4xl" mb="6" >
                        Privacy Policy
                    </Text>
                    <Text as="p">
                        Carera, Inc. (“Carera” or “We”) respect your privacy and recognize the importance of your personal information. We are committed to protecting your information through our compliance with this Privacy Policy.<br/>
                        This Privacy Policy describes the types of information we may collect when you visit the Carera website or subscribe to receive the Carera services (collectively, our “Services”) and our practices for using, maintaining, protecting and disclosing that information.
                    </Text>
                    <UnorderedList>
                        <ListItem>
                            A . Information Collection, Use and Sharing<br/>
The only personal information about you that we collect is information you voluntarily give us. <br/>
This information may include:<br/>
Information you provide when you subscribe to our Services, such as your name, physical address and email address, as well as your credit card or other billing information.<br/>
Information you provide to respond to a Carera survey.<br/>
Records and copies of your correspondence, including email address, if you contact us.<br/>
Details of any other transactions you carry out through our Services.<br/>
We will only use your personal information (i) for the purpose you provided it, such as to present the Services and its content to you, (ii) to notify you about Carera events or services, and (iii) for the purpose of aggregated statistical analysis regarding how you and others use our Services. For any other use, we will first obtain your explicit consent.<br/>
We will not sell or rent your personal information to anyone. We will not disclose your personal information to any third party outside of our organization, other than:<br/>
To contractors, service providers and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.<br/>
To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of Indio’s assets, whether as a going concern or as part of a bankruptcy, liquidation or similar proceeding, in which personal information held by Indio about our users is among the assets transferred.<br/>
To fulfill the purpose for which you provide it.<br/>
For any other purpose disclosed by us when you provide the information.<br/>
With your consent.<br/>
To comply with any court order, law or legal process, including to respond to any government or regulatory request.<br/>
To enforce or apply our Subscription Agreement and other agreements.<br/>
As you use our Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions and usage patterns. The technologies we use for this automatic data collection include cookies. A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your web browser.
                        </ListItem>
                        <ListItem>
                            B. Your Access To and Control Over Information<br/>
You may do the following at any time by contacting us at info@mycarera.com:<br/>
Opt out of any future contacts from us.<br/>
See what information we have about you, if any.<br/>
Change, correct, or have us delete any information we have about you.<br/>
Express any concern you have about our use of your information.
                        </ListItem>
                        <ListItem>
                            C. Security<br/>
We have implemented reasonable measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration and disclosure. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted via our Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained in the Services.
                        </ListItem>
                        <ListItem>
                            D. Updates<br/>
We post any changes we make to our Privacy Policy on this page. The date the Privacy Policy was last updated is identified at the bottom of this page. You are responsible for periodically visiting the Carera website and this Privacy Policy to check for any changes.
                        </ListItem>
                        <ListItem>
                            E. Questions and comments<br/>
To ask questions about or comment on this Privacy Policy and our privacy practices, you may contact us at <Link color="teal.400" as="a" href="mailto:info@mycarera.com">info@mycarera.com</Link>.
                        </ListItem>
                    </UnorderedList>
                    <Text>Updated: October 6, 2021</Text>

                </VStack>
            </Box>
        <Footer />
    </Box>
    )
}

export default PrivacyPolicy
