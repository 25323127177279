import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';

import firebase, { db } from "../../firebase";
import DbGarage from "./DbGarage";
import ImageCarousel from './ImageCarousel';
import {EditButton} from '../Common/CommonStyledControls';
import Services from '../Services/ServicesList';
import SchedulingInfo from '../Calendar/schedulingInfo';
import DbAttachment from '../Attachments/DbAttachment';
import {jsonAddressToString} from '../Common/AddressForm';
import Reviews from './Reviews';
import instantBookIcon from '../Common/InstantBook.svg';



const StyledGarage = styled.div`
    /* width: 30vw; */
    /* flex-direction: column; */
    /* border: 1px solid black; */
    /* border-radius: 20px; */
    /* margin: 20px; */
    /* box-shadow: -5px 5px 0px black; */
    /* text-align: center; */
    width: 40vw;
    min-width: 300px;
    max-width: 450px;
    display: inline-flex;
    flex-direction: column;
    margin: 5px 15px;
`;

const GarageHeading=styled.h2`
    margin: 0 10px;
`;

const GarageP=styled.p`
    margin: 0 10px;
`;

const StyledPopup = styled(Popup)`
    // for ".popup-overlay"
    &-overlay { 
        background-color:rgba(106,102,125,0.66);
        backdrop-filter:blur(30px);
        box-shadow: inset 0 0 40px rgb(72,69,90);
    }

    // for .popup-content"
    &-content {
        min-width:50%;
        min-height:50%;
        background-color:white;
        border-radius:30px;
        box-shadow: 0 0 40px rgb(72,69,90);
        padding:0 30px;
        font-size:120%;
    }
`;

const ActionButton=styled.button`
    margin: 1px 0px;
    background-color: cornflowerblue;
    border: 0;
    cursor: pointer;
    padding: 2px;
    width: 90%;
    font-size: 10pt;
    padding: 0.75em;
    margin: 0.3em 5%;
    color: #fff;
    border-radius: 5px;
    
    &:first-child{
        margin: 0 5% 0.3em;
    }
`;


const ActionButtons=styled.div`
    margin: 3px;
    border-radius: 0 0 20px 20px;
    overflow: clip;
`;

const Distance=styled.div`
    font-size:10pt;
    /* text-align:right; */
    display: block;
    margin: 0 10px 10px 10px;
    padding-right: 5px;
`;


export interface OnGarageSelectedFunction { (garage: DbGarage): void }
export interface OnScheduleFunction { (info:SchedulingInfo): void }

interface GarageProps {

    /** database info about this garage */
    garage:DbGarage,

    /** is the garage editable for this user? */
    editable:boolean,

    /** (optional) database location to log user's actions to */
    actionsCollection?:firebase.firestore.CollectionReference,

    /** (optional) function to be called when this garage's scheduling calendar is to be accessed */
    onSchedule?:OnScheduleFunction,

    /** (optional) function to be called when this garage is selected */
    onGarageSelected?:OnGarageSelectedFunction
};


/** show one single garage 
 * NOTE: makes use of react-popup package
 * https://react-popup.elazizi.com/
*/
function Garage({garage,editable,actionsCollection,onSchedule,onGarageSelected}:GarageProps){
    const [imageUrls,setImageUrls]=useState<string[]>([]);
    const [phonePopupOpen,setPhonePopupOpen]=useState(false);
    const [addressPopupOpen,setAddressPopupOpen]=useState(false);

    /** get all the image urls from the database */
    function getImages() {
        const garageImagesSubCollection=db.collection("garages").doc(garage.id).collection("images");
        const unregister=garageImagesSubCollection.onSnapshot(
            (querySnapshot)=>{
                const items:DbAttachment[]=[];
                querySnapshot.forEach((doc)=>{
                    items.push(doc.data() as DbAttachment);
                });
                items.sort((a,b)=>a.order-b.order);
                setImageUrls(items.map(item=>item.url));
                unregister();
            },
            (error:any) => {
                console.error("Garage.tsx: Error getting images for garage",garage.id);
                console.error(error);
            }
        );
    }

    // this is set up to run once on component creation
    useEffect(()=>{
        getImages();
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    /** log a single action to actionsCollection, if provided */
    async function logAction(actionName:string){
        if(actionsCollection===undefined) return;
        let dbFile={
            name:actionName,
            garageId:garage.id,
            timestamp:Date.now(),
        };
        try {
            let newDocRef=actionsCollection.doc(); // creates new entry
            newDocRef.set(dbFile);
        } catch(error) {
            console.error(error);
        }
    }

    /** select this garage */
    function selectGarage(){
        if(onGarageSelected!==undefined){
            onGarageSelected(garage);
        }
    }

    /** get a url link that a mobile device is most likely to pop out into a map app */
    function getMapUrl(){
        // determine what to use
        var address=encodeURIComponent(jsonAddressToString(garage.address));
        var mapAgent='google';
        if(navigator.userAgent.includes("Mac")){
            mapAgent='apple';
        }
        if(navigator.userAgent.includes("Android")){
            mapAgent='google';
        }
        if(navigator.userAgent.includes("Windows")){
            mapAgent='bing';
        }
        // now create a url based upon that
        var url='';
        if(mapAgent==='apple'){
            // see: https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
            url=`https://maps.apple.com/?daddr=${address}&dirflg=d&t=h`;
        }else if(mapAgent==='bing'){
            // see: https://docs.microsoft.com/en-us/bingmaps/articles/create-a-custom-map-url
            url=`https://bing.com/maps/default.aspx?rtp=~adr.${address}&ss=${garage.name}`;
        }else{ // use google maps
            // see: https://developers.google.com/maps/documentation/urls/get-started
            url=`https://www.google.com/maps/dir/?api=1&destination=${address}&destination_place_id=${garage.name}`;
        }
        return url;
    }

    /** format drive time as a nice string */
    function driveTime(t?:number){
        if(t===undefined) return '';
        let h=Math.trunc(t/60);
        let m=t%60;
        if(h>0) return `( ${h} hr ${m} min )`;
        return `( ${m} min )`;
    }

    return (
        <StyledGarage>
            <GarageHeading>{garage.name}
                { // add an edit icon if the "editable" property is set
                    editable?(
                        <NavLink to={'/garages/'+garage.id+'/edit'}><EditButton/></NavLink>
                    ):''
                }
            </GarageHeading>
            { Reviews(garage) }
            <Distance>{garage.distance===undefined?'':`${garage.distance} Miles`} {driveTime(garage.driveTime)}</Distance>
            <GarageP>{garage.about}</GarageP>
            <Services serviceIdList={garage.services} />
            <ImageCarousel id={garage.id} images={imageUrls}></ImageCarousel>
            <ActionButtons>
                {onSchedule===undefined||garage.instantBook===false?"":(
                    <ActionButton onClick={(e)=>{selectGarage();logAction('calendar');logAction('schedule');onSchedule(garage);}}><img src={instantBookIcon} alt="" style={{display:"inline-block",height:"20px"}}/> Instant Book</ActionButton>
                )}
                <ActionButton onClick={(e)=>{e.preventDefault();selectGarage();setPhonePopupOpen(true);logAction('phone')}} >📞 Call or text</ActionButton>
                <ActionButton onClick={(e)=>{e.preventDefault();selectGarage();setAddressPopupOpen(true);logAction('address')}} >🧭 Address + Directions</ActionButton>
            </ActionButtons>
            <StyledPopup open={phonePopupOpen} onClose={()=>setPhonePopupOpen(false)} modal closeOnDocumentClick position="center center">
                <div><h2>📞 Phone number:</h2><a href={`tel://${garage.phone}`}>{garage.phone}</a></div>
            </StyledPopup>
            <StyledPopup open={addressPopupOpen} onClose={()=>setAddressPopupOpen(false)} modal closeOnDocumentClick position="center center">
                <div><h2>🧭 Address:</h2><a href={getMapUrl()}>
                    {jsonAddressToString(garage.address,true).split('\n').map(addressLine=>(<p>{addressLine}</p>))}
                </a></div>
            </StyledPopup>
        </StyledGarage>
    );
}


export default Garage;