import React, { useContext, useEffect } from "react";
import { createContext } from "react";
import { db } from "../../firebase";

const catRef = db.collection("listing").doc('categories');

interface ClassifiedsData {
    values: string[]
}

interface ClassifiedsCatItem{
    id:string;
    name: string;
}

export interface ClassifiedsContextProps {
    categories:string[]
}

export enum ClassifiedsCtx {
    add = 'ADD',
    addInitial = 'INITIAL',
    remove = 'REMOVE',
    edit = 'EDIT'
}

export type ClassifiedsAction = 
    | {type:ClassifiedsCtx.add, payload:string}
    | {type:ClassifiedsCtx.addInitial, payload: string[]}
    | {type:ClassifiedsCtx.remove, payload:string}
    | {type:ClassifiedsCtx.edit, payload:[string,string]}


const reducer = (state:ClassifiedsContextProps, action:ClassifiedsAction) => {
    switch (action.type) {
        case ClassifiedsCtx.add:{
            try {
                const current = new Set(state.categories)
                current.add(action.payload)
                const values = [...current]
                catRef.set({values})
                return {...state, categories:values}
            } catch (error) {
                console.log(error)
                return state
            }
        }
        case ClassifiedsCtx.remove:{
            try {
                const current = new Set(state.categories)
                current.delete(action.payload)
                const values = [...current]
                catRef.set({values})
                return {...state, categories: values}
            } catch (error) {
                console.log(error)
                return state
            }
        }
        case ClassifiedsCtx.edit:{
            try {
                const current = new Set(state.categories)
                current.delete(action.payload[0])
                current.add(action.payload[1])
                const values = [...current]
                catRef.set({values})
                return {...state, categories: values}
            } catch (error) {
                console.log(error)
                return state
            }
        }
        case ClassifiedsCtx.addInitial:
            return {...state, categories:action.payload}
        default:
            return state
    }
}

const initialState:ClassifiedsContextProps = {
    categories:[]
}
const defaultDispatch: React.Dispatch<ClassifiedsAction> = () => initialState

const ClassifiedsCategoryContext = createContext({state: initialState, dispatch: defaultDispatch})

const ClassifiedsCategoryProvider = (props: React.PropsWithChildren<Record<string, unknown>>) => {

    const [state, dispatch] = React.useReducer(reducer, initialState)

    useEffect(() => {
        const getCategories = () => {
            catRef.get()
            .then((res) => {
                if(res.exists){
                    const data = res.data() as ClassifiedsData
                    dispatch({type:ClassifiedsCtx.addInitial, payload: data.values})
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }
        getCategories()
    }, [])
    

    return <ClassifiedsCategoryContext.Provider value={{state,dispatch}} {...props} />
}

const useClassifiedsCategoryContext = () => {
    const categoryContext = useContext(ClassifiedsCategoryContext)
    if(!categoryContext){
        throw new Error('No ClassifiedsCategoryContext.Provider found when calling useClassifiedsCategoryContext');
    }
    return categoryContext
}

export { ClassifiedsCategoryProvider, useClassifiedsCategoryContext }