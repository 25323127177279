import { Box, Center, Text, useColorModeValue, VStack } from "@chakra-ui/react"
import { motion } from "framer-motion"

const svgTopVariants = {
    hidden: {
        pathLength: 0,
        pathOffset: 0.01
    },
    visible: {
        pathLength: 1,
        pathOffset: 1,
        transition: {
            duration: 2,
            ease: "easeOut",
            repeat: Infinity,
            delay: 0.5
        }
    }
}

const svgBottomVariants = {
    hidden: {
        pathLength: 0,
        pathOffset: 0.01
    },
    visible: {
        pathLength: 1,
        pathOffset: 1,
        transition: {
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
        }
    }
}

const mainLoadingVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.1,
            delay: 0.1
        }
    }
}

const Loading = ( {height = "calc(100vh - 150px", logoWidth = ["256px", "300px", "400px"]}:{height?:string, logoWidth?:string[]}) => {

    const color = useColorModeValue('#707070', 'white');

    return (
        <motion.div style={{width: "100%", height:`${height}`}}
            variants={mainLoadingVariants}
            initial="hidden"
            animate="visible"
        >
            <Center h={"full"}>
            <VStack align={"center"} justify="center">
                <Box w={logoWidth}>
            <svg clipRule="evenodd" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="1.5" viewBox="0 0 640 246" xmlns="http://www.w3.org/2000/svg">
                <motion.path 
                    variants={svgBottomVariants}
                    initial="hidden"
                    animate="visible"
                    d="m95.554 130.162c12.396-1.357 37.189-4.073 106.871-17.428 46.978-9.004 65.385-24.823 112.688-30.081 46.319-5.148 93.568 10.262 128.524 17.499 71.671 14.84 89.364 14.975 98.21 15.043" 
                    fill="none" 
                    stroke={color}
                    strokeWidth="3.05"/>
                <motion.path
                    variants={svgTopVariants}
                    initial="hidden"
                    animate="visible"
                    d="m289.287 67.023c14.675-3.902 44.023-11.707 99.237.403 65.132 14.285 33.292 9.877 86.684 19.315 35.372 6.252 59.644 5.506 71.78 5.133" 
                    fill="none" 
                    stroke={color} 
                    strokeWidth="3.05"/>
                <g fillRule="nonzero" transform="matrix(.1 0 0 -.1 -4.9385 316.2586)" fill={color} >
                    <path d="m3272.44 2177.01c-187.08-27.26-302.44-67.01-464.44-148.01-54-27-98-51-98-53 0-1 48 16 108 40 237 95 399 128 627 127 170 0 338-19 545-59 125-25 339-81 395-105 28-12 25-13-60-20-182-14-375-64-505-129-71-36-76-50-6-20 212 90 600 133 1001 110 110-6 209-13 220-16 16-4 17-3 4 5-27 17-290 51-450 57-152 6-156 7-264 50-178 70-358 117-565 149-104 15-335.09 34.22-487.56 12.01z"/>
                    <path d="m3218 2090c-75.8-13.87-158-40-220-71-32-16-58-31-58-33s35 11 78 30c139 60 332 77 609 54 117-9 183-30 216-67 25-29 10-38-129-74-151-40-276-47-679-36-439 11-961 4-1115-17-214-29-534-112-619-162-15-9-17-12-6-8 187 66 407 116 626 140 141 16 353 16 1349-1 228-4 449 29 578 85 79 35 67 81-35 132 0 0-25.4 13.85-72.29 23.24-50.66 10.15-168.36 22.59-253.07 23.37-87.12.79-188.03-3.68-269.64-18.61z"/>
                    <path d="m4790 1862c0-5 27-41 60-80l61-72h174l52-118-124-133c-125-136-179-204-107-138 229 210 274 253 274 263 0 6-14 46-32 89l-32 77h-182l-37 38c-20 21-37 41-37 44s51 9 113 14c93 6 105 9 72 15-54 11-255 11-255 1z"/>
                    <path d="m1943 1776c-63-20-125-60-166-105-61-67-66-91-8-39 157 142 365 153 529 28 58-44 56-26-5 32-83 81-243 119-350 84z"/>
                    <path d="m4160 1774c-58-18-132-73-170-126-42-58-37-62 16-10 26 24 77 58 113 76 60 28 75 31 166 31s106-3 165-31c36-17 83-46 105-65 28-25 35-28 27-13-21 40-113 113-168 133-65 25-185 27-254 5z"/>
                    <path d="m1307 1656c-96-18-96-29 0-24l83 5 39-47 40-48-47-6c-26-3-78-8-115-12s-70-11-73-15c-11-18 8-47 47-75 22-15 47-39 54-53 8-14 14-20 15-12 0 19-38 78-62 95-18 13-17 14 19 20 21 3 84 8 141 12 56 3 102 9 102 13 0 17-143 161-158 160-10-1-47-7-85-13z"/>
                </g>
            </svg>
            </Box>
            <Text mt={["-15px !important", "-25px !important"]} fontSize={"sm"} color={useColorModeValue("gray.400","white")} >LOADING</Text>
            </VStack>
            </Center>
        </motion.div>
    )
}

export default Loading