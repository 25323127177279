import { Button, Flex, HStack, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { faGripHorizontal, faList, faNewspaper, faPlus, faTable } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import Typesense from 'typesense'
import firebase, { db, storage } from '../../firebase'
import AuthContext from '../Account/AuthContext'
import { NoResults } from '../Common/Graphics'
import Loading from '../Common/Loading'
import SearchBar from '../Common/SearchBar'
import useViewport from '../Hooks/Viewport'
import { AddClassifiedsForm, ClassifiedFormValues, ClassifiedListing, StorageImageData } from './AddClassifiedsForm'
import ClassifiedsTableView from './ClassifiedsTableView'


export interface ClassifiedFirebaseResponse extends ClassifiedFormValues{
    createdAt:firebase.firestore.FieldValue;
    summary:string;
    thumbUrl?:string;
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(()=>new URLSearchParams(search),[search])
}

export const ViewClassifieds = () => {

    const { term }= useParams<{term:string}>();
    const query = useQuery();
    const history = useHistory();
    
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState<boolean>(true);
    const { width } = useViewport();
    const [list, setList] = useState<ClassifiedListing[]>([]);
    const [nextPage, setNextPage] = useState<boolean>(false);
    const Auth = useContext(AuthContext);
    const size = query.get("size")
    const listingCount = size ? parseInt(size) : 20;
    
    const classifiedRef=db.collection("classifieds");
    let termQuery = classifiedRef.orderBy("createdAt").limit(listingCount + 1);
    if(term !== 'latest') termQuery = classifiedRef.where('category', '==', term).orderBy("createdAt").limit(listingCount + 1);

    const [docQuery, setDocQuery] = useState(termQuery)

    useEffect(() => {
        const items:ClassifiedListing[] = [];
        docQuery.get().then((snapshot) => {
            snapshot.forEach(async (doc) => {
                const id = doc.id
                let data:ClassifiedListing = {id:id, ...doc.data() as ClassifiedFirebaseResponse}    
                items.push(data)
            })
            // const lastVisible = snapshot.docs[snapshot.docs.length-1]
            if(items.length === listingCount+1){
                const lastVisible = snapshot.docs[snapshot.docs.length-2]
                const next = termQuery.startAfter(lastVisible).limit(listingCount);
                setNextPage(true)
                setDocQuery(next)
            } 
            // if(lastVisible !== undefined){
            //     const next = query.startAfter(lastVisible).limit(listingCount);
            //     setNextPage(true)
            //     setDocQuery(next)
            // }
        }).finally(()=>{
            setList(items)
            setLoading(false)
        })
    }, []);

    const moreListings = () => {
        const items:ClassifiedListing[] = [];
        docQuery.get().then((snapshot) => {
            snapshot.forEach(async (doc) => {
                const id = doc.id
                let data:ClassifiedListing = {id:id, ...doc.data() as ClassifiedFirebaseResponse}    
                items.push(data)
            })
            if(items.length === listingCount+1){
                const lastVisible = snapshot.docs[snapshot.docs.length-2]
                const next = termQuery.startAfter(lastVisible).limit(listingCount);
                setNextPage(true)
                setDocQuery(next)
            } else {
                setNextPage(false)
            }
        }).finally(()=>{
            setList((oldList)=> {
                const updatedList = [...oldList,...items]
                return updatedList
            })
            setLoading(false)
        })
    }


    const smBreakpoint = 480;

    const onSearch = (searchString:string) => {
        if(searchString === '') {
            return
        }
        const searchTerm = encodeURI(searchString)
        console.log(searchTerm)
        history.push(`/dashboard/classifieds/search?term=${searchTerm}&page=1&size=20`)
    }

  return (
    <>
    <Flex w={"full"} mb={6} px={[0,4]} pos="relative">
        <SearchBar searchFn={onSearch} searchLeftStart={width < smBreakpoint ? "calc(100% - 8.5rem)" : "calc(100% - 10rem)"} />
        <Spacer/>
        {/* {width > smBreakpoint && <IconButton aria-label="table" mr={[2,3]} icon={<FontAwesomeIcon icon={faTable}/>} />} */}
        {/* {width> smBreakpoint && <IconButton aria-label="card" mr={[2,3]} icon={<FontAwesomeIcon icon={faGripHorizontal} />} />} */}
        {
            Auth.isGarage && <Button colorScheme={'blue'} onClick={onOpen} leftIcon={<FontAwesomeIcon icon={faPlus}/>} ><FontAwesomeIcon icon={faNewspaper} /></Button>}
        {
            Auth.isAdmin && <Link to={'/dashboard/classifieds/categories'}><Button colorScheme={'blue'} ml={[2,3]} leftIcon={<FontAwesomeIcon icon={faPlus}/>} ><FontAwesomeIcon icon={faList} /></Button></Link>
        }
    </Flex>
    {
        loading
        ? <Loading/>
        : list.length > 0
            ?<VStack>
                <ClassifiedsTableView classifieds={list} setList={setList} itemCount={listingCount} />
                {nextPage && <HStack p={2} justify={'center'} ><Button onClick={moreListings}>Next</Button></HStack>}
            </VStack> 
            : <NoResults />
    }
    <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
        <ModalOverlay/>
        <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton/>
            <ModalBody mb={8}>
                <AddClassifiedsForm setList={setList} onClose={onClose}/>
            </ModalBody>
        </ModalContent>
    </Modal>
    </>
  )
}
