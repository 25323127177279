import { Box, Button, chakra, Flex, IconButton, SimpleGrid, Text, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { faFilter, faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import firebase, { db } from "../../firebase";
import React, { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { NoResults } from "../Common/Graphics";
import Loading from "../Common/Loading";
import PageControl from "../Common/PageControl";
import { SearchTermsType } from "../Dashboards/UserDashboard";
import UserEditSearchForm from "../Form/UserEditSearchForm";
import { SearchValues } from "../Form/UserSearchForm";
import DbGarage, { DbGarageImage } from "./DbGarage";
import GarageSearchResultCard from "./GarageSearchResultCard";
import useViewport from "../Hooks/Viewport";

type GarageSearchProps = {

    // the search terms the user submitted
    searchTerms:SearchTermsType,

    // resubmit search parameters
    onSubmit:SubmitHandler<SearchValues>

    // move on to step two, book garage
    bookGarage: Function

}

/** How many items per page to start with (user can change later) */
const defaultItemsPerPage=5;

// a list of garages (pulled from database or webservice)
export default function GarageSearchResults({searchTerms, onSubmit, bookGarage}:GarageSearchProps){

    const address = searchTerms.address;
    const services = searchTerms.services;
    const instantBook = searchTerms.instantBook;
    const [garages, setGarages]=useState<DbGarage[]>([]);
    const [filteredGarages, setFilteredGarages]=useState<DbGarageImage[]>([]);
    const [showGarages, setShowGarages]=useState<DbGarageImage[]>([])
    const [loading, setLoading]=useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const [page,setPage]=useState(0);
    const [itemsPerPage,setItemsPerPage]=useState(defaultItemsPerPage);
    const { width } = useViewport();
    const mdBreakpoint = 480;

    const garagesCollection = db.collection("garages");

    // CHOOSE ONE:
    // production server
    const webservice="https://us-central1-carera-be9c6.cloudfunctions.net/getGarages?fromAddress=";
    // local firebase emulator
    //const webservice="https://localhost:5001/carera-be9c6/us-central1/getGarages?fromAddress=";

    /** get all the garages from the database */
    async function getGarages() {
 
        
    }

    /**
     * called when the page we are on changes
     */
    async function onPageChange(startItem:number,count:number){
        let pageNo=Math.floor(startItem/count);
        setPage(pageNo);
        // we are getting the next page from the filtered garage set
        setShowGarages(filteredGarages.slice(startItem,startItem+count));
        if(count!==itemsPerPage){
            setItemsPerPage(count);
        }
    }

    /** 
     * go back to the first page when the filtered garage list changes
     */
    useEffect(()=>{
        // Jump back to the first page
        onPageChange(0,itemsPerPage);
    },[filteredGarages,itemsPerPage]);

        /**
     * filter a list of garages by requiredServices and returns only those that match 
     */
    useEffect(()=>{
        var results:DbGarage[]=[];
        for(let i=0;i<garages.length;i++){
            let garage=garages[i];
            if(instantBook && !garage.instantBook){
                continue;
            }
            if(services === undefined || !services.length || services.every(s => garage.services.includes(s))){
                results.push(garage);
            }
        }
        setFilteredGarages(results);
    },[garages,services]);


    // this is set up to run once on component creation
    // we use it to kick off the database query
    useEffect(()=>{
        let unmounted = false;
        let source = axios.CancelToken.source();
        const fetchGarage = async () => {
            if(address){
                // setLoading(true);
                // get the list from the webservice (which also calulates distance)
                var response=null;
                var url=webservice+encodeURIComponent(address);
                try{
                    response=await axios.get(url, {
                        cancelToken: source.token,
                        timeout:60000
                    });
                    if (!unmounted) {
                        var items:DbGarage[]=response.data as DbGarage[];
                        items.sort((a,b)=>(b.distance===undefined||b.distance===0||a.distance===undefined||a.distance===0)?0:a.distance-b.distance); // order by shortest distance
                        setGarages(items);
                    }
                }catch(error:any){
                    if(!unmounted){
                        toast({
                            title: "Search Error",
                            description: "Sorry, there was an error while getting the search results, try again",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        })
                        if(error.name==="NetworkError"){
                            console.error(error.request)
                            console.error(error)
                            console.error(`${error.response.status} - ${error.response.statusText}`);
    
                        }else{
                            throw(error);
                        }
                    }
                } finally {
                    if(!unmounted){
                        setLoading(false);
                    }
                }
            }
        }
        fetchGarage();
        return function () {
            unmounted = true;
            source.cancel("Cancelling in cleanup");
        };
        }
    ,[]);

    return (
    <Flex w="full" h="full">
    {loading 
        ? <Loading height="100%"/>
        : showGarages.length
            ? <Flex w="full" pt="3" justify="space-between" direction="column" h="full" pos="relative" >
                <SimpleGrid minChildWidth="295px" spacing="30px" px={[0,2]} w="full" gridAutoRows={"minmax(200px, min-content)"}>
                {
                showGarages.map((garage) => {
                    return (
                        <GarageSearchResultCard key={garage.id} garage={garage} bookGarage={bookGarage} />
                    )
                })
                }
                </SimpleGrid>
                <Flex pb="4">
                    <PageControl totalItems={filteredGarages.length} itemsPerPage={itemsPerPage} page={page} onPageChange={onPageChange} pagLoadingState={loading} />
                    {
                        width > mdBreakpoint
                            ? <Button colorScheme="teal" mt="6" onClick={()=>onOpen()} aria-label="Edit Search" rightIcon={<FontAwesomeIcon icon={faSearchLocation} />}> Edit </Button>
                            : <IconButton colorScheme="teal" mt="6" aria-label="Edit Search" icon={ <FontAwesomeIcon icon={faSearchLocation} /> } onClick={()=>onOpen()} />
                            
                    }
                </Flex>
                <UserEditSearchForm isOpen={isOpen} onClose={onClose} searchTerms={searchTerms} onSubmit={onSubmit} />
            </Flex>
            : <VStack justify="space-between" w="full" >
                <NoResults height="100%" />
                <Flex justify="flex-end" w="full"><IconButton colorScheme="teal" mt="6" onClick={()=>onOpen()} aria-label="edit-search" icon={<FontAwesomeIcon icon={faSearchLocation} />} /></Flex>
                <UserEditSearchForm isOpen={isOpen} onClose={onClose} searchTerms={searchTerms} onSubmit={onSubmit} />
            </VStack>
    }
    </Flex>
    )
}