import React, { useEffect, useRef, useState } from "react";
import { IconButton, Input, InputGroup, InputLeftAddon, InputRightAddon } from "@chakra-ui/react";
import { faSearch, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import useViewport from "../Hooks/Viewport";

interface SearchProps {
    searchFn: (searchString: string) => void,
    searchLeftStart: string
}

function SearchBar({searchFn, searchLeftStart}:SearchProps){

    const inputRef = useRef<null | HTMLElement>(null);
    const [searchText,setSearchText]=useState<string>('');
    const [ isSearchOpen, setIsSearchOpen ] = useState(false);
    const { width } = useViewport();
    const breakpoint = 768;
    const mdBreakpoint = 480;

    // useEffect(() => {
    //     setSearchText(text);
    // }, [text]);

    useEffect(() => {
        if (isSearchOpen) {
            inputRef.current?.focus();
        }
    }, [isSearchOpen]);

    const variants = {
        open: { width: "100%", left: "0px", right: "16px", zIndex: 1, background: "#CBD5E0", padding: "0.5rem"},
        closed: { width: "40px", left: `${searchLeftStart}` , background: "#fff"},
    }

    return (
        width < breakpoint 
        ? <motion.div animate={isSearchOpen ? "open" : "closed" } transition={{type:"spring", bounce:0, duration:0.2}} variants={variants} style={{position:"absolute", display:"flex", alignItems:"center", overflow:"hidden", borderRadius: "0.5rem"}}  >
            <IconButton mr="2" aria-label="Search" onClick={() => setIsSearchOpen(!isSearchOpen)} icon={<FontAwesomeIcon size="lg" icon={isSearchOpen ? faTimes : faSearch} />} />
            <Input
                _focus={{
                    background: "gray.100",
                }}
                ref={inputRef as any}
                placeholder="Search"
                variant="filled"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onSubmit={() => {searchFn(searchText); setIsSearchOpen(false);setSearchText('')}}
                onKeyPress={(e) => {if(e.key==='Enter'){searchFn(searchText); setIsSearchOpen(false);setSearchText('')}}}
                />
        </motion.div>
        : <InputGroup>
            <InputLeftAddon onClick={e=>searchFn(searchText)} children={<FontAwesomeIcon icon={faSearch}/>} _hover={{cursor:"pointer"}} />
            <Input 
                placeholder="Search" 
                variant="filled" 
                w={["45vw", "40vw", "56"]} 
                value={searchText}
                onChange={(e)=>setSearchText(e.target.value)}
                onKeyPress={(e)=>{if(e.key==='Enter'){(e.target as HTMLInputElement).blur();searchFn(searchText);}}}
                />
            {searchText !=="" && <InputRightAddon
                onClick={()=>{
                    setSearchText('');
                    searchFn('');
                }}
                children={<FontAwesomeIcon icon={faTimesCircle}/>} 
                _hover={{cursor:searchText===''?"auto":"pointer"}} />}
        </InputGroup>
    )
}

export default SearchBar