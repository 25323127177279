import React, { useState, useEffect } from 'react';
import firebase, { db } from "../../firebase";
import DbAttachment from '../Attachments/DbAttachment';

import {
    Box,
    Center,
    useColorModeValue,
    Text,
    Stack,
    Image,
    Tag,
    Wrap,
    useDisclosure,
    Flex,
    IconButton,
    Skeleton,
    VStack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
} from '@chakra-ui/react';

import placeHolder from '../../placeholder.png'
import DbGarage from './DbGarage';


import { faAddressCard as addressIcon, faPhone as phoneIcon, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';

import ServicesList from '../Services/ServicesList';
import { AnimatePresence, motion } from 'framer-motion';
import { PreloadImage } from '../Common/preloadImage';
import Reviews from './Reviews';
import useViewport from '../Hooks/Viewport';
import Sheet from 'react-modal-sheet';
import GarageContent from './GarageContent';
import { RemoveScroll } from 'react-remove-scroll';
import GarageFull from './GarageFull';
import { formatPhoneNumber } from '../Helpers/phoneFormatting';

const imageHeight = "150px";

export type UpdateGarageFn=(garage:DbGarage)=>void;
export type DeleteGarageFn=(garageId:string)=>void;

interface CardProps{
    garage: DbGarage,
    updateGarage: UpdateGarageFn,
    deleteGarage: DeleteGarageFn,
}

interface Address {
    line1: string,
    city: string,
    state: string,
    zip: string,
}


export default function GarageCard({garage, updateGarage, deleteGarage}:CardProps) {

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [ actions, setActions ] = useState(false)
    const [ image, setImage] = useState<string>();
    const [ imageLoading, setImageLoading ] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const { width } = useViewport();
    const mdBreakpoint = 480;

    /** get the first-ordered image url from the database */
    async function getImage() {
        const garageImagesSubCollection=db.collection("garages").doc(garage.id).collection("images");
        const querySnapshot=await garageImagesSubCollection.where("order","==",0).get(); // only need the first-ordered item
        if(!querySnapshot.empty){
            const doc=querySnapshot.docs[0];
            const item:DbAttachment=doc.data() as DbAttachment;
            setImage(item.url);
        }
        setImageLoading(false);
    }

    // called on startup to load the card image
    useEffect(()=>{
        getImage();
    },[]);


    const ServiceChip = (service: string) => {
        return (
            <Tag key={service} minW={'initial'} borderRadius="full" size="sm">
                {service}
            </Tag>
        )
    }

    const createAddress = (address:string) => {
        try{
            const fullAdd:Address = JSON.parse(address);
            return (
                <>
                <Text>{fullAdd.line1}</Text>
                <Text>{`${fullAdd.city}, ${fullAdd.state} ${fullAdd.zip}`}</Text>
                </>
            )
        }catch(error:any){
            console.error(`while parsing "${garage.name}" address ${address}`);
            console.error(error);
        }
        return '';
    }


    const ActionButtons = () => {
        return (
            <AnimatePresence>
                <motion.div style={{position:"absolute", top:"16px", right:"16px", zIndex:10}}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <IconButton mr={2} aria-label="edit" icon={<EditIcon/>} />
                    <IconButton aria-label="delete" icon={<DeleteIcon/>} />
                </motion.div>
            </AnimatePresence>
        )
    }

    return (
    <>
    <Center>
        <Box
            onClick={onOpen}
            onMouseEnter={ () => setActions(true) }
            onMouseLeave={ () => setActions(false)}
            cursor={'pointer'}
            position={'relative'}
            height={'100%'}
            w="full"
            maxW={{base: "450px", md: "330px"}}
            boxShadow={["md", "md", "none"]}
            _after={{
                md: {
                    rounded:'md',
                    content: `""`,
                    boxShadow:'2xl',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    transition: 'box-shadow 0.3s ease-in-out',
                }
            }}
            _hover={{
                _after:{
                    boxShadow:'md',
                }
            }}
        >
            {/* {actions && <ActionButtons/>
            } */}
            <Box
                maxW={{base:"450px", md:"330px"}}
                w={'full'}
                bg={useColorModeValue('white', 'gray.900')}
                rounded={'md'}
                // p={6}
                pt={[6,6,6]}
                px={[6,6,6]}
                pb={[0,0,6]}
                overflow={'hidden'}
                transition={'0.3s'}
                position={'relative'}
            >
                <Box
                    bg={'gray.100'}
                    mt={-6}
                    mx={-6}
                    mb={[3,3,6]}
                    pos={'relative'}>
                    {imageLoading 
                        ? <Skeleton height={imageHeight} w={"full"} />  
                        : image 
                            ? <PreloadImage height={imageHeight} loadingImages={imageLoading} src={image} /> 
                            : <Image src={placeHolder} height={imageHeight} fit="cover" width="full" />
                    
                    }
                </Box>
                <Stack>
                    <Text
                        color={'green.500'}
                        textTransform={'uppercase'}
                        fontWeight={900}
                        fontSize={'sm'}
                        letterSpacing={1.1}>
                        {garage.name}
                    </Text>
                    { Reviews(garage) }
                    <Flex>
                        <Box mr={2} color={'gray.500'}><FontAwesomeIcon icon={addressIcon} /></Box>
                        <VStack spacing={0} align={"flex-start"} color="gray.500" lineHeight="1.3">
                            {createAddress(garage.address)}
                        </VStack>
                    </Flex>
                </Stack>
                {garage.phone && <Tag borderRadius="full" colorScheme="green" mt={2}>
                    <Box mr={1}> <FontAwesomeIcon icon={phoneIcon} /></Box>                    
                    <Text whiteSpace="nowrap" fontWeight={600}>{formatPhoneNumber(garage.phone)}</Text>
                </Tag>}
                {/* <Wrap mt={4}> */}
                    {/* {garage.services.map((service) =>  ServiceChip(service))} */}
                    <ServicesList single serviceIdList={garage.services} />
                {/* </Wrap> */}
            </Box>
        </Box>
    </Center>
    <GarageFull isOpen={isOpen} onClose={onClose} editMode={editMode} setEditMode={setEditMode} garage={garage} updateGarage={updateGarage} deleteGarage={deleteGarage}  />
    {/* {isOpen && width > mdBreakpoint
    ? <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" isCentered={editMode ? false: true} size={editMode ? "3xl" : "lg"}>
            <ModalOverlay />
                <ModalContent overflow="hidden">
                <IconButton aria-label="Close" onClick={onClose} icon={<FontAwesomeIcon icon={faTimes}/>} pos="absolute" top={2} right={2} />
                <ModalBody p={0}>
                    <GarageContent garage={garage} editable={false} isOpen={isOpen} onClose={onClose} updateGarage={updateGarage} deleteGarage={deleteGarage} editMode={editMode} setEditMode={setEditMode}  />
                </ModalBody>
            </ModalContent>
        </Modal>
    : <Sheet rootId="root" isOpen={isOpen} onClose={onClose} >
        <RemoveScroll>
        <Sheet.Container>
            <Sheet.Header></Sheet.Header>
            <Sheet.Content>
                <GarageContent garage={garage} editable={false} isOpen={isOpen} onClose={onClose} updateGarage={updateGarage} deleteGarage={deleteGarage} editMode={editMode} setEditMode={setEditMode}  />                
            </Sheet.Content>
        </Sheet.Container>
        </RemoveScroll>
        <Sheet.Backdrop onTap={onClose} style={{touchAction:"none"}} />
    </Sheet>
    } */}
    </>
    );
}

