import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import firebase, { auth, db, functions } from '../../firebase';
import ServicesList from '../Services/ServicesList';
import AuthContext from '../Account/AuthContext';
import Attachments, {Uploader} from '../Attachments/Attachments';
import Garages from '../Garages/Garages';
import { Button, InputText, InputTextLabel, InputTextarea, InputTextareaLabel, ErrorText, StyledCheckbox } from '../Common/CommonStyledControls'
import SchedulingInfo from '../Calendar/schedulingInfo';
import CalendarScheduler from '../Calendar/CalendarScheduler';
import AddressForm from '../Common/AddressForm';
import InsuranceProvidersList from '../InsuranceProviders/InsuranceProvidersList';
import { DbWorkorderTypeData } from './DbWorkorderType';
import instantBookIcon from '../Common/InstantBook.svg';
import DbGarage from '../Garages/DbGarage';


/** You can use this for testing the form without adding anything
 * to the database. ALWAYS SET =fale WHEN CHECKING IN CODE!!
 */
const DisableDatabaseWrite=false;


const StyledCreateWorkorder=styled.div`
  text-align: center;
  background-color:rgba(106,102,125,0.66);
  width:100vw;
  min-height:100vh;
  position: absolute;
  box-shadow: inset 0 0 40px rgb(72,69,90);
`;

const WorkorderForm=styled.div`
  position: relative;
  display: inline-block;
  border: 1px solid black;
  border-radius: 30px;
  padding: 0 15px;
  text-align: center;
  overflow: hidden;
  margin-top:100px;
  width: 66%;
  background-color: white;
  box-shadow: 0 0 40px rgb(72,69,90);
`;

const InputText5050=styled(InputText)`
    display:inline-block;
    box-sizing: border-box;
    width:50%;
    border-right: 10px solid rgba(0,0,0,0);
`;

const Heading1=styled.h1`
     border-bottom: 1px solid black;
     margin:30px 0 0 0;
     font-size:16pt;
     font-weight:800;
`;

const Heading2=styled.h2`
     border-bottom: 1px solid black;
     margin:30px 0 0 0;
     font-size:14pt;
     font-weight:600;
`;

const Heading3=styled.h3`
     border-bottom: 1px solid black;
     margin:30px 0 0 0;
     font-size:14pt;
     font-weight:600;
     border: none;
`;

const IndentBlock=styled.div`
    margin: 0 40px;
`;

const InstantBookText=styled.div`
    display: inline-block;
    color: #0a0;
    font-weight: 600;
`;

const disabledStyle={
    color:"#555",
    backgroundColor:"#bbb"
};


/** utility to compare two arrays for being identical */
function compareArrays(array1:any[],array2:any[]) {
    if (array1.length!==array2.length) return false;
    array1=array1.slice();
    array1.sort();
    array2=array2.slice();
    array2.sort();
    for (let i=0; i<array1.length; i++) {
      if(array1[i]!==array2[i]) {
        return false;
      }
    }
    return true;
}


/** A component to add/create-new work order */
function CreateWorkOrder(){

    const [firstName,setFirstName]=useState<string>('');
    const [lastName,setLastName]=useState<string>('');
    const [email,setEmail]=useState<string>('');
    const [instantBook,setInstantBook]=useState(true);
    const [phone,setPhone]=useState<string>('');
    const [address,setAddress]=useState<string>('');
    const [altPickupPerson,setAltPickupPerson]=useState('');
    const [description,setDescription]=useState<string>('');
    const [error,setErrors]=useState<string>('');
    const [dbAttachmentCollection,setDbAttachmentCollection]=useState<any>();
    const [storageFolder,setStorageFolder]=useState<string>('');
    const [selectedServices,setSelectedServices]=useState<string[]>([]);
    const [selectedServices2,setSelectedServices2]=useState<string[]>([]);
    const [progress,setProgress]=useState<number>(0);
    const [step,setStep]=useState<number>(0);
    const [actionsCollection,setActionsCollection]=useState<firebase.firestore.CollectionReference|undefined>();
    const [schedulingInfo,setSchedulingInfo]=useState<SchedulingInfo>();
    const [insurancePolicy,setInsurancePolicy]=useState('');
    const [insuranceProvider,setInsuranceProvider]=useState('');
    const [allowInsurance,setAllowInsurance]=useState(false);
    const [hasInsuranceInfo,setHasInsuranceInfo]=useState(false);
    const [addressValid,setAddressValid]=useState(false);

    const selectedGarage=useRef<DbGarage>();
    const dbDocRef=useRef<firebase.firestore.DocumentReference|undefined>();
    const workorderNo=useRef(createWorkorderNumber());
 
    /** attachments function used to initiate an upload */
    const attachmentUpload=useRef<Uploader|undefined>(undefined);

    const history=useHistory();
    const collectionsRef=db.collection("work_orders");

    const Auth = useContext(AuthContext);

    // this is set up to run once on component creation
    useEffect(()=>{
        if(Auth.firstName!==undefined) setFirstName(Auth.firstName);
        if(Auth.lastName!==undefined) setLastName(Auth.lastName);
        if(Auth.phone!==undefined) setPhone(Auth.phone);
        if(Auth.email!==undefined) setEmail(Auth.email);
        if(Auth.address!==undefined) setAddress(Auth.address);
    }
    ,[]); // eslint-disable-line react-hooks/exhaustive-deps

    /** when insurancePolicy or insuranceProvider changes, we can
     * determine whether hasInsuranceInfo is true or not
     */
    useEffect(()=>{
        setHasInsuranceInfo((insurancePolicy.length>0)&&(insuranceProvider.length>0));
    },[insurancePolicy,insuranceProvider]);
    
    /** create a unique workirder number
     * this is based upon the current time in milliseconds, plus a random value
     */
    function createWorkorderNumber(){
        return ((Date.now()-1630534917869)*100)+Math.floor(Math.random()*100);
    }


    async function onStep1FormSubmit(event:React.FormEvent<HTMLFormElement>) {
        event.preventDefault(); // prevents the <form> from going to another page
        if(DisableDatabaseWrite){
            onProgress(100);
            return;
        }
        try{
            // upload a new workorder doc
            let dbFile:DbWorkorderTypeData={
                userId: Auth.id,
                workorderNo:workorderNo.current,
                firstName,
                lastName,
                email,
                phone,
                description,
                hasInsurance:insurancePolicy.trim()!==''&&insuranceProvider.trim()!=='',
                insurancePolicy,
                insuranceProvider,
                closed:false,
                selectedServiceIds:selectedServices,
                cancelled:false,
            };
            if(dbDocRef.current===undefined){
                const dbRef=collectionsRef.doc();
                dbDocRef.current=dbRef;
                dbFile.createdDate=moment().unix();
                await dbDocRef.current.set(dbFile);
                // adjust the counts (starting off with no insurance)
                const countersRef=collectionsRef.doc('counters');
                await countersRef.update("total",firebase.firestore.FieldValue.increment(1))
                await countersRef.update("open_noinsurance",firebase.firestore.FieldValue.increment(1))
                // select this as the location to add actions
                setActionsCollection(dbDocRef.current.collection("actions"));
            } else {
                await dbDocRef.current.update(dbFile);
            }
            onProgress(100);
        } catch(err:any) {
            setErrors(err.message);
        }
    }

    async function onStep4FormSubmit(event:React.FormEvent<HTMLFormElement>) {
        event.preventDefault(); // prevents the <form> from going to another page
        if(DisableDatabaseWrite){
            onProgress(100);
            return;
        }
        try {
            // update the user info if necessary
            var userInfoChanged=false;
            if(Auth.firstName!==firstName){
                userInfoChanged=true;
                Auth.firstName=firstName;
            }
            if(Auth.lastName!==lastName){
                userInfoChanged=true;
                Auth.lastName=lastName;
            }
            if(Auth.phone!==phone){
                userInfoChanged=true;
                Auth.phone=phone;
            }
            if(Auth.address!==address){
                userInfoChanged=true;
                Auth.address=address;
            }
            if(Auth.email!==email){
                userInfoChanged=true;
                Auth.email=email;
            }
            if(userInfoChanged){
                // only hit the database if something has changed
                const userDocRef=db.collection("users").doc(Auth.id);
                await userDocRef.update({firstName:firstName,lastName:lastName,phone:phone,address:address,email:email});
            }
            // update the workorder
            if(dbDocRef.current===undefined) throw new Error("Lost handle to document.");
            var dbFile={
                firstName,
                lastName,
                email,
                phone,
                description,
                insurancePolicy,
                insuranceProvider,
                altPickupPerson
            }
            await dbDocRef.current.update(dbFile);
            // adjust the counts
            const countersRef=collectionsRef.doc('counters');
            if(hasInsuranceInfo){
                await countersRef.update("open_noinsurance",firebase.firestore.FieldValue.increment(-1))
                await countersRef.update("open_insurance",firebase.firestore.FieldValue.increment(1))
            }
            // upload the attachments
            let storageLocation=`/work_orders/${dbDocRef.current.id}`;
            setDbAttachmentCollection(dbDocRef.current.collection("attachments"));
            setStorageFolder(storageLocation);
            if(attachmentUpload.current===undefined){
                setErrors("Attachment uploader missing.");
            } else{
                await attachmentUpload.current(onProgress);
            }
            // will go to next step after progress has finished
        } catch(err:any) {
          setErrors(err.message);
        }
      };

    /** called to watch file upload progress */
    function onProgress(percent:number){
        // update the ui
        setProgress(percent); 
        // redirect to another step
        if(percent>=100){
            next();
        }
    }

    /** called when the user selects an appointment from the calendar */
    async function onAppointmentSelect(appointment:[moment.Moment,moment.Moment]){
        if(DisableDatabaseWrite){
            next();
            return;
        }
        if(dbDocRef.current===undefined){
            console.error('Somehow attempting to set appointment for undefined database record');
        } else {
            const jsonAppointment=[appointment[0].unix(),appointment[1].unix()];
            await dbDocRef.current.update({
                confirmed:selectedGarage.current!==undefined&&selectedGarage.current.instantBook,
                appointment:jsonAppointment
            });
            // send emails to both the user and the garage
            if(selectedGarage.current!==undefined){
                const sendEmail = functions.httpsCallable('sendEmail');
                const token = await auth.currentUser?.getIdToken();
                await sendEmail({
                    token: token,
                    name: Auth.firstName,
                    email: Auth.email,
                    template: 'userAppointment',
                    garageId:selectedGarage.current.id,
                    workorderId: dbDocRef.current.id // attach workorder calendar event
                });
                if(selectedGarage.current.notificationEmail!==undefined&&selectedGarage.current.notificationEmail!==''){
                    await sendEmail({
                        token: token,
                        name: selectedGarage.current.name,
                        email: selectedGarage.current.notificationEmail,
                        template: 'garageAppointment',
                        workorderId: dbDocRef.current.id // attach workorder calendar event
                    });
                }
            }
            // go to the next step
            next();
        }
    }

    /** will be called when a garage determines it has been selected */
    function onGarageSelected(garage:DbGarage){
        if(DisableDatabaseWrite){
            return
        }
        if(dbDocRef.current!==undefined){
            let theUpdate:any={};
            if(garage!==selectedGarage.current){
                selectedGarage.current=garage;
                if(dbDocRef.current!==undefined){
                    // update the selected garage in the database
                    theUpdate.selectedGarageId=selectedGarage.current;
                }
            }
            if(dbDocRef.current!==undefined&&!compareArrays(selectedServices,selectedServices2)){
                // selection has changed, so we need to update the workorder
                theUpdate.selectedServiceIds=selectedServices2;
                setSelectedServices(selectedServices2);
            }
            if(Object.keys(dbDocRef).length>0){
                dbDocRef.current.update(theUpdate);
            }
        }
    }

    /** Component for step 1 */
    function Step1() {
        return (
            <form onSubmit={(e) => onStep1FormSubmit(e)}>
                {/*<Heading2>Garage Search Criteria</Heading2>*/}
                <IndentBlock>
                    <Heading3>Find Garages Nearest</Heading3>
                    <AddressForm zipcodeRequired={true} address={address} onChange={(addr)=>setAddress(addr)} onValidChange={setAddressValid}></AddressForm>
                    <div>&nbsp;</div>
                    <StyledCheckbox checked={instantBook} onClick={()=>setInstantBook(!instantBook)}/>&nbsp;Garage must support <InstantBookText>Instant Book <img alt="" src={instantBookIcon} style={{display:"inline-block",height:"20px"}}/></InstantBookText>
                    <Heading3>Services Required:</Heading3>
                    <ServicesList serviceIdSelections={selectedServices} onChange={(services)=>{setSelectedServices(services);setSelectedServices2(services)}} />
                </IndentBlock>

                <Button type="submit" readOnly={!addressValid}>Search</Button>
                <p>{progress>0&&progress<100?`${progress}%`:''}</p>
            </form>
        );
    }

    /** Component for step 2 */
    function Step2() {
        return (
            <form>
                <InputTextareaLabel>Garages that may meet your needs:</InputTextareaLabel>
                <div>(List is ordered by closest distance.)</div>
                <Garages distanceFromAddress={address} matchingServices={selectedServices2} requireInstantBook={instantBook} actionsCollection={actionsCollection} onGarageSelected={onGarageSelected} onSchedule={(info)=>{setSchedulingInfo(info);next();}} />
                <p>&nbsp;</p>
                <Button onClick={(e)=>prev()}>Back</Button>
            </form>
        );
    }

    /** Component for step 3 */
    function Step3() {
        return schedulingInfo===undefined||selectedGarage.current===undefined?'':(
            <form>
                <InputTextareaLabel>Book appointment with: {schedulingInfo.name}</InputTextareaLabel>
                <CalendarScheduler onSelect={onAppointmentSelect} garageCalendar={selectedGarage.current.garageCalendar} limitToHours={selectedGarage.current.limitToHours}/>
                <Button style={{display:"inline-block",width:"50%"}} onClick={(e)=>prev()}>Back</Button>
                <Button style={{display:"inline-block",width:"50%"}} onClick={(e)=>next()}>Next</Button>
            </form>
        );
    }

    /** Component for step 4 */
    function Step4() {
        return (<form onSubmit={(e) => onStep4FormSubmit(e)}>
            <Heading2>Carera Advantage</Heading2>
            <p>For your convenience, we can contact your insurance company and sort out your claim details for you.</p>
            <p>As an added bonus, will even cover your deductable up to $500!</p>
            <p>Would you like to do that?</p>
            <div>&nbsp;</div>
            <StyledCheckbox checked={allowInsurance} onClick={()=>setAllowInsurance(!allowInsurance)}/>&nbsp;Allow Carera to contact my insurance on my behalf.
                
            <Heading2>Insurance Info</Heading2>
            <IndentBlock style={allowInsurance?{}:disabledStyle}>
            <InputTextareaLabel>Policy Info:</InputTextareaLabel>
            <div>
                <InputTextLabel htmlFor="name">Name:</InputTextLabel>
            </div>
            <div>
                <InputText5050
                    onChange={(e) => setFirstName(e.target.value)}
                    name="firstName"
                    value={firstName}
                    type="text"
                    placeholder="First"
                />
                <InputText5050
                    onChange={(e) => setLastName(e.target.value)}
                    name="lastName"
                    value={lastName}
                    type="text"
                    placeholder="Last"
                />
            </div>
            <div>
                <InputTextLabel htmlFor="email">Email:</InputTextLabel>
                <InputText
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    value={email}
                    type="email"
                    placeholder="myName@email.com"
                />
            </div>
            <div>
                <InputTextLabel htmlFor="phone">Phone:</InputTextLabel>
                <InputText
                    onChange={(e) => setPhone(e.target.value)}
                    name="phone"
                    value={phone}
                    type="phone"
                    readOnly={!allowInsurance}
                    placeholder="(555) 555-5555"
                />
            </div>
            <div>
                <InputTextLabel htmlFor="altPickupPerson">Alternate drop-off/pick-up person:</InputTextLabel>
                <InputText
                    onChange={(e) => setAltPickupPerson(e.target.value)}
                    name="altPickupPerson"
                    value={altPickupPerson}
                    type="text"
                    readOnly={!allowInsurance}
                    placeholder="Name (optional)"
                />
            </div>
            <div>
                <InputTextLabel htmlFor="insuranceProvider">Insurance Provider:</InputTextLabel>
                <InsuranceProvidersList name="insuranceProvider"
                    required={allowInsurance} 
                    readOnly={!allowInsurance} insuranceProviderSelection={insurancePolicy} onChange={setInsuranceProvider} />
            </div>
            <div>
                <InputTextLabel htmlFor="insurancePolicy">Insurance Policy:</InputTextLabel>
                <InputText name="insurancePolicy" missingRequired={allowInsurance&&insurancePolicy.length<1}
                    readOnly={!allowInsurance} value={insurancePolicy} onChange={e=>setInsurancePolicy(e.target.value)} />
            </div>
            <div>
                <InputTextareaLabel htmlFor="description">Description of work required:</InputTextareaLabel>
                <InputTextarea
                    onChange={(e) => setDescription(e.target.value)}
                    name="description"
                    value={description}
                    readOnly={!allowInsurance||!hasInsuranceInfo}
                    style={allowInsurance&&hasInsuranceInfo?{}:disabledStyle}
                    placeholder="Example: I have a scratched fender and I need my flat tire patched."
                />
            </div>
            <InputTextareaLabel>Attachments:</InputTextareaLabel>
            <Attachments
                dbAttachmentCollection={dbAttachmentCollection} storageFolder={storageFolder}
                acceptFileTypes="image/*,.jpg,.jpeg,.png,.pdf,.doc,.docx,.xls,.xslx,.tiff,.ps" 
                maxFileSize={15} maxTotalSize={50} maxAttachments={10} 
                readOnly={!allowInsurance||!hasInsuranceInfo}
                onUploaderReady={(uploader)=>{attachmentUpload.current=uploader}} />
            </IndentBlock>
            <Button type="submit" readOnly={allowInsurance?hasInsuranceInfo:true}>Done!</Button>
            <p>{progress>0&&progress<100?`${progress}%`:''}</p>
        </form>);
    }

    const steps=[Step1,Step2,Step3,Step4];

    /** go to the next page */
    function next() {
        if(step>=steps.length-1){
            history.push('/dashboard');
        }else{
            setStep(step+1);
        }
    }

    /** go to the previous page */
    function prev() {
        if(step<1){
            history.push('/dashboard');
        }else{
            setStep(step-1);
        }
    }

    return (
        <StyledCreateWorkorder><WorkorderForm>
            <Heading1>Create Work Order</Heading1>
            { steps[step]() }
            <ErrorText>{error}</ErrorText>
            <p>Step {step+1} of {steps.length}</p>
        </WorkorderForm></StyledCreateWorkorder>
    );
}


export default CreateWorkOrder;