import React, { useContext, useEffect, useRef, useState } from 'react'
import AuthContext from "../Account/AuthContext";
import firebase, { auth, db, functions } from '../../firebase';
import DbWorkorderType, { DbWorkorderTypeData, formatWorkorderNo } from './DbWorkorderType';
import { Box, Flex, SimpleGrid } from '@chakra-ui/layout';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Header from '../Navigation/HeaderLisa';
import Footer from '../Navigation/Footer';
import { chakra } from '@chakra-ui/system';
import Loading from '../Common/Loading';
import { DbGarage } from '../Garages/DbGarage';
import moment from 'moment-timezone';


export default function ViewWorkorders() {
    const Auth = useContext(AuthContext);
    const collectionsRef=db.collection("work_orders");
    const [loading, setLoading] = useState(true);

    const [workorders, setWorkorders] = useState<DbWorkorderType[]>();

    const workordersCollection=db.collection("work_orders");

    useEffect(() => {
        async function getOrders(){
            var querySnapshot = await collectionsRef.where("userId","==",Auth.id).get();
            if(!querySnapshot.empty){
                let orders:DbWorkorderType[] = [];
                querySnapshot.forEach((sub) => {
                    orders.push({...sub.data(),'id':sub.id} as DbWorkorderType);
                })
                setWorkorders(orders);
            }
        }
        getOrders();
    }, [])

    useEffect(() => {
        if(workorders?.length !== undefined && workorders.length > 0){
            setLoading(false);
        }
    }, [workorders])

    async function cancelWorkorder(workorder:DbWorkorderType) {
        var countersDoc;
        try{
            countersDoc=await workordersCollection.doc('counters');
        }catch(error:any){
            console.error("Error getting workorder counters");
            console.error(error);
            return;
        }
        const closed:boolean|undefined = workorder.closed;
        const hasInsurance:boolean|undefined = workorder.hasInsurance;
        if(closed!==undefined&&!closed){
            if(hasInsurance!==undefined && hasInsurance){
                await countersDoc.update("open_insurance",firebase.firestore.FieldValue.increment(-1));
                await countersDoc.update("cancelled_count",firebase.firestore.FieldValue.increment(1));
            } else if(hasInsurance!==undefined && !hasInsurance){
                await countersDoc.update("open_noinsurance",firebase.firestore.FieldValue.increment(-1));
                await countersDoc.update("cancelled_count",firebase.firestore.FieldValue.increment(1));
            }
        }
        
        if(workorder.id===undefined){
            throw new Error("BOGUS workorder with no id!");
        }
        // modify the workorders list
        const originalDate=workorder.appointment===undefined||workorder.appointment===null?0:workorder.appointment[0];
        const dateCancelled=moment().unix();
        const newValues={
            "appointment":null,
            "cancelled":[dateCancelled,originalDate]};
        setWorkorders(workorders?.map((wo)=>{
            if(wo.id==workorder.id) {
                wo["appointment"]=undefined;
                wo["cancelled"]=[newValues.cancelled[0],newValues.cancelled[1]];
            }
            return wo;
        }));
        // mark the workorder cancelled
        const workorderDoc=collectionsRef.doc(workorder.id);
        await workorderDoc.update(newValues).then(async()=>{
            // send a notification to the user and garage
            const sendEmail = functions.httpsCallable('sendEmail');
            const token = await auth.currentUser?.getIdToken();
            try {
                // send a new email to user
                var emailParams:any={
                    token: token,
                    name: workorder.firstName,
                    email: workorder.email,
                    template: 'userAppointmentCancelled',
                    garageId: workorder.selectedGarageId,
                    workorderId: workorder.id
                };
                sendEmail(emailParams);
                // send a new email to garage
                const garagesCollection=db.collection("garages");
                const garageDoc=await garagesCollection.doc(workorder.selectedGarageId).get();
                const garage={...garageDoc.data(),id:garageDoc.id} as DbGarage;
                emailParams={
                    token: token,
                    name: garage.name,
                    email: garage.notificationEmail,
                    template: 'garageAppointmentCancelled',
                    workorderId: workorder.id
                };
                sendEmail(emailParams);
            } catch(error) {
                console.error(error);
            }
        })
    }

    function WorkorderCard(workorder:DbWorkorderTypeData){
        const [isOpen, setIsOpen] = useState(false);
        const onClose = () => setIsOpen(false);
        const cancelRef = useRef<HTMLButtonElement>(null);

        return (
        <>
        <Flex
        shadow="lg"
        rounded="lg"
        maxW="md"
        direction="column"
        px={6}
        py={4}
        pos={"relative"}
        >
            {
            workorder.cancelled !== false &&
                    <Box pos={"absolute"} w={"200px"} h={"200px"} overflow={"hidden"} top={"-10px"} left={"-10px"} _before={{"position":"absolute", "content":"''", "display":"block", "border":"5px solid #c24c4c","borderLeftColor":"transparent", "borderTopColor":"transparent", "top":"0", "right": "92px"}} _after={{"position":"absolute", "content":"''", "display":"block", "border":"5px solid #c24c4c", "borderTopColor":"transparent", "borderLeftColor":"transparent", "bottom":"92px", "left":"0"}} >
                        <Box as={"span"} pos={"absolute"} display={"block"} width={"170px"} padding={"10px 9px 7px 0"} backgroundColor={"red.400"} color={"#fff"} textAlign={"center"} right={"70px"} top={"20px"} transform={"rotate(-45deg)"} zIndex={"1"} textTransform={"uppercase"} fontSize={"smaller"} boxShadow={"md"} >
                            Canceled
                        </Box>
                    </Box>
            }
            {
            workorder.confirmed === false && workorder.cancelled === false &&
                    <Box pos={"absolute"} w={"200px"} h={"200px"} overflow={"hidden"} top={"-10px"} left={"-10px"} _before={{"position":"absolute", "content":"''", "display":"block", "border":"5px solid #a14cc2","borderLeftColor":"transparent", "borderTopColor":"transparent", "top":"0", "right": "92px"}} _after={{"position":"absolute", "content":"''", "display":"block", "border":"5px solid #a14cc2", "borderTopColor":"transparent", "borderLeftColor":"transparent", "bottom":"92px", "left":"0"}} >
                        <Box as={"span"} pos={"absolute"} display={"block"} width={"170px"} padding={"10px 9px 7px 0"} backgroundColor={"purple.400"} color={"#fff"} textAlign={"center"} right={"70px"} top={"20px"} transform={"rotate(-45deg)"} zIndex={"1"} textTransform={"uppercase"} fontSize={"smaller"} boxShadow={"md"} >
                            Pending
                        </Box>
                    </Box>
            }
            <chakra.h1 mb={"1"} fontSize="2xl">{workorder.selectedGarageName}</chakra.h1>
            <Flex alignItems={"center"}>
                <Text mr={"2"} fontSize={"sm"} color={"gray.400"} as="span">Appointment: </Text>
                {   
                    workorder.cancelled !== false && typeof workorder.cancelled !== "boolean" ? <chakra.h2>{moment.unix(workorder.cancelled[1]).format("MM/DD/YY hh:mm a")}</chakra.h2> : workorder.appointment !== undefined && workorder.appointment !== null && <chakra.h2>{moment.unix(workorder.appointment[0]).tz("America/Los_Angeles").format("ddd, MMM DD YYYY \\at h:mm A")}</chakra.h2>
                }
            </Flex>
            {
                workorder.workorderNo && <Flex alignItems={"center"}><Text mr="2" color={"gray.400"} as={"span"} fontSize={"sm"} >Order No:</Text><chakra.h2>{formatWorkorderNo(workorder.workorderNo)}</chakra.h2></Flex>
            }
            <Flex>
                {workorder.insuranceProvider && 
                    <>
                    <Text mr={"2"} fontSize={"sm"} color={"gray.400"} as="span">Insurance:</Text>
                    <chakra.h2 mr={"2"}>{workorder.insuranceProvider}</chakra.h2>
                    <chakra.h2>{workorder.insurancePolicy}</chakra.h2>
                    </>
                }
            </Flex>
            {
                workorder.description && 
                <>
                    <Text mt={"2"} fontSize={"sm"} color={"gray.400"} as="span">Notes</Text>
                    <chakra.h2 noOfLines={2}>{workorder.description}</chakra.h2>
                </>
            }
            {
                workorder.cancelled===false &&
                (<Button mt={4} borderRadius="sm" onClick={()=>setIsOpen(true)} ><FontAwesomeIcon icon={faTrash}/> &nbsp; Cancel Appointment</Button>)
            }
        </Flex>

        <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={cancelRef}
        >
        <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Cancel Appointment
                </AlertDialogHeader>

                <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        Go Back
                    </Button>
                    <Button colorScheme='red' onClick={()=>{cancelWorkorder(workorder);onClose();}} ml={3}>
                        Cancel
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
        </AlertDialog>
        </>
        )
    }
    
    return (
        <Flex w="100vw" minH="100vh" direction="column" justify="space-between" >
            <Box w="full" h="full" pb={12} >
                <Header />
                {
                    loading
                    ? <Loading height="100%" />
                    : <Box maxW="container.xl" w="full" h="calc(100% - 2rem - 70px)" margin="auto" >
                    {workorders!== undefined && workorders.length>0 &&
                        <SimpleGrid minChildWidth="295px" spacing="30px" px={[2,6]} w="full" gridAutoRows={"minmax(200px, min-content)"}  >
                            {workorders.map((workorder) => {
                                return <WorkorderCard key={workorder.workorderNo} {...workorder} />
                            })}
                        </SimpleGrid>
                    }
                </Box>
                }
            </Box>
            <Footer/>
        </Flex>
    )
}
