import React from 'react'
import {
    Badge,
    Box,
    Flex,
    Link,
    Menu,
    MenuButton
} from '@chakra-ui/react'

import { NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { active } from 'sortablejs';
import { NavSize } from './SidebarContext';

interface NavProps{
    icon?: any, 
    title:string, 
    // navSize: string, 
    navSize: NavSize,
    linkTo: string,
    color?: string,
    count?:number,
    fontSize?: string,
    active?: boolean
}

export function NavItem({ icon, title, navSize, linkTo, count, fontSize = "0.875em" }:NavProps) {
    return (
        <AnimatePresence>
            <Flex
                mt={2}
                flexDir="column"
                w="100%"
                alignItems={navSize === NavSize.Small ? "center" : "flex-start"}
            >
                <Menu placement="right">
                    <Link
                        strict
                        className="sidebarLink"
                        to={linkTo}
                        // onClick={onClose}
                        activeClassName="selected"
                        as={NavLink}
                        display={"flex"}
                        p={3}
                        borderRadius={"sm"}
                        _hover={{ textDecor: 'none', backgroundColor: "gray.200" }}
                        w={navSize === NavSize.Large ? "100%" : "initial"}
                    >
                        <MenuButton w="100%">
                            <Flex alignItems={"center"}>
                                {/* <Icon as={icon} fontSize="xl"/> */}
                                <Box pos="relative">
                                    {icon && <FontAwesomeIcon icon={icon} />}
                                    {count && <Box
                                        as="span"
                                        pos="absolute"
                                        top="2px"
                                        right="-1px"
                                        px={1.5}
                                        py={0.5}
                                        fontSize="xs"
                                        fontWeight="bold"
                                        lineHeight="none"
                                        color="red.100"
                                        transform="translate(50%,-50%)"
                                        bg="red.600"
                                        rounded="full"
                                        >
                                        {count}
                                        </Box>
                                    }
                                </Box>
                                {/* <Text lineHeight={"none"} ml={4} display={navSize === "small" ? "none" : "flex"} fontSize={"sm"} letterSpacing={"wide"}>{title}</Text> */}
                                {navSize === NavSize.Large ? (
                                    <motion.p 
                                    initial={{ opacity: 1 }}
                                    // animate={{ opacity: 1 }}
                                    // exit={{ opacity: 0 }}
                                    style={{marginLeft:"16px", display:"flex", fontSize: fontSize, letterSpacing:"1.1", textAlign:"left"}}>
                                    {title}
                                </motion.p>
                                ) : <div></div>
                                }
                            </Flex>
                        </MenuButton>
                    </Link>
                </Menu>
            </Flex>
        </AnimatePresence>
    )
}

export function SubNav({ active, icon, title, navSize, linkTo, color = "#000", count, fontSize = "0.875em" }:NavProps){
        return (
        <AnimatePresence>
            <Flex
                ml={navSize === NavSize.Small ? 0 : 4}
                // mt={1}
                flexDir="column"
                w={navSize === NavSize.Small ? "full" : "calc(100% - 16px)" }
                alignItems={navSize === NavSize.Small ? "center" : "flex-start"}
            >
                <Menu placement="right">
                    <Link
                        className={active ? "sidebarLink selected" : "sidebarLink"}
                        to={linkTo}
                        // onClick={onClose}
                        // activeClassName="selected"
                        as={NavLink}
                        display={"flex"}
                        px={3}
                        py={3}
                        borderRadius={"sm"}
                        _hover={{ textDecor: 'none', backgroundColor: "gray.200" }}
                        w={navSize === NavSize.Large ? "100%" : "initial"}
                    >
                        <MenuButton w="100%">
                            <Flex alignItems={"center"} fontSize="sm">
                                {icon && <FontAwesomeIcon icon={icon} color={color} />}
                                {navSize === NavSize.Large ? (
                                    <motion.div
                                    initial={{ opacity: 1 }}
                                    // animate={{ opacity: 1 }}
                                    // exit={{ opacity: 0 }}
                                    style={{marginLeft:"16px", display:"flex", fontSize:fontSize, letterSpacing:"1.1", textAlign:"left", width:"100%" }}>
                                    <Flex justify="space-between" w="full" lineHeight="1">
                                        {title}
                                        {count && <Badge>{count}</Badge>}
                                    </Flex>
                                </motion.div>
                                ) : <div></div>
                                }
                            </Flex>
                        </MenuButton>
                    </Link>
                </Menu>
            </Flex>
        </AnimatePresence>
        )
}