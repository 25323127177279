import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';

import { auth } from '../../firebase';
import { InputText, InputTextLabel, Button } from "../Common/CommonStyledControls";


const StyledPasswordReset=styled.div`
  text-align: center;
  background-color:rgba(106,102,125,0.66);
  width:100vw;
  height:100vh;
  position: absolute;
  box-shadow: inset 0 0 40px rgb(72,69,90);
`;

const ResetForm=styled.div`
  position: relative;
  display: inline-block;
  border: 1px solid black;
  border-radius: 30px;
  padding: 0 15px;
  text-align: center;
  overflow: hidden;
  margin-top:100px;
  width: 66%;
  background-color: white;
  box-shadow: 0 0 40px rgb(72,69,90);
`;


/** Component to handle password reset functionality. */
function PasswordReset() {
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);
  
  function onChangeHandler(event:React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    }
  }

  async function sendResetEmail(event:React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    try {
      await auth.sendPasswordResetEmail(email);
      setEmailHasBeenSent(true);
    } catch(err:any) {
      setError(err.message);
    }
  };

  return (
    <StyledPasswordReset><ResetForm>
      <h1>
        Reset your Password
      </h1>
      <div>
        <form action="">
          {emailHasBeenSent && (
            <div>
              An email has been sent to you!
            </div>
          )}
          {error !== null && (
            <div>
              {error}
            </div>
          )}
          <InputTextLabel htmlFor="userEmail">Email:</InputTextLabel>
          <InputText
            type="email"
            name="userEmail"
            id="userEmail"
            value={email}
            placeholder="Input your email"
            onChange={onChangeHandler}
          />
          <p></p>
          <Button onClick={sendResetEmail}>Send me a reset link</Button>
        </form>
        <Link to ="/login">
          &larr; back to login page
        </Link>
      </div>
    </ResetForm></StyledPasswordReset>
  );
};


export default PasswordReset;