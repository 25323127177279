import React from "react"
import { Link } from "react-router-dom"

function CareraLogo({fillColor = "#000", width="100%", height="100%", to="/"}:{fillColor?: string, width?: string, height?: string, to?:string}){

    return (
        <Link to={to}>
        <svg fill={fillColor} width={width} height={height} viewBox="0 0 640 246" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve">
            <g transform="matrix(0.1,0,0,-0.1,0,246)">
                <path d="M3310,2184C3136,2162 2970,2110 2808,2029C2754,2002 2710,1978 2710,1976C2710,1975 2758,1992 2818,2016C3055,2111 3217,2144 3445,2143C3615,2143 3783,2124 3990,2084C4115,2059 4329,2003 4385,1979C4413,1967 4410,1966 4325,1959C4143,1945 3950,1895 3820,1830C3749,1794 3744,1780 3814,1810C4026,1900 4414,1943 4815,1920C4925,1914 5024,1907 5035,1904C5051,1900 5052,1901 5039,1909C5012,1926 4749,1960 4589,1966C4437,1972 4433,1973 4325,2016C4147,2086 3967,2133 3760,2165C3656,2180 3377,2192 3310,2184Z"/>
                <path d="M3218,2090C3142,2077 3060,2050 2998,2019C2966,2003 2940,1988 2940,1986C2940,1984 2975,1997 3018,2016C3157,2076 3350,2093 3627,2070C3744,2061 3810,2040 3843,2003C3868,1974 3853,1965 3714,1929C3563,1889 3438,1882 3035,1893C2596,1904 2074,1897 1920,1876C1706,1847 1386,1764 1301,1714C1286,1705 1284,1702 1295,1706C1482,1772 1702,1822 1921,1846C2062,1862 2274,1862 3270,1845C3498,1841 3719,1874 3848,1930C3927,1965 3915,2011 3813,2062L3746,2095L3505,2097C3373,2098 3244,2095 3218,2090Z"/>
                <path d="M4790,1862C4790,1857 4817,1821 4850,1782L4911,1710L5085,1710L5137,1592L5013,1459C4888,1323 4834,1255 4906,1321C5135,1531 5180,1574 5180,1584C5180,1590 5166,1630 5148,1673L5116,1750L4934,1750L4897,1788C4877,1809 4860,1829 4860,1832C4860,1835 4911,1841 4973,1846C5066,1852 5078,1855 5045,1861C4991,1872 4790,1872 4790,1862Z"/>
                <path d="M1943,1776C1880,1756 1818,1716 1777,1671C1716,1604 1711,1580 1769,1632C1926,1774 2134,1785 2298,1660C2356,1616 2354,1634 2293,1692C2210,1773 2050,1811 1943,1776Z"/>
                <path d="M4160,1774C4102,1756 4028,1701 3990,1648C3948,1590 3953,1586 4006,1638C4032,1662 4083,1696 4119,1714C4179,1742 4194,1745 4285,1745C4376,1745 4391,1742 4450,1714C4486,1697 4533,1668 4555,1649C4583,1624 4590,1621 4582,1636C4561,1676 4469,1749 4414,1769C4349,1794 4229,1796 4160,1774Z"/>
                <path d="M1307,1656C1211,1638 1211,1627 1307,1632L1390,1637L1429,1590L1469,1542L1422,1536C1396,1533 1344,1528 1307,1524C1270,1520 1237,1513 1234,1509C1223,1491 1242,1462 1281,1434C1303,1419 1328,1395 1335,1381C1343,1367 1349,1361 1350,1369C1350,1388 1312,1447 1288,1464C1270,1477 1271,1478 1307,1484C1328,1487 1391,1492 1448,1496C1504,1499 1550,1505 1550,1509C1550,1526 1407,1670 1392,1669C1382,1668 1345,1662 1307,1656Z"/>
                <path d="M555,1016C415,972 323,880 285,746C255,642 278,519 345,428C453,281 658,229 821,308C856,325 896,348 910,359L935,379L911,404L888,429L847,399C757,334 623,323 524,373C460,406 387,486 366,547C317,690 380,852 511,925C560,952 573,955 660,955C744,955 761,952 806,928C833,914 862,894 870,885C882,871 885,871 907,891C919,904 930,917 930,921C930,937 835,994 778,1012C710,1033 614,1035 555,1016Z"/>
                <path d="M1580,993C1560,949 1269,306 1263,292C1259,282 1268,279 1299,282L1341,285L1384,380L1426,475L1631,478L1836,480L1880,380L1925,280L2009,280L1946,418C1912,493 1837,660 1779,787L1675,1020L1634,1020C1599,1020 1591,1016 1580,993ZM1718,746C1763,645 1800,559 1800,556C1800,553 1723,550 1629,550C1535,550 1460,554 1462,559C1489,629 1626,930 1631,930C1634,930 1674,847 1718,746Z"/>
                <path d="M2410,1020L2410,280L2479,280L2482,398L2485,515L2739,521L2820,400C2900,280 2900,280 2940,280C2962,280 2980,282 2980,285C2980,288 2944,342 2900,405C2856,468 2820,523 2820,527C2821,531 2844,549 2872,565C2946,607 2980,674 2980,774C2980,859 2959,911 2907,957C2848,1009 2791,1020 2588,1020L2410,1020ZM2816,929C2933,871 2943,694 2833,620C2799,596 2788,595 2638,592L2480,589L2480,768C2480,866 2483,950 2488,954C2492,959 2556,960 2631,958C2750,954 2773,951 2816,929Z"/>
                <path d="M3460,1020L3460,280L3980,280L3980,340L3540,340L3540,620L3920,620L3920,690L3540,690L3540,960L3960,960L3960,1020L3460,1020Z"/>
                <path d="M4430,1020L4430,280L4510,280L4510,520L4765,520L4923,280L5011,280L4926,404C4879,473 4840,531 4840,533C4840,536 4850,540 4863,544C4898,553 4959,608 4982,653C5014,715 5014,828 4981,894C4955,946 4896,992 4830,1010C4811,1015 4713,1020 4613,1020L4430,1020ZM4840,929C4864,916 4892,893 4903,879C4943,825 4943,716 4903,661C4892,647 4864,625 4840,613C4800,592 4781,590 4653,590L4510,590L4510,963L4654,958C4781,954 4802,950 4840,929Z"/>
                <path d="M5702,1003C5685,971 5380,291 5380,285C5380,282 5397,280 5418,280L5457,280L5543,480L5955,480L5999,380L6042,280L6081,280C6103,280 6120,283 6119,288C6119,292 6046,457 5956,655L5793,1015L5752,1018C5721,1020 5709,1017 5702,1003ZM5836,743C5882,640 5920,555 5920,553C5920,551 5843,550 5749,550C5590,550 5578,551 5584,568C5605,624 5746,937 5749,934C5751,932 5791,846 5836,743Z"/>
            </g>
        </svg>
        </Link>
    )
}

export default CareraLogo