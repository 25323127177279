import { Badge, Box, IconButton, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import DbWorkorderType, { formatWorkorderNo } from "./DbWorkorderType";

import { Search2Icon } from '@chakra-ui/icons'
import { AvailableInsuranceProvidersContext, AvailableInsuranceProvidersContextType } from "../InsuranceProviders/AvailableInsuranceProviders";
import React, { useContext } from "react";
import WorkorderContentView from "./WorkorderContentView";
import moment from "moment";


export default function WorkorderTableView({workorders, updateState}:{workorders:DbWorkorderType[], updateState:Function}){

    
    return (
        <Box px={[0,4]} overflowX="auto" w="full" >
            <Table variant="striped" size={"md"}>
                <Thead>
                    <Tr>
                        <Th w="10%" >Order</Th>
                        <Th w="17%">Name</Th>
                        <Th w="30%">Assigned Garage</Th>
                        <Th w="29%">Insurance Provider</Th>
                        {/* <Th w="7%" textAlign={"center"}>Estimate</Th> */}
                        <Th w="7%" textAlign={"right"} >View</Th>
                    </Tr>
                </Thead>
                <Tbody>
                { workorders.map((workorder)=>(
                        <TableRow key={workorder.id} workorder={workorder} updateState={updateState} />
                )) }
                </Tbody>
            </Table>
        </Box>
    )
}

function TableRow({workorder, updateState}:{workorder:DbWorkorderType, updateState:Function}){
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const availableInsuranceProviders=useContext(AvailableInsuranceProvidersContext) as AvailableInsuranceProvidersContextType;
    const createdDate = workorder.createdDate;
    const newDate = moment().subtract(5, 'days');

    function indicatorDot(orderClosed:boolean){
        return <Box
            verticalAlign="inherit"
            as={"div"}
            display="inline-block"
            h="1.5"
            w="1.5"
            mr={1}
            color={orderClosed ? "red.100" : "green.100" }
            bg={orderClosed ? "red.600" : "green.600" }
            rounded="full"
            />
    }

    return (
        <>
        {/* <Tr textDecoration={workorder.cancelled !== false ? "line-through" : "none"} color={workorder.cancelled !==false ? "red.200" : "#252B42"} > */}
        <Tr>
            <Td fontSize="sm" fontWeight="bold" px="2" textAlign="center" whiteSpace="nowrap" pos={"relative"} >
                {indicatorDot(workorder.closed)}{formatWorkorderNo(workorder.workorderNo)}
                {
                workorder.cancelled !== false &&
                        <Box pos={"absolute"} w={"78px"} h={"78px"} overflow={"hidden"} top={"-5px"} left={"-5px"} _before={{"position":"absolute", "content":"''", "display":"block", "border":"2.5px solid #c24c4c","borderLeftColor":"transparent", "borderTopColor":"transparent", "top":"0", "right": "25px"}} _after={{"position":"absolute", "content":"''", "display":"block", "border":"2.5px solid #c24c4c", "borderTopColor":"transparent", "borderLeftColor":"transparent", "bottom":"25px", "left":"0"}} >
                            <Box as={"span"} pos={"absolute"} display={"block"} width={"85px"} padding={"5px 0px 2px 3px"} backgroundColor={"red.400"} color={"#fff"} textAlign={"center"} right={"20px"} top={"5px"} transform={"rotate(-45deg)"} fontSize={"8px"} >
                                Canceled
                            </Box>
                        </Box>
                }
                {
                workorder.confirmed === false && workorder.cancelled === false &&
                        <Box pos={"absolute"} w={"78px"} h={"78px"} overflow={"hidden"} top={"-5px"} left={"-5px"} _before={{"position":"absolute", "content":"''", "display":"block", "border":"2.5px solid #a14cc2","borderLeftColor":"transparent", "borderTopColor":"transparent", "top":"0", "right": "25px"}} _after={{"position":"absolute", "content":"''", "display":"block", "border":"2.5px solid #a14cc2", "borderTopColor":"transparent", "borderLeftColor":"transparent", "bottom":"25px", "left":"0"}} >
                            <Box as={"span"} pos={"absolute"} display={"block"} width={"85px"} padding={"5px 0px 2px 3px"} backgroundColor={"purple.400"} color={"#fff"} textAlign={"center"} right={"20px"} top={"5px"} transform={"rotate(-45deg)"} fontSize={"8px"} >
                                Pending
                            </Box>
                        </Box>
                }
            </Td>
            <Td pos={"relative"} px="3" maxW="0px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" >
                {
                    createdDate !== undefined && moment.unix(createdDate).isAfter(newDate) && <Badge pos={"absolute"} left={"-2px"} top={"12px"} fontSize={"x-small"} colorScheme={"teal"} >New</Badge>
                }
                {workorder.firstName && `${workorder.firstName} ${workorder.lastName}`}
            </Td>
            <Td maxW="0px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{workorder.selectedGarageName}</Td>
            <Td maxW="0px" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{availableInsuranceProviders.getInsuranceProvider(workorder.insuranceProvider)}</Td>
            {/* <Td textAlign={"center"} fontSize="sm" fontWeight="bold" px="3" >{workorder.estimate}</Td> */}
            <Td textAlign={"right"} ><IconButton onClick={onOpen} bg={"gray.200"} aria-label="search" icon={<Search2Icon />}/></Td>
        </Tr>
        <WorkorderContentView isOpen={isOpen} onClose={onClose} workorder={workorder} updateState={updateState} cancelWorkorderCB={(wo:DbWorkorderType)=>console.log(wo)} />
        </>
    )
}