import { ReactNode } from 'react';

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Container,
    IconButton,
    Input,
    Link,
    List,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import CareraLogo from '../../carera-logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmailIcon } from '@chakra-ui/icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import useViewport from "../Hooks/Viewport";
import { HashLink } from 'react-router-hash-link';
import {Link as ReactRouterLink} from 'react-router-dom'


const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
    <Text fontWeight={"bold"} fontSize={"small"} mb={1}>
        {children}
    </Text>
    );
};

const FooterColumn = ({ children, columnText }:{children: ReactNode, columnText: string}) => {
    return (
        <Stack align={'flex-start'} spacing="2">
            <ListHeader>{columnText}</ListHeader>
            {children}
        </Stack>
    )
}


export default function Footer({logoFillColor}:{logoFillColor?: string,}) {

    const logoColor = useColorModeValue("black", "white");
    const background = useColorModeValue('gray.50', 'gray.600');
    const textColor = useColorModeValue('gray.700', 'gray.200');
    const { width } = useViewport();
    const breakpoint = 768;


    const FooterContentHeader = () => {
        return (
            <Stack spacing={1} justify="center" color={textColor}>
                <Box>
                    <CareraLogo height="32" fillColor={logoFillColor ?? logoColor} />
                </Box>
                <Text fontSize={"xs"} textAlign="center">© 2023 Carera. All rights reserved</Text>
            </Stack>
        )
    }

    const FooterContent = () => {
        return (
            <SimpleGrid
                py={4}
                color={textColor}
                templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr' }}
                spacing={8}>
                    {width > breakpoint && <FooterContentHeader />}
                    <FooterColumn columnText="Product">
                        {/* <Link href={'#'} fontSize="xs" >Overview</Link> */}
                        <HashLink style={{fontSize:"0.75rem"}} to="/#get-started">Overview</HashLink>
                        <HashLink style={{fontSize:"0.75rem"}} to="/#how-it-works">Features</HashLink>
                        {/* <Link href={'#'} fontSize="xs" >Features</Link> */}
                    </FooterColumn>
                    {/* <FooterColumn columnText="Company">
                        <Link href={'#'} fontSize="xs">About</Link>
                        <Link href={'#'} fontSize="xs">Press</Link>
                    </FooterColumn> */}
                    <FooterColumn columnText="Support">
                        {/* <Link href={'#'} fontSize="xs">Help Center</Link> */}
                        <HashLink to="/privacy" style={{fontSize:"0.75rem"}}>Privacy Policy</HashLink>
                        <HashLink to="/terms" style={{fontSize:"0.75rem"}}>Terms & Conditions</HashLink>
                    </FooterColumn>
                </SimpleGrid>
        )
    }

    return (
    width > breakpoint
    ? <Box
        bg={background}
        color={textColor}>
        <Container as={Stack} maxW={'container.xl'} py={3}>
            <FooterContent/>
        </Container>
    </Box>
    :<Accordion bgColor={background} allowToggle >
        <AccordionItem>
            <h2>
                <AccordionButton>
                    <Box w="full"><FooterContentHeader/></Box>
                    <AccordionIcon color={textColor} />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <FooterContent/>
            </AccordionPanel>
        </AccordionItem>
    </Accordion>
    );
}



function SecondaryFooter(){
    return (
        <Box
                        bg={useColorModeValue('gray.50', 'gray.900')}
                        color={useColorModeValue('gray.700', 'gray.200')}>
                            <Container as={Stack} maxW={'6xl'} py={10}>
                                <SimpleGrid
                                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
                                    spacing={8}>
                                    <Stack spacing={6}>
                                        <Box>
                                        <CareraLogo width="120px" height="70px" />   
                                        </Box>
                                        <Text fontSize={'sm'}>
                                        © 2023 CARERA. All rights reserved.
                                        </Text>
                                        <Stack direction={'row'} spacing={6}>
                                        {/* <Button label={'Instagram'} href={'#'}>
                                            <faInstagram />
                                            </Button> */}
                                            <FontAwesomeIcon icon={faSearch} />
                                        {/* <Button label={'TikTok'} href={'#'}>
                                            <faTikTok />
                                            </Button> */}
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Stack>
                                    </Stack>
                                    <Stack align={'flex-start'}>
                                        <List>Company</List>
                                        <Link href={'#'}>About</Link>
                                        <Link as={ReactRouterLink} to={'/contact'}>Contact</Link>
                                    </Stack>
                                    <Stack align={'flex-start'}>
                                        <List>Support</List>
                                        <Link href={'#'}>Help Center</Link>
                                        <Link href={'#'}>Terms of Service</Link>
                                        <Link href={'#'}>Privacy Policy</Link>
                                    </Stack>
                                    <Stack align={'flex-start'}>
                                        <List>Stay up to date</List>
                                            <Stack direction={'row'}>
                                                <Input
                                                    placeholder={'Your email address'}
                                                    bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                                                    border={0}
                                                    _focus={{
                                                    bg: 'whiteAlpha.300',
                                                    }}
                                                />
                                                <IconButton
                                                    bg={useColorModeValue('teal.400', 'teal.800')}
                                                    color={useColorModeValue('white', 'gray.800')}
                                                    _hover={{
                                                    bg: 'teal.600',
                                                    }}
                                                    aria-label="Subscribe"
                                                    icon={< EmailIcon />}
                                                />
                                            </Stack>
                                    </Stack>
                                </SimpleGrid>
                            </Container>
                        </Box>
    )
}