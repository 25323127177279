import Sidebar from '../Common/Sidebar';
import Header from './HeaderLisa';
import { Box, Container, Flex } from '@chakra-ui/layout';
import { SidebarContextProvider } from '../Common/SidebarContext';

export function AdminNav(props: {children: React.ReactNode}){
    return (
        <>
        <Header bgColor={"#474747"} logoFillColor={"#fff"} />
        <Container maxW={"container.xl"} pb={5} bg={"white"} >
            <Flex w={"100%"} pos="relative">
                <Sidebar />
                <Box w={"full"} mt={6} minW={60}>
                    {props.children}
                </Box>
            </Flex>
        </Container>
        </>
    )
}