import {useContext, useState, useEffect} from 'react';
import {AvailableServicesContext,AvailableServicesContextType,DbAvailableService} from '../Services/AvailableServices';
import { Tag, Wrap } from '@chakra-ui/react';



interface ServicesListProps{

    /** if specified, will turn all services into checkboxes, and call this function when any change */
    onChange?: (ids:string[]) => void;

    /** if specified, will only display these services. otherwise, will display all */
    serviceIdList?: string[],

    /** if specified along with onChange, will determine the initial selected state of checkboxes */
    serviceIdSelections?: string[],

    /** if given, will return the first service in the array, plus a count */
    single?: boolean,
};


/** a list of services, optionally selectable with check boxes.
 * IMPORTANT: must ultimately be a descendant of the <AvailableServicesProvider> context.
 */
function ServicesList({onChange,serviceIdList,serviceIdSelections, single}:ServicesListProps){
    const [selectedServices,setSelectedServices]=useState<string[]>([]);
    const [allServices,setAllServices]=useState<DbAvailableService[]>([]);

    const availableServices = useContext(AvailableServicesContext) as AvailableServicesContextType;

    // this is set up to run whenever context services change (including on startup)
    useEffect(()=>{
        if(availableServices.services.length>0){
            setAllServices(availableServices.services);
        }
    },[availableServices.services]);

    // this is set up to run whenever selected services change (including on startup)
    useEffect(()=>{
        if(serviceIdSelections!==undefined){
            setSelectedServices(serviceIdSelections);
        }
    },[serviceIdSelections]);

    /** set whether a service is selected */
    function setSelectionValue(serviceId:string|undefined,selected:boolean){
        if(serviceId===undefined) return;
        if(selected){
            if(selectedServices.indexOf(serviceId)<0){
                let newServices=[...selectedServices,serviceId]
                setSelectedServices(newServices);
                if(onChange!==undefined) onChange(newServices);
            }
        }else{
            let newServices=selectedServices.filter((v)=>v!==serviceId);
            setSelectedServices(newServices);
            if(onChange!==undefined) onChange(newServices);
        }
    }

    /** adds a single service (if it is supported) */
    function Service(service:DbAvailableService){

        return (
            <Tag key={service.id} my={2} borderRadius="full" colorScheme="green" variant={service.id===undefined ? "outline" : selectedServices.indexOf(service.id)>=0 ? "solid" : serviceIdList!== undefined && serviceIdList?.indexOf(service.id)>=0? "solid":"outline"} >
                {onChange!==undefined
                ?(<>
                <input type="checkbox" id={service.id} style={{display:"none"}} checked={service.id===undefined?false:selectedServices.indexOf(service.id)>=0} onChange={(e)=>{setSelectionValue(service.id,e.target.checked)}} />
                <label htmlFor={service.id} style={{cursor:"pointer"}}>{service.name}</label>
                </>)
                :service.name
                }
            </Tag>
        );
    }

    return (
        <>
        {single && serviceIdList!== undefined && serviceIdList.length > 1
        ? allServices.find((service) => service.id === serviceIdList[0])?.name
            ? <Wrap my={4}>
                <Tag variant="solid" my={2} borderRadius="full" colorScheme="green">{allServices.find((service) => service.id === serviceIdList[0])?.name}</Tag>
                {serviceIdList.length > 1 && <Tag variant="solid" my={2} borderRadius="full" colorScheme="green">+{serviceIdList.length-1}</Tag>}
            </Wrap>
            : <Wrap my={4} ><Tag borderRadius="full" >Open to view</Tag></Wrap>
        : <Wrap my={4}>
            {allServices.map((service)=>(serviceIdList === undefined || service.id===undefined || serviceIdList.indexOf(service.id) >= 0) ? Service(service) : '')}
        </Wrap>
        
        }
        </>
    );
}


export default ServicesList;