import React, {useEffect, useState} from "react";
import styled from 'styled-components';


const StyledAddressForm=styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
`;

const AddressHeading=styled.h2`
    color: cornflowerblue;
    font-size: 12pt;
    font-weight: 600;
`;

const InputTextField=styled.input`
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 8px 10px;
    margin: 0 0 12px 0;
`;

const InputTextFieldWide=styled(InputTextField)`
    display: block;
    box-sizing: border-box;
    width: 100%;
`;

const Dropdown = styled.select`
    display: inline-block;
    margin: 0 12px 12px 12px;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 8px 10px;
    width: 6em;
    min-height: 25px;
    appearance:none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: 90% 80%;
    background-repeat: no-repeat;
`;

const Numbers=styled(InputTextField)`
    width: 6em;
    -moz-appearance: textfield;

    &:-webkit-outer-spin-button,
    &:-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export interface AddressType{
    line1:string,
    line2:string,
    city:string,
    state:string,
    zip:string
};

function addressToString(address:AddressType,multiline:boolean=false){
    if(multiline){
        return [address.line1,address.line2,[[address.city,address.state].join(', '),address.zip].join(' ')].join('\n');
    }
    return [address.line1,address.line2,[address.city,address.state].join(', '),address.zip].join(' ');
}

export function jsonAddressToString(addressJson:string,multiline:boolean=false){
    if(addressJson===undefined||addressJson===''){
        return '';
    }
    var address=JSON.parse(addressJson) as AddressType;
    return addressToString(address,multiline);
}

export function jsonAddressToObject(addressJson:string|undefined):AddressType{
    if(addressJson===undefined||addressJson===''){
        return {} as AddressType;
    }
    var address = JSON.parse(addressJson) as AddressType;
    return address;
}

interface OnChange {(address:string): void};
interface OnValidChange {(isValid:boolean): void};


const states=["","AL","AK","AZ","AR","CA","CO","CT","DE","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY"];

function AddressForm({address,onChange,zipcodeRequired=false,onValidChange=undefined}:{address:string,onChange:OnChange,zipcodeRequired?:boolean,onValidChange?:OnValidChange}){

    const [line1,setLine1]=useState('');
    const [line2,setLine2]=useState('');
    const [city,setCity]=useState('');
    const [state,setState]=useState('');
    const [zip,setZip]=useState('');
    const [valid,setValid]=useState(false);

    function change(){
        onChange(getString());
    }

    function getString():string{
        return JSON.stringify({line1,line2,city,state,zip});
    }
    function setString(addr:string){
        var a:AddressType|undefined=undefined;
        try{
            a=JSON.parse(addr) as AddressType;
        }catch{
            console.error('Unable to parse:');
            console.error(addr);
        }
        if(a!==undefined){
            setLine1(a.line1);
            setLine2(a.line2);
            setCity(a.city);
            setState(a.state);
            setZip(a.zip);
        }
    }

    useEffect(()=>{
        setString(address);
    },[address]);

    /** called to monitor whether the address is valid or not */
    useEffect(()=>{
        if(zipcodeRequired){
            setValid(zip.length!==5);
        }
    },[zip]); // eslint-disable-line react-hooks/exhaustive-deps

    /** called to propogate whether the address is valid up to a parent component */
    useEffect(()=>{
        if(onValidChange!==undefined){
            onValidChange(valid);
        }
    },[valid]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <StyledAddressForm>
            <AddressHeading>Address</AddressHeading>
            <InputTextFieldWide placeholder="Line 1" value={line1} onChange={e=>setLine1(e.target.value)} onBlur={(e)=>change()}></InputTextFieldWide>
            <InputTextFieldWide placeholder="Line 2" value={line2} onChange={e=>setLine2(e.target.value)} onBlur={(e)=>change()}></InputTextFieldWide>
            <InputTextField placeholder="City" value={city} onChange={e=>setCity(e.target.value)} onBlur={(e)=>change()}></InputTextField>
            <Dropdown placeholder="State" value={state} onChange={e=>setState(e.target.value)} onBlur={(e)=>change()}>
                {states.map((stateItem,idx)=>idx>0||state===''?(
                    <option key={stateItem} value={stateItem}>{stateItem}</option>
                ):'')}
            </Dropdown>
            <Numbers placeholder="Zip Code" maxLength={5} pattern="[0-9]{5}" value={zip}
                style={{backgroundColor:(zipcodeRequired&&(zip.length!==5)?"pink":"transparent")}}
                onChange={e=>setZip(e.target.value)} onBlur={(e)=>change()}></Numbers>
        </StyledAddressForm>
    );
}


export default AddressForm;