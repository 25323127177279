import { Box, Flex, IconButton, Input, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { faBan, faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useState } from 'react'
import { ClassifiedsCtx, useClassifiedsCategoryContext } from './ClassifiedsCategoryContext';

export const EditCategories = () => {

    const {state:{categories}, dispatch} = useClassifiedsCategoryContext()
    const [newCategory, setNewCategory] = useState("")

    const handleOnAdd = () => {
        dispatch({type:ClassifiedsCtx.add, payload:newCategory})
        setNewCategory("")
    }

    const onDelete = (cat:string) => {
        dispatch({type:ClassifiedsCtx.remove, payload:cat})
    }

    const onEdit = (oldValue:string, newValue:string) => {
        dispatch({type:ClassifiedsCtx.edit, payload:[oldValue, newValue]})
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}>
            <Box px={[0,4]} pt={[16,16,0]} >
                <Table variant={"simple"} size={"sm"}>
                    <Thead>
                        <Tr>
                            <Th>Category Name</Th>
                            <Th w={5} textAlign={"center"}>Delete</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {categories.map((category) => (
                            <CategoryItem key={category} category={category} onDelete={onDelete} onEdit={onEdit} />
                        ))}
                    </Tbody>
                </Table>
                <Flex px={4} mt={8}>
                    <Input id="newCategory" type="text" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} placeholder="Add Category" mr={4} />
                    <IconButton colorScheme={"green"} onClick={handleOnAdd} aria-label="Add Category" icon={<FontAwesomeIcon icon={faPlus}/>} />
                </Flex>
            </Box>
        </motion.div>
    );
}


const CategoryItem = ({category, onDelete, onEdit}:{category:string, onDelete:(cat:string)=>void, onEdit:(oldValue:string, newValue:string)=>void}) => {

    const [edit, setEdit] = useState<boolean>(false)
    const [text, setText] = useState<string>(category)
    const currentName:string = category;


    return (
        <Tr>
            <Td><Input type="text" onChange={(e) => {
                setText(e.target.value)
                setEdit(true);
                }} value={text}/></Td>
            <Td textAlign={"right"} >
                {edit && 
                    <Flex>
                        <IconButton mr={1} colorScheme="green" aria-label="commit" icon={<FontAwesomeIcon icon={faCheck}/>} onClick={() => {
                            onEdit(currentName, text)
                            setEdit(false);
                        }} />
                        <IconButton aria-label="cancel" colorScheme="red" icon={<FontAwesomeIcon icon={faTimes}/>} onClick={() => {
                            setText(currentName);
                            setEdit(false);
                        }} />
                    </Flex>
                }
                {!edit && <IconButton colorScheme={"red"} aria-label="delete service"  icon={<FontAwesomeIcon icon={faBan} onClick={()=>onDelete(currentName)} /> }/>}
            </Td>
        </Tr>
        )
}