import firebase, {storage}  from '../../firebase';
import DbAttachment, { DbAttachmentData } from "../Attachments/DbAttachment";

/** similar to firebase store uploadTask only better because it's all async and nice 
*
* returns downloadUrl
*/
async function firebaseStorageUploader(path:string,data:File|Blob|Uint8Array|ArrayBuffer,onProgress:any=undefined) {
    return new Promise<string>(function(resolve,reject) {
        const storageRef=storage.ref(path);
        const uploadTask=storageRef.put(data);
        uploadTask.on('state_changed',
            function(snapshot) {
                if(onProgress!==undefined) onProgress(snapshot.bytesTransferred/snapshot.totalBytes);
            },
            function error(err:any) {
                console.error('error', err)
                reject()
            },
            function complete() {
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    resolve(downloadURL)
                })
            }
        )
    })
}

/** add an attachment to firebase 
 * returns the data created
*/
async function addFirebaseAttachment(storageFolder:string,dbAttachmentCollection:firebase.firestore.CollectionReference,file:File,attachment:DbAttachment){
    var ret:DbAttachment|undefined=undefined;
    const storageFilename=`${storageFolder}/${attachment.fileName}`;
    if(file!==undefined){
        //try {
        let url=await firebaseStorageUploader(storageFilename,file);
        const firestoreDbFile:DbAttachmentData={
            fileName:attachment.fileName,
            storageFilename:storageFilename,
            type:attachment.type,
            size:attachment.size,
            url:url,
            order:attachment.order
        }
        // now add info about the new attachment to the database
        if(dbAttachmentCollection!==undefined){
            let result=await dbAttachmentCollection.add(firestoreDbFile);
            ret={...firestoreDbFile,id:result.id};
        }
    }
    return ret;
}

/** called to upload all of the attachements 
 * attachements are in order, where photo[0] is the "cover image"
*/
export default async function uploadAttachments(storageFolder:string,dbAttachmentCollection:firebase.firestore.CollectionReference,photos:File[]) {
    for(let i=0;i<photos.length;i++){
        let photo=photos[i];
        let attachment:DbAttachment|undefined={
            id:'', // filled in later
            fileName:photo.name,
            storageFilename: '', // filled in later
            size:photo.size,
            url:'', //filled in later
            type:photo.type,
            order:i
        };
        if(attachment!==undefined) attachment=await addFirebaseAttachment(storageFolder,dbAttachmentCollection,photo,attachment);
    }
}