import React, {useEffect, useState} from 'react';
import Header from './HeaderLisa';
import { Box, Flex, Button, Container, useColorModeValue, Icon, IconProps, useColorMode, VStack, Wrap, useDisclosure, IconButton, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, CloseButton, DrawerFooter } from '@chakra-ui/react';
import Hero from '../MainWebsite/Hero';
import StepOne from '../MainWebsite/StepOne';
import StepTwo from '../MainWebsite/StepTwo';
import StepThree from '../MainWebsite/StepThree';
import Reviews from '../MainWebsite/Reviews';
import Footer from './Footer';
import * as Scroll from 'react-scroll';
import carImage from '../../images/get-your-car-fixed.jpeg';
import SectionWrapper from '../MainWebsite/SectionWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import CareraLogo from '../../carera-logo';
import StepFour from '../MainWebsite/StepFour';
import StepFive from '../MainWebsite/StepFive';
import StepSix from '../MainWebsite/StepSix';
import StepSeven from '../MainWebsite/StepSeven';
import { Nav } from './Nav';

var Link      = Scroll.Link;
let Element = Scroll.Element;
let scroller = Scroll.scroller;
var scroll    = Scroll.animateScroll;

export const Blob = (props: IconProps) => {
    return (
        <Icon
        width={'100%'}
        viewBox="0 0 578 440"
        fill="none"
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
            fill="currentColor"
        />
        </Icon>
    );
};


const MainPage = () => {

    return (
        <>
        <Box>
            <Nav/>
            <Hero />
            <StepOne />
            <StepTwo />
            <StepThree />
            <StepFour/>
            <StepFive/>
            <StepSix/>
            <StepSeven/>
            <Reviews />
            <Footer />
        </Box>
        </>
    )
}

export default MainPage;
