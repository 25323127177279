import { Button, Menu, MenuButton, MenuItem, MenuList, Box, Circle, Flex, HStack, SimpleGrid, Text, VStack, Image } from "@chakra-ui/react";
import { faChevronDown, faHandPointRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import getAvailableAppointments, { MonthlyAppointmentsType, AppointmentsType, defaultLimitToHours } from "../../availableAppointments";
import { DbGarageImage } from "../Garages/DbGarage";
import DatePicker from "./DatePicker";
import Loading from '../Common/Loading';

type TimeSlotHour = {
    hour: number,
    times: TimeSlotTimes[]
}

type TimeSlotTimes = {
    from: moment.Moment,
    to: moment.Moment
}

export default function GarageScheduler({garage, prevStep, onSubmit}:{garage:DbGarageImage, prevStep: () => void, onSubmit:(time:[moment.Moment,moment.Moment]) => void}){

    const today=moment();

    const [startDate, setStartDate] = useState<Date | null>();
    const [viewMonth, setViewMonth] = useState<moment.Moment>(moment(today).date(1));
    const [highlightDates, setHighlightDates] = useState<Date[] | undefined>();
    const [availableTimes, setAvailableTimes] = useState<AppointmentsType | undefined>();
    const [selectedAppointment, setSelectedAppointment] = useState<TimeSlotTimes>();
    const garageCalendar = garage.garageCalendar;
    const [availableAppointments,setAvailableAppointments]=useState<MonthlyAppointmentsType>([]);
    const [timeSlots, setTimeSlots] = useState<TimeSlotHour[] | undefined >();
    //inital state of page is loading
    const [loading, setLoading] = useState(true);

    function addDays(date:Date, days:number) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const searchVariant = {
        start: { top: "10px" },
        finish: { top: "calc(50% - 5.5rem)" },
    }

    const garageInfoVariant = {
        start: { opacity: 0 },
        finish: { opacity: 1 },
    }


    /** called once upon startup */
    useEffect(()=>{
        let isCancelled = false;
        const fetchCal = async () => {
            setAvailableAppointments([]);
            let limitToHoursArray=undefined;
            if(garage.limitToHours!==undefined&&garage.limitToHours!==null&&garage.limitToHours!==''){
                limitToHoursArray=JSON.parse(garage.limitToHours);
            }else if(garage.storeHours!==undefined&&garage.storeHours!==null&&garage.storeHours!==''){
                // if no limit hours, use store hours
                limitToHoursArray=JSON.parse(garage.storeHours);
            }else{
                // if no store hours either, use the default
                limitToHoursArray=defaultLimitToHours;
            }

            let appointments=await getAvailableAppointments(viewMonth,garageCalendar,limitToHoursArray);

            if(!isCancelled){
                // setAvailableAppointments(appointments);
                appointmentLoaded(appointments);
            }

        }

        fetchCal();
        return () => {
            isCancelled = true;
        }

        // updateCalendarControls();
    },[viewMonth,garageCalendar]); // eslint-disable-line react-hooks/exhaustive-deps


    function appointmentLoaded(appointments:MonthlyAppointmentsType){
        setAvailableAppointments(appointments);
        setLoading(false);
    }

    function monthChange(date:Date){
        const newMonth = date.getMonth();
        const curMonth = moment(today).month();
        if(curMonth < newMonth){
            setViewMonth(moment().month(newMonth).date(1).hour(0).minute(0));
        } else {
            setViewMonth(moment(today).date(1));
        }
    }

    useEffect(() => {
        const available = availableAppointments;
        let daysList:Date[] = [];
        const limit = moment().add(60, 'days');
        for (let i = 0; i < available.length; i++) {
            const a = available[i];
            if(a.length){
                const first = a[0][0];
                if(first.isBefore(limit)){
                    const date = first.toDate();
                    daysList.push(date);
                }
            }
        }
        setHighlightDates(daysList);
    }, [availableAppointments])


    function getListOfTimes(date:Date){
        setStartDate(date);
        if(date === null) {
            setAvailableTimes(undefined);
            setSelectedAppointment(undefined);
            setTimeSlots(undefined);
        } else {
            const dayOfMonth:number = date.getDate();
            if(availableAppointments){
            const appointmentDay = availableAppointments[dayOfMonth - 1];
            // console.log(appointmentDay);
            setAvailableTimes(appointmentDay);
            orderTimes(appointmentDay);
        } 
        }
    }

    function orderTimes(timesToSort:AppointmentsType){
        if(timesToSort){
            let timeSlots:TimeSlotHour[] = [];
            for (let index = 0; index < 23; index++) {
                let hour:TimeSlotTimes[] = [];
                for (let i = 0; i < timesToSort.length; i++) {
                    const a = timesToSort[i];
                    if(a[0].hour() === index){
                        const slot:TimeSlotTimes = {to: a[0], from: a[1]} 
                        hour.push(slot);
                    }
                }
                if(hour.length){
                    const fullSlot:TimeSlotHour = {hour: index, times: hour};
                    timeSlots.push(fullSlot)
                }
            }
            setTimeSlots(timeSlots);
        }
    }

    function Confirmation({selected}:{selected: TimeSlotTimes}){
        const timeTo = selected.to;
        const timeFrom = selected.from;
        const time:[moment.Moment,moment.Moment] = [timeTo, timeFrom];

        return (
            <Box w="full" textAlign={["right", "center"]} ><Button onClick={()=> onSubmit(time)} rightIcon={ <FontAwesomeIcon icon={faHandPointRight} /> } size="lg" colorScheme="blue" >{timeTo.format("hh:mm a")} - {timeFrom.format("hh:mm a")}</Button></Box>
        )
    }

    
    return (
        <>
        {loading 
            //while we are loading the calendar, show a loading animation
            ? <Loading />
            :  <>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: {delay:0.5} }} exit={{ opacity: 0 }}> 
            <Flex align="center" >
                { garage.imageUrls !== undefined && garage.imageUrls[0] !== undefined && <Circle mr="4" overflow="hidden" size="14" ><Image src={garage.imageUrls[0]}/></Circle> }
                <VStack spacing="0" >
                    <Text lineHeight="1" fontSize="sm" >You're booking with:</Text>
                    <Text fontWeight="bold" >{garage.name}</Text>
                </VStack>
            </Flex>
        </motion.div>
        <VStack w="full" align="center" h="full" pos="relative">
            <motion.div style={{position:"absolute"}} variants={searchVariant} animate={ timeSlots !== undefined && timeSlots.length ? "start" : "finish" } >
                <DatePicker
                    selected={startDate}
                    isClearable={true}
                    placeholderText={"Pick a date to see times..."}
                    onChange={getListOfTimes}
                    dateFormat="EEE MMM do"
                    minDate={new Date()}
                    maxDate={addDays(new Date(), 60)}
                    onMonthChange={monthChange}
                    highlightDates={highlightDates}
                    allowSameDay={false}
                    withPortal
                >
                    <Box w="full" py="0" px="2">
                            <HStack w="full" py="4">
                                <HStack w="full" justify="center" >
                                    <Circle size="3" bgColor="green.300" />
                                    <Text>Available</Text>
                                </HStack>
                                {/* <HStack w="full" justify="center">
                                    <Circle size="3" bgColor="red.300" />
                                    <Text>Booked</Text>
                                </HStack> */}
                            </HStack>
                    </Box>
                </DatePicker>
            </motion.div>
            {
                // availableTimes!== undefined && availableTimes.length && 
                //     availableTimes.map((time, index) => {
                //         return (
                //         <Flex key={index}><Text>{time[0].format("hh:mm")}-{time[1].format("hh:mm")}</Text> </Flex>
                //         )
                //     })
                timeSlots !== undefined && timeSlots.length &&
                <motion.div initial={{opacity:0}} animate={{opacity:1, transition:{delay:0.2}}} exit={{opacity:0}} style={{width:"100%", height:"100%"}} >
                <Flex w="full" h="full" align="center" justify="center" pt="calc(2rem + 5px)">
                    <SimpleGrid spacing={6} minChildWidth={["100px","120px"]} maxW={["md"]} >
                        {
                            timeSlots.map((slot, index) => {
                                const hourFormatted = moment().hour(slot.hour).minute(0).format("hh:mm");
                                return (
                                    <Menu key={index}>
                                        <MenuButton as={Button} rightIcon={ <FontAwesomeIcon icon={faChevronDown} /> } >{hourFormatted}</MenuButton>
                                        <MenuList>
                                            {
                                                slot.times.map((time, index) => {
                                                    const timeTo = time.to.format("hh:mm");
                                                    const timeFrom = time.from.format("hh:mm");
                                                    return (
                                                        <MenuItem onClick={() => setSelectedAppointment(time)} key={index} >{`${timeTo} - ${timeFrom}`}</MenuItem>
                                                    )
                                                })
                                            }
                                        </MenuList>
                                    </Menu>
                                )
                            })
                        }
                    </SimpleGrid>
                </Flex>
                </motion.div>
            }
            {
                selectedAppointment && 
                    <Confirmation selected={selectedAppointment} />
            }
        <Button colorScheme="teal" onClick={prevStep} pos="absolute" bottom="0" left="0" rightIcon={<FontAwesomeIcon icon={faSearch} />} >Back to</Button>
        </VStack>
        </>
        }
        </>
    );
}
