import { Box, Button, Checkbox, Collapse, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Input, Select, Switch, Text, useColorModeValue, useDisclosure, VStack, Wrap } from "@chakra-ui/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { SearchTermsType } from "../Dashboards/UserDashboard";
import { AddressType } from "../Garages/EditGarageForm";
import { AvailableServicesContext, AvailableServicesContextType, DbAvailableService } from "../Services/AvailableServices";
import { SearchValues } from "./UserSearchForm";

type UserEditSearchFormProps = {
    isOpen: boolean,
    onClose: () => void,
    searchTerms: SearchTermsType,
    onSubmit: SubmitHandler<SearchValues>,
}

interface AddressTypeSearch extends AddressType {
    lineOne: string,
    lineTwo: string,
}

export default function UserEditSearchForm({isOpen, onClose, searchTerms, onSubmit}:UserEditSearchFormProps){

    const { setValue, register, handleSubmit, control, reset, formState: {errors} } = useForm({mode: "onBlur"});
    const availableServices = useContext(AvailableServicesContext) as AvailableServicesContextType;
    const [allServices,setAllServices]=useState<DbAvailableService[]>([]);
    const [fullAddress, setFullAddress] = useState<AddressTypeSearch>();
    const { isOpen:isToggleOpen, onToggle, onOpen:onOpenToggle } = useDisclosure();
    const textColor = useColorModeValue('gray.900', 'white');
    const states=["AL","AK","AZ","AR","CA","CO","CT","DE","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY"];

    useEffect(()=>{
        if(availableServices.services.length>0){
            setAllServices(availableServices.services);
        }
    },[availableServices.services]);

    useEffect(() => {
        if(searchTerms.address){
            const address:AddressTypeSearch = JSON.parse(searchTerms.address) as AddressTypeSearch;
            setFullAddress(address);
        }
        if(searchTerms.instantBook) setValue("instantBook", true);
        if(searchTerms.services){
            searchTerms.services.map((service) => {
                setValue(`services[${service}]`, true);
            })
        }
    }, [])


    useEffect(() => {
        if(fullAddress!== undefined){
            onOpenToggle();
            if(fullAddress.lineOne) setValue("addressLineOne", fullAddress.lineOne);
            if(fullAddress.lineTwo) setValue("addressLineTwo", fullAddress.lineTwo);
            if(fullAddress.city) setValue("city", fullAddress.city);
            if(fullAddress.state) setValue("state", fullAddress.state);
            if(fullAddress.zip) setValue("zip", fullAddress.zip, {shouldValidate: true})
        }
    }, [fullAddress])

    return (
        <Drawer isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent color={textColor}>
                <DrawerCloseButton />
                <DrawerHeader>Edit Search</DrawerHeader>
                <DrawerBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Divider mb="4"/>
                        <Box>
                            <Flex alignItems="center" pb="4">
                                <Text as="h3" fontWeight="bold" mr="1" >Zip Code</Text>
                                <Text as="span" color="GrayText" fontSize="sm" >(required)</Text>
                            </Flex>


                            <FormControl isInvalid={errors.zip}>
                                <Input {...register("zip", {required:"Please make sure you enter a 5 digit Zip Code",minLength: 5,maxLength: 5,})} placeholder="e.g. 90210"/>
                                <FormErrorMessage>
                                    {errors.zip && errors.zip.message}
                                </FormErrorMessage>
                            </FormControl>


                            <Flex justify="flex-end" ><Button onClick={onToggle} size="sm" mt="2">Full Address</Button></Flex>
                            <Collapse in={isToggleOpen} animateOpacity>
                                <Box mt="2">
                                    <Input {...register("addressLineOne")} placeholder="Address Line One" mb="2" />
                                    <Input {...register("addressLineTwo")} placeholder="Address Line Two" mb="2" />
                                    <HStack >
                                        <Input {...register("city", {maxLength: {value: 40, message:"Please make sure your name is no longer than 40 characters" }})} placeholder="City" />
                                        <FormControl>
                                            <Select 
                                                placeholder="State" {...register("state")}>
                                                    {states.map((state) => <option key={state} value={state}>{state}</option>)}
                                            </Select>
                                        </FormControl>
                                    </HStack>
                                </Box>
                            </Collapse>

                            <Divider my="6" />
                            {allServices.length > 0 && 
                            <VStack alignItems="flex-start" spacing="0">
                                <Text as="h3" pb="4" fontWeight="bold">Services</Text>
                                    <Wrap>
                                        {allServices.map((service) => {
                                            return (
                                                <Controller 
                                                    key={service.id}
                                                    control={control}
                                                    name={`services[${service.id}]`}
                                                    render={({field:{value, ref, name, onBlur, onChange}}) => (
                                                        <Checkbox spacing="0" display="flex" justifyContent="space-between" flexDirection="row-reverse" pb={2} w={["calc(100% - 0.5rem)"]} onBlur={onBlur} onChange={onChange} isChecked={!!value} ref={ref} value={service.id} >{service.name}</Checkbox>
                                                    )}
                                                />
                                            )
                                        })}
                                    </Wrap>
                            </VStack>
                            }
                            <Divider my="6" />
                            <Controller
                                control={control}
                                name={"instantBook"}
                                render={({field}) => (
                                    <Flex justify="space-between" alignItems="center" >
                                        <FormLabel m="0" fontWeight="bold" htmlFor={"instantBook"}>Instant Book</FormLabel>
                                        <Switch id={"instantBook"} {...field} size="lg" colorScheme="green" isChecked={!!field.value} />
                                    </Flex>
                                )}
                            />
                        </Box>
                        <Divider my="4"/>
                        <Button w="full" mb="4" borderRadius="full" type="submit" ><FontAwesomeIcon icon={faSearch}/></Button>

                    </form>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}