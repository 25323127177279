
/** represents how a work order is stored in the database */
export interface DbWorkorderTypeData {

    /** user id attached to this order */
    userId: string,

    /** a more readable workorder number */
    workorderNo:number,

    /** the user name, specific to this work order */
    firstName:string,

    /** the user name, specific to this work order */
    lastName:string,

    /** the user email, specific to this work order */
    email?:string,

    /** the user phone number, specific to this work order */
    phone?:string,
    
    /** description of the repair needed */
    description?:string,

    /** services that the user requested */
    selectedServiceIds?:string[];

    /** used to identify the garage they have booked an appointment with */
    selectedGarageId?:string,

    /** appointment */
    appointment?:[number,number],
    
    /** whether or not the garage has confimed this booking */
    confirmed?:boolean,

    /** whether or not they have insurance
     * (for firestore to search on - cannot do insurancePolicy!="" without wrecking search order)
     */
    hasInsurance:boolean,

    /** associated insurance policy number */
    insurancePolicy:string,

    /** associated insurance policy provider */
    insuranceProvider:string,

    /** the workorder is complete and has been moved to an archived status */
    closed:boolean,

    /** estimate/bid tracking for the garage's use */
    // estimate?:string,

    /** carera can add notes to the workorder to keep track of the state of things */
    // careraNotes?:string,

    /** field to show garage name */
    selectedGarageName?:string,

    /** somebody else who might drop-off/pick-up the car */
    altPickupPerson?:string

    /** the unix timestamp created date of workorder */
    createdDate?: number,

    /** the unix timestamp for date of booking (deprecated) */ 
    // bookedDate?: number,

    /** indicates that the appointment has been cancelled [appointmentDate,cancelledDate] */
    cancelled: [number, number] | boolean,
}

/** the database data, plus the doucment id */
interface DbWorkorderType extends DbWorkorderTypeData {

    /** id of this workorder in the database */
    id?:string,
};

export interface DbWorkorderNotes {

    /** carera can add notes to the workorder to keep track of the state of things */
    careraNotes?: string,

    /** estimate/bid tracking for the garage's use */
    estimate?:string,

}


/** for the special "counters" doc in the workorders */
export interface DbWorkorderCounters {
    closed_insurance:number,
    open_insurance:number,
    closed_noinsurance:number,
    open_noinsurance:number,
    cancelled_count:number,
    total:number
};

/** utility function to always properly format the workorder number */
export function formatWorkorderNo(workorderNo:number){
    const wo=String(workorderNo).padStart(12,'0');
    return wo.slice(0,-8)+'-'+wo.slice(-8,-4)+'-' +wo.slice(-4);
}

export default DbWorkorderType;