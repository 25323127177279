import {useState, useEffect} from 'react';
import styled from 'styled-components';

import FileIcon from './FileIcon';
import DbAttachment from "./DbAttachment";
import { IconButton } from "../Common/CommonStyledControls";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';

const StyledAttachment = styled.div`
    display: inline-flex;
    flex-direction: column;
    margin: 1em 0.75em;
    position:relative;
    max-height:150px;
    text-align:center;
`;

const Filename = styled.div`
    display:block;
    font-size:75%;
    background: #c7c7c7;
    padding: 0.3em 0 0.6em;
    border-bottom: solid 1px #c7c7c7;
    border-left: solid 1px #c7c7c7;
    border-right: solid 1px #c7c7c7;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #4b4b4b;
`;

const IconDelete = 
    <div style={{width:"1.4em", height:"1.4em", background:"#fff", borderRadius:"1em", display:"flex", alignItems:"center", justifyContent:"center"}}>
        <FontAwesomeIcon icon={faMinusCircle} style={{color: "#c91616", fontSize:"1.5em"}} />
    </div>

interface OnDeleteFunction { (url: string): void }


interface AttachmentProps {

    /** the attachment data from the database to display */
    dbAttachmentData:DbAttachment,

    /** if this is set to a function, it will make the image deleteable
     * and call this to do its dirty work.
     * 
     * it works like: onDelete(url)
     */
    onDelete?:OnDeleteFunction;
};


/** A component for a single attachment */
function Attachment({dbAttachmentData,onDelete}:AttachmentProps){
    const [fileExtension,setFileExtension]=useState('');
    const [isImage,setIsImage]=useState<boolean|undefined>();
    
    // this is set up to run once on component creation or if the filename changes
    useEffect(()=>{
        let namesplit=dbAttachmentData.fileName.split('.');
        var extension='';
        if(namesplit.length>1){
            extension='.'+namesplit[namesplit.length-1].toUpperCase();
        }
        setFileExtension(extension);
        setIsImage({'':false,'.SVG':true,'.JPG':true,'.JPEG':true,'.GIF':true,'.BMP':true,'.PNG':true}[extension]);
    }
    ,[dbAttachmentData.fileName]);

    return (
        <StyledAttachment draggable="true">
            {isImage?
                /* if it is an image, simply show it */ (
                <img height="120px" src={dbAttachmentData.url} alt="uploaded" />
            ):onDelete===undefined?
                /* if we are NOT in upload mode, file icon with download on click */(
                <a href={dbAttachmentData.url} download><FileIcon height="120px" extension={fileExtension} /></a>
            ):( /* if we are in upload mode, just a file icon */
                <FileIcon height="120px" extension={fileExtension} />
            )}
            <Filename>{dbAttachmentData.fileName}</Filename>
            {onDelete===undefined?'':(
                <IconButton style={{zIndex:1,position:"absolute",top:"-2px",right:"-7px"}} onClick={()=>onDelete(dbAttachmentData.url)}>{IconDelete}</IconButton>
            )}
        </StyledAttachment>
    );
}


export default Attachment;