import React from 'react';

function PageNotFound(){
  return (
    <div>
      <h3>
        404 - Page has been eaten by the Yeti!
      </h3>
    </div>
  );
}

export default PageNotFound;