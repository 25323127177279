import React, { useState, useEffect} from 'react';
import {DbInsuranceProviderData} from './DbInsuranceProvider';
import firebase, { db } from '../../firebase';
import { Input, InputGroup, InputRightElement, Select } from '@chakra-ui/react';


interface InsuranceProvidersListProps{

    /** if specified, will return the selected insurance provider */
    onChange?: (ids:string) => void;

    /** will determine the initial contents of the field */
    insuranceProviderSelection?: string,

    /** html form input name */
    name?: string,

    /** html form input readonly property */
    readOnly?: boolean,

    /** is this a required field? */
    required?: boolean
};


/** a list of insurance providers with drop-down combo box
 */
function InsuranceProvidersList({onChange,insuranceProviderSelection,name=undefined,readOnly=undefined,required=undefined}:InsuranceProvidersListProps){
    const [selectedInsuranceProvider,setSelectedInsuranceProvider]=useState<string>('');
    const [allInsuranceProviders,setAllInsuranceProviders]=useState<string[]>([]);

    const insuranceProvidersCollection = db.collection("insurance_providers");

    /** get all the insurance providers from the database */
    async function getInsuranceProviders() {
        if(insuranceProvidersCollection!==undefined){
            const unregister=insuranceProvidersCollection.onSnapshot(
                (querySnapshot)=>{
                    const items:string[]=[];
                    querySnapshot.forEach((doc)=>{
                        let data=doc.data() as DbInsuranceProviderData;
                        items.push(data.name);
                    });
                    setAllInsuranceProviders(items);
                    unregister();
                },
                (error:any) => {
                    console.error("InsuranceProvidersList.tsx: Error getting insurance providers");
                    console.error(error);
                }
            );
        }
    }
    
    // this is set up to run once on component creation
    useEffect(()=>{
        getInsuranceProviders();
      }
      ,[]); // eslint-disable-line react-hooks/exhaustive-deps

    /** set whether a service is selected */
    function setSelectionValue(serviceId:string){
        if(onChange){
            onChange(serviceId);
        }
        setSelectedInsuranceProvider(serviceId);
    }

    return (
        <InputGroup>
        <Input style={{backgroundColor:(required===true&&selectedInsuranceProvider.length<1?"pink":"rgba(0,0,0,0)")}}  type="text" readOnly={readOnly} name={name} placeholder='Insurance Provider' value={selectedInsuranceProvider} onChange={e=>setSelectionValue(e.target.value)} />
        {readOnly?"":(
        <InputRightElement children={(
            <Select width='40px' style={{padding:0,margin:0,width:'40px',color:'white',backgroundColor:'white'}} value={selectedInsuranceProvider} onChange={e=>setSelectionValue(e.target.value)}>
                {allInsuranceProviders?.map(provider=>(
                    <option key={provider} value={provider}>{provider}</option>
                ))};
                { // make the user-specified text into a selection
                allInsuranceProviders.indexOf(selectedInsuranceProvider)<0?(
                    <option style={{backgroundColor:"#ddd"}} key={selectedInsuranceProvider} value={selectedInsuranceProvider}>{selectedInsuranceProvider}</option>
                ):''}
            </Select>
        )} />)}
        </InputGroup>
    );
}


export default InsuranceProvidersList;