import axios from 'axios';
var moment = require('moment-timezone');


type DailySlotsType=[number,number,number,number][];
type WeeklySlotsType=DailySlotsType[];
export type LimitToHoursType=[[number,number][],[number,number][],[number,number][],[number,number][],[number,number][],[number,number][],[number,number][]]
export type AppointmentType=[moment.Moment,moment.Moment];
export type AppointmentsType=AppointmentType[];

/** list of appointments for each day of the month */
export type MonthlyAppointmentsType=AppointmentsType[]


// CHOOSE ONE:
// production server
const webservice="https://us-central1-carera-be9c6.cloudfunctions.net/getCalendar?url=";
// local firebase emulator
//const webservice="https://localhost:5001/carera-be9c6/us-central1/getCalendar?url=";


/**
 * starting with sunday, the available store hours for each day
 * [[from,to],...]
 */
 export const defaultLimitToHours:LimitToHoursType=[
    [], // sun
    [[900,1200],[1300,1700]], // mon
    [[900,1200],[1300,1700]], // tue
    [[900,1200],[1300,1700]], // wed
    [[900,1200],[1300,1700]], // thu
    [[900,1200],[1300,1700]], // fri
    [] // sat
];


/**
 * This particular set of store hours is used to keep things wide open when they want to exclusively 
 * use an external calendar.
 * 
 * starting with sunday, the available store hours for each day
 * [[from,to],...]
 */
 export const allLimitToHours:LimitToHoursType=[
    [[800,1800]], // sun
    [[800,1800]], // mon
    [[800,1800]], // tue
    [[800,1800]], // wed
    [[800,1800]], // thu
    [[800,1800]], // fri
    [[800,1800]] // sat
];


/** fetch a calendar via url 
 * fetch style is one of 
 * "client" - to attempt to fetch directly from web browser (subject to CORS)
 * "server" - work around CORS by using the server to do the fetch
 * "test" - load a test file instead
*/
async function fetchCalendar(url?:string,fetchStyle:string='server'){
    if(url===undefined||url===null||url===''){
        return undefined;
    }
    var data=undefined;
    if(fetchStyle==='client'){
        var response=null;
        try{
            response=await axios.get(url);
            data=response.data;
        }catch(error:any){
            if(error.name==="NetworkError"){
                console.error(error.request)
                console.error(error)
                console.error(`${error.response.status} - ${error.response.statusText}`);
                /* TODO: if the address is a public google calendar url such as 
                    https://calendar.google.com/calendar/ical/.../public/basic.ics
                    or
                    https://calendar.google.com/calendar/embed?src=... 
                    and they get a 404
                    then the problem is most likely that they did not make the calendar public */

            }else{
                throw(error);
            }
        }
    } else if(fetchStyle==='server') {
        var webserviceUrl=webservice+encodeURIComponent(url);
        try {
            let response=await axios.get(webserviceUrl);
            data=response.data;
        }catch(error:any){
            console.error("Unable to get garage's personal calendar!");
            console.error(error);
        }
    } else if(fetchStyle==='test'){
        const resp=await fetch('sample_data/calendar.ics');  // sample for testing
        data=await resp.text();
    }
    if(data===undefined){
        return undefined;
    }
    const ics=parse_ical(data);
    return ics;
}

/** simple, but effective ical prser
 * NOTE: ical_events(parse_ical(data)) will get you a simple array of events
 */
type IcalVal=[string,string|IcalCtx];
type IcalCtx=IcalVal[];
function parse_ical(data:string):IcalCtx{
    let ctxstack:IcalCtx[]=[];
    let namestack:string[]=[];
    let ctx:IcalCtx=[];
    let ctxName='';
    if(data===undefined){
        return ctx;
    }
    let lines=data.split('\n');
    for(let i=0;i<lines.length;i++){
        let [k,v]=lines[i].split(':',2);
        k=k.trim();
        if(v===undefined) v='';
        v=v.trim();
        if(k==='BEGIN'){
            namestack.push(ctxName);
            ctxstack.push(ctx);
            ctx=[];
            ctxName=v;
        }else if(k==='END'){
            if(v==='VCALENDAR') return ctx; // ended the calendar, so return it
            let p_obj=ctxstack.pop()
            if(p_obj===undefined) return ctx; // shouldn't happen
            p_obj.push([ctxName,ctx]);
            ctx=p_obj;
            ctxName=namestack.pop() as string;
        }else{
            ctx.push([k,v]);
        }
    }
    return ctxstack[0]; // we do the best we can
}

/** get an array of events from a parsed ical tree */
function ical_events(ical:IcalCtx):IcalCtx[]{
    return ical_ctxes(ical,'VEVENT');
}

/** return sub-ctxes of a ctx */
function ical_ctxes(ical:IcalCtx,name:string):IcalCtx[]{
    let ret:IcalCtx[]=[];
    for(let i=0;i<ical.length;i++){
        if(ical[i][0]===name) ret.push(ical[i][1] as IcalCtx);
    }
    return ret;
}

/** get single value from an ical */
function ical_val(ical:IcalCtx,name:string,defaultVal:any=undefined){
    for(let i=0;i<ical.length;i++){
        if(ical[i][0]===name) return ical[i][1];
    }
    return defaultVal;
}

/** get [name,value] where name starts with passed in name */
function ical_val_startswith(ical:IcalCtx,name:string,defaultVal:any=undefined){
    for(let i=0;i<ical.length;i++){
        if(ical[i][0].startsWith(name)) return ical[i];
    }
    return defaultVal;
}

/** returns [dayofweek[ timeslot[hour,min,endHour,endMin] ] ] representing start/end times for all appointment slots */
function getAllWeeklyTimeslots(limitToHours:LimitToHoursType|null=defaultLimitToHours,minutes:number=20):WeeklySlotsType{
    let ret:WeeklySlotsType=[]
    for(let day=0;day<7;day++){
        let slots:DailySlotsType=[];
        if(limitToHours!==null){ // make sure there are some hours
            limitToHours[day].forEach((timespan)=>{ // go through all timespans for this day
                var hour=Math.floor(timespan[0]/100);
                var minute=timespan[0]%100;
                if(minute%minutes!==0){
                    // be sure to start out on the next exact multiple of the minutes parameter
                    minute=Math.ceil(minute/minutes)*minutes;
                }
                var maxHour=Math.floor(timespan[1]/100);
                var maxMinute=timespan[1]%100;
                for(;hour<=maxHour;hour++){
                    let minutesThisHour=(hour===maxHour?maxMinute:60)-minutes;
                    for(;minute<=minutesThisHour;minute+=minutes){
                        slots.push([hour,minute,hour,minute+minutes])
                    }
                    minute=0;
                }
            });
        }
        ret.push(slots);
    }
    return ret;
}

/** split an ical params into params json object
 * eg "this=5;that = 10;something"
 * becomes {this:"5",that:"10",something:""}
 */
function icalParams(v?:string):object{
    var ret:any={};
    if(v!==undefined){
        v.split(';').forEach((param)=>{
            let kv:string[]=param.split('=',2);
            if(kv.length>1){
                ret[kv[0].trim()]=kv[1].trim();
            }else{
                ret[kv[0].trim()]="";
            }
        });
    }
    return ret as object;
}

/**
 * parse an ical duration
 * 
 * @param dur 
 * @param start the result will be a moment() offset by the duration
 */
function icalDuration(dur:string,start:moment.Moment){
    let e=start;
    if(dur.startsWith('P')){
        let re=/^P\s*(?<plusminus>[+-])?\s*((?<days>[0-9]+)\s*D)?\s*((?<weeks>[0-9]+)\s*W)?\s*((?<months>[0-9]+)\s*M)?\s*((?<years>[0-9]+)\s*Y)?\s*(T\s*((?<hours>[0-9]+)\s*H)?\s*((?<minutes>[0-9]+)\s*M)?\s*((?<seconds>[0-9]+)\s*S)?)?/g
        let match=re.exec(dur);
        if(match===null||match.groups===undefined){
            console.warn(`malformed ical duration time "${dur}"`);
        }else{
            let g=match.groups;
            if(g.plusminus===undefined||g.plusminus==='+'){
                if(g.days!==undefined)    e=e.days    (e.days()    +parseInt(g.days    ));
                if(g.weeks!==undefined)   e=e.weeks   (e.weeks()   +parseInt(g.weeks   ));
                if(g.months!==undefined)  e=e.months  (e.months()  +parseInt(g.months  ));
                if(g.years!==undefined)   e=e.years   (e.years()   +parseInt(g.years   )); 
                if(g.hours!==undefined)   e=e.hours   (e.hours()   +parseInt(g.hours   ));
                if(g.minutes!==undefined) e=e.minutes (e.minutes() +parseInt(g.minutes ));
                if(g.seconds!==undefined) e=e.seconds (e.seconds() +parseInt(g.seconds ));
            } else {
                if(g.days!==undefined)    e=e.days    (e.days()    -parseInt(g.days    ));
                if(g.weeks!==undefined)   e=e.weeks   (e.weeks()   -parseInt(g.weeks   ));
                if(g.months!==undefined)  e=e.months  (e.months()  -parseInt(g.months  ));
                if(g.years!==undefined)   e=e.years   (e.years()   -parseInt(g.years   )); 
                if(g.hours!==undefined)   e=e.hours   (e.hours()   -parseInt(g.hours   ));
                if(g.minutes!==undefined) e=e.minutes (e.minutes() -parseInt(g.minutes ));
                if(g.seconds!==undefined) e=e.seconds (e.seconds() -parseInt(g.seconds ));
            }
        }
    } else {
        console.warn(`malformed ical duration time "${dur}"`);
    }
    return e;
}

/** convert an ical time to moment object(s) */
function iCalTimeToMoment(k:string,v:string):AppointmentsType{
    let ret:AppointmentsType=[];
    let params:any=icalParams(k);
    if(params.VALUE===undefined||params.VALUE==='DATE-TIME'){
        // it is a date+time value
        let m:moment.Moment;
        if(params.TZID!==undefined){
            let tzid=params.TZID;
            let match=tzid.match(/(Pacific|Mountain|Central|Eastern)\s*(Standard|Daylight)\s*Time/)
            if(match!==null){
                tzid=`US/${match[1]}`;
            }
            m=moment.tz(v,tzid);
        } else {
            m=moment(v);
        }
        ret.push([m,m]);
    }else if(params.VALUE==='DATE'){
        // it is a whole day value 
        let s=moment(v).hour(0).minute(0).second(0);
        let e=moment(v).hour(23).minute(59).second(59);
        ret.push([s,e]);
    }else if(params.VALUE==='PERIOD'){
        // it is a time span
        v.split(',').forEach((period)=>{
            let kv=v.split('/',2);
            let s:moment.Moment;
            let e:moment.Moment;
            if(params.TZID!==undefined){
                s=moment.tz(kv[0],params.TZID);
            } else {
                s=moment(kv[0]);
            }
            if(kv.length<2||kv[1]===''){
                ret.push([s,s]);
            }else if(kv[1][0]==='P'){
                // this is start+some time
                e=icalDuration(kv[1],s);
                ret.push([s,e]);
            }else{
                // end is simply another date
                if(params.TZID!==undefined){
                    e=moment.tz(kv[1],params.TZID);
                } else {
                    e=moment(kv[1]);
                }
                ret.push([s,e]);
            }
        });
    }else{
        console.warn(`Unknown date type of VALUE="${params.VALUE}"`)
    }
    return ret;
}

/** takes one or more sets of times and returns a [start,end] that encoumpasses them all */
function maximizeTime(start?:moment.Moment|AppointmentsType,end?:moment.Moment|AppointmentsType):[moment.Moment,moment.Moment]{
    let minTime:moment.Moment|undefined=undefined;
    let maxTime:moment.Moment|undefined=undefined;
    function checkall(t:any){
        if(Array.isArray(t)){
            t.forEach(checkall);
        } else if(minTime===undefined||maxTime===undefined){
            minTime=t;
            maxTime=t;
        } else if(minTime<t){
            minTime=t;
        } else if(maxTime>t){
            maxTime=t;
        }
    }
    if(start!==undefined) checkall(start);
    if(end!==undefined) checkall(end);
    if(maxTime===undefined||minTime===undefined){
        let t=moment.Moment(0);
        return [t,t];
    }
    return [maxTime,minTime];
}

/**
 * month - moment object of the month to fill out
 * 
 * returns [[moment.start,moment.end]]
 */
function getScheduledAppointments(month:moment.Moment,fromCalendar?:IcalCtx):AppointmentsType{
    let ret:AppointmentsType=[];
    let freeTime:AppointmentsType=[]; // one way to get free time is from the calendar
    if(fromCalendar!==undefined){
        const events=ical_events(fromCalendar);
        for(let event of events){
            let dtstart=ical_val_startswith(event,'DTSTART',undefined);
            let s=dtstart[1]===undefined?undefined:iCalTimeToMoment(dtstart[0],dtstart[1]);
            let dtend=ical_val_startswith(event,'DTEND',undefined);
            let e=dtend[1]===undefined?undefined:iCalTimeToMoment(dtend[0],dtend[1]);
            let mt=maximizeTime(s,e);
            let start=mt[0];
            let end=mt[1];
            // add any blocks of free time
            let freebusy=ical_val_startswith(event,'FREEBUSY',undefined);
            if(freebusy!==undefined){
                let params:any=icalParams(freebusy[0]);
                if(params.FBTYPE==='FREE'){
                    freeTime=[...freeTime,...iCalTimeToMoment(freebusy[0]+';VALUE=PERIOD',freebusy[1] as string)];
                }
            }
            // figure out the madness of recurrance rules
            // see also: https://www.kanzaki.com/docs/ical/rrule.html and https://www.kanzaki.com/docs/ical/recur.html
            let recurrance=ical_val(event,'RDATE');
            if(recurrance!==undefined){
                console.warn('calendar "RDATE" not implemented');
            }
            recurrance=ical_val(event,'RRULE');
            if(recurrance!==undefined){
                let until;
                let count=99999;
                let days=[0,1,2,3,4,5,6];
                let interval=1;
                let duration=end.unix()-start.unix();
                let freq="DAILY";
                Object.entries(icalParams(recurrance)).forEach(([k,v])=>{
                    if(v==='UNTIL'){
                        until=iCalTimeToMoment(k,v)[0][0];
                    }else if(k==='FREQ'){
                        freq=v;
                    }else if(k==='COUNT'){
                        count=parseInt(v);
                    }else if(k==='INTERVAL'){
                        interval=parseInt(v);
                    }else if(k==='BYDAY'){
                        let daysList=['SU','MO','TU','WE','TH','FR','SA'];
                        days=v.split(',').map((day:string)=>daysList.indexOf(day.trim()));
                        if(days.length===0) days=[0,1,2,3,4,5,6];
                    }else{
                        // TODO: not all values are accounted for yet
                        console.warn(`param "${k}" for recurring event "${ical_val(event,'SUMMARY')}" found and we don't know how to handle those yet!`);
                    }
                });
                let d:moment.Moment=start;
                for(let i=0;i<count;i++){
                    if(until!==undefined&&d>until){
                        // gone far enough according to the rules
                        break;
                    }
                    if(d.year()>month.year()||(d.year()===month.year()&&d.month()>month.month())){
                        // gone too far for what we are looking for, nothing after this will match anyway
                        break;
                    }
                    if(d.year()===month.year()&&d.month()===month.month()){
                        // this occourance is within our month of interest
                        let appointment:AppointmentType=[d,moment(d).add(duration,'s')];
                        //console.log(`appointment "${ical_val(event,'SUMMARY')}" ${appointment}`);
                        ret.push(appointment);
                    }
                    do{ // find next matching event
                        if(freq==="SECONDLY"){
                            d=d.add(interval,'seconds');
                        } else if(freq==="MINUTELY"){
                            d=d.add(interval,'minutes');
                        } else if(freq==="HOURLY"){
                            d=d.add(interval,'hours');
                        } else if(freq==="WEEKLY"){
                            d=d.add(interval,'weeks');
                        } else if(freq==="MONTHLY"){
                            d=d.add(interval,'months');
                        } else if(freq==="YEARLY"){
                            d=d.add(interval,'years');
                        } else { // "DAILY"
                            d=d.add(interval,'days');
                        }
                    }while(days.indexOf(d.day())<0);
                }
            }else{
                if(start.month()!==month.month()&&end.month()!==month.month()){
                    // the event is not even for this month, so skip it
                    continue;
                }
                let appointment:AppointmentType=[start,end];
                //console.log(`appointment "${ical_val(event,'SUMMARY')}" ${appointment}`);
                ret.push(appointment);
            }
        }
    }
    return ret;
}


/**
 * check to see if a date falls within a range of dates
 */
function dateInRange(check:moment.Moment,range:[moment.Moment,moment.Moment]){
    return check.isAfter(range[0])&&check.isBefore(range[1]);
}


/**
 * check to see if two date ranges overlap at all
 */
 function dateRangesOverlap(range1:[moment.Moment,moment.Moment],range2:[moment.Moment,moment.Moment]){
    return dateInRange(range1[0],range2)||dateInRange(range1[1],range2)||dateInRange(range2[0],range1)||dateInRange(range2[1],range1);
}


/**
 * check to see if a proposed scheduled appointment would have conflicts
 * 
 * @param {*} appointment - [momentStart,momentEnd] 
 * @param {*} scheduledAppointments - [[momentStart,momentEnd]]
 * @returns {bool} - whether it conflicts or not
 */
function scheduleConflicts(appointment:[moment.Moment,moment.Moment],scheduledAppointments:[moment.Moment,moment.Moment][]):boolean{
    var conflicts=false;
    scheduledAppointments.every((existing)=>{
        if(appointment[0].date()===existing[0].date()&&dateRangesOverlap(appointment,existing)){
            //console.log("Appointment:",appointment)
            //console.log("Conflicts with:",existing)
            conflicts=true;
            return false; // quit looping
        }
        return true; // keep looping
    });
    return conflicts;
}

/**
 * Get all the open appointments for a given month
 * 
 * @param {*} slots - appointment time slots
 * @param {*} scheduledAppointments - appointment times already scheduled
 * @returns {*} [[startTime,endTime],...] - for each day of the month
 */
function monthlyAppointments(month:moment.Moment,slots:WeeklySlotsType,scheduledAppointments:AppointmentType[]):MonthlyAppointmentsType{
    let ret=[]
    var tz="US/Pacific";
    const now=moment().tz(tz);
    const today=moment(now).hour(0).minute(0).second(0).millisecond(0);
    var date:moment.Moment=moment.tz(month,tz); // start with this month
    date.date(1); // day of month
    date.second(0);
    date.millisecond(0);
    // go through each day in the month given and figure out what's available
    for(;date.month()===month.month();date.add(1,'d')){
        let schedToday:AppointmentType[]=[];
        if(!date.isBefore(today)){ // cannot schedule appointments in the past! (will still need to check times)
            let weekday=date.day();
            let weekdaySlots=slots[weekday];
            weekdaySlots.forEach((slot)=>{
                // check to see which time slot is open
                let sched:AppointmentType=[moment(date),moment(date)];
                sched[0].hour(slot[0]);
                sched[0].minute(slot[1]);
                sched[1].hour(slot[2]);
                sched[1].minute(slot[3]);
                if(!sched[1].isBefore(now)) { // make sure the time of day is not in the past
                    if(!scheduleConflicts(sched,scheduledAppointments)){ // make sure it is not booked
                        schedToday.push(sched);
                    }
                }
            });
        }
        ret.push(schedToday);
    };
    return ret;
}

/**
 * Gets all available appointments for a given month
 * 
 * @param {*} month - the month we are getting appointments for (a moment object) 
 * @param {*} calendarUrl - the online calendar whose slots we need to work around (optional)
 * @param {*} limitToHours - available hours available for appointments (see defaultLimitToHours for example) (optional)
 * @param {*} minutes - how many minutes each scheduled appointment should take (default=20)
 * @returns [eachdayofmonth[ appointment[startMoment,endMoment] ] ]
 */
async function getAvailableAppointments(month:moment.Moment,calendarUrl?:string,limitToHours:LimitToHoursType=allLimitToHours,minutes:number=20):Promise<MonthlyAppointmentsType>{
    // get all timeslots available within weekly store hours
    const appointmentSlots=getAllWeeklyTimeslots(limitToHours,minutes);
    // get all appointments already scheduled on garage's personal calendar
    const ical=await fetchCalendar(calendarUrl);
    const scheduledAppointments=getScheduledAppointments(month,ical);
    // trim down appointmentSlots to only those that are still available
    const availableAppointments=monthlyAppointments(month,appointmentSlots,scheduledAppointments);
    //console.log(availableAppointments);
    return availableAppointments;
}

export default getAvailableAppointments;