import { Badge, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Divider, Flex, Heading, HStack, Image, Link, Spacer, Stack, Tag, TagLabel, TagLeftIcon, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { Link as ReactRouterLink, useHistory, useParams } from 'react-router-dom';
import { functions } from '../../firebase';
import Loading from '../Common/Loading';
import SearchBar from '../Common/SearchBar';
import { ClassifiedListingFull, StorageImageData } from './AddClassifiedsForm';
import FsLightBox from "fslightbox-react";
import firebase from "firebase";
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import useViewport from '../Hooks/Viewport';


interface FunctionTimestamp{
    _seconds:number;
    _nanoseconds:number;
}


export const ClassifiedListing = () => {

    const {id}=useParams<{id:string}>();
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const getClassified = functions.httpsCallable('getClassified');
    const [classified, setClassified] = useState<ClassifiedListingFull|undefined>(undefined)

    useEffect(() => {
        onPageLoad()
    }, [])

    async function onPageLoad(){
        try {
            const response = await getClassified(id)
            const json = response.data
            const data = JSON.parse(json) as ClassifiedListingFull
            // console.log(data)
            setClassified(data)
            setLoading(false)
        } catch (error) {
            console.log(error)
        }

    }

    const onSearch = (searchString:string) => {
        if(searchString === '') {
            return
        }
        const searchTerm = encodeURI(searchString)
        console.log(searchTerm)
        history.push(`/dashboard/classifieds/search?term=${searchTerm}&page=1&size=20`)
    }

    const ClassifiedListingItem = ({listing}:{listing:ClassifiedListingFull}) => {

        const fT = listing.createdAt as unknown as FunctionTimestamp
        const fireBaseTime = new Date(
            fT._seconds * 1000 + fT._nanoseconds / 1000000,
          );

        return (
            <>
            <Flex w={"full"} mb={6} pos="relative">
                <SearchBar searchFn={onSearch} searchLeftStart={"calc(100% - 10rem)"} />
            </Flex>
            <Divider mt={[16,0]} mb={4}/>
            <ListingBreadcrumbs title={listing.title}/>
            <Stack align={'flex-start'} mb={12} direction={['column','row']} >
                <Box as='section' w={['100%','40%']} mt={2}>
                    {listing.images
                        ? <PhotoGallery images={listing.images} />
                        : <EmptyImage />
                    }
                </Box>
                <Box as='article' w={['full','60%']}>
                    <Heading size={'lg'}>{listing.title}</Heading>
                    <Badge colorScheme={'green'}>listed {fireBaseTime.toLocaleDateString()}</Badge>
                    <Text mt={6}>{listing.description}</Text>
                    {
                        (listing.phone || listing.email) &&
                        <HStack mt={4}>
                            {listing.phone && 
                                <Link href={`tel: ${listing.phone}`}>
                                    <Tag borderRadius={'full'} size={'lg'}>
                                        <TagLeftIcon as={PhoneIcon} />
                                        <TagLabel>{listing.phone}</TagLabel>
                                    </Tag>
                                </Link>
                            }
                            {listing.email && 
                                <Link href={`mailto: ${listing.email}`}>
                                    <Tag borderRadius={'full'} size={'lg'}>
                                        <TagLeftIcon as={EmailIcon} />
                                        <TagLabel>{listing.email}</TagLabel>
                                    </Tag>
                                </Link>
                            }
                        </HStack>
                    }
                    <Heading mt={8} mb={8}>${listing.price}</Heading>
                </Box>
            </Stack>
            </>
        )
    }

    const ListingBreadcrumbs = ({title}:{title:string}) => (
        <Breadcrumb mb={2}>
            <BreadcrumbItem>
                <BreadcrumbLink as={ReactRouterLink} to={'/dashboard'}>Dashboard</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
                <BreadcrumbLink as={ReactRouterLink} to={'/dashboard/classifieds/latest'} >Classifieds</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink href='#'>{title.substring(0,10)}...</BreadcrumbLink>
            </BreadcrumbItem>
        </Breadcrumb>
    )

    const EmptyImage = () => {
        return (
            <Box pb={'100%'} pos={'relative'} display={'block'}>
                <Stack w={'100%'} h={'100%'} pos={'absolute'} justifyContent={'center'} alignItems={'center'}><FontAwesomeIcon icon={faImage} color={'lightgray'} style={{fontSize:"75px"}} /></Stack>
            </Box>
        )
    }

    const PhotoGallery = ({images}:{images:StorageImageData[]}) => {

        const [mainImage, setMainImage] = useState<number>(0)
        const [slideImages, setSlideImages] = useState<string[]>([])
        const [lightboxController, setLightboxController] = useState({
            toggler: false,
            slide: 1
        });

        const setNewMainImage = (arrNum:number) => {
            setMainImage(arrNum)
        }

        function openLightboxOnSlide(number:number) {
            setLightboxController({
                toggler: !lightboxController.toggler,
                slide: number
            });
        }

        useEffect(() => {
            const imgArr:string[] = []
            images.map((i) => {
                imgArr.push(i.url)
            })
            setSlideImages(imgArr)
        }, [])
        
        
        return(
            <>
            <Box mr={[0,8]}>
                <Box className='img-container' pos={'relative'} display={'block'} pb={'100%'} border={'2px'} borderColor={'gray.200'}>
                    <Image src={images[mainImage].url} w={'100%'} h={'100%'} pos={'absolute'} objectFit={'cover'} onClick={()=>openLightboxOnSlide(mainImage+1)} cursor={'pointer'}/>
                </Box>
                <HStack mt={3} spacing={1}>
                    {images.map((item, index) => (
                        <Image border={'4px'} borderColor={mainImage === index ? "blue.400" : "transparent"} w={'100px'} h={'100px'} objectFit={'cover'} key={item.fileName} src={item.thumbUrl} onClick={()=>{setNewMainImage(index)}} cursor={'pointer'} />
                    ))}
                </HStack>
            </Box>
            <FsLightBox
                type={'image'}
                toggler={lightboxController.toggler}
                sources={slideImages}
                slide={lightboxController.slide}
            />
            </>
        )
    }
    

    return (
        <>
        {
            !loading && classified
            ? <ClassifiedListingItem listing={classified} />
            : <Loading />
        }
        </>
    )
}
