import React, { useContext, useEffect, useState } from 'react'
import { ClassifiedListing, ClassifiedListingFull } from './AddClassifiedsForm';
import { ClassifiedFirebaseResponse } from './ViewClassifieds';
import firebase, { db, functions } from '../../firebase'
import AuthContext from '../Account/AuthContext';
import Loading from '../Common/Loading';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Heading, HStack, Table, Tbody, Td, Th, Thead, Tr, VStack, Text, IconButton, useDisclosure, useToast, Image, Stack } from '@chakra-ui/react';
import { NoResults } from '../Common/Graphics';
import ClassifiedsTableView from './ClassifiedsTableView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage, faImage } from '@fortawesome/free-solid-svg-icons';
import { CloseIcon, EditIcon } from '@chakra-ui/icons';
import { DeleteMyClassifiedModal } from './DeleteMyClassifiedModal';
import { EditMyClassifiedModal } from './EditMyClassifiedModal';
import useViewport from '../Hooks/Viewport';

export interface EditProps {
    loading:boolean;
    data?:ClassifiedListingFull|undefined;
    error?:any|undefined;
}

export const MyClassifieds = () => {

    const Auth = useContext(AuthContext);
    const garageId = Auth.garageId
    const listingCount = 20;
    const classifiedRef=db.collection("classifieds");
    let termQuery = classifiedRef.where("garageId", "==", garageId).orderBy("createdAt").limit(listingCount);
    const [docQuery, setDocQuery] = useState(termQuery)
    const [nextPage, setNextPage] = useState<boolean>(false);
    const [list, setList] = useState<ClassifiedListing[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { width } = useViewport();
    const smBreakpoint = 480;

    useEffect(() => {
        const items:ClassifiedListing[] = [];
        docQuery.get().then((snapshot) => {
            snapshot.forEach(async (doc) => {
                const id = doc.id
                let data:ClassifiedListing = {id:id, ...doc.data() as ClassifiedFirebaseResponse}    
                items.push(data)
            })
            if(items.length === listingCount+1){
                const lastVisible = snapshot.docs[snapshot.docs.length-2]
                const next = termQuery.startAfter(lastVisible).limit(listingCount);
                setNextPage(true)
                setDocQuery(next)
            } 
        }).finally(()=>{
            setList(items)
            setLoading(false)
        })
    }, []);

    
    const moreListings = () => {
        const items:ClassifiedListing[] = [];
        docQuery.get().then((snapshot) => {
            snapshot.forEach(async (doc) => {
                const id = doc.id
                let data:ClassifiedListing = {id:id, ...doc.data() as ClassifiedFirebaseResponse}    
                items.push(data)
            })
            if(items.length === listingCount+1){
                const lastVisible = snapshot.docs[snapshot.docs.length-2]
                const next = termQuery.startAfter(lastVisible).limit(listingCount);
                setNextPage(true)
                setDocQuery(next)
            } else {
                setNextPage(false)
            }
        }).finally(()=>{
            setList((oldList)=> {
                const updatedList = [...oldList,...items]
                return updatedList
            })
            setLoading(false)
        }).catch(err => {
            console.log(err)
        })
    }
    

    const TableView = ({classifieds, itemCount}:{classifieds:ClassifiedListing[], itemCount:number}) => {

        const { isOpen, onOpen, onClose } = useDisclosure()
        const { isOpen:isOpenEdit, onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure()
        const cancelRef:any = React.useRef()
        const cancelRedEdit:any = React.useRef()
        const [itemId, setItemId] = useState<string|undefined>(undefined);
        const toast = useToast();
        const deleteClassified = functions.httpsCallable('deleteClassified');
        const items = classifieds.slice(0,itemCount);
        const getClassified = functions.httpsCallable('getClassified');
        const [editState, setEditState] = useState<EditProps>({loading:false})

        const onCloseDialog = () => {
            setItemId(undefined)
            onClose()
        }

        const onConfirm = (id:string) => {
            setItemId(id)
            onOpen()
        }

        const onEditClose = () => {
            setEditState({loading:false, data:undefined, error:undefined})
            onCloseEdit()
        }

        const onCompleteFn = (classified:Partial<ClassifiedListing>|undefined) => {
            // find the existing classified listing with the same id and replace it in the state
            if(classified && classified.id){
                const newClassifieds = classifieds.map((c) => {
                    if(c.id === classified.id){
                        const updatedClassified = {...c, ...classified}
                        return updatedClassified
                    } else {
                        return c
                    }
                })
                setList(newClassifieds)
            }
            toast({
                title:'Successfully edited listing',
                description:'The listing was successfully edited',
                status: 'success',
                duration: 3000,
                isClosable: true
            })
        }

        const loadEditData = (id:string) => {
            setEditState({loading:true})
            onOpenEdit()
            getClassified(id)
            .then((value) => {
                const json = value.data
                const data = JSON.parse(json) as ClassifiedListingFull
                setEditState({loading:false, data:data})
            })
            .catch((err) => {
                setEditState({loading:false, error:err})
            })
        }



        const onDelete = async () => {
            if(itemId){
                try {
                    const result = await deleteClassified(itemId)
                    const id = result.data as string
                    if(id === itemId){
                        setList((oldList) => {
                            const newList = oldList.filter((item)=>{
                                return item.id !== itemId
                            })
                            return newList
                        })
                        // onClose()
                        toast({
                            title:'Listing Deleted',
                            description: `The listing was successfully deleted`,
                            status: 'success',
                            duration: 5000,
                            isClosable: true
                        })
                    }
                } catch (error) {
                    console.log(error)
                    toast({
                        title:'Error',
                        description: `There was an error removing the listing, please try again`,
                        status: 'error',
                        duration: 5000,
                        isClosable: true
                    })
                }
            }
        }

        // const onDelete = (id:string) => {
        //     if(id === itemId){
        //         setList((oldList) => {
        //             const newList = oldList.filter((item)=>{
        //                 return item.id !== itemId
        //             })
        //             return newList
        //         })

        //         toast({
        //             title:'Listing Deleted',
        //             description: `The listing was successfully deleted`,
        //             status: 'success',
        //             duration: 5000,
        //             isClosable: true
        //         })
        //     }
        // }

        return (
            <>
        <Box px={[0,4]} overflowX="auto" w="full" mt={[10,0]} >
            <Table variant="striped" size={"md"} style={{tableLayout:'fixed'}}>
                <Thead>
                    <Tr>
                        <Th px={[1,6]} w={"50px"}></Th>
                        <Th px={[1,4]} w={['45%',"15%"]}>Title</Th>
                        {width > smBreakpoint && <Th px={2} w={"40%"}>Description</Th>}
                        <Th px={[0,6]} textAlign={['center','left']} w="15%">{width>smBreakpoint ? 'Price' : '$'}</Th>
                        <Th px={[0,6]} w={['25%',"15%"]} textAlign={'center'} >{width>smBreakpoint ? 'Actions' : ''}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                { 
                    items.map((classified)=>(
                        <TableRow key={classified.id} classified={classified} onConfirm={onConfirm} onEdit={loadEditData} isAdmin={Auth.isAdmin} />
                    ))
                }
                </Tbody>
            </Table>
        </Box>
        <DeleteMyClassifiedModal isOpen={isOpen} cancelRef={cancelRef} onCloseDialog={onCloseDialog} onDelete={onDelete} itemId={itemId} />
        <EditMyClassifiedModal isOpen={isOpenEdit} onCloseDialog={onEditClose} onComplete={onCompleteFn} editState={editState} />
        </>
        )
    }

    const TableRow = ({classified, onConfirm, onEdit, isAdmin}:{classified:ClassifiedListing, onConfirm:(id:string)=>void, onEdit:(id:string)=>void, isAdmin:boolean|undefined}) => {
    
        return (
            <>
            <Tr>
                <Td px={[1,6]} textAlign={"center"} w={"50px"}>
                    <Box minW={'50px'} w={'50px'} h={'50px'} border={'1px'} borderColor={'gray.200'} mr={2}>
                    {
                        classified.thumbUrl
                        ? <Image width={'full'} height={'full'} objectFit={"cover"} src={classified.thumbUrl}/>
                        : <Stack h={'full'} justifyContent={'center'} alignItems={'center'}><FontAwesomeIcon icon={faImage} color={'lightgray'} style={{fontSize:"25px"}} /></Stack>
                    }
                    </Box>
                </Td>
                <Td px={[2,4]}  w={['45%','15%']} fontSize="sm" fontWeight="bold" pos={"relative"} >
                    <Heading as={'h4'} size={'sm'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'} >{classified.title}</Heading>
                </Td>
                {width > smBreakpoint && <Td px={2} w={'40%'} pos={"relative"} textOverflow="ellipsis" >
                    <Text noOfLines={1}>{classified.summary}</Text>
                </Td>}
                <Td px={[0,6]} w={'15%'} textOverflow="ellipsis">${classified.price}</Td>
                <Td px={[0,6]} w={['25%','15%']} textAlign={"center"} >
                    <IconButton onClick={()=>onEdit(classified.id)} aria-label='edit' bg={"gray.200"} icon={<EditIcon/>} />
                    <IconButton ml={1} onClick={()=>onConfirm(classified.id)} bg={"gray.200"} aria-label="delete" icon={<CloseIcon/>}/>
                </Td>
            </Tr>
            </>
        )
    }

    
  return (
    <>
    {
        loading
        ? <Loading/>
        : list.length > 0
            ?<VStack>
                <TableView classifieds={list} itemCount={listingCount} />
                {nextPage && <HStack p={2} justify={'center'} ><Button onClick={moreListings}>Next</Button></HStack>}
            </VStack> 
            : <NoResults />
    }
    </>
  )
}
