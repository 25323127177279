import { Image } from "@chakra-ui/image";
import { Skeleton } from "@chakra-ui/react";
import {useImage} from "../Hooks/LoadImage";

export const PreloadImage = ({src, loadingImages, height, width = "full"}:{src: string, loadingImages: boolean, height: string, width?: string}) => {

    const { hasLoaded } = useImage(src);

    return (
        <>
        {loadingImages || !hasLoaded 
            ? <Skeleton height={height} w={width}/> 
            : <Image src={src} w={width} h={height} fit={"cover"} /> 
        }
        </>
    )
}