import {useState, useEffect, useContext, useRef} from 'react';
import { RouteProps } from 'react-router-dom';

import AuthContext from "../Account/AuthContext";
import firebase, { db } from '../../firebase';
import algoliasearch from 'algoliasearch';
import { SearchResponse } from '@algolia/client-search';

import DbWorkorderType, { DbWorkorderCounters, DbWorkorderTypeData } from './DbWorkorderType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripHorizontal, faTable } from '@fortawesome/free-solid-svg-icons'

import { Flex, IconButton, Text, Spacer, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import Loading from '../Common/Loading';
import * as Scroll from 'react-scroll';
import WorkorderViewOption from './WorkorderViewOption';
import { AvailableInsuranceProviders } from '../InsuranceProviders/AvailableInsuranceProviders';
import SearchBar from '../Common/SearchBar';
import useViewport from '../Hooks/Viewport';
import {NoResults} from '../Common/Graphics';


const algoliaClient=algoliasearch('XS1SQLAXE9','af8f230b9d3aeb9c8a16febb6666ac26');
const algoliaIndex=algoliaClient.initIndex('work_orders');


let Element = Scroll.Element;
let scroller = Scroll.scroller;

interface WorkorderTableProps {
    /** option to filter workorders by garage id */
    filterByGarageId?: string;

    /** option to filter workorders by user id */
    filterByUserId?: string;

    /** Magical way to get cgi query parameters part of the url */
    location: RouteProps["location"];
};


/** convert a string to true,false, or undefined */
function tristate(s?:string|null){
    if(s!==null&&s!==undefined&&s!==''&&s!=='undefined'){
        if(s[0]==='1'||s[0]==='y'||s[0]==='t'){
            return true;
        }else{
            return false;
        }
    }
    return undefined;
}


const defaultItemsPerPage=10;


/** A list of workorders based on garage, user, or just all of them */
function WorkorderTable({filterByGarageId, filterByUserId, location}:WorkorderTableProps){

    // unpack cgi query parameters part of the url
    const query = new URLSearchParams(location===undefined?'':location.search);

    const [page,setPage]=useState(0);
    const [itemsPerPage,setItemsPerPage]=useState(defaultItemsPerPage);
    const [searchText,setSearchText]=useState<string>('');
    const [displayedWorkorders,setDisplayedWorkorders]=useState<DbWorkorderType[]>([]);
    const [totalItems,setTotalItems]=useState<number>(); // total number of items
    const [tableView, setTableView] = useState(true); // view type, table or grid
    const [loading, setLoading] = useState(true); // initial state of page
    const [pagLoading, setPagLoading] = useState(false); // pagination loading state
    const [isSearching, setIsSearching] = useState({
        state: false,
        term: '',
    });
    const { width } = useViewport();
    const dkBreakpoint = 992;
    const smBreakpoint = 480;

    const garageId=useRef(filterByGarageId);
    const userId=useRef(filterByUserId);
    const dbCounters=useRef<DbWorkorderCounters>();

    const insurance=useRef(tristate(query.get("insurance")));
    const closed=useRef(tristate(query.get("closed")));
    const cancelled=useRef(tristate(query.get("cancelled")));
    const dbRecords=useRef<firebase.firestore.DocumentData[]>([]); // maintain this so we can jump back to a particular point

    const workordersCollection=db.collection("work_orders");
    
    const Auth = useContext(AuthContext);

    useEffect(() => {
        let newInsurance = tristate(query.get("insurance"));
        let newClosed = tristate(query.get("closed"));
        let newCancelled = tristate(query.get("cancelled"));
        if(newInsurance!==insurance.current || newClosed!==closed.current){
            insurance.current = newInsurance;
            closed.current = newClosed;
            cancelled.current = newCancelled;
            dbRecords.current = [];
            setLoading(true);
            calculateTotalItems();
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    // this is set up to run once on component creation
    useEffect(()=>{
        // limit what the logged-in user can access
        if(Auth.isLoggedIn){
            if(Auth.isAdmin){
                // can do whatever
            }else if(Auth.isGarage){
                garageId.current=Auth.garageId;
            }else{
                userId.current=Auth.id;
            }
        }else{
            garageId.current='0';
            userId.current='0';
        }
        calculateTotalItems();
    }
    ,[]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(width < dkBreakpoint){
            setTableView(false);
        }
    }, [])

    /**
     * figure out how many workorders there are so we can make pagination work
     */
    async function calculateTotalItems(){
        var countersDoc;
        try{
            countersDoc=await workordersCollection.doc('counters').get();
        }catch(error:any){
            console.error("Error getting workorder counters");
            console.error(error);
            return;
        }
        var counters=countersDoc.data() as DbWorkorderCounters|undefined;
        if(counters!==undefined){
            // make sure each of them is always set
            if(counters.total===undefined) counters.total=0;
            if(counters.closed_insurance===undefined) counters.closed_insurance=0;
            if(counters.closed_noinsurance===undefined) counters.closed_noinsurance=0;
            if(counters.open_insurance===undefined) counters.open_insurance=0;
            if(counters.open_noinsurance===undefined) counters.open_noinsurance=0;
            if(counters.cancelled_count===undefined) counters.cancelled_count=0;
        }
        dbCounters.current=counters;
        //repairDatabaseCounters();
        selectDbCounter();
    }

    /** utility to fix the counters record in the database */
    async function repairDatabaseCounters(){ // eslint-disable-line 
        var dbResults;
        try{
            dbResults=await workordersCollection.get();
        }catch(error:any){
            console.error("Error getting workorders collection");
            console.error(error);
            return;
        }
        let counters:DbWorkorderCounters={
            closed_insurance:0,
            open_insurance:0,
            closed_noinsurance:0,
            open_noinsurance:0,
            cancelled_count:0,
            total:dbResults.size
        }
        dbResults.forEach((record)=>{
            let data=record.data();
            if(data.closed){
                if(data.insurancePolicy===undefined||data.insurancePolicy===''){
                    counters.closed_noinsurance++;
                } else {
                    counters.closed_insurance++;
                }
            } else {
                if(data.insurancePolicy===undefined||data.insurancePolicy===''){
                    counters.open_noinsurance++;
                } else {
                    counters.open_insurance++;
                }
            }
        });
        await workordersCollection.doc('counters').set(counters);
    }

    /** select which database counter to be used based upon insurance and closed filters */
    function selectDbCounter(){
        var counters=dbCounters.current!==undefined?dbCounters.current:{
            total:0,
            closed_insurance:0,
            closed_noinsurance:0,
            open_insurance:0,
            open_noinsurance:0,
            cancelled_count:0,
        };
        var total=0;

        if(insurance.current===undefined && closed.current===undefined && cancelled.current===undefined){
            total=counters.total;
        }else {
            insurance.current ? total=counters.open_insurance : total=counters.open_noinsurance;
            if(closed.current) total=counters.closed_noinsurance + counters.closed_insurance;
            if(cancelled.current) total=counters.cancelled_count;
        }
        
        // console.log("ITEMS:",counters);
        setTotalItems(total);
        setLoading(false)
    }

    /** called after we get the item count 
     * this is what loads the first page of data
    */
    useEffect(()=>{
        onPageChange(0,itemsPerPage);
    },[totalItems]);   // eslint-disable-line react-hooks/exhaustive-deps

    
    /** restart the search whenever the search text changes
    */
     useEffect(()=>{
        search(searchText)
    },[searchText]);   // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * called when the page we are on changes
     */
    async function onPageChange(startItem:number,count:number){
        let pageNo=Math.floor(startItem/count);
        setPage(pageNo);
        if(searchText===''){
            // we are getting the next page from firebase
            setPagLoading(true);
            //console.log(`onPageChange to item ${startItem} and get ${count} items`);
            // get more stuff from the database if necessary
            if(totalItems !== undefined && dbRecords.current.length<totalItems){ // if we don't already have everything downloaded
                if(startItem+count>dbRecords.current.length){ // if we haven't already downloaded this range
                    let downloadStartIdx=Math.max(dbRecords.current.length,startItem);
                    let downloadStartDoc=downloadStartIdx===0?undefined:dbRecords.current[downloadStartIdx-1];
                    let downloadCount=(startItem+count)-dbRecords.current.length; // the count, minus any portion we may already have
                    await loadWorkorders(downloadStartDoc,downloadCount).then(() => {if(loading)setLoading(false)});
                }
            }
            let docs=dbRecords.current.slice(startItem,startItem+count);
            let workorders:DbWorkorderType[]=docs.map((doc)=>{return {...doc.data() as DbWorkorderType,id:doc.id}});
            setDisplayedWorkorders(workorders);
            setPagLoading(false);
            if(window.scrollY!==0){
                scroller.scrollTo('listContainer',{duration: 500, smooth: true, offset: -24});
            }
        } else {
            // next page of search results
            search(searchText,pageNo,count);
        }
        if(count!==itemsPerPage){
            setItemsPerPage(count);
        }
    }

    // useEffect( () => {
    //     if(totalItems !== undefined && loading){
    //         setLoading(false)
    //     }
    // },[displayedWorkorders]); // eslint-disable-line react-hooks/exhaustive-deps

    /** Loads a block of workorders from the database 
     * 
     * downloadStartIdx - where in dbRecords we should start downloading to (usually will be the end)
     * count - how many records to pull (if undefined, get everything)
     *
     * fills out dbRecords with the values and returns nothing
    */
    async function loadWorkorders(startWithDoc:firebase.firestore.DocumentData|undefined=undefined,count:number|undefined=undefined) {
        let u:firebase.firestore.CollectionReference|firebase.firestore.Query=workordersCollection;
        // where clauses
        if(garageId.current!==undefined){
            u=u.where('selectedGarageId','==',garageId.current);
        }
        if(userId.current!==undefined){
            u=u.where('userId','==',userId.current);
        }
        if(insurance.current!==undefined){
            u=u.where('hasInsurance','==',insurance.current);
        }
        if(closed.current!==undefined){
            u=u.where('closed','==',closed.current);
        }
        if(cancelled.current!==undefined){
            u=u.where('cancelled','!=', false);
        }else if(insurance.current!==undefined || closed.current!==undefined){
            u=u.where('cancelled','==',false);
        }
        // ordering of results
        if(cancelled.current!==undefined){
            u=u.orderBy('cancelled')
        }else{
            u=u.orderBy('createdDate','desc');
        }
        // pagination
        if(startWithDoc!==undefined){
            u=u.startAfter(startWithDoc);
        }
        if(count!==undefined){
            u=u.limit(count);
        }
        // DO IT!
        var dbResults;
        try{
            dbResults=await u.get();
        }catch(error:any){
            console.error("Error getting workorder query");
            console.error(error);
            return;
        }
        // evaluate the results
        if(count===undefined){
            setTotalItems(dbResults.size);
        }
        dbResults.forEach((doc)=>{
            dbRecords.current.push(doc);
        });
    }

    function updateState(workorder:DbWorkorderType){
        const updatedOrders = displayedWorkorders.map((order) => {
            if(order.id === workorder.id){
                return workorder;
            } else {
                return order;
            }
        });
        setDisplayedWorkorders(updatedOrders);
    }

    /** process search results */
    function processSearchResults(response:SearchResponse<DbWorkorderTypeData>):DbWorkorderType[]{
        return response.hits.map(hit=>({...hit,id:hit.objectID}));
    }

    /** Search for a particular workorder 
     */
    async function search(queryString:string,page:number=0,numItemsPerPage:number=itemsPerPage){
        if(queryString===''){
            // they blanked out the search box, so get things directly from firebase again
            setIsSearching({
                state: false,
                term: '',
            });
            setDisplayedWorkorders([]);
            selectDbCounter();
            onPageChange(0,itemsPerPage);
            return;
        }
        setIsSearching({
            state: true,
            term: queryString,
        });
        setLoading(true);
        let searchOptions:any={
            hitsPerPage: numItemsPerPage
        }
        if(page!=0){
            searchOptions.page=page;
        }
        if(closed.current!==undefined){
            searchOptions.filters=`closed:${closed.current}`;
        }
        if(insurance.current!==undefined){
            searchOptions.filters=`hasInsurance:${insurance.current}`;
        }
        queryString=queryString.replaceAll('-','').replaceAll(' ','');
        // since workorderNo is stored as number, need to strip any leading zeroes to match properly
        while(queryString.length>1 && queryString[0]==='0'){
            queryString=queryString.slice(1);
        }
        const response=await algoliaIndex.search<DbWorkorderTypeData>(queryString,searchOptions);
        setTotalItems(response.nbHits);
        const items=processSearchResults(response);
        setDisplayedWorkorders(items);
        setLoading(false);
    }

    return (
        <>
        <AvailableInsuranceProviders> {/* Want to make the insurance providers list available to all child componenets. */}
        <Element name='listContainer' className="list_container">
            <Flex w={"full"} mb={6} px={[0,4]} pos="relative">
                <SearchBar searchFn={search} searchLeftStart={width < smBreakpoint ? "calc(100% - 8.5rem)" : "calc(100% - 10rem)"} />
                <Spacer/>
                <IconButton aria-label="table" mr={[2,3]} isActive={tableView} onClick={() => setTableView(true)} icon={<FontAwesomeIcon icon={faTable}/>} />
                <IconButton aria-label="card" isActive={!tableView} onClick={()=>setTableView(false)} icon={<FontAwesomeIcon icon={faGripHorizontal} />} />
            </Flex>
            { isSearching.state && 
                <Flex px={4} pb={6} alignItems="center">
                    <Text as="h1" fontSize="large" >Search Results for: </Text>
                    <Tag size="lg" colorScheme="blue" fontWeight="bold" ml={2} fontStyle="italic" ><TagLabel>{isSearching.term}</TagLabel><TagCloseButton onClick={() => {search('');setSearchText('');}} /></Tag>
                </Flex> 
            }
            {loading && <Loading/>}
            {totalItems !== undefined && totalItems > 0
                ? <WorkorderViewOption tableView={tableView} page={page} displayedWorkorders={displayedWorkorders} totalItems={totalItems} onPageChange={onPageChange} itemsPerPage={itemsPerPage} pagLoading={pagLoading} updateState={updateState} />
                : <NoResults />
            }
        </Element> 
        </AvailableInsuranceProviders>
        </>
    );
}


export default WorkorderTable;