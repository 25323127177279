import { Box, Button, Flex, Link, Wrap, useColorModeValue, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, CloseButton, useDisclosure, Spacer } from '@chakra-ui/react'
import React from 'react'
import CareraLogo from '../../carera-logo';
import Header from './HeaderLisa';
import { Link as ReactRouterLink} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

export const Nav = () => {

    const [scrolling, setScrolling] = React.useState(false);
    const [scrollTop, setScrollTop] = React.useState(0);
    const background = useColorModeValue('white', 'gray.800');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const logoColor = useColorModeValue("black", "white");

    React.useEffect(() => {
        function onScroll() {
        let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
        if (currentPosition > scrollTop) {
            // downscroll code
            setScrolling(false);
        } else {
            // upscroll code
            setScrolling(true);
        }
        setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    return (
        <>
        <Box top="0" width="full" pos={scrolling ? "fixed" : "absolute"} zIndex={"modal"} display={["none", "none", "block"]} bgColor={scrolling ? background : "transparent"} boxShadow={scrolling ? "0 0 20px rgb(0 0 0 / 20%)" : "none"} >
            <Header >
                <Wrap spacing="4" color={useColorModeValue('gray.800', 'gray.100')}>
                    {/* <Link to="get-started" spy={true} duration={750}><Button size="sm" variant="outline" >Get Started</Button></Link>
                    <Link to="how-it-works" spy={true} duration={750} ><Button size="sm" variant="outline">How It Works</Button></Link>
                    <Link to="reviews" spy={true} duration={750}><Button size="sm" variant="outline">Reviews</Button></Link> */}
                    <HashLink to={'/#how-it-works'}><Button size={'md'} variant={'outline'}>Features</Button></HashLink>
                    <Link as={ReactRouterLink} to="/contact"><Button size="md" variant="outline">Contact</Button></Link>
                </Wrap>
            </Header>
        </Box>
        <Flex top="0" w="full" pos="fixed" display={["block", "block", "none"]} bgColor={background} zIndex={"modal"} >
            <Header showButton buttonFn={onOpen} />
        </Flex>
        <Drawer isOpen={isOpen} onClose={onClose} placement={"right"}>
            <DrawerOverlay />
            <DrawerContent >
                <DrawerHeader> <Flex justify="space-between" > <CareraLogo width="30%" fillColor={logoColor} /> <CloseButton color={logoColor} onClick={onClose} /></Flex> </DrawerHeader>
                <DrawerBody>
                        {/* <Link to="get-started" spy={true} duration={750} ><Button onClick={onClose} size="lg" mb="2" variant="ghost" w="full" >Get Started</Button></Link>
                        <Link to="how-it-works" spy={true} duration={750} ><Button onClick={onClose} size="lg" mb="2" variant="ghost" w="full">How It Works</Button></Link>
                        <Link to="reviews" spy={true} duration={750} ><Button onClick={onClose} size="lg" variant="ghost" w="full">Reviews</Button></Link> */}
                        <HashLink to={'/#how-it-works'}><Button size={'md'} variant={'outline'}>Features</Button></HashLink>
                        <Link as={ReactRouterLink} to="/contact"><Button size="md" variant="outline">Contact</Button></Link>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
        </>
    )
}
