import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React from "react";
import Sheet from "react-modal-sheet";
import { RemoveScroll } from "react-remove-scroll";
import useViewport from "../Hooks/Viewport";
import DbWorkorderType from "./DbWorkorderType";
import WorkorderContent from "./WorkorderContent";

interface WorkorderContentViewProps {
    isOpen: boolean,
    onClose: () => void,
    workorder: DbWorkorderType,
    updateState?: Function,
    guestView?: boolean,
    cancelWorkorderCB:Function // Calls this when workorder is cancelled from the calendar
}

export default function WorkorderContentView({isOpen, onClose, workorder, updateState, guestView, cancelWorkorderCB}:WorkorderContentViewProps){

    const { width } = useViewport();
    const mdBreakpoint = 480;

    return (
        <>
        {isOpen && width > mdBreakpoint
        ? <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" isCentered size={"xl"}>
            <ModalOverlay />
            <ModalContent overflow="hidden" pos="relative">
                <WorkorderContent guestView={guestView} workorderInput={workorder} onClose={onClose} updateState={updateState} cancelWorkorderCB={cancelWorkorderCB} />
            </ModalContent>
        </Modal>
        : <Sheet rootId="root" isOpen={isOpen} onClose={onClose}>
            <RemoveScroll>
            <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content style={{position:"relative"}}>
                    <WorkorderContent guestView={guestView} workorderInput={workorder} onClose={onClose} updateState={updateState} cancelWorkorderCB={cancelWorkorderCB} />
                </Sheet.Content>
            </Sheet.Container>
            </RemoveScroll>
            <Sheet.Backdrop onTap={onClose} style={{touchAction:"none"}} />
        </Sheet>
    }
        </>
    )
}